import { css } from "@emotion/react";
import { EditableChannelTypeSchema } from "@zeffiroso/cantata/models";
import { memo } from "@zeffiroso/utils/react/memo";
import type { TableColumnProps } from "antd";
import { useMemo } from "react";
import type { z } from "zod";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { Trans } from "@/app/i18n/Trans";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { Alert } from "@/components/Alert";
import { NarrowIconButton } from "@/components/Button/NarrowIconButton";
import { BarLoading } from "@/components/Loading/BarLoading";
import { MotifIcon } from "@/components/MotifIcon";
import { Table } from "@/components/Table";
import { MainLayout } from "@/layout/MainLayout";
import { ChannelTypeIcon } from "@/resources/channel/ChannelTypeIcon";
import { ConnectionStatus } from "@/resources/channel/ConnectionStatus";
import { useSortChannels } from "@/resources/channel/useSortChannels";
import { compileToString } from "@/router/utils/compileTo";
import { AddChannel } from "@/routes/Settings/Channels/pages/List/addChannel";
import { GoToAdminCenter } from "@/routes/Settings/Channels/pages/List/GoToAdminCenter";
import { cssFlexInheritAndFill } from "@/shared/emotion";

const cssWrapper = css`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding: 8px 12px;
`;

const cssActionsWrapper = css`
  ${cssFlexInheritAndFill};
  justify-content: flex-end;
  gap: 8px;
`;

const isChannelEditable = (
  channelType: CantataTypes["Channel"]["type"],
): channelType is z.infer<typeof EditableChannelTypeSchema> => {
  return EditableChannelTypeSchema.options.includes(channelType);
};

const EditLinkButton = memo(function ({
  channelId,
}: {
  channelId: CantataTypes["Channel"]["id"];
}) {
  const to = useMemo(
    () =>
      compileToString({
        pathname: "/settings/channels/edit/:channelId",
        params: {
          channelId,
        },
      }),
    [channelId],
  );
  return (
    <NarrowIconButton
      icon={<MotifIcon un-i-motif="edit" />}
      iconSize="small"
      size="middle"
      to={to}
    />
  );
});

const Channels = memo(function Channels() {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const query = cantata.channel.useList({
    params: {
      orgId,
    },
  });

  const columns = useMemo<TableColumnProps<CantataTypes["Channel"]>[]>(
    () => [
      {
        key: "name",
        title: <Trans i18nKey="organization.channels.headers.channel" />,
        width: "33%",
        render: (_, channel) => {
          return (
            <div
              css={css`
                display: flex;
                align-items: center;
                gap: 10px;
              `}
            >
              <ChannelTypeIcon
                channelType={channel.type}
                css={css`
                  font-size: 1.5rem;
                `}
              />
              <span>{channel.name}</span>
            </div>
          );
        },
      },
      {
        key: "status",
        title: <Trans i18nKey="organization.channels.headers.status" />,
        width: "33%",
        render: (_, channel) => {
          return <ConnectionStatus status={channel.status} />;
        },
      },
      {
        key: "actions",
        width: "33%",
        render: (_, channel) => (
          <div css={cssActionsWrapper}>
            {!isChannelEditable(channel.type) ? (
              <GoToAdminCenter type={channel.type} uuid={channel.uuid} />
            ) : (
              <EditLinkButton channelId={channel.id} />
            )}
          </div>
        ),
      },
    ],
    [],
  );

  const sortChannels = useSortChannels();
  const sortedChannels = useMemo(
    function getSortedChannels() {
      return sortChannels(query.data?.channels ?? []);
    },
    [query.data?.channels, sortChannels],
  );
  if (query.isLoading) {
    return (
      <BarLoading wrapperStyle={{ position: "relative", minHeight: 150 }} />
    );
  }

  if (query.isError)
    return <Alert type="error" message={query.error.message} />;

  return (
    <MainLayout.Section>
      <div css={cssWrapper}>
        <AddChannel />
      </div>
      <Table
        rowKey="id"
        columns={columns}
        dataSource={sortedChannels}
        loading={query.isLoading}
        pagination={false}
      />
    </MainLayout.Section>
  );
});

export { Channels as List };
