import { isSupported } from "firebase/messaging";
import PQueue from "p-queue";

import { logError } from "@/shared/application/logger/sentry";

const swPath = import.meta.env.PROD ? `/sw.js` : "/dev-sw.js?dev-sw";

let cache: Awaited<ReturnType<typeof navigator.serviceWorker.getRegistration>>;
const queue = new PQueue({ concurrency: 1 });

/**
 * Disable logging because there is no actions for this.
 */
const logGetServiceWorkerRegistrationFailure: boolean = false;

/**
 * To get the service worker registration, we need to wait for the service
 * worker to be ready. It will return `undefined` if it's not supported by the
 * browser.
 */
const getServiceWorkerRegistration = () =>
  queue.add(
    async function getServiceWorkerRegistration() {
      if (!(await isSupported())) return;
      if (cache) return cache;
      cache = await navigator.serviceWorker.getRegistration(swPath);
      if (!cache) {
        if (!logGetServiceWorkerRegistrationFailure) return;
        const errorMessage = `Service worker is not registered at ${swPath}`;
        logError(errorMessage);
        throw new Error(errorMessage);
      }
      return cache;
    },
    {
      throwOnTimeout: true,
    },
  );

export { getServiceWorkerRegistration };
