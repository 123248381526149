import { createContext } from "@chatbotgang/etude/react/createContext";
import { fc } from "@chatbotgang/etude/react/fc";
import { define } from "@chatbotgang/etude/util/define";
import type {
  SetupInfiniteLoadControllerOptions,
  StateBase,
} from "@zeffiroso/utils/react-lib/useSetupInfiniteLoadController";
import { useSetupInfiniteLoadController } from "@zeffiroso/utils/react-lib/useSetupInfiniteLoadController";
import { mapValues, omit } from "lodash-es";
import { type ReactNode, useCallback, useMemo } from "react";

import { cantataClient } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { API_DEFAULT_LIMIT } from "@/env";
import { mergeDataFn } from "@/resources/message/utils";

type Response = Awaited<ReturnType<typeof cantataClient.message.list>>;
type Item = Response["messages"][number];
type Options = SetupInfiniteLoadControllerOptions<Response, Item>;
type State = StateBase<Item>;

const Context = createContext<
  ReturnType<typeof useSetupInfiniteLoadController<Response, Item>>
>({
  name: "Messages",
});

const useMessagesController = Context.useContext;
const MessagesProvider = fc<{ children: ReactNode }>(function MessagesProvider({
  children,
}) {
  const messagesController = useSetupInfiniteLoadController<Response, Item>();
  return (
    <Context.Provider value={messagesController}>{children}</Context.Provider>
  );
});

const getData: Options["getData"] = function getData(response) {
  return response.messages;
};

const getNextCursor: Options["getNextCursor"] = function getNextCursor(
  response,
) {
  return response.cursor.after ?? undefined;
};

const getPreviousCursor: Options["getPreviousCursor"] =
  function getPreviousCursor(response) {
    return response.cursor.before ?? undefined;
  };

function useSetup({
  initialData,
  previousCursor,
  nextCursor,
  orgId,
  memberId,
  limit = API_DEFAULT_LIMIT,
}: {
  orgId: CantataTypes["Org"]["id"];
  memberId: CantataTypes["Member"]["id"];
  limit?: number;
} & Pick<Options, "initialData" | "previousCursor" | "nextCursor">) {
  const controller = useMessagesController();
  const fetch = useCallback<Options["fetch"]>(
    async function fetch({ cursor, signal }) {
      return cantataClient.message.list({
        params: {
          orgId,
          memberId,
        },
        queries: {
          cursor,
          limit,
        },
        signal,
      });
    },
    [limit, memberId, orgId],
  );
  const mergedOptions: Options = useMemo(
    function computeOptions() {
      return {
        /**
         * Load feature messages in previous direction first
         */
        priorityDirection: "previous",
        initialData,
        previousCursor,
        nextCursor,
        fetch,
        getData,
        getNextCursor,
        getPreviousCursor,
        mergeDataFn,
      };
    },
    [fetch, initialData, nextCursor, previousCursor],
  );
  controller.useSetup(mergedOptions);
}

function useController() {
  const controller = useMessagesController();
  const ret = useMemo(() => {
    const rest = omit(controller, ["useSetup"]);
    function addMessages(next: Item[]) {
      const current = controller.useStore.getState().data;
      controller.useStore.setState({
        data: mergeDataFn({
          current,
          next,
        }),
      });
    }
    const selectors = define<Record<string, (state: State) => any>>()({
      /**
       * Previous is future.
       */
      canTryPrevious: (state) =>
        Boolean(state.previousCursor) || state.firstFetch,
      canTryNext: (state) => Boolean(state.nextCursor),
      isLoading: (state) => state.isPreviousLoading || state.isNextLoading,
    });
    const computedValues = mapValues(
      selectors,
      (selector) => () => selector(controller.useStore.getState()),
    );
    return {
      ...rest,
      addMessages,
      selectors,
      computedValues,
    };
  }, [controller]);
  return ret;
}

const messages = {
  Provider: MessagesProvider,
  useController,
  useSetup,
};

export { messages };
