import { initializeApp } from "firebase/app";

const sharedFirebaseApp = initializeApp(
  {
    apiKey: window.env.VITE_FIREBASE_SHARED_API_KEY,
    appId: window.env.VITE_FIREBASE_SHARED_APP_ID,
    authDomain: window.env.VITE_FIREBASE_SHARED_AUTH_DOMAIN,
    projectId: window.env.VITE_FIREBASE_SHARED_PROJECT_ID,
  },
  "shared",
);

export { sharedFirebaseApp };
