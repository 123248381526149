/// <reference lib="webworker" />

import { createUseOn } from "@zeffiroso/utils/react/createUseOn";
import EventEmitter from "eventemitter3";
import { objectKeys } from "tsafe";
import { z } from "zod";

import { eventsSchema } from "./events";
import { Sw2ClientZodError } from "./Sw2ClientZodError";

declare const self: ServiceWorkerGlobalScope;

/**
 * Emit an event to the clients. Notice that this function is used in the
 * service worker.
 */
async function emit2Client<EventKey extends keyof typeof eventsSchema>(
  client: WindowClient | WindowClient[] | "all",
  eventKey: EventKey,
  ...args: z.input<(typeof eventsSchema)[EventKey]> extends void
    ? []
    : [body: z.input<(typeof eventsSchema)[EventKey]>]
): Promise<void> {
  const clients =
    client === "all"
      ? await self.clients.matchAll({
          type: "window",
          includeUncontrolled: false,
        })
      : Array.isArray(client)
        ? client
        : [client];
  clients.forEach((client) => {
    client.postMessage({
      type: eventKey,
      ...(args.length === 0
        ? {}
        : {
            body: args[0],
          }),
    });
  });
}

/**
 * Listen to service worker
 */
function createEmitter(
  options: {
    onError?: (error: Sw2ClientZodError) => void;
  } = {},
) {
  type Events = typeof eventsSchema;
  const emitter = new EventEmitter<{
    [K in keyof Events]: (data: z.infer<Events[K]>) => void;
  }>();
  const outerSchema = z.object({
    type: z.enum(
      objectKeys(eventsSchema) as [
        keyof typeof eventsSchema,
        ...(keyof typeof eventsSchema)[],
      ],
    ),
    body: z.unknown(),
  });
  if ("navigator" in window && "serviceWorker" in navigator) {
    navigator.serviceWorker.addEventListener("message", async (e) => {
      const safeParsedOuter = outerSchema.safeParse(e.data);
      if (!safeParsedOuter.success) return;
      const { type, body } = safeParsedOuter.data;
      const safeParsedInner = eventsSchema[type].safeParse(body);
      if (!safeParsedInner.success) {
        options.onError?.(
          new Sw2ClientZodError({
            cause: safeParsedInner.error,
            data: e.data,
          }),
        );
        return;
      }
      emitter.emit(type, safeParsedInner.data as any);
    });
  }
  const useSwMessage = createUseOn(emitter);
  return {
    emitter,
    useSwMessage,
  };
}

export { createEmitter, emit2Client };
