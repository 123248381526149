import type { Action, FlexBubble } from "@line/bot-sdk";
import { noop } from "lodash-es";
import type { CSSProperties } from "react";

import { FlexBoxComponent } from "@/components/LINEFlexMessage/box";
import { useProps } from "@/components/LINEFlexMessage/context";
import { FlexImageComponent } from "@/components/LINEFlexMessage/image";
import { classNames, StyleProvider } from "@/components/LINEFlexMessage/styled";
import { isFlexBox, isURIAction } from "@/components/LINEFlexMessage/types";

type BubbleProps = { index: number; bubble: FlexBubble };

type FlexAction =
  | Action<{
      label: string;
    }>
  | undefined;

const useAction = (action: FlexAction): (() => void) => {
  let text = "";
  let clickCallback = noop;

  if (isURIAction(action)) {
    text = action.uri;
    clickCallback = () => {
      if (action.type === "uri") window.open(text);
    };
  }
  return clickCallback;
};

const footerClassName = (bubble: FlexBubble) => {
  return {
    hasFooter: bubble.footer !== undefined,
  };
};

export const Bubble = ({ index, bubble }: BubbleProps): React.ReactNode => {
  const { rowIndex } = useProps();
  const { header, hero, body, footer, size, direction, styles, action } =
    bubble;
  const onBubbleClick = useAction(action);
  const bubbleStyle: CSSProperties = {
    cursor: action?.type === "uri" ? "pointer" : "default",
  };

  return (
    <div key={`FlexPreview${rowIndex}-${index}`}>
      <StyleProvider>
        <div
          className={classNames(size ?? "mega")}
          onClick={onBubbleClick}
          style={bubbleStyle}
        >
          <div className={classNames("T1", direction ?? "ltr")}>
            {header && (
              <div
                className={classNames("header")}
                style={{
                  backgroundColor: styles?.header?.backgroundColor,
                }}
              >
                <FlexBoxComponent data={header} />
              </div>
            )}
            {hero && (
              <div
                className="t1Hero"
                style={{
                  backgroundColor: styles?.hero?.backgroundColor,
                }}
              >
                {isFlexBox(hero) && <FlexBoxComponent data={hero} />}
                {hero.type === "image" && <FlexImageComponent data={hero} />}
              </div>
            )}
            {body && (
              <div
                className={classNames("body", footerClassName(bubble))}
                style={{
                  backgroundColor: styles?.body?.backgroundColor,
                }}
              >
                <FlexBoxComponent data={body} />
              </div>
            )}
            {footer && (
              <div
                className={classNames("footer")}
                style={{
                  backgroundColor: styles?.footer?.backgroundColor,
                }}
              >
                <FlexBoxComponent data={footer} />
              </div>
            )}
          </div>
        </div>
      </StyleProvider>
    </div>
  );
};
