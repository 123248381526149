import type { SVGProps } from "react";

import type { MotifIcon } from "@/components/MotifIcon";

/**
 * @deprecated Use `MotifIcon` instead.
 * @see {@link MotifIcon}
 */
export function Empty(props: SVGProps<SVGSVGElement>): React.ReactNode {
  return (
    <svg
      width={80}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M37.589 63.685 74.127 52.18a.675.675 0 0 0 .345-.24.695.695 0 0 0-.31-1.067l-8.66-3.264-27.913 16.076Z"
        fill="#E3EEFF"
      />
      <path
        d="m65.392 51.63-27.68 11.985L9.855 51.63l27.678-17.583 27.86 17.583Z"
        fill="#fff"
      />
      <path
        d="M37.714 63.926a.292.292 0 0 1-.12-.025L9.736 51.922a.306.306 0 0 1-.185-.262.316.316 0 0 1 .143-.288l27.68-17.588a.3.3 0 0 1 .321 0l27.86 17.582a.308.308 0 0 1 .144.288.314.314 0 0 1-.185.262l-27.68 11.985a.315.315 0 0 1-.12.025ZM10.515 51.577l27.199 11.7L64.73 51.578 37.534 34.414 10.515 51.577Z"
        fill="#3B6BEF"
      />
      <path
        d="M65.328 51.677 37.714 63.615 9.854 51.63l27.68-17.583 27.794 17.629Z"
        fill="#fff"
      />
      <path
        d="M37.714 63.926a.294.294 0 0 1-.12-.025L9.736 51.922a.306.306 0 0 1-.185-.262.316.316 0 0 1 .143-.288l27.68-17.589a.3.3 0 0 1 .322 0l27.795 17.63a.308.308 0 0 1 .143.288.314.314 0 0 1-.186.262L37.833 63.901a.32.32 0 0 1-.12.025ZM10.515 51.577l27.199 11.7 26.953-11.65-27.133-17.213-27.019 17.163Z"
        fill="#3B6BEF"
      />
      <path
        d="m9.942 22.83 27.624-9.746L37.532 39 9.854 51.63l.088-28.8Z"
        fill="#fff"
      />
      <path
        d="M9.856 51.942a.3.3 0 0 1-.216-.091.31.31 0 0 1-.09-.22l.088-28.801a.316.316 0 0 1 .206-.295l27.622-9.744a.301.301 0 0 1 .373.152.316.316 0 0 1 .033.143L37.84 39a.314.314 0 0 1-.18.283L9.978 51.915a.307.307 0 0 1-.123.027Zm.39-28.887-.085 28.097L37.228 38.8l.033-25.277-27.014 9.532Z"
        fill="#3B6BEF"
      />
      <path
        d="m37.47 13.059 27.859 9.772-.088 28.8-27.86-14.001.089-24.571Z"
        fill="#fff"
      />
      <path
        d="M65.24 51.942a.305.305 0 0 1-.135-.033l-27.86-14a.307.307 0 0 1-.17-.28l.089-24.571a.316.316 0 0 1 .127-.254.302.302 0 0 1 .278-.04l27.855 9.772a.316.316 0 0 1 .206.295l-.084 28.796a.314.314 0 0 1-.305.315ZM37.689 37.437l27.248 13.694.087-28.083-27.25-9.553-.086 23.942Z"
        fill="#3B6BEF"
      />
      <path
        d="m37.801 34.815-.088 28.8L9.853 51.63l.089-28.8 27.86 11.984Z"
        fill="#BAD3FF"
      />
      <path
        d="M37.713 63.926a.292.292 0 0 1-.119-.024l-27.86-11.98a.307.307 0 0 1-.186-.286l.088-28.801a.317.317 0 0 1 .138-.259.303.303 0 0 1 .287-.027l27.86 11.98a.306.306 0 0 1 .136.114c.033.051.05.112.05.173l-.088 28.8a.315.315 0 0 1-.09.219.304.304 0 0 1-.216.091Zm-27.553-12.5 27.249 11.72.086-28.126L10.247 23.3l-.087 28.126Z"
        fill="#3B6BEF"
      />
      <path
        d="m37.801 34.815-.089 28.8L9.854 51.63l.087-28.8 27.86 11.984Z"
        fill="#BAD3FF"
      />
      <path
        d="M37.713 63.926a.293.293 0 0 1-.119-.025L9.734 51.922a.308.308 0 0 1-.186-.286l.088-28.801a.317.317 0 0 1 .138-.259.303.303 0 0 1 .287-.027l27.86 11.979a.307.307 0 0 1 .187.288l-.09 28.8a.315.315 0 0 1-.09.219.304.304 0 0 1-.215.09Zm-27.553-12.5 27.249 11.72.086-28.126L10.247 23.3l-.087 28.125Z"
        fill="#3B6BEF"
      />
      <path
        d="M37.677 34.884 65.357 22.9l-.089 28.8-27.68 11.985.09-28.8Z"
        fill="#3B6BEF"
      />
      <path
        d="m37.382 64 .09-29.26 28.093-12.164-.09 29.26L37.382 64Zm.502-28.98-.087 28.343 27.265-11.806.087-28.34L37.884 35.02Z"
        fill="#3B6BEF"
      />
      <path
        d="M37.679 34.884 65.359 22.9l-.09 28.8-27.68 11.985.09-28.8Z"
        fill="#3B6BEF"
      />
      <path
        d="m37.382 64 .09-29.26 28.093-12.164-.09 29.26L37.382 64Zm.503-28.98-.087 28.343 27.265-11.806.086-28.34L37.885 35.02Z"
        fill="#3B6BEF"
      />
      <path
        d="m57.956 8.344 3.028.884s-.379 2.86-3.72 4.097l-2.509-1.473s2.974-.875 3.201-3.508ZM59.096 17.088l3.416 1.263s-1.35 3.347-4.839 4.636l-2.613-1.53s3.799-1.629 4.036-4.37Z"
        fill="#E3EEFF"
      />
      <path
        d="M79.084 15.13a.16.16 0 0 1-.114-.048.165.165 0 0 1-.047-.116v-1.513a.165.165 0 0 1 .051-.108.16.16 0 0 1 .27.108v1.513a.166.166 0 0 1-.046.116.158.158 0 0 1-.114.048Z"
        fill="#6CA0FF"
      />
      <path
        d="M79.826 14.373H78.34a.16.16 0 0 1-.106-.052.166.166 0 0 1 0-.223.16.16 0 0 1 .106-.052h1.486a.16.16 0 0 1 .16.098.166.166 0 0 1-.038.186.16.16 0 0 1-.122.043ZM69.748 16.641a.858.858 0 0 1-.483-.149.88.88 0 0 1-.32-.396.899.899 0 0 1 .189-.963.864.864 0 0 1 .946-.192c.159.067.294.18.39.325a.895.895 0 0 1-.108 1.116.86.86 0 0 1-.614.26Zm0-1.445a.542.542 0 0 0-.507.345.568.568 0 0 0 .119.609.547.547 0 0 0 .598.12.566.566 0 0 0 .177-.909.544.544 0 0 0-.387-.161v-.004ZM13.463 1.856a.158.158 0 0 1-.15-.1.166.166 0 0 1-.011-.063V.177a.167.167 0 0 1 .042-.124.162.162 0 0 1 .118-.053.16.16 0 0 1 .152.11c.008.021.01.044.009.067V1.69a.167.167 0 0 1-.046.117.16.16 0 0 1-.114.049Z"
        fill="#6CA0FF"
      />
      <path
        d="M14.206 1.1H12.72a.159.159 0 0 1-.122-.043.164.164 0 0 1-.039-.186.164.164 0 0 1 .095-.09.159.159 0 0 1 .066-.008h1.486a.159.159 0 0 1 .122.043.164.164 0 0 1 .038.186.164.164 0 0 1-.095.09.159.159 0 0 1-.065.008ZM19.963 9.793a.857.857 0 0 1-.483-.149.88.88 0 0 1-.32-.396.898.898 0 0 1 .189-.963.863.863 0 0 1 .946-.192c.159.067.294.18.39.326a.895.895 0 0 1-.108 1.115.86.86 0 0 1-.614.26Zm0-1.445a.543.543 0 0 0-.508.345.57.57 0 0 0 .119.61.547.547 0 0 0 .6.122.566.566 0 0 0 .178-.913.546.546 0 0 0-.39-.164Z"
        fill="#6CA0FF"
      />
      <path
        d="M48.194 30.337s-.75-1.225-6.073-.503c-5.323.723-3.762 2.062-6.625 1.375-4.347-1.054-6.533-.305-6.533-.305l8.84 3.915 10.391-4.482Z"
        fill="#E3EEFF"
      />
      <path
        d="m37.678 34.884 9.999 13.82 27.048-13.688L65.357 22.9l-27.68 11.984Z"
        fill="#E3EEFF"
      />
      <path
        d="M47.673 49.014a.3.3 0 0 1-.246-.127L37.43 35.07a.312.312 0 0 1 .127-.47l27.68-11.985a.302.302 0 0 1 .36.098l9.367 12.117a.313.313 0 0 1-.105.47L47.813 48.982a.292.292 0 0 1-.14.032Zm-9.52-13.997 9.616 13.291 26.482-13.4-8.99-11.628-27.109 11.737Z"
        fill="#3B6BEF"
      />
      <path
        d="M12.933 24.04v28.554l6.314 2.689V26.729l-6.314-2.689ZM21.11 27.524v28.554l3.726 1.587V29.111l-3.726-1.587Z"
        fill="#BAD3FF"
        style={{
          mixBlendMode: "soft-light",
        }}
      />
      <path
        d="m37.678 34.885-9.56 12.574L.305 34.395 9.942 22.83l27.736 12.054Z"
        fill="#E3EEFF"
      />
      <path
        d="M28.117 47.77a.297.297 0 0 1-.128-.028L.177 34.677a.306.306 0 0 1-.17-.215.317.317 0 0 1 .065-.269L9.71 22.63a.304.304 0 0 1 .353-.085L37.797 34.6a.303.303 0 0 1 .176.207.316.316 0 0 1-.054.27l-9.56 12.573a.303.303 0 0 1-.242.121ZM.798 34.284l27.227 12.789 9.164-12.06-27.16-11.806L.8 34.284Z"
        fill="#3B6BEF"
      />
    </svg>
  );
}
