import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { css } from "@emotion/react";
import type { Overwrite } from "@mui/types";
import { theme } from "@zeffiroso/theme";
import type { ElementRef } from "react";
import { useTranslation } from "react-i18next";

import { Trans } from "@/app/i18n/Trans";
import { ExternalLink } from "@/components/ExternalLink";
import { PreviewBlock } from "@/components/PreviewBlock";
import { TooltipInfo } from "@/components/TooltipInfo";
import enDemo from "@/routes/Settings/ChatSettings/Invitation/images/en/demo.png";
import jpDemo from "@/routes/Settings/ChatSettings/Invitation/images/ja/demo.png";
import thDemo from "@/routes/Settings/ChatSettings/Invitation/images/th/demo.png";
import zhDemo from "@/routes/Settings/ChatSettings/Invitation/images/zh/demo.png";
import { InvitationTagsSetting } from "@/routes/Settings/ChatSettings/Invitation/tags";
import { defineStyles } from "@/shared/emotion";
import type { AvailableLanguage } from "@/shared/hooks/formatLangCode";
import { useLanguage } from "@/shared/hooks/useLanguage";

const demoImageLangMap = {
  "zh-hant": zhDemo,
  en: enDemo,
  ja: jpDemo,
  th: thDemo,
} satisfies Record<AvailableLanguage, string>;

const styles = defineStyles({
  InvitationSetting: css({
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    maxWidth: 776,
  }),
  items: css({
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  }),
  title: css({
    marginBottom: 0,
    color: theme.colors.neutral010,
    fontSize: "1.125rem",
    fontWeight: 700,
  }),
  fgTitle: css({
    color: theme.colors.neutral010,
    fontSize: "1rem",
    fontWeight: 500,
    display: "flex",
    gap: "0.25em",
  }),
  note: css({
    fontSize: "0.875rem",
    color: theme.colors.neutral007,
  }),
  body: css({
    fontSize: "0.875rem",
    color: theme.colors.neutral009,
  }),
  center: css({
    textAlign: "center",
  }),
  tooltip: css({
    maxWidth: "300px",
  }),
  tooltipIcon: css({
    color: theme.colors.neutral007,
  }),
});

namespace InvitationSetting {
  export type Ref = ElementRef<"div">;
  export interface OwnProps {
    // props here
  }
  export type Props = Overwrite<ComponentProps<"div">, OwnProps>;
}

const InvitationSettingInternal = forwardRef<
  InvitationSetting.Ref,
  InvitationSetting.Props
>(function InvitationSettingInternal(props, ref) {
  const { t } = useTranslation();
  const language = useLanguage();
  return (
    <div css={styles.InvitationSetting} ref={ref} {...props}>
      <div css={styles.items}>
        <h2 css={styles.title}>
          <Trans i18nKey="settings.conversation.invitation.title" />
        </h2>
        <div css={styles.note}>
          <Trans i18nKey="settings.conversation.invitation.note" />
        </div>
      </div>
      <div css={styles.items}>
        <div css={styles.fgTitle}>
          <Trans i18nKey="settings.conversation.invitation.tags.title" />
        </div>
        <InvitationTagsSetting />
      </div>
      <div>
        <div css={styles.items}>
          <div css={styles.fgTitle}>
            <Trans i18nKey="settings.conversation.invitation.message.title" />
            <TooltipInfo
              css={styles.tooltipIcon}
              title={
                <div css={css([styles.items, styles.tooltip])}>
                  <Trans i18nKey="settings.conversation.invitation.message.desc" />
                  <div css={styles.center}>
                    <img
                      src={demoImageLangMap[language]}
                      alt="demo"
                      width="260px"
                    />
                  </div>
                  <div>
                    <ExternalLink
                      href={t("settings.conversation.invitation.message.link")}
                      trailingIcon
                    >
                      {t("common.learnMore")}
                    </ExternalLink>
                  </div>
                </div>
              }
            />
          </div>
          <PreviewBlock>
            <Trans
              i18nKey="settings.conversation.invitation.message.content"
              components={{
                tag: <PreviewBlock.Tag />,
              }}
            />
          </PreviewBlock>
        </div>
      </div>
    </div>
  );
});

/**
 * Invitation Setting for LINE channels
 */
const InvitationSetting = Object.assign(InvitationSettingInternal, {
  styles,
});

assignDisplayName(InvitationSetting, "InvitationSetting");

export { InvitationSetting };
