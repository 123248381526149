import { MutationCache, QueryCache, QueryClient } from "@tanstack/react-query";
import { isAbortError } from "@zeffiroso/utils/vanilla/isAbortError";
import { ZodiosError } from "@zodios/core";
import { AxiosError, CanceledError } from "axios";
import { minutesToMilliseconds } from "date-fns";

import { DEFAULT_QUERY_RETRY_MAX_COUNT } from "@/env";
import { UnAuthError } from "@/lib/httpClient";
import { mutationErrorEventEmitter } from "@/lib/react-query/mutationErrorEventEmitter";
import { logError } from "@/shared/application/logger/sentry";

const queryCache = new QueryCache({
  onError(error) {
    if (isAbortError(error)) return;
    if (error instanceof CanceledError) return;
    if (error instanceof ZodiosError) logError(error);
  },
});

const mutationCache = new MutationCache({
  onError(error) {
    if (isAbortError(error)) return;
    if (error instanceof CanceledError) return;
    // Common error handling for all mutations.
    mutationErrorEventEmitter.emit("error", error);
  },
});

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: minutesToMilliseconds(1),
      retry: (failureCount, error) => {
        if (error instanceof CanceledError) return false;
        if (error instanceof ZodiosError) return false;
        /**
         * Always retry when un-authenticated to prevent clear cache.
         */
        if (error instanceof UnAuthError) return true;
        if (error instanceof AxiosError) {
          const isNetworkError = error.code === "ERR_NETWORK";
          const isTimeoutError = error.code === "ECONNABORTED";
          // Do not throw error immediately when network error, it may be
          // temporary.
          if (isNetworkError || isTimeoutError) {
            return failureCount < DEFAULT_QUERY_RETRY_MAX_COUNT;
          }
          if (!error.response || !error.request) return false;
          const is4xxError =
            error.response.status >= 400 && error.response.status < 500;
          const is401Error = error.response.status === 401;
          /**
           * Always retry when un-authenticated to prevent clear cache.
           */
          if (is401Error) return true;
          if (is4xxError) return false;
        }
        return failureCount < DEFAULT_QUERY_RETRY_MAX_COUNT;
      },
    },
    mutations: {
      retry: () => false,
    },
  },
  queryCache,
  mutationCache,
});
