import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { AxiosError, CanceledError } from "axios";
import { type ReactNode, useCallback } from "react";

import { useMessage } from "@/components/message";
import { mutationErrorEventEmitter } from "@/lib/react-query/mutationErrorEventEmitter";
import type { MutationErrorEmitter } from "@/lib/react-query/useMutationError";
import { logError } from "@/shared/application/logger/sentry";
import {
  apiErrorNameTransUtilsMap,
  getApiErrorBodyFromUnknownError,
} from "@/shared/domains/error";
import { argsT } from "@/shared/g11n/argsT";

const t = argsT;

/**
 * Handles application errors or logs them to Sentry. It will return a
 * translation key by different types of error. If the error is not an exact
 * error, or we don't want to handle it globally, it will return `null`.
 *
 * @example
 *
 * ```ts
 * const errorMessageTransUtils = handleError(error);
 * message.error(errorMessageTransUtils.node);
 * ```
 */
function handleError<T = Error>(
  /**
   * The error to handle.
   */
  error: T,
): ReturnType<typeof t> | null {
  /**
   * Cancel error is not an error, it's just a signal to cancel the request.
   */
  if (error instanceof CanceledError) return null;
  const errorApiBody = getApiErrorBodyFromUnknownError(error);
  if (errorApiBody) {
    mutationErrorEventEmitter.emit("knownError", errorApiBody);
    return apiErrorNameTransUtilsMap[errorApiBody.name] ?? null;
  }
  logError(error);
  return t("common.applicationError.unknownError");
}

type UseGetErrorMessageOptions = {
  unknownErrorMessage?: ReactNode;
};

/**
 * Hook to get error message from any error including cancellation error and
 * non-global error.
 */
function useGetErrorMessage() {
  const getErrorMessageNode = useCallback<
    (e: unknown, options?: UseGetErrorMessageOptions) => React.ReactNode
  >(function getErrorMessageNode(e, options) {
    const errorApiBody = getApiErrorBodyFromUnknownError(e);
    const transUtils =
      (errorApiBody && errorApiBody?.name !== "UNKNOWN_ERROR"
        ? apiErrorNameTransUtilsMap[errorApiBody.name]
        : apiErrorNameTransUtilsMap.UNKNOWN_ERROR) ??
      apiErrorNameTransUtilsMap.UNKNOWN_ERROR;
    const mainErrorMessage =
      transUtils === apiErrorNameTransUtilsMap.UNKNOWN_ERROR
        ? options?.unknownErrorMessage ??
          apiErrorNameTransUtilsMap.UNKNOWN_ERROR.node
        : transUtils.node;
    if (!mainErrorMessage) return mainErrorMessage;
    return (
      <>
        {mainErrorMessage}
        {transUtils !== apiErrorNameTransUtilsMap.UNKNOWN_ERROR
          ? null
          : `: ${
              e instanceof Error
                ? `[${e.name}]: ${e.message}`
                : inspectMessage`Non-error object thrown: ${e}`
            }`}
      </>
    );
  }, []);
  /**
   * Get error message from any error including cancellation error and
   * non-global error.
   */
  return getErrorMessageNode;
}

/**
 * Shorthand for handling errors and showing error message.
 *
 * @example
 *
 * ```ts
 * const handleErrorAndShowMessage = useHandleErrorAndShowMessage();
 * const mutation = useMutation(...);
 *
 * const onFinish = useHandler<FormProps<FieldValues>(async onFinish (values) => {
 *   const result = await safePromise(() =>mutation.mutateAsync(values));
 *   if (result.error) {
 *     handleErrorAndShowMessage(result.error);
 *   return;
 * }
 * ```
 *
 * The code is currently set up to handle mutation errors globally.
 * It is recommended to avoid using this in components, and instead use
 * useMutation, which will always handle common errors appropriately.
 *
 * This will be moved to `<MutationErrorEmitter />` in the future.
 *
 * @see {@link MutationErrorEmitter}
 */
function useHandleErrorAndShowMessage() {
  const message = useMessage();
  const getErrorMessage = useGetErrorMessage();
  const handleErrorAndShowMessage = useHandler(
    function handleErrorAndShowMessage<E = Error>(err: E) {
      const errorMessageTranslationKey = handleError(err);
      if (!errorMessageTranslationKey) return;
      const is4xxError = (() => {
        if (!(err instanceof AxiosError)) return false;
        const response = err.response;
        if (!response) return false;
        return response.status >= 400 && response.status < 500;
      })();
      message[is4xxError ? "warning" : "error"](getErrorMessage(err));
    },
  );
  return handleErrorAndShowMessage;
}

export { handleError, useGetErrorMessage, useHandleErrorAndShowMessage };
