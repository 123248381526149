import { makeApi } from "@zodios/core";
import { z } from "zod";

import { OrgSchema, TagSchema } from "../models";

const api = makeApi([
  {
    /**
     * Get the unification tags for an organization.
     * Spec: [Notion](https://www.notion.so/cresclab/Redirect-unification-19f8ce15893880eb8c7afa4a442c6e93?pvs=4#1af8ce158938807296e4c11b2133ae30)
     */
    alias: "getUnificationTags",
    method: "get",
    path: "/api/v1/orgs/:orgId/organization/unification-setting/tags",
    parameters: [
      {
        type: "Path",
        name: "orgId",
        schema: OrgSchema.shape.id,
      },
    ],
    response: z.object({
      tagIds: TagSchema.shape.id.array(),
    }),
  },
  {
    /**
     * Update the unification tags for an organization.
     * [Spec](https://www.notion.so/cresclab/Redirect-unification-19f8ce15893880eb8c7afa4a442c6e93?pvs=4#97042e1075064487b37b3e9f8dc5b3dc)
     */
    alias: "updateUnificationTags",
    method: "put",
    path: "/api/v1/orgs/:orgId/organization/unification-setting/tags",
    parameters: [
      {
        type: "Path",
        name: "orgId",
        schema: OrgSchema.shape.id,
      },
      {
        type: "Body",
        name: "body",
        schema: z.object({
          tagIds: TagSchema.shape.id.array(),
        }),
      },
    ],
    response: z.unknown(),
  },
]);

export { api };
