import { Ymd } from "@zeffiroso/utils/date/Ymd";
import {
  eachDayOfInterval,
  eachMonthOfInterval,
  eachWeekOfInterval,
} from "date-fns";
import { flow } from "lodash-es";

namespace fillYmdRange {
  export interface CommonOptions<
    T extends object,
    TDateKey extends PropertyKey,
  > {
    dateKey: TDateKey;
    data?: Array<T & Record<TDateKey, Ymd>>;
    fillValue: T;
    start: Ymd;
    end: Ymd;
  }
}

const fillYmdRange = {
  /**
   * Return an array of objects filled with the specified fillValue for each day
   * from the start date to the end date.
   */
  byDay: <T extends object, TDateKey extends PropertyKey>({
    dateKey,
    data = [],
    fillValue,
    start,
    end,
    eachDayOfIntervalOptions,
  }: fillYmdRange.CommonOptions<T, TDateKey> & {
    eachDayOfIntervalOptions?: Parameters<typeof eachDayOfInterval>[1];
  }): Array<T & Record<TDateKey, Ymd>> => {
    /**
     * If the end date is before the start date, return an empty array.
     */
    if (end.isBefore(start)) return [];

    const filledArray: typeof data = flow(
      () =>
        eachDayOfInterval(
          {
            start: start.localStartOfDay,
            end: end.localEndOfDay,
          },
          eachDayOfIntervalOptions,
        ),
      (dates) =>
        dates.map(
          (date) =>
            ({
              ...fillValue,
              [dateKey]: new Ymd(date),
            }) as (typeof data)[number],
        ),
    )();

    return filledArray.map((filledItem) => {
      const existingItem = data.find((existingItem) =>
        filledItem[dateKey].isEqual(existingItem[dateKey]),
      );
      return existingItem || filledItem;
    });
  },

  /**
   * Fills an array with the specified fillValue for each week from the start date
   * to the end date.
   */
  byWeek: <T extends object, TDateKey extends PropertyKey>({
    dateKey,
    data = [],
    fillValue,
    start,
    end,
    eachWeekOfIntervalOptions,
  }: fillYmdRange.CommonOptions<T, TDateKey> & {
    eachWeekOfIntervalOptions?: Parameters<typeof eachWeekOfInterval>[1];
  }): Array<T & Record<TDateKey, Ymd>> => {
    if (end < start) return [];

    const filledArray: typeof data = flow(
      () => ({ start, end }),
      Ymd.ymdToDateDeep,
      (interval) => eachWeekOfInterval(interval, eachWeekOfIntervalOptions),
      Ymd.dateToYmdDeep,
      (dates) =>
        dates.map(
          (date) =>
            ({
              ...fillValue,
              [dateKey]: date,
            }) as (typeof data)[number],
        ),
    )();

    return filledArray.map((filledItem) => {
      const existingItem = data.find((existingItem) =>
        filledItem[dateKey].isEqual(existingItem[dateKey]),
      );
      return existingItem || filledItem;
    });
  },

  byMonth: <T extends object, TDateKey extends PropertyKey>({
    dateKey,
    data = [],
    fillValue,
    start,
    end,
  }: fillYmdRange.CommonOptions<T, TDateKey>): Array<
    T & Record<TDateKey, Ymd>
  > => {
    if (end < start) return [];

    const filledArray: typeof data = flow(
      () => ({ start, end }),
      Ymd.ymdToDateDeep,
      eachMonthOfInterval,
      Ymd.dateToYmdDeep,
      (dates) =>
        dates.map(
          (date) =>
            ({
              ...fillValue,
              [dateKey]: date,
            }) as (typeof data)[number],
        ),
    )();

    return filledArray.map((filledItem) => {
      const existingItem = data.find((existingItem) =>
        filledItem[dateKey].isEqual(existingItem[dateKey]),
      );
      return existingItem || filledItem;
    });
  },
};

export { fillYmdRange };
