import { LoadingOutlined } from "@ant-design/icons";
import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { random } from "@chatbotgang/etude/string/random";
import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import type { StringOrLiteral } from "@zeffiroso/utils/type/string";
import type { TooltipProps } from "antd";
import classNames from "classnames";
import { useMemo } from "react";

import { Tooltip } from "@/components/Tooltip";

import { Button } from ".";

const seed = random();
const cssVariablePrefix = `--narrow-icon-button-${seed}-` as const;
const cssVariableSize = `${cssVariablePrefix}size` as const;
const cssVariableIconSize = `${cssVariablePrefix}icon-size` as const;

const classNamePrefix = `narrow-icon-button-${seed}-` as const;
const classNameRecord = {
  root: `${classNamePrefix}root` as const,
} as const satisfies Record<string, string>;

type StandardSize = "small" | "middle" | "large";
type ValidSize = StringOrLiteral<StandardSize> | number;

namespace NarrowIconButton {
  export type Ref = Button.Ref;
  export type Props = Omit<Button.Props, "size" | "children"> & {
    size?: ValidSize;
    iconSize?: ValidSize;
    tooltipProps?: TooltipProps;
    wrapperSpanProps?: ComponentProps<"span">;
  };
  export interface Type {
    (props: Props, ref: React.ForwardedRef<Ref>): React.ReactNode;
  }
}

const sizeMap = {
  small: 16,
  middle: 24,
  large: 32,
} as const satisfies Record<StandardSize, number | string>;

const isStandardSize = (size: string | number): size is StandardSize =>
  Object.keys(sizeMap).includes(size as string) && size in sizeMap;

function calculateSize(size: ValidSize): string {
  if (typeof size === "number") return `${size}px`;

  if (isStandardSize(size)) {
    const sizeMapValue = sizeMap[size];
    return typeof sizeMapValue === "number"
      ? `${sizeMapValue}px`
      : sizeMapValue;
  }
  return size;
}

/**
 * The Ant Design Badge component adds the `ant-scroll-number-custom-component`
 * class to the parent element for badge positioning. However, when using
 * Tooltip and Badge together, the badge may be incorrectly positioned. This is
 * because the class is added to the Button element instead of the wrapper span.
 * To address this issue, it is necessary to remove the class from the Button
 * and instead add it to the wrapper span.
 */
const antdInBadgeClassName = "ant-scroll-number-custom-component";

/**
 * Button without border and padding.
 * Inherit styles from parent.
 *
 * ```ts
 * const sizeMap = {
 *   small: 16,
 *   middle: 24,
 *   large: 32,
 * };
 * ```
 */
const NarrowIconButtonInternal: NarrowIconButton.Type = forwardRef(
  function NarrowIconButtonInternal(
    {
      size = "middle",
      iconSize = size,
      loading,
      tooltipProps,
      wrapperSpanProps,
      ...props
    },
    ref,
  ) {
    const hasTooltip = tooltipProps?.title !== undefined;
    const allClasses = useMemo(
      function computedAllClasses() {
        return (props?.className ?? "")
          .split(" ")
          .map((className) => className.trim());
      },
      [props?.className],
    );
    const inBadge = useMemo(
      function computedInBadge() {
        return allClasses.includes(antdInBadgeClassName);
      },
      [allClasses],
    );

    const shouldMoveBadgeClassToWrapperSpan = hasTooltip && inBadge;

    const classNameWithoutAntdInBadge = useMemo(
      function computed() {
        if (!shouldMoveBadgeClassToWrapperSpan) return props.className;
        return allClasses
          .filter((className) => className !== antdInBadgeClassName)
          .join(" ");
      },
      [allClasses, props.className, shouldMoveBadgeClassToWrapperSpan],
    );
    const mergedWrapperSpanProps = useMemo(
      function computedMergedWrapperSpanProps() {
        if (!shouldMoveBadgeClassToWrapperSpan) return wrapperSpanProps;
        return {
          ...wrapperSpanProps,
          className: classNames(
            wrapperSpanProps?.className,
            antdInBadgeClassName,
          ),
        };
      },
      [shouldMoveBadgeClassToWrapperSpan, wrapperSpanProps],
    );
    const ret = (
      <Button
        css={css`
          @layer emotion-component {
            & {
              display: flex;
              width: var(${cssVariableSize});
              min-width: var(${cssVariableSize});
              max-width: var(${cssVariableSize});
              height: var(${cssVariableSize});
              min-height: var(${cssVariableSize});
              max-height: var(${cssVariableSize});
              align-items: center;
              justify-content: center;
              padding: 0;
              border: none;
              background: none;
              box-shadow: none;
              font-size: var(${cssVariableIconSize});
            }

            &:hover {
              background: none;
            }

            & > span,
            & > span > span {
              font-size: inherit;
            }

            & svg,
            & [un-motif-icon] {
              font-size: inherit;
            }

            & .anticon {
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: inherit;
            }

            &:not([disabled], :active, :focus, :hover) .anticon {
              background-color: transparent;
              color: ${theme.colors.neutral007};
            }
          }
        `}
        {...props}
        className={classNames(
          classNameRecord.root,
          classNameWithoutAntdInBadge,
        )}
        style={{
          [cssVariableSize]: calculateSize(size),
          [cssVariableIconSize]: calculateSize(iconSize),
          ...props.style,
        }}
        icon={
          loading ? (
            <LoadingOutlined spin data-chromatic="ignore" />
          ) : (
            props.icon
          )
        }
        ref={ref}
      />
    );

    if (!hasTooltip) return ret;

    return (
      <Tooltip {...tooltipProps}>
        <span {...mergedWrapperSpanProps}>{ret}</span>
      </Tooltip>
    );
  } satisfies NarrowIconButton.Type,
);

assignDisplayName(NarrowIconButtonInternal, "NarrowIconButton");

const NarrowIconButton = Object.assign(NarrowIconButtonInternal, {
  classNameRecord,
});

export { isStandardSize, NarrowIconButton };
export type { StandardSize, ValidSize };

/**
 * @deprecated Use `Button` instead.
 */
type NarrowIconButtonProps = NarrowIconButton.Props;
export type { NarrowIconButtonProps };
