import { UndoOutlined } from "@ant-design/icons";
import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import useChange from "@react-hook/change";
import useSwitch from "@react-hook/switch";
import { useQueryClient } from "@tanstack/react-query";
import { theme } from "@zeffiroso/theme";
import { memo } from "@zeffiroso/utils/react/memo";
import { getImmutableQueryKeyByAlias } from "@zeffiroso/zodios/getImmutableQueryKeyByAlias";
import type { FC, ReactNode } from "react";
import { Fragment, useEffect, useMemo } from "react";
import type { Parameters } from "tsafe";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { withFeatureFlagWrapper } from "@/app/featureFlag";
import { Trans } from "@/app/i18n/Trans";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { Alert } from "@/components/Alert";
import { Button } from "@/components/Button";
import { NarrowIconButton } from "@/components/Button/NarrowIconButton";
import { ExternalLink } from "@/components/ExternalLink";
import { MotifIcon } from "@/components/MotifIcon";
import { Tooltip } from "@/components/Tooltip";
import { Description } from "@/components/Typography";
import { useLegatoEvent } from "@/legato";
import { ga4Event } from "@/lib/ga4";
import { memberQueriesContext } from "@/queriesContext/memberQueriesContext";
import { orgQueriesContext } from "@/queriesContext/orgQueriesContext";
import { useChatState } from "@/resources/member/useChatState";
import { useFeatureControl } from "@/resources/organization/featureControl";
import { compileToString } from "@/router/utils/compileTo";
import { useOpenQuickTemplateDrawer } from "@/routes/Chat/ui/ChatPanel/Editor/Old/QuickTemplates";
import { useUserPermission } from "@/shared/application/user";
import { cssOneLine, defineStyles } from "@/shared/emotion";
import { ThreeDot } from "@/shared/icons/common/ThreeDots";

const styles = defineStyles({
  outer: css({
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0.5rem",
    boxShadow: "0 -1px 2px 0 rgb(175 188 201 / 20%)",
    gap: "0.25rem",
  }),
  noSuggestions: css({ gap: "0.5rem" }),
  content: css({
    display: "flex",
    overflow: "hidden",
    gap: "inherit",
  }),
  actions: css({
    display: "flex",
    minHeight: "1.5rem",
    alignItems: "center",
    gap: "inherit",
  }),
  externalLink: css([cssOneLine, { fontSize: "0.75rem" }]),
  templateButton: css({
    overflow: "hidden",
    "&>span": {
      display: "block",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  }),
  regenerateButton: css({
    "& .ant-btn-icon": {
      transform: "rotate(-90deg) scaleX(-1)",
      transition: "all 0.3s ease-in-out",
    },

    "&:hover .ant-btn-icon": {
      rotate: "360deg",
      scale: 1.1,
    },
  }),
  aiTemplateIcon: css({
    flexShrink: 0,
    padding: 4,
    color: theme.colors.purple005,
  }),
  loadingIcon: css({
    color: theme.colors.neutral006,
    fontSize: 24,
  }),
});

function enabledQuickTemplate(
  member: Pick<CantataTypes["Member"], "processingState">,
) {
  return (
    member.processingState === "new" || member.processingState === "follow-up"
  );
}

function useEnabledQuickTemplate() {
  const member = memberQueriesContext.useMember();
  const channel = memberQueriesContext.useMemberChannel();
  const chatState = useChatState(member);
  const enabled = useMemo(
    () =>
      enabledQuickTemplate(member) &&
      chatState !== "CannotSendAnyContent" &&
      channel.status === "connected",
    [member, chatState, channel.status],
  );
  return enabled;
}

const useCreateRecommendQuickTemplate = (
  options?: Omit<
    Parameters<
      typeof cantata.chatAiTemplate.useCreateRecommendQuickTemplate
    >[2],
    | "enabled"
    | "staleTime"
    | "refetchInterval"
    | "refetchOnWindowFocus"
    | "refetchOnMount"
  >,
) => {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const member = memberQueriesContext.useMember();
  const enabledQuickTemplate = useEnabledQuickTemplate();

  return cantata.chatAiTemplate.useCreateRecommendQuickTemplate(
    {
      memberId: member.id,
    },
    {
      params: {
        orgId,
      },
    },
    {
      enabled: enabledQuickTemplate,
      staleTime: Number.POSITIVE_INFINITY,
      refetchInterval: false,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      ...options,
    },
  );
};

const TemplateButton: FC<{
  template: CantataTypes["QuickTemplate"]["templates"][number];
}> = ({ template }) => {
  const openDrawer = useOpenQuickTemplateDrawer();
  const handleClick = useHandler<ComponentProps<typeof Button>["onClick"]>(
    function openQuickTemplateDrawer() {
      openDrawer({
        source: "aiRecommend",
        templateId: template.id,
      });
    },
  );
  return (
    <Button size="small" onClick={handleClick} css={styles.templateButton}>
      {template.name}
    </Button>
  );
};

/**
 * Remove recommend quick template cache when member is not active member and receive message or processing state changed
 */
const RemoveRecommendQuickTemplateCache = withFeatureFlagWrapper(
  "aiQuickTemplate",
  memo(function RemoveRecommendQuickTemplateCache() {
    const orgId = useActiveOrgIdStore((state) => state.value);
    const member = memberQueriesContext.useMember();
    const queryClient = useQueryClient();

    const removeRecommendCache = useHandler(function removeCache(
      memberId: number,
    ) {
      const queryKey = getImmutableQueryKeyByAlias(
        cantata.chatAiTemplate,
        "createRecommendQuickTemplate",
        {
          memberId,
        },
        {
          params: {
            orgId,
          },
        },
      );

      queryClient.removeQueries({
        queryKey,
      });
    });

    useLegatoEvent(
      "messages",
      function removeCacheOnInactiveMemberMessaging(event) {
        // on legato message and member is not active member, and then remove cache
        if (
          event.content.senderType !== "member" ||
          event.content.channelId !== member.channelId ||
          event.content.memberId === member.id ||
          event.content.contentType !== "text"
        )
          return;

        removeRecommendCache(event.content.memberId);
      },
    );

    const enabledRecommendQuickTemplate = useEnabledQuickTemplate();

    useEffect(
      function removeCacheOnProcessingStateChanged() {
        if (!enabledRecommendQuickTemplate) removeRecommendCache(member.id);
      },
      [enabledRecommendQuickTemplate, member.id, removeRecommendCache],
    );
    return null;
  }),
);

const RecommendQuickTemplate = withFeatureFlagWrapper(
  "aiQuickTemplate",
  memo(function RecommendQuickTemplate() {
    const orgId = useActiveOrgIdStore((state) => state.value);
    const orgLevelData = orgQueriesContext.useData();
    const userId = orgLevelData.me.id;
    const member = memberQueriesContext.useMember();

    const [regenerateVisible, toggleRegenerateVisible] = useSwitch(false);
    const { hasPermission } = useUserPermission();
    const userCanEditQuickTemplates = hasPermission("editQuickTemplate");

    const query = useCreateRecommendQuickTemplate();

    useChange(query.data, (data) => {
      if (data) toggleRegenerateVisible.off();
    });

    useLegatoEvent("messages", (event) => {
      if (
        event.content.senderType !== "member" ||
        event.content.channelId !== member.channelId ||
        event.content.memberId !== member.id ||
        event.content.contentType !== "text"
      )
        return;

      toggleRegenerateVisible.on();
    });

    const regenerateAiRecommendTemplate = useHandler(
      function regenerateAiRecommendTemplate() {
        query.remove();
        query.refetch();

        ga4Event("regenerateAiRecommend", {
          orgId,
          channelId: member.channelId,
          orgUserId: userId,
        });
      },
    );

    const regenerateButton = useMemo(
      () =>
        !regenerateVisible ? null : (
          <NarrowIconButton
            iconSize="16px"
            icon={<UndoOutlined />}
            onClick={regenerateAiRecommendTemplate}
            tooltipProps={{
              title: <Trans i18nKey="chat.aiQuickTemplate.refresh.tooltip" />,
            }}
            css={styles.regenerateButton}
          />
        ),
      [regenerateVisible, regenerateAiRecommendTemplate],
    );

    const enabledRecommendQuickTemplate = useEnabledQuickTemplate();

    const content = useMemo<ReactNode>(
      function getContent() {
        if (!enabledRecommendQuickTemplate) return null;

        if (query.isLoading) {
          return (
            <div css={styles.outer}>
              <span css={styles.loadingIcon}>
                <ThreeDot />
              </span>
            </div>
          );
        }

        if (query.isError) {
          return (
            <div css={styles.outer}>
              <Alert type="error" message={query.error.message} />
            </div>
          );
        }

        if (query.data.quickTemplates.length === 0) {
          return (
            <div css={css([styles.outer, styles.noSuggestions])}>
              <Description>
                <Trans i18nKey="chat.aiQuickTemplate.noResult" />
              </Description>
              <div css={styles.actions}>
                {!userCanEditQuickTemplates ? null : (
                  <ExternalLink
                    href={compileToString("/quick-templates")}
                    css={styles.externalLink}
                  >
                    <Trans i18nKey="chat.aiQuickTemplate.goToQuickTemplates" />
                  </ExternalLink>
                )}
                {regenerateButton}
              </div>
            </div>
          );
        }

        return (
          <div css={styles.outer}>
            <div css={styles.content}>
              <Tooltip title={<Trans i18nKey="chat.aiQuickTemplate.tooltip" />}>
                <span css={styles.aiTemplateIcon}>
                  <MotifIcon un-i-motif="ai_template" />
                </span>
              </Tooltip>
              {query.data.quickTemplates.map((quickTemplate) =>
                quickTemplate.templates.map((template) => (
                  <TemplateButton
                    key={`template--${template.id}`}
                    template={template}
                  />
                )),
              )}
            </div>
            {regenerateButton}
          </div>
        );
      },
      [
        enabledRecommendQuickTemplate,
        query.data?.quickTemplates,
        query.error?.message,
        query.isError,
        query.isLoading,
        regenerateButton,
        userCanEditQuickTemplates,
      ],
    );
    return (
      <>
        <RemoveRecommendQuickTemplateCache />
        {content}
      </>
    );
  }),
  Fragment,
);

const Wrapped: FC = () => {
  const enabledRecommendQuickTemplate = useFeatureControl(
    "aiQuickTemplateRecommendationAvailability",
  );

  if (!enabledRecommendQuickTemplate) return null;

  return <RecommendQuickTemplate />;
};

assignDisplayName(Wrapped, "RecommendQuickTemplate");

export { Wrapped as RecommendQuickTemplate };
