import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { css } from "@emotion/react";
import type { Overwrite } from "@mui/types";
import { theme } from "@zeffiroso/theme";
import { memo } from "@zeffiroso/utils/react/memo";
import type { SelectProps as AntSelectProps } from "antd";
import { Select as AntSelect } from "antd";
// eslint-disable-next-line no-restricted-imports -- Non-exported types.
import type {
  BaseOptionType,
  DefaultOptionType,
  RefSelectProps,
} from "antd/es/select";
import type { ElementRef, Ref } from "react";
import { useTranslation } from "react-i18next";

import { NotFoundContent } from "@/components/Empty/NotFoundContent";

const cssSelect = css`
  @layer emotion-component {
    & {
      /**
       * Narrow as possible.
       */
      min-width: 0;

      /**
       * Prevents the select from growing wider than the content.
       */
      max-width: 100%;
    }

    .ant-select-arrow {
      svg,
      .anticon,
      [role="img"] {
        /**
         * The custom suffix icon. Customize icon will not response click open
         * to avoid icon designed to do other interactive. We use
         * pointer-events: none style to bypass.
         *
         * See https://ant.design/components/select#select-props
         */
        pointer-events: none;
      }
    }
  }
`;

namespace Select {
  export interface OwnProps {
    noContentMessage?: string;
  }
  export type Props<
    ValueType = any,
    OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType,
  > = Overwrite<AntSelectProps<ValueType, OptionType>, OwnProps>;
  export type Ref = RefSelectProps;
  export type OptionType = DefaultOptionType;
}

const Select = memo(
  forwardRef(function Select<
    ValueType = any,
    OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType,
  >(
    { noContentMessage, ...props }: Select.Props<ValueType, OptionType>,
    ref?: Ref<ElementRef<typeof AntSelect>>,
  ): React.ReactNode {
    const { t } = useTranslation();
    return (
      <AntSelect<ValueType, OptionType>
        css={cssSelect}
        dropdownStyle={{
          borderRadius: theme.shape.borderRadius,
          ...props.dropdownStyle,
        }}
        notFoundContent={
          <NotFoundContent
            message={noContentMessage || t("common.noResults")}
          />
        }
        {...props}
        ref={ref}
      />
    );
  }),
) as <
  ValueType = any,
  OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType,
>(
  props: Select.Props<ValueType, OptionType>,
  ref?: Ref<ElementRef<typeof AntSelect>>,
) => React.ReactNode as typeof AntSelect;

Select.Option = AntSelect.Option;
Select.OptGroup = AntSelect.OptGroup;

export { Select };
