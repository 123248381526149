import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { safePromise } from "@chatbotgang/etude/safe/safePromise";
import type { Account } from "@chatbotgang/fb-sdk";
import type { MutationOptions } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import pMap from "p-map";
import { useMemo } from "react";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { fbSdk } from "@/app/fbSdk";
import { cantata, cantataClient } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { logError } from "@/shared/application/logger/sentry";

function useInvalidateChannelsQuery() {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const channelsQuery = cantata.channel.useList({
    params: {
      orgId,
    },
    enabled: false,
  });

  return channelsQuery.invalidate;
}
const getChannelInfo = (() => {
  type Info = {
    externalChannelId: string;
    channelName: string;
  };
  const getChannel: Record<
    CantataTypes["EditableMetaChannelType"],
    (account: Account, signal?: AbortSignal) => Info | Promise<Info>
  > = {
    fb(account) {
      return {
        externalChannelId: account.id,
        channelName: account.name,
      };
    },
    async ig(account, signal) {
      if (!account.instagram_business_account) {
        throw new Error(
          inspectMessage`Account ${account.id} is not connect to an Instagram account`,
        );
      }

      const user = await fbSdk.api.getInstagramUser({
        id: account.instagram_business_account.id,
        signal,
      });

      return {
        externalChannelId: account.instagram_business_account.id,
        channelName: user.name || user.username,
      };
    },
  };

  return async function getChannelInfo({
    channelType,
    account,
    signal,
  }: {
    channelType: CantataTypes["EditableMetaChannelType"];
    account: Account;
    signal?: AbortSignal;
  }) {
    return getChannel[channelType](account, signal);
  };
})();

/**
 * Find a Facebook account by its id.
 */
function findFbAccountById({
  accounts,
  accountId,
}: {
  accounts: Array<Account>;
  accountId: CantataTypes["Channel"]["externalChannelId"];
}) {
  return accounts.find((account) => account.id === accountId);
}

/**
 * Find an Instagram account by its id.
 */
function findIgAccountById({
  accounts,
  accountId,
}: {
  accounts: Array<Account>;
  accountId: CantataTypes["Channel"]["externalChannelId"];
}) {
  return accounts.find((account) =>
    !account.instagram_business_account
      ? false
      : account.instagram_business_account?.id === accountId,
  );
}

const useBulkCreateChannelMutation = (() => {
  function getMutationFn({ orgId }: { orgId: CantataTypes["Org"]["id"] }) {
    return async function mutationFn({
      channelType,
      signal,
    }: {
      channelType: CantataTypes["EditableMetaChannelType"];
      signal?: AbortSignal;
    }) {
      const allAccounts = await fbSdk.api.getAccounts({ signal });
      const accounts =
        channelType === "ig"
          ? /**
             * When `channelType` is `ig`, we only want to create channels for
             * accounts that have an Instagram account connected.
             */
            allAccounts.filter((account) =>
              Boolean(account.instagram_business_account?.id),
            )
          : allAccounts;

      if (accounts.length === 0) {
        const error = new Error(
          inspectMessage`No account found for channel type ${channelType}, accounts: ${accounts}`,
        );
        error.name = "NoAccountFoundError";
        throw error;
      }

      const result = await safePromise(() =>
        pMap(
          accounts,
          async function createChannel(account: Account) {
            const channelInfo = await getChannelInfo({
              account,
              channelType,
              signal,
            });

            return cantataClient.channel.create(
              {
                type: channelType,
                name: channelInfo.channelName,
                externalChannelSecret: "no secret",
                externalChannelId: channelInfo.externalChannelId,
                accessToken: account.access_token,
              },
              {
                params: {
                  orgId,
                },
                signal,
              },
            );
          },
          {
            concurrency: 1,
            signal,
          },
        ),
      );

      if (result.isError) {
        if (!(result.error instanceof Error)) {
          const unexpectedError = new Error(
            inspectMessage`Failed to create channels: ${result.error}`,
          );
          unexpectedError.cause = result.error;
          logError(unexpectedError);
          throw unexpectedError;
        }

        throw result.error;
      }

      return result.data;
    };
  }
  type MutationFn = ReturnType<typeof getMutationFn>;
  type TData = Awaited<ReturnType<MutationFn>>;
  type TError = unknown;
  type TVariables = Parameters<MutationFn>[0];
  type TContext = unknown;
  return function useBulkCreateChannelMutation(
    options?: Omit<
      MutationOptions<TData, TError, TVariables, TContext>,
      "mutationFn"
    >,
  ) {
    const orgId = useActiveOrgIdStore((state) => state.value);
    const invalidateChannelsQuery = useInvalidateChannelsQuery();
    const mutationFn = useMemo(() => getMutationFn({ orgId }), [orgId]);

    return useMutation({
      ...options,
      mutationFn,
      async onSettled(...args) {
        invalidateChannelsQuery();
        options?.onSettled?.(...args);
      },
    });
  };
})();

const useReconnectChannelMutation = (() => {
  function getMutationFn({
    orgId,
    channelId,
    channelType,
  }: {
    orgId: CantataTypes["Org"]["id"];
    channelId: CantataTypes["Channel"]["id"];
    channelType: CantataTypes["EditableMetaChannelType"];
  }) {
    return async function mutationFn({
      accountId,
      signal,
    }: {
      accountId: string;
      signal?: AbortSignal;
    }) {
      const accounts = await fbSdk.api.getAccounts({ signal });
      signal?.throwIfAborted();

      const account =
        channelType === "fb"
          ? findFbAccountById({ accounts, accountId })
          : channelType === "ig"
            ? findIgAccountById({ accounts, accountId })
            : (() => {
                channelType satisfies never;
                throw new Error(
                  inspectMessage`Unexpected channel type: ${channelType}`,
                );
              })();
      if (!account) {
        const err = new Error(inspectMessage`Account ${accountId} not found`);
        err.name = "AccountNotFoundError";
        throw err;
      }

      /**
       * We use create api to reconnect channel
       * Backend will check if channel already exists and update it
       */
      const result = await safePromise(() =>
        cantataClient.channel.updateStatus(
          {
            status: "connected",
            accessToken: account.access_token,
          },
          {
            params: {
              orgId,
              channelId,
            },
            signal,
          },
        ),
      );

      if (result.isError) {
        if (!(result.error instanceof Error)) {
          const unexpectedError = new Error(
            inspectMessage`Failed to reconnect channels: ${result.error}`,
          );
          unexpectedError.cause = result.error;
          throw unexpectedError;
        }

        throw result.error;
      }

      return result.data;
    };
  }
  type MutationFn = ReturnType<typeof getMutationFn>;
  type TData = Awaited<ReturnType<MutationFn>>;
  type TError = unknown;
  type TVariables = Parameters<MutationFn>[0];
  type TContext = unknown;
  return function useReconnectChannelMutation(
    channelId: CantataTypes["Channel"]["id"],
    channelType: CantataTypes["EditableMetaChannelType"],
    options?: Omit<
      MutationOptions<TData, TError, TVariables, TContext>,
      "mutationFn"
    >,
  ) {
    const orgId = useActiveOrgIdStore((state) => state.value);
    const invalidateChannelsQuery = useInvalidateChannelsQuery();

    const mutationFn = useMemo(
      () => getMutationFn({ orgId, channelId, channelType }),
      [orgId, channelId, channelType],
    );

    return useMutation({
      ...options,
      mutationFn,
      async onSettled(...args) {
        invalidateChannelsQuery();
        options?.onSettled?.(...args);
      },
    });
  };
})();

export { useBulkCreateChannelMutation, useReconnectChannelMutation };
