// cspell:words caacapp ariontechs
import { z } from "zod";

/**
 * Slack: [#proj-caacapp-with-ariontechs](https://chatbotgang.slack.com/archives/C08106MDD19/p1736932253681749?thread_ts=1736928024.852869&cid=C08106MDD19)
 */
const FlutterNotificationPermissionSchema = z.enum([
  // The app is authorized to create notifications.
  "AuthorizationStatus.authorized",
  // The app is not authorized to create notifications.
  "AuthorizationStatus.denied",
  // The app user has not yet chosen whether to allow the application to create
  // notifications. Usually this status is returned prior to the first call
  // of [requestPermission].
  "AuthorizationStatus.notDetermined",
  // The app is currently authorized to post non-interrupting user notifications.
  "AuthorizationStatus.provisional",
]);

const flutterNotificationPermissionNotificationPermissionMap: Record<
  FlutterWebviewAppSdk.FlutterNotificationPermission,
  NotificationPermission
> = {
  "AuthorizationStatus.authorized": "granted",
  "AuthorizationStatus.denied": "denied",
  "AuthorizationStatus.notDetermined": "default",
  "AuthorizationStatus.provisional": "default",
};

const flutterNotificationPermissionMapToNotificationPermission = (
  permission: FlutterWebviewAppSdk.FlutterNotificationPermission,
): NotificationPermission => {
  if (!(permission in flutterNotificationPermissionNotificationPermissionMap)) {
    return "default";
  }
  return flutterNotificationPermissionNotificationPermissionMap[permission];
};

namespace FlutterWebviewAppSdk {
  export type FlutterNotificationPermission = z.infer<
    typeof FlutterNotificationPermissionSchema
  >;
  export type RequestCallbackDefinition<
    TResponse = void,
    TArgs extends Array<any> = [],
  > = {
    args: TArgs;
    response: TResponse;
  };
  /**
   * The request map for the flutter webview app SDK.
   * - key: request name
   * - value: request result (promise)
   */
  export type RequestMap = {
    requestNotificationPermission: RequestCallbackDefinition<FlutterNotificationPermission>;
    getCaacWebViewAppFcmToken: RequestCallbackDefinition<string>;
    revokeCaacWebViewAppFcmToken: RequestCallbackDefinition;
    downloadBase64Image: RequestCallbackDefinition<void, [string]>;
    caacWebViewAppOpenBrowser: RequestCallbackDefinition<void, [string]>;
  };

  export type RequestName = keyof RequestMap;
  export type RequestArgs<TRequestName extends RequestName> =
    RequestMap[TRequestName]["args"];
  export type RequestResult<TRequestName extends RequestName> =
    RequestMap[TRequestName]["response"];

  export interface CallHandler {
    <TRequestName extends RequestName>(
      requestName: TRequestName,
      ...args: RequestArgs<TRequestName>
    ): Promise<RequestResult<TRequestName>>;
  }
  export interface InAppWebView {
    callHandler: CallHandler;
  }

  /**
   * The event map for the flutter webview app SDK.
   */
  export type EventMap = {
    flutterPermissionUpdated: [NotificationPermission];
  };
}

declare global {
  interface Window {
    flutter_inappwebview?: FlutterWebviewAppSdk.InAppWebView;
    flutterPermissionUpdated?: (
      permission: FlutterWebviewAppSdk.FlutterNotificationPermission,
    ) => void;
  }
}

export {
  flutterNotificationPermissionMapToNotificationPermission,
  FlutterNotificationPermissionSchema,
};
export type { FlutterWebviewAppSdk };
