import { extname } from "pathe";
import type { QRCodeToDataURLOptions } from "qrcode";

export type QrCodeType = NonNullable<QRCodeToDataURLOptions["type"]>;

type Extname = `.${string}`;

const typeExtnameRecord: Record<QrCodeType, Extname | [Extname, ...Extname[]]> =
  {
    "image/png": ".png",
    "image/jpeg": [".jpg", ".jpeg"],
    "image/webp": ".webp",
  };

function ensureExtname(fileName: string, type: QrCodeType) {
  const fileExtname = typeExtnameRecord[type];
  const currentExt = extname(fileName);
  if (Array.isArray(fileExtname)) {
    if (fileExtname.includes(currentExt)) {
      return fileName;
    }
    return fileName + fileExtname[0];
  }
  if (currentExt === fileExtname) return fileName;
  return fileName + fileExtname;
}

export { ensureExtname };
