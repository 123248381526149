import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { css } from "@emotion/react";
import { lowerFirst } from "lodash-es";
import {
  type ReactNode,
  useCallback,
  useImperativeHandle,
  useMemo,
} from "react";
import { useTranslation } from "react-i18next";

import { Trans } from "@/app/i18n/Trans";
import { Alert } from "@/components/Alert";
import { Button } from "@/components/Button";
import { ExternalLink } from "@/components/ExternalLink";
import { Form, FormItem } from "@/components/Form";
import { Input, InputPassword } from "@/components/Input";
import { MAAC_FORGET_PASSWORD_URL } from "@/env";
import { StyledForm } from "@/routes/Auth/components/Styled";

type LoginFormValues = {
  email: string;
  password: string;
};

type RegistrationFormValues = LoginFormValues & {
  passwordConfirmation: string;
};

type ImperativeHandle = {
  setFields: (
    fields: Parameters<ReturnType<typeof Form.useForm>[0]["setFields"]>[0],
  ) => void;
};

type LoginFormProps = {
  loading: boolean;
  formValues?: Partial<LoginFormValues>;
  errorMessage?: ReactNode;
  shouldShowResetPasswordLink?: boolean;
  actionText?: string;
  onSubmit: (values: LoginFormValues) => void;
  disabledFields?: Array<keyof LoginFormValues>;
};

const LoginForm = forwardRef<ImperativeHandle, LoginFormProps>(
  function LoginForm(
    {
      loading,
      formValues,
      errorMessage,
      shouldShowResetPasswordLink = false,
      actionText,
      onSubmit,
      disabledFields = [],
    },
    ref,
  ) {
    const { t } = useTranslation();
    const isEmailFieldDisabled = loading || disabledFields.includes("email");
    const isPasswordFieldDisabled =
      loading || disabledFields.includes("password");
    const [form] = Form.useForm();

    const imperativeHandle = useMemo<ImperativeHandle>(
      () => ({
        setFields(fields) {
          form.setFields(fields);
        },
      }),
      [form],
    );

    useImperativeHandle(
      ref,
      useCallback(() => imperativeHandle, [imperativeHandle]),
    );

    return (
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: stretch;
          justify-content: center;
          gap: 48px;
        `}
      >
        {!errorMessage ? null : <Alert message={errorMessage} type="error" />}
        <StyledForm
          form={form}
          requiredMark={false}
          onFinish={onSubmit}
          initialValues={formValues}
        >
          <FormItem
            name="email"
            label={<Trans i18nKey="glossary.email" />}
            style={{ width: `100%` }}
            rules={[
              {
                required: true,
                message: t("validation.pleaseInputField", {
                  field: lowerFirst(t("glossary.email")),
                }),
              },
            ]}
          >
            <Input
              type="email"
              autoComplete={isEmailFieldDisabled ? "off" : "username email"}
              autoFocus
              prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder="example@mail.com"
              disabled={isEmailFieldDisabled}
              data-test="login-field-username"
              tabIndex={1}
            />
          </FormItem>
          <FormItem
            name="password"
            css={css`
              label {
                display: flex;
                width: 100%;

                &::after {
                  content: none !important;
                }
              }
            `}
            label={
              <div
                css={css`
                  display: flex;
                  flex: 1;
                  align-items: center;
                  justify-content: space-between;
                  gap: 1rem;
                `}
              >
                <div>
                  <Trans i18nKey="glossary.password" />
                </div>

                {shouldShowResetPasswordLink && (
                  <ExternalLink href={MAAC_FORGET_PASSWORD_URL}>
                    {t("login.forgetPassword")}
                  </ExternalLink>
                )}
              </div>
            }
            rules={[
              {
                required: true,
                message: t("validation.pleaseInputField", {
                  field: lowerFirst(t("glossary.password")),
                }),
              },
            ]}
          >
            <InputPassword
              prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
              type="password"
              autoComplete="current-password"
              placeholder={t("common.inputFieldName", {
                fieldName: lowerFirst(t("glossary.password")),
              })}
              disabled={isPasswordFieldDisabled}
              data-test="login-field-password"
              tabIndex={1}
            />
          </FormItem>
          <FormItem>
            <Button
              block
              size="large"
              type="primary"
              htmlType="submit"
              loading={loading}
              data-test="login-action-submit"
            >
              {actionText ?? t("common.login")}
            </Button>
          </FormItem>
        </StyledForm>
      </div>
    );
  },
);

type RegistrationFormProps = {
  loading: boolean;
  formValues?: Partial<RegistrationFormValues>;
  onSubmit: (values: RegistrationFormValues) => void;
  disabledFields?: Array<keyof RegistrationFormValues>;
};

const RegistrationForm = forwardRef<ImperativeHandle, RegistrationFormProps>(
  function RegistrationForm(
    { loading, formValues, onSubmit, disabledFields = [] },
    ref,
  ) {
    const { t } = useTranslation();

    const [form] = Form.useForm();
    const isEmailFieldDisabled = loading || disabledFields.includes("email");
    const isPasswordFieldDisabled =
      loading || disabledFields.includes("password");
    const isPasswordConfirmFieldDisabled =
      loading || disabledFields.includes("passwordConfirmation");

    useImperativeHandle(
      ref,
      () => ({
        setFields(fields: Parameters<typeof form.setFields>[0]) {
          form.setFields(fields);
        },
      }),
      [form],
    );

    return (
      <StyledForm
        form={form}
        requiredMark={false}
        onFinish={onSubmit}
        initialValues={formValues}
        disabled={loading}
      >
        <FormItem
          name="email"
          data-test="email-field-register"
          label={<Trans i18nKey="glossary.email" />}
          style={{ width: `100%` }}
          rules={[
            {
              required: true,
              message: t("validation.pleaseInputField", {
                field: lowerFirst(t("glossary.email")),
              }),
            },
          ]}
        >
          <Input
            prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
            placeholder="example@mail.com"
            type="email"
            autoComplete="off"
            disabled={isEmailFieldDisabled}
          />
        </FormItem>
        <FormItem
          name="password"
          data-test="password-field-register"
          label={<Trans i18nKey="glossary.password" />}
          rules={[
            {
              required: true,
              message: t("validation.pleaseInputField", {
                field: lowerFirst(t("glossary.password")),
              }),
            },
          ]}
        >
          <InputPassword
            prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
            type="password"
            autoComplete="new-password"
            placeholder={t("common.inputFieldName", {
              fieldName: lowerFirst(t("glossary.password")),
            })}
            disabled={isPasswordFieldDisabled}
          />
        </FormItem>
        <FormItem
          name="passwordConfirmation"
          data-test="password-confirmation-field-register"
          label={<Trans i18nKey="login.confirmPassword" />}
          dependencies={["password"]}
          rules={[
            {
              required: true,
              message: t("validation.pleaseInputField", {
                field: lowerFirst(t("glossary.password")),
              }),
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value)
                  return Promise.resolve();

                return Promise.reject(
                  new Error(t("login.confirmPassword.notMatch")),
                );
              },
            }),
          ]}
        >
          <InputPassword
            prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
            type="password"
            autoComplete="new-password"
            placeholder={t("common.inputFieldName", {
              fieldName: lowerFirst(t("glossary.password")),
            })}
            disabled={isPasswordConfirmFieldDisabled}
          />
        </FormItem>
        <FormItem>
          <Button
            block
            size="large"
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            <Trans i18nKey="common.register" />
          </Button>
        </FormItem>
      </StyledForm>
    );
  },
);

export { LoginForm, RegistrationForm };
