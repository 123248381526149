import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { css } from "@emotion/react";
import type { DistributiveOmit, Overwrite } from "@mui/types";
import { screenTypeBreakpoints, theme } from "@zeffiroso/theme";
import { useTranslation } from "react-i18next";

import { AppDownloadLink } from "@/components/AppDownloadLink";
import { Button } from "@/components/Button";
import { CrescendoLabPageCornerLogo } from "@/components/CrescendoLabPageCornerLogo";
import { CAAC_APPLE_APP_STORE_LINK, CAAC_GOOGLE_PLAY_LINK } from "@/env";
import { MainLayout } from "@/layout/MainLayout";
import { defineStyles } from "@/shared/emotion";

const breakpoint = screenTypeBreakpoints.tabletLandscape;
// Figma: https://www.figma.com/design/86kJNE9cWYYI9cHpau4EE3?node-id=8240-106399#1197287739
const gteTabletLandscapeMediaQuery = `@media (min-width: ${breakpoint}px)`;

const styles = defineStyles({
  Ui: css({
    padding: "24px",
    gap: "80px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    color: theme.colors.neutral010,
    [gteTabletLandscapeMediaQuery]: {
      gap: "40px",
    },
  }),
  logo: css({
    alignSelf: "flex-start",
  }),
  mainContent: css({
    // [Figma](https://www.figma.com/design/86kJNE9cWYYI9cHpau4EE3?node-id=8240-106358#1197739907)
    maxWidth: "540px",
    gap: "96px",
  }),
  description: css({
    fontSize: "1rem",
    color: theme.colors.neutral007,
  }),
  header: css({
    margin: "0",
    fontSize: "1.5rem",
  }),
  activateAppButton: css({
    padding: "8px",
    height: "auto",
  }),
  installAppDescription: css({
    fontSize: "0.875rem",
  }),
  downloadLinks: css({
    display: "flex",
    flexDirection: "row",
    flexFlow: "wrap",
    alignItems: "center",
    justifyContent: "center",
    "&>*": {
      width: "120px",
    },
  }),
});

namespace Ui {
  export interface OwnProps {
    email: string;
    signOutButtonProps?: DistributiveOmit<
      ComponentProps<typeof Button>,
      "children" | "type" | "css"
    >;
    continueButtonProps?: Omit<
      React.ComponentProps<typeof Button>,
      "children" | "type" | "css"
    >;
    openAppButtonProps?: DistributiveOmit<
      ComponentProps<typeof Button>,
      "children" | "type" | "css"
    >;
  }
  export type Props = Overwrite<ComponentProps<"div">, OwnProps>;
}

const UiInternal: React.FC<Ui.Props> = ({
  email,
  signOutButtonProps,
  continueButtonProps,
  openAppButtonProps,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <MainLayout.Body css={styles.Ui} {...props}>
      <CrescendoLabPageCornerLogo css={styles.logo} />
      <MainLayout.Content css={styles.mainContent}>
        <MainLayout.Section>
          <h1 css={styles.header}>
            {t("page.backToFlutterWebviewApp.welcomeMessage", {
              userEmail: email,
            })}
          </h1>
          <span css={styles.description}>
            {t("page.backToFlutterWebviewApp.openAppAndSignIn.description")}
          </span>
          <MainLayout.Group>
            <Button
              type="primary"
              css={styles.activateAppButton}
              {...openAppButtonProps}
            >
              {t("page.backToFlutterWebviewApp.openAppAndSignIn.action.label")}
            </Button>
            <Button {...continueButtonProps}>
              {t("page.backToFlutterWebviewApp.continueInBrowser.button.label")}
            </Button>
          </MainLayout.Group>
          <Button type="text" {...signOutButtonProps}>
            {t("page.backToFlutterWebviewApp.signOut.button.label")}
          </Button>
        </MainLayout.Section>
        <MainLayout.Group>
          <span css={styles.installAppDescription}>
            {t("page.backToFlutterWebviewApp.installApp.description")}
          </span>
          <MainLayout.Item css={styles.downloadLinks}>
            <AppDownloadLink
              storeType="appleAppStore"
              href={CAAC_APPLE_APP_STORE_LINK}
            />
            <AppDownloadLink
              storeType="googlePlayStore"
              href={CAAC_GOOGLE_PLAY_LINK}
            />
          </MainLayout.Item>
        </MainLayout.Group>
      </MainLayout.Content>
    </MainLayout.Body>
  );
};

/**
 * BackToFlutterWebviewApp
 */
const Ui = Object.assign(UiInternal, {
  styles,
});

export { Ui };
