import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { memo } from "@zeffiroso/utils/react/memo";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useBadgeCount } from "@/app/sw/badge";
import { TITLE } from "@/env";

const MyHelmet = memo(function MyHelmet() {
  const badgeCount = useBadgeCount();
  const { i18n } = useTranslation();
  useEffect(
    function setTitle() {
      document.title = (badgeCount ? `(${badgeCount}) ` : "") + TITLE;
    },
    [badgeCount],
  );
  useEffect(
    function setLang() {
      document.querySelector("html")?.setAttribute("lang", i18n.language);
    },
    [i18n.language],
  );
  return null;
});

assignDisplayName(MyHelmet, "Helmet");

export { MyHelmet as Helmet };
