/**
 * Convert a Blob to a File object
 */
export const blobToFile = (
  /**
   * The Blob to convert
   */
  blob: Blob,
  /**
   * Name for the resulting File
   */
  fileName: string,
  /**
   * Optional File configuration
   */
  options?: {
    /**
     * The MIME type of the Blob
     */
    type?: string;
    /**
     * The last modified date of the Blob
     */
    lastModified?: number;
  },
): File => {
  return new File([blob], fileName, {
    type: options?.type ?? blob.type,
    lastModified: options?.lastModified ?? Date.now(),
  });
};
