import type { FlexSeparator } from "@line/bot-sdk";

import { classNames } from "@/components/LINEFlexMessage/styled";
import { marginClassName } from "@/components/LINEFlexMessage/utils";

interface FlexSeparatorComponentProps {
  data: FlexSeparator;
}

const FlexSeparatorComponent = ({
  data: { margin, color },
}: FlexSeparatorComponentProps): React.ReactNode => {
  /**
   * The icon's `flex` property is fixed to 0.
   */
  return (
    <div
      className={classNames("separator", "flex0", marginClassName(margin, "t"))}
      style={{ borderColor: color }}
    ></div>
  );
};

export { FlexSeparatorComponent };
