import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import type { GencSchema } from "@zeffiroso/cantata/models";
import { memo } from "@zeffiroso/utils/react/memo";
import objectInspect from "object-inspect";
import type { ElementRef } from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import type { z } from "zod";

import { Trans } from "@/app/i18n/Trans";
import { Select } from "@/components/Select";
import { gencOptions, isValidGenc } from "@/resources/genc";
import { useLocaleCompare } from "@/shared/hooks/useLocaleCompare";

type GencSelectProps = Omit<
  Select.Props<z.infer<typeof GencSchema>, GencOption>,
  "showSearch" | "optionFilterProp" | "optionLabelProp" | "options"
>;

type GencOption = Omit<(typeof gencOptions)[number], "filterI18nKey"> & {
  filter?: string;
  disabled?: boolean;
};

/**
 * A select component for GENC.
 *
 * - [NSG STANDARDS REGISTRY](https://nsgreg.nga.mil/registries/browse/results.jsp?registryType=genc&registerField=IE4&browseType=genc)
 *
 * @see {@link GencSchema}
 */
const GencSelect = memo(
  forwardRef<ElementRef<typeof Select>, GencSelectProps>(
    function GencSelect(props, ref) {
      const { t } = useTranslation();
      const localeCompare = useLocaleCompare();
      const value = props.value;
      const options = useMemo<GencOption[]>(
        () => [
          ...gencOptions
            .map((option) => ({
              ...option,
              filter: [t(option.filterI18nKey), option.value].join(
                /**
                 * Since it's not possible to type `\n` in the search box, we utilize
                 * it as a separator to align the search box with the option label.
                 */
                "\n",
              ),
            }))
            .sort((a, b) => {
              /**
               * Put "Other" at the end of the list.
               */
              if (a.value === "other") return 1;
              if (b.value === "other") return -1;
              return localeCompare(t(a.filterI18nKey), t(b.filterI18nKey));
            }),
          // unexpected country code
          ...(value && !isValidGenc(value)
            ? [
                {
                  value: value as z.infer<typeof GencSchema>,
                  label: (
                    <Trans
                      i18nKey="feature.genc.option.unknown.label"
                      values={{ value: objectInspect(value) }}
                    />
                  ),
                  disabled: true,
                },
              ]
            : []),
        ],
        [localeCompare, t, value],
      );
      return (
        <Select<z.infer<typeof GencSchema>, GencOption>
          showSearch
          allowClear
          optionFilterProp="filter"
          optionLabelProp="label"
          popupMatchSelectWidth={false}
          options={options}
          {...props}
          ref={ref}
        />
      );
    },
  ),
);

assignDisplayName(GencSelect, "GencSelect");

export { GencSelect };
export type { GencSelectProps };
