import { createContext } from "@chatbotgang/etude/react/createContext";
import { fc } from "@chatbotgang/etude/react/fc";
import { define } from "@chatbotgang/etude/util/define";
import type {
  SetupInfiniteLoadControllerOptions,
  StateBase,
} from "@zeffiroso/utils/react-lib/useSetupInfiniteLoadController";
import { useSetupInfiniteLoadController } from "@zeffiroso/utils/react-lib/useSetupInfiniteLoadController";
import { mapValues, omit } from "lodash-es";
import { type ReactNode, useCallback, useMemo } from "react";

import { cantataClient } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { API_DEFAULT_LIMIT } from "@/env";
import { mergeDataFn } from "@/resources/message/utils";

type Response = Awaited<
  ReturnType<typeof cantataClient.message.listConversationMessages>
>;
type Item = Response["messages"][number];
type Options = SetupInfiniteLoadControllerOptions<Response, Item>;
type State = StateBase<Item>;

const Context = createContext<
  ReturnType<typeof useSetupInfiniteLoadController<Response, Item>>
>({
  name: "ConversationHistoryMessages",
});

const useConversationHistoryMessagesController = Context.useContext;
const ConversationHistoryProvider = fc<{ children: ReactNode }>(
  function ConversationHistoryProvider({ children }) {
    const conversationHistoryMessagesController =
      useSetupInfiniteLoadController<Response, Item>();
    return (
      <Context.Provider value={conversationHistoryMessagesController}>
        {children}
      </Context.Provider>
    );
  },
);

const getData: Options["getData"] = function getData(response) {
  return response.messages;
};

const getNextCursor: Options["getNextCursor"] = function getNextCursor(
  response,
) {
  return response.cursor.after ?? undefined;
};

const getPreviousCursor: Options["getPreviousCursor"] =
  function getPreviousCursor(response) {
    return response.cursor.before ?? undefined;
  };

function useSetup({
  orgId,
  memberId,
  conversationId,
  limit = API_DEFAULT_LIMIT,
}: {
  orgId: CantataTypes["Org"]["id"];
  memberId: CantataTypes["Member"]["id"];
  conversationId: CantataTypes["Conversation"]["conversationId"];
  limit?: number;
}) {
  const controller = useConversationHistoryMessagesController();
  const fetch = useCallback<Options["fetch"]>(
    async function fetch({ cursor, signal }) {
      return cantataClient.message.listConversationMessages({
        params: {
          orgId,
          memberId,
          conversationId,
        },
        queries: {
          cursor,
          limit,
        },
        signal,
      });
    },
    [conversationId, limit, memberId, orgId],
  );
  const mergedOptions: Options = useMemo(
    function computeOptions() {
      return {
        fetch,
        getData,
        getNextCursor,
        getPreviousCursor,
        mergeDataFn,
      };
    },
    [fetch],
  );
  controller.useSetup(mergedOptions);
}

function useController() {
  const controller = useConversationHistoryMessagesController();

  const ret = useMemo(
    function computeRet() {
      const selectors = define<Record<string, (state: State) => any>>()({
        /**
         * Previous is future.
         */
        canTryPrevious: (state) =>
          Boolean(state.previousCursor) || state.firstFetch,
        canTryNext: (state) => Boolean(state.nextCursor),
        isLoading: (state) => state.isPreviousLoading || state.isNextLoading,
      });
      const computedValues = mapValues(
        selectors,
        (selector) => () => selector(controller.useStore.getState()),
      );
      return {
        ...omit(controller, ["useSetup"]),
        selectors,
        computedValues,
      };
    },
    [controller],
  );
  return ret;
}

const conversationHistoryMessages = {
  Provider: ConversationHistoryProvider,
  useController,
  useSetup,
};

export { conversationHistoryMessages };
