import { initializeApp } from "firebase/app";

import {
  FIREBASE_CONTENT_STORAGE_API_KEY,
  FIREBASE_CONTENT_STORAGE_APP_ID,
  FIREBASE_CONTENT_STORAGE_PROJECT_ID,
  FIREBASE_CONTENT_STORAGE_SENDER_ID,
} from "@/env";

const caacFirebaseApp = initializeApp(
  {
    apiKey: FIREBASE_CONTENT_STORAGE_API_KEY,
    projectId: FIREBASE_CONTENT_STORAGE_PROJECT_ID,
    messagingSenderId: FIREBASE_CONTENT_STORAGE_SENDER_ID,
    appId: FIREBASE_CONTENT_STORAGE_APP_ID,
  },
  "caac",
);

export { caacFirebaseApp };
