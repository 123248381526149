import type { SerializedStyles } from "@emotion/react";
import { css } from "@emotion/react";
import type { Overwrite } from "@mui/types";
import type { ModalProps as AntModalProps } from "antd";
import { Modal as AntModal } from "antd";
import { type FC, useMemo } from "react";

import type { Button } from "@/components/Button";
import {
  DisabledContextProvider,
  useDisabled,
} from "@/components/Form/DisabledContext";
import { BarLoadingBlock } from "@/components/Loading/BarLoading";
import { Footer } from "@/components/Modal/shared";
import { StyledExclamationIcon } from "@/shared/icons/deprecated/styled/StyledExclamationIcon";

/**
 * @deprecated Use `Modal` instead.
 */
type ModalProps = Modal.Props;

namespace Modal {
  export type Props = Overwrite<
    AntModalProps,
    {
      limitedHeight?: boolean;
      okText?: Button.Props["children"];
      okType?: Button.Props["type"];
      onOk?: Button.Props["onClick"];
      okButtonProps?: Omit<Button.Props, "children">;
      cancelText?: Button.Props["children"];
      cancelType?: Button.Props["type"];
      onCancel?: Button.Props["onClick"];
      cancelButtonProps?: Omit<Button.Props, "children">;
    }
  >;
}

const ExtendedModal: FC<Modal.Props> = function Modal({
  limitedHeight,
  footer,
  ...restProps
}) {
  const disabled = useDisabled();
  const dialogFooter = useMemo(
    () =>
      /* Replace with this after upgrading antd to 5.18.0
       * - [feat: Modal support loading #48848](https://github.com/ant-design/ant-design/pull/48848)
       */
      /* footer !== null && !restProps.loading */
      footer !== null ? (
        <DisabledContextProvider disabled={disabled}>
          <Footer
            {...restProps}
            footer={footer}
            onOk={restProps.onOk}
            onCancel={restProps.onCancel}
          />
        </DisabledContextProvider>
      ) : null,
    [disabled, footer, restProps],
  );

  return (
    <AntModal
      css={
        !limitedHeight
          ? null
          : css`
              /**
               * Should be the same as padding-bottom from .ant-modal
               */
              --zeffiroso-modal-outer-padding-block: 24px;

              /**
               * Should be the same as top from .ant-modal
               */
              --zeffiroso-modal-outer-padding-top-max: 100px;

              /**
               * To center the modal vertically.
               */
              --zeffiroso-modal-outer-padding-top: calc((100dvh - 100%) / 2);

              /**
               * Use max-height and translate from .ant-modal-content for positioning instead.
               */
              top: 0;
              padding-bottom: 0;

              & .ant-modal-content {
                display: flex;
                overflow: auto;
                width: 100%;
                max-height: calc(
                  100dvh - var(--zeffiroso-modal-outer-padding-block) * 2
                );
                flex-direction: column;
                translate: 0
                  min(
                    var(--zeffiroso-modal-outer-padding-top-max),
                    var(--zeffiroso-modal-outer-padding-top)
                  );

                & .ant-modal-body {
                  position: relative;
                  display: flex;
                  overflow: auto;
                  flex: 1;
                  flex-direction: column;
                }
              }
            `
      }
      {...restProps}
      footer={dialogFooter}
    />
  );
};

const Warning = (() => {
  const styles = {
    content: css`
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 32px;
      text-align: center;

      > :first-child {
        display: flex;
        width: min(100%, 110px);
        align-items: center;
        justify-content: center;
        aspect-ratio: 1;

        > * {
          overflow: hidden;
          width: 100%;
          height: 100%;
          aspect-ratio: 1;
        }
      }
    `,
  } satisfies Record<string, SerializedStyles>;
  const Warning: FC<Modal.Props> = (props) => {
    return (
      <ExtendedModal {...props}>
        <div css={styles.content}>
          <div>
            <StyledExclamationIcon />
          </div>
          <div>{props.children}</div>
        </div>
      </ExtendedModal>
    );
  };
  return Warning;
})();

const originModalApi = {
  ...AntModal,
};

const Loading: FC<Omit<Modal.Props, "children" | "footer" | "closable">> = (
  props,
) => (
  <Modal footer={null} closable={Boolean(props.onCancel)} {...props}>
    <BarLoadingBlock />
  </Modal>
);

// inherit origin Modal static methods.
const Modal = Object.assign(ExtendedModal, {
  ...originModalApi,
  Warning,
  Loading,
});

export { Modal };

export type { ModalProps };
