import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

const searchParamKey = "fromFlutterWebviewApp";

namespace backToFlutterWebviewAppPage {
  export interface GenerateLinkOptions {
    url: string;
  }
}

function useMatchUrl() {
  const [searchParams] = useSearchParams();
  const isFromFlutterWebviewApp = useMemo(
    () => Boolean(searchParams.has(searchParamKey)),
    [searchParams],
  );
  return isFromFlutterWebviewApp;
}

const backToFlutterWebviewAppPage = {
  useMatchUrl,
  searchParamKey,
};

export { backToFlutterWebviewAppPage };
