import styled from "@emotion/styled";
import { theme } from "@zeffiroso/theme";
import type { MouseEventHandler, ReactNode } from "react";

const UploadHint = styled.span`
  font-size: 14px;
  line-height: 20px;
`;

const UploadHintTitle = styled(UploadHint)`
  color: ${theme.colors.neutral008};
`;

const UploadHintDescription = styled(UploadHint)`
  padding: 0 8px;
  color: ${theme.colors.neutral007};
  text-align: center;
  white-space: pre-wrap;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

const ReplaceWrapper = styled(Wrapper)`
  position: absolute;
  z-index: 200;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  border-radius: ${theme.shape.borderRadius};
  background-color: rgb(44 56 72 / 80%);
  opacity: 0;
  transition: 0.45s opacity ease-in-out;

  &:hover {
    opacity: 1;
  }
`;

const Icon = styled.div`
  --size: 24px;

  display: flex;
  width: var(--size);
  height: var(--size);
  align-items: center;
  justify-content: center;
  color: ${theme.colors.neutral006};
  cursor: pointer;
  font-size: 21px;
`;

const ReplaceUploadHints = ({
  children,
  hintTitle,
  hintDescription,
  onClick,
}: {
  children?: ReactNode;
  hintTitle?: string;
  hintDescription?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
}): React.ReactNode => (
  <ReplaceWrapper onClick={onClick}>
    <Icon>{children}</Icon>
    {hintTitle && <UploadHintTitle>{hintTitle}</UploadHintTitle>}
    {hintDescription && (
      <UploadHintDescription>{hintDescription}</UploadHintDescription>
    )}
  </ReplaceWrapper>
);

const UploadHints = ({
  children,
  hintTitle,
  hintDescription,
}: {
  children?: ReactNode;
  hintTitle?: string;
  hintDescription?: string;
}): React.ReactNode => (
  <Wrapper>
    <Icon>{children}</Icon>
    {hintTitle && <UploadHintTitle>{hintTitle}</UploadHintTitle>}
    {hintDescription && (
      <UploadHintDescription>{hintDescription}</UploadHintDescription>
    )}
  </Wrapper>
);

export { ReplaceUploadHints, UploadHints };
