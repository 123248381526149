/// <reference lib="webworker" />

import EventEmitter from "eventemitter3";
import { objectKeys } from "tsafe";
import { z } from "zod";

import { Client2SwZodError } from "./Client2SwZodError";
import { eventsSchema } from "./events";

/**
 * Emit an event to the service worker. Notice that this function is used in the
 * client.
 */
function emit2Sw<EventKey extends keyof typeof eventsSchema>(
  sw: ServiceWorkerRegistration,
  eventKey: EventKey,
  ...args: z.input<(typeof eventsSchema)[EventKey]> extends void
    ? []
    : [body: z.input<(typeof eventsSchema)[EventKey]>]
): void {
  sw.active?.postMessage({
    type: eventKey,
    ...(args.length === 0
      ? {}
      : {
          body: args[0],
        }),
  });
}

declare const self: ServiceWorkerGlobalScope;
/**
 * Listen to events from the clients. Notice that this function is used in the
 * service worker.
 */
function createEmitter() {
  type Events = typeof eventsSchema;
  const emitter = new EventEmitter<
    {
      [K in keyof Events]: (
        client: WindowClient,
        data: z.infer<Events[K]>,
      ) => void;
    } & {
      error: (error: Client2SwZodError) => void;
    }
  >();
  const outerSchema = z.object({
    type: z.enum(
      objectKeys(eventsSchema) as [
        keyof typeof eventsSchema,
        ...(keyof typeof eventsSchema)[],
      ],
    ),
    body: z.unknown(),
  });
  self.addEventListener("message", (e) => {
    if (!(e.source instanceof WindowClient)) return;
    const safeParsedOuter = outerSchema.safeParse(e.data);
    if (!safeParsedOuter.success) return;
    const { type, body } = safeParsedOuter.data;
    const safeParsedInner = eventsSchema[type].safeParse(body);
    if (!safeParsedInner.success) {
      emitter.emit(
        "error",
        new Client2SwZodError({
          data: e.data,
          cause: safeParsedInner.error,
        }),
      );
      return;
    }
    emitter.emit(type, e.source, safeParsedInner.data as any);
  });
  return emitter;
}

export { createEmitter, emit2Sw };
