import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import { memo } from "@zeffiroso/utils/react/memo";
import type { FC, ReactNode } from "react";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { ItemWithIcon } from "@/components/Menu/ItemWithIcon";
import { Select } from "@/components/Select";
import { orgQueriesContext } from "@/queriesContext/orgQueriesContext";
import { Dot } from "@/resources/user/onDutyStatus/Dot";
import { Name } from "@/resources/user/onDutyStatus/Name";
import { colorMap } from "@/resources/user/onDutyStatus/utils/colorMap";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  root: css({
    height: "auto",
    "& .ant-select-selector": {
      border: "none",
      paddingBlock: 2,
      paddingInlineStart: 6,
      backgroundColor: "transparent",
      fontSize: 12,
    },
    [["& .ant-select-selector", "& .ant-select-selection-search-input"].join(
      ", ",
    )]: {
      height: "auto",
    },
    [[
      "& .ant-select-selector",
      "& .ant-select-selector:after",
      "& .ant-select-selection-item",
    ].join(", ")]: {
      lineHeight: "normal",
    },
    "& .ant-select-arrow .anticon": {
      transition: "rotate 0.3s ease-in-out",
    },
    "&.ant-select-open .ant-select-arrow .anticon": {
      rotate: "180deg",
    },
  }),
});

type ValueType = CantataTypes["UserOnDutyStatus"];

type OptionType = {
  key: ValueType;
  value: ValueType;
  label: ReactNode;
};

type UserOnDutyStatusSelectorProps = Omit<
  Select.Props<ValueType, OptionType>,
  "options"
>;

function getStatusOption(status: CantataTypes["UserOnDutyStatus"]): OptionType {
  return {
    key: status,
    value: status,
    label: (
      <ItemWithIcon
        style={{
          color: colorMap[status],
          gap: 4,
        }}
        startIcon={<Dot status={status} size={6} />}
      >
        <Name status={status} />
      </ItemWithIcon>
    ),
  };
}

const UserOnDutyStatusSelector = memo(function UserOnDutyStatusSelector(
  props: UserOnDutyStatusSelectorProps,
) {
  return (
    <Select<ValueType, OptionType>
      {...props}
      options={[
        getStatusOption("online"),
        getStatusOption("offline"),
        getStatusOption("away"),
      ]}
    />
  );
});

const OnDutyStatus: FC = () => {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const mutation = cantata.user.useUpdateMyOnDutyStatus({
    params: {
      orgId,
    },
  });
  const orgQueriesData = orgQueriesContext.useData();
  const mutating = mutation.isLoading;
  const onChange = useHandler<
    ComponentProps<typeof UserOnDutyStatusSelector>["onChange"]
  >(function onChange(value) {
    mutation.mutate({
      onDutyStatus: value,
    });
  });
  return (
    <UserOnDutyStatusSelector
      css={styles.root}
      popupMatchSelectWidth={false}
      value={orgQueriesData.me.onDutyStatus}
      onChange={onChange}
      loading={mutating}
      {...(!mutating
        ? null
        : {
            inert: "",
          })}
    />
  );
};

export { OnDutyStatus };
