import { flutterWebViewAppSdk } from "@zeffiroso/flutter-webview-app-sdk/flutterWebViewAppSdk";
import { blobToDataUrl } from "@zeffiroso/utils/file/blobToDataUrl";
// eslint-disable-next-line no-restricted-imports -- Wrap the internal function to add the `base64` option for Flutter Webview App.
import { downloadFile as downloadFileInternal } from "@zeffiroso/utils/file/downloadFile";

import { getFeatureFlag } from "@/app/featureFlag";
import { Trans } from "@/app/i18n/Trans";
import { message } from "@/components/message";

const downloadFile = async function downloadFile(options) {
  const flutterWebviewAppDownload = getFeatureFlag("flutterWebviewAppDownload");
  if (
    flutterWebviewAppDownload === "always" ||
    (flutterWebviewAppDownload !== "never" &&
      flutterWebViewAppSdk.isFlutterWebViewApp)
  ) {
    const dataUrl = await blobToDataUrl(options.obj);
    try {
      await flutterWebViewAppSdk.downloadFile(dataUrl);
      message.success(
        <Trans i18nKey="flutterWebviewApp.downloadBase64Image.success.description" />,
      );
    } catch (error) {
      message.error(
        <Trans i18nKey="flutterWebviewApp.downloadBase64Image.fail.description" />,
      );
    }
    return;
  }
  return downloadFileInternal(options);
} satisfies typeof downloadFileInternal;

export { downloadFile };
