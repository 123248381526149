import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { define } from "@chatbotgang/etude/util/define";
import { css } from "@emotion/react";
import useSwitch from "@react-hook/switch";
import {
  EditableChannelTypeSchema,
  EditableMetaChannelTypeSchema,
} from "@zeffiroso/cantata/models";
import { theme } from "@zeffiroso/theme";
import { useUseAbortControllerStore } from "@zeffiroso/utils/react/abortControllerStore";
import { memo } from "@zeffiroso/utils/react/memo";
import { Radio } from "antd";
import type { ComponentProps, ReactNode } from "react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { fbSdk } from "@/app/fbSdk";
import { useGetFeatureFlag } from "@/app/featureFlag";
import { Trans } from "@/app/i18n/Trans";
import type { CantataTypes } from "@/cantata/types";
import { Button } from "@/components/Button";
import { DisabledContextProvider } from "@/components/Form/DisabledContext";
import { useMessage } from "@/components/message";
import { Modal } from "@/components/Modal";
import type { featureFlags } from "@/config/featureFlags";
import { ChannelTypeIcon } from "@/resources/channel/ChannelTypeIcon";
import { channelTypeScopeMap } from "@/resources/channel/facebook/scope";
import { useBulkCreateChannelMutation } from "@/resources/channel/mutations";
import {
  type FeatureControlName,
  useGetFeatureControl,
} from "@/resources/organization/featureControl";
import { usePlatformLink } from "@/resources/platform/platformLink";
import { useGetErrorMessage } from "@/shared/application/error/handleError";
import { handleNonGlobalApiError } from "@/shared/domains/error";
import { defineStyles } from "@/shared/emotion";
import { argsT } from "@/shared/g11n/argsT";

const cssAddChannelRadio = defineStyles({
  self: css`
    width: 100%;
    padding: 12px;
    border-radius: ${theme.shape.borderRadius};

    &.ant-radio-wrapper-checked {
      background-color: ${theme.colors.blue001};
    }
  `,
  content: css`
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    gap: 10px;
    line-height: 1.42;
  `,
  icon: css`
    font-size: 2.5rem;
  `,
  name: css`
    margin-bottom: 4px;
    font-weight: 700;
  `,
  description: css`
    color: ${theme.colors.neutral007};
  `,
});

const channelTypeToOptionTransUtilsMap: Record<
  CantataTypes["ChannelType"],
  { name: ReturnType<typeof argsT>; description: ReturnType<typeof argsT> }
> = (() => {
  const t = argsT;
  return {
    line: {
      name: t("organization.channels.add.modal.line.name"),
      description: t("organization.channels.add.modal.line.description"),
    },
    fb: {
      name: t("organization.channels.add.modal.fb.name"),
      description: t("organization.channels.add.modal.fb.description"),
    },
    ig: {
      name: t("organization.channels.add.modal.ig.name"),
      description: t("organization.channels.add.modal.ig.description"),
    },
    whatsapp: {
      name: t("organization.channels.add.modal.whatsapp.name"),
      description: t("organization.channels.add.modal.whatsapp.description"),
    },
    wccs: {
      name: t("organization.channels.add.modal.wccs.name"),
      description: t("organization.channels.add.modal.wccs.description"),
    },
  };
})();

const translationTransUtilsMap = (() => {
  const t = argsT;
  return define<
    Record<
      CantataTypes["EditableMetaChannelType"],
      {
        addChannelFailedTitle: ReturnType<typeof t>;
        addChannelFailedDescription: {
          channelBelongToAnotherOrg: ReturnType<typeof t>;
          common: ReturnType<typeof t>;
        };
        createChannelSuccess: ReturnType<typeof t>;
        loginFailedMessage: ReturnType<typeof t>;
      }
    >
  >({
    fb: {
      addChannelFailedTitle: t("organization.channels.addFbFailed.modal.title"),
      addChannelFailedDescription: {
        channelBelongToAnotherOrg: t(
          "organization.channels.addFbFailed.modal.conflict.description",
        ),
        common: t("organization.channels.addFbFailed.modal.description"),
      },
      createChannelSuccess: t("organization.channels.addFbSuccess.message"),
      loginFailedMessage: t("organization.channels.loginFbFailed.message.text"),
    },
    ig: {
      addChannelFailedTitle: t("organization.channels.addIgFailed.modal.title"),
      addChannelFailedDescription: {
        channelBelongToAnotherOrg: t(
          "organization.channels.addIgFailed.modal.conflict.description",
        ),
        common: t("organization.channels.addIgFailed.modal.description"),
      },
      createChannelSuccess: t("organization.channels.addIgSuccess.message"),
      loginFailedMessage: t("organization.channels.loginIgFailed.message.text"),
    },
  });
})();

const isEditableMetaChannel = (
  channelType: CantataTypes["Channel"]["type"],
): channelType is CantataTypes["EditableMetaChannelType"] => {
  return EditableMetaChannelTypeSchema.options.includes(channelType);
};

const availableChannels = define<
  Array<{
    channelType: CantataTypes["Channel"]["type"];
    requireFeature?: keyof typeof featureFlags;
    requireFeatureControl?: FeatureControlName;
  }>
>([
  ...EditableChannelTypeSchema.options.map((channelType) => ({
    channelType,
  })),
  {
    channelType: "whatsapp",
    requireFeatureControl: "whatsappAvailability",
  },
  {
    channelType: "wccs",
    requireFeatureControl: "wccsAvailability",
  },
]);

type AvailableChannelType = (typeof availableChannels)[number]["channelType"];

const GoToAdminCenterButton: React.FC = () => {
  const platformLink = usePlatformLink({ path: "channels" });
  return (
    <Button
      key="submit"
      type="primary"
      target="_blank"
      icon={null}
      href={platformLink.href}
      onClick={platformLink.handleClick}
      disabled={platformLink.isLoading}
      loading={platformLink.isLoading}
    >
      <Trans i18nKey="organization.channels.add.modal.goToAdminCenter.label" />
    </Button>
  );
};

const AddChannel = memo(function AddChannel() {
  const [addModalOpen, toggleAddModalOpen] = useSwitch(false);
  const [failReason, setFailReason] = useState<ReactNode>(null);
  const [channelType, setChannelType] = useState<AvailableChannelType>("fb");
  const checkFeature = useGetFeatureFlag();
  const { t } = useTranslation();
  const message = useMessage();
  const getErrorMessage = useGetErrorMessage();
  const useAbortControllerStore = useUseAbortControllerStore();
  const createChannelsMutation = useBulkCreateChannelMutation({
    onSuccess() {
      if (!isEditableMetaChannel(channelType)) return;
      toggleAddModalOpen.off();
      message.success(
        t(translationTransUtilsMap[channelType].createChannelSuccess.key),
      );
    },
    async onError(error) {
      if (!isEditableMetaChannel(channelType)) return;
      toggleAddModalOpen.off();
      const handled = await handleNonGlobalApiError(error, {
        CHANNEL_BELONG_TO_ANOTHER_ORG() {
          setFailReason(
            translationTransUtilsMap[channelType].addChannelFailedDescription
              .channelBelongToAnotherOrg.node,
          );
        },
      });
      /**
       * If the error is handled, return early.
       */
      if (handled) return;
      /**
       * Fallback to the default error message.
       */
      setFailReason(
        getErrorMessage(error, {
          unknownErrorMessage:
            translationTransUtilsMap[channelType].addChannelFailedDescription
              .common.node,
        }),
      );
    },
  });

  const handleClose = useHandler(function handleClose() {
    setFailReason(undefined);
  });

  const abortAndReset = useHandler(function abortAndReset() {
    createChannelsMutation.reset();
    useAbortControllerStore.getState().abort();
  });

  useEffect(() => {
    return function cleanUp() {
      abortAndReset();
    };
  }, [abortAndReset]);

  const handleCancel = useHandler(function handleCancel() {
    if (createChannelsMutation.isLoading) return;

    toggleAddModalOpen.off();
  });

  const handleChange = useHandler<
    ComponentProps<typeof Radio.Group>["onChange"]
  >(function handleChange(event) {
    setChannelType(event.target.value);
  });

  const handleNext = useHandler(function handleNext() {
    if (createChannelsMutation.isLoading) return;
    if (!isEditableMetaChannel(channelType)) return;
    loginMutation.mutate({
      loginOptions: {
        scope: channelTypeScopeMap[channelType],
      },
    });
  });

  const loginMutation = fbSdk.hooks.mutations.useLoginMutation({
    onSuccess(data) {
      if (!isEditableMetaChannel(channelType)) return;
      if (data.status !== "connected") {
        message.error(
          t(translationTransUtilsMap[channelType].loginFailedMessage.key) +
            inspectMessage`, status: ${data.status}`,
        );
        return;
      }

      useAbortControllerStore.getState().abort();
      createChannelsMutation.mutate({
        channelType,
        signal: useAbortControllerStore.getState().signal,
      });
    },
    onError(e) {
      if (!isEditableMetaChannel(channelType)) return;
      message.error(
        getErrorMessage(e, {
          unknownErrorMessage: t(
            translationTransUtilsMap[channelType].loginFailedMessage.key,
          ),
        }),
      );
    },
  });

  const checkFeatureControl = useGetFeatureControl();

  const renderAvailableChannels = useMemo(() => {
    return availableChannels.filter((channel) => {
      // if requireFeature and requireFeatureControl is not defined, return true
      // if one of them is defined, check the feature
      // if one of them return checks truthy, return true
      // No requirements, always include
      if (!channel.requireFeature && !channel.requireFeatureControl)
        return true;

      // Include if any requirement check passes
      return (
        (channel.requireFeature && checkFeature(channel.requireFeature)) ||
        (channel.requireFeatureControl &&
          checkFeatureControl(channel.requireFeatureControl))
      );
    });
  }, [checkFeature, checkFeatureControl]);

  return (
    <>
      <Button type="primary" onClick={toggleAddModalOpen.on}>
        <Trans i18nKey="organization.channels.add" />
      </Button>
      <DisabledContextProvider disabled={createChannelsMutation.isLoading}>
        <Modal
          open={addModalOpen}
          onCancel={handleCancel}
          title={<Trans i18nKey="organization.channels.add.modal.title" />}
          footer={[
            <Button key="cancel" type="default" onClick={handleCancel}>
              <Trans i18nKey="common.cancel" />
            </Button>,
            isEditableMetaChannel(channelType) ? (
              <Button
                key="submit"
                type="primary"
                onClick={handleNext}
                loading={createChannelsMutation.isLoading}
              >
                <Trans i18nKey="organization.channels.add.modal.confirm" />
              </Button>
            ) : (
              <GoToAdminCenterButton />
            ),
          ]}
        >
          <Radio.Group
            onChange={handleChange}
            value={channelType}
            style={{ display: "block" }}
          >
            {renderAvailableChannels.map((channel) => (
              <Radio
                key={`channelType_${channel.channelType}`}
                value={channel.channelType}
                css={cssAddChannelRadio.self}
              >
                <div css={cssAddChannelRadio.content}>
                  <ChannelTypeIcon
                    channelType={channel.channelType}
                    css={cssAddChannelRadio.icon}
                  />
                  <div>
                    <div css={cssAddChannelRadio.name}>
                      {
                        channelTypeToOptionTransUtilsMap[channel.channelType]
                          .name.node
                      }
                    </div>
                    <div css={cssAddChannelRadio.description}>
                      {
                        channelTypeToOptionTransUtilsMap[channel.channelType]
                          .description.node
                      }
                    </div>
                  </div>
                </div>
              </Radio>
            ))}
          </Radio.Group>
        </Modal>
      </DisabledContextProvider>
      {!isEditableMetaChannel(channelType) ? null : (
        <Modal
          open={Boolean(failReason)}
          onCancel={handleClose}
          title={
            translationTransUtilsMap[channelType].addChannelFailedTitle.node
          }
          footer={[
            <Button key="confirm" type="primary" onClick={handleClose}>
              <Trans i18nKey="organization.channels.addFbFailed.modal.confirm" />
            </Button>,
          ]}
        >
          {failReason}
        </Modal>
      )}
    </>
  );
});

export { AddChannel };
