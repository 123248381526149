import { PictureOutlined } from "@ant-design/icons";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import styled from "@emotion/styled";
import { theme } from "@zeffiroso/theme";
import { Form } from "antd";
import { extname } from "pathe";
import { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import {
  MAX_CHAT_UPDATE_IMAGE_DIMENSION,
  MAX_CHAT_UPLOAD_IMAGE_HEIGHT,
  MAX_CHAT_UPLOAD_IMAGE_WIDTH,
} from "@/appConstant";
import type { FileInputProps } from "@/components/Input";
import { FileInput } from "@/components/Input";
import { BarLoading } from "@/components/Loading/BarLoading";
import { useMessage } from "@/components/message";
import { ga4Event } from "@/lib/ga4";
import {
  validateImage,
  validator,
} from "@/resources/attachment/attachmentValidator";
import { uploadToFirebaseStorage } from "@/resources/attachment/uploadToFirebaseStorage";
import { buildImageMessage } from "@/routes/QuickTemplate/applications/utils";
import { SelectedChannelContext } from "@/routes/QuickTemplate/SelectedChannelContext";
import { FileFieldWrapper } from "@/routes/QuickTemplate/ui/FileFieldWrapper";
import { UploadFileLabel } from "@/routes/QuickTemplate/ui/UploadFileLabel";
import {
  ReplaceUploadHints,
  UploadHints,
} from "@/routes/QuickTemplate/ui/UploadHints";

const ImageFieldWrapper = styled(FileFieldWrapper)`
  --width: ${theme.shape.mediaFieldSize};
  --height: ${theme.shape.mediaFieldSize};
`;

type QuickTemplateImageMessageFormValue = ReturnType<typeof buildImageMessage>;

const ImageField = ({
  value,
  onChange,
}: {
  value?: QuickTemplateImageMessageFormValue;
  onChange?: (value: QuickTemplateImageMessageFormValue) => void;
}): React.ReactNode => {
  const { t } = useTranslation();
  const { status } = Form.Item.useStatus();
  const labelRef = useRef<HTMLLabelElement>(null);
  const uploadImageFile = useRef<File>();
  const [isLoading, setIsLoading] = useState(false);
  const message = useMessage();
  const orgId = useActiveOrgIdStore((state) => state.value);
  const channel = SelectedChannelContext.useContext();
  const channelId = channel.id;

  const uploadImage = useHandler(async (selectedFile: File) => {
    const validateFileError = validateImage(channel.type, selectedFile);
    if (validateFileError) {
      message.error(validateFileError.reactNode);
      return;
    }

    const downloadLink = await uploadToFirebaseStorage({
      feature: "quickTemplate",
      pathParams: {
        channelId: channel.id,
      },
      file: selectedFile,
    });

    const draft = value ?? buildImageMessage();

    onChange?.({
      ...draft,
      originUrl: downloadLink.downloadUrl,
      previewUrl: downloadLink.downloadUrl,
    });
    setIsLoading(false);
  });

  const handleFileChange = useHandler<FileInputProps["onChange"]>(
    async function handleFileChange(e) {
      if (e.currentTarget.files === null || e.currentTarget.files.length === 0)
        return;

      const file = e.currentTarget.files[0];
      if (!file) return;
      ga4Event("attachmentUpload", {
        orgId,
        channelId,
        extname: extname(file.name),
        fileSize: file.size,
        feature: "quickTemplate",
      });
      try {
        const img = new Image();
        img.src = window.URL.createObjectURL(file);
        uploadImageFile.current = file;

        img.onload = async () => {
          const validateError = validateImage(channel.type, file);
          if (validateError) {
            message.error(validateError.reactNode);
            return;
          }
          if (
            img.naturalWidth > MAX_CHAT_UPLOAD_IMAGE_WIDTH ||
            img.naturalHeight > MAX_CHAT_UPLOAD_IMAGE_HEIGHT
          ) {
            message.error(
              t("message.uploadWidthHeightLimit", {
                size: MAX_CHAT_UPDATE_IMAGE_DIMENSION,
              }),
            );
            return;
          }
          setIsLoading(true);
          await uploadImage(file);
        };
      } catch (error) {
        message.error(
          t("common.failToFetchModule", { module: t("glossary.image") }),
        );
      }
    },
  );

  const accept = useMemo(
    () =>
      validator[channel.type].rules.image.extnames
        .map((ext) => `.${ext}`)
        .join(","),
    [channel.type],
  );

  return (
    <ImageFieldWrapper $status={status}>
      <UploadFileLabel $imageUrl={value?.originUrl} ref={labelRef}>
        <FileInput accept={accept} onChange={handleFileChange} />
        {isLoading && <BarLoading />}
      </UploadFileLabel>
      {value?.originUrl !== "" && (
        <ReplaceUploadHints
          hintDescription={t("common.replace")}
          onClick={() => labelRef.current?.click()}
        >
          <PictureOutlined />
        </ReplaceUploadHints>
      )}
      {!isLoading && value?.originUrl === "" && (
        <UploadHints
          hintTitle={t("common.uploadImage")}
          hintDescription={t("quickTemplate.imageUploadLimit", {
            fileSize: "10mb",
            widthHeight: MAX_CHAT_UPDATE_IMAGE_DIMENSION,
          })}
        >
          <PictureOutlined />
        </UploadHints>
      )}
    </ImageFieldWrapper>
  );
};

export { ImageField };
