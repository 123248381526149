import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { safePromise } from "@chatbotgang/etude/safe/safePromise";
import { flow, uniq } from "lodash-es";
import { type FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { createWithEqualityFn } from "zustand/traditional";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata, cantataFree } from "@/cantata";
import { ErrorBoundary } from "@/components/ErrorBoundary";
import { Modal, type ModalProps } from "@/components/Modal";
import {
  EasyForm,
  Form,
} from "@/routes/Settings/Users/People/List/BatchInviteUsersModal/Form";
import {
  Loader,
  type Task,
} from "@/routes/Settings/Users/People/List/BatchInviteUsersModal/Loader";
import { Result } from "@/routes/Settings/Users/People/List/BatchInviteUsersModal/Result";
import { getApiErrorBodyFromUnknownError } from "@/shared/domains/error";

function setupTasksUtils() {
  type State = {
    tasks: Task[];
  };

  const useStore = createWithEqualityFn<State>(() => ({
    tasks: [],
  }));

  function setupTasks(emails: string[]) {
    useStore.setState(() => ({
      tasks: emails.map((email) => ({
        email,
        state: "pending",
      })),
    }));
  }

  function updateTask(email: string, invitingState: Task["state"]) {
    const items = useStore.getState().tasks;
    if (!items.some((item) => item.email === email)) {
      return;
    }
    useStore.setState((state) => ({
      tasks: state.tasks.map((item) =>
        item.email === email ? { ...item, state: invitingState } : item,
      ),
    }));
  }

  return {
    useStore,
    setupTasks,
    updateTask,
  };
}

type ModalStep = "form" | "loader" | "result";

type ModalStepConfig = {
  modalMoreProps: ModalProps;
  renderComponent: () => React.ReactNode;
};

interface BatchInviteUsersModalProps {
  onCancel: () => void;
}

const BatchInviteUsersModal: FC<BatchInviteUsersModalProps> = ({
  onCancel,
}) => {
  const { t } = useTranslation();
  const orgId = useActiveOrgIdStore((state) => state.value);
  const [easyForm, form] = EasyForm.useForm();
  const [currentModalStep, setCurrentModalStep] = useState<ModalStep>("form");
  const [verifiedEmailsCount, setVerifiedEmailsCount] = useState<number | null>(
    null,
  );

  const isEmailsVerified = Boolean(verifiedEmailsCount);

  const inviteUserMutation = cantata.user.useInviteUser({
    params: {
      orgId,
    },
  });
  const inviteUserMutationWithoutInvalidate = cantataFree.user.useInviteUser({
    params: {
      orgId,
    },
  });

  const verifyEmails = useHandler(() => {
    const emails: string = easyForm.controller.getFieldValue("emails");
    // Emails can be separated by comma, space, tab, or newline
    const verifiedEmails = flow(
      () =>
        emails.split(/,|\s|\t|\n/).flatMap((email) => {
          const formattedEmail = email.trim().toLowerCase();
          return !formattedEmail ||
            !z.string().email().safeParse(formattedEmail).success
            ? []
            : [formattedEmail];
        }),
      uniq,
    )();
    easyForm.controller.setFieldsValue({
      emails: verifiedEmails.join("\n"),
    });
    setVerifiedEmailsCount(verifiedEmails.length);
  });

  const [tasksUtils] = useState(setupTasksUtils);
  const tasks = tasksUtils.useStore((state) => state.tasks);

  const handleFinishForm = useHandler<
    ComponentProps<typeof EasyForm>["onFinish"]
  >(async (values) => {
    const emails = values.emails.split("\n");
    tasksUtils.setupTasks(emails);

    setCurrentModalStep("loader");

    for (let i = 0; i < emails.length; i++) {
      const email = emails[i];
      if (!email)
        throw new Error(inspectMessage`email is falsy, email: ${email}`);
      const isLastMail = i === emails.length - 1;
      /**
       * Only invalidate query cache after the last email update
       * to prevent unnecessary re-fetching api.
       */
      const inviteUser = isLastMail
        ? inviteUserMutation
        : inviteUserMutationWithoutInvalidate;
      const result = await safePromise(async () =>
        inviteUser.mutateAsync({ email, roleId: values.roleId }),
      );
      if (result.error) {
        const errorApiBody = getApiErrorBodyFromUnknownError(result.error);
        if (errorApiBody?.name === "PARAMETER_USER_EXIST") {
          tasksUtils.updateTask(email, "exist");
          continue;
        }
        tasksUtils.updateTask(email, "error");
        continue;
      }
      tasksUtils.updateTask(email, "success");
    }

    setCurrentModalStep("result");
  });

  const modalStepsConfig: Record<ModalStep, ModalStepConfig> = useMemo(
    () => ({
      form: {
        modalMoreProps: isEmailsVerified
          ? {
              onOk: form.submit,
              okText: t("page.settings.users.invite.batch.action.invite"),
            }
          : {
              onOk: verifyEmails,
              okText: t("page.settings.users.invite.batch.action.verify"),
            },
        renderComponent: () => (
          <Form
            form={form}
            verifiedEmailsCount={verifiedEmailsCount}
            resetVerifiedEmailsCount={() => setVerifiedEmailsCount(null)}
            handleFinishForm={handleFinishForm}
          />
        ),
      },
      loader: {
        modalMoreProps: {
          okButtonProps: {
            style: {
              display: "none",
            },
          },
          cancelButtonProps: {
            style: {
              display: "none",
            },
          },
        },
        renderComponent: () => <Loader tasks={tasks} />,
      },
      result: {
        modalMoreProps: {
          onOk: onCancel,
          okText: t("page.settings.users.invite.batch.action.done"),
          cancelButtonProps: {
            style: {
              display: "none",
            },
          },
        },
        renderComponent: () => <Result tasks={tasks} />,
      },
    }),
    [
      form,
      tasks,
      isEmailsVerified,
      verifiedEmailsCount,
      verifyEmails,
      handleFinishForm,
      onCancel,
      t,
    ],
  );

  return (
    <ErrorBoundary.Modal ModalProps={{ onCancel }}>
      <Modal
        open
        closable={currentModalStep !== "loader"}
        title={t("organization.addUser")}
        cancelText={t("common.cancel")}
        onCancel={onCancel}
        {...modalStepsConfig[currentModalStep].modalMoreProps}
      >
        {modalStepsConfig[currentModalStep].renderComponent()}
      </Modal>
    </ErrorBoundary.Modal>
  );
};

export { BatchInviteUsersModal };
