import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ThemeProvider } from "@zeffiroso/theme/Provider";
import type { ReactNode } from "react";
import { Suspense } from "react";

import { useFeatureFlag } from "@/app/featureFlag";
import { WaveLoading } from "@/components/Loading/WaveLoading";
import { Helmet } from "@/internal/helmet/Helmet";
import { MessageProvider } from "@/internal/message";
import { PageErrorBoundary } from "@/internal/PageErrorBoundary";
import { PwaUpdatePrompt } from "@/internal/PwaUpdatePrompt";
import { RequirePermission } from "@/internal/requirePermission";
import { Zendesk } from "@/internal/zendesk/Zendesk";
import { DevMode } from "@/layout/DevMode";
import { queryClient } from "@/lib/react-query/queryClient";
import { MutationErrorEmitter } from "@/lib/react-query/useMutationError";
import { Router } from "@/router/Router";
import { ApplyI18nFromFeatureFlag } from "@/shared/g11n/i18n";
import { LocaleProvider } from "@/shared/store/locale";

const App: React.FC = () => {
  return <Router />;
};

const ReactQueryProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const position = useFeatureFlag("reactQueryDevtool");
  return (
    <QueryClientProvider client={queryClient}>
      <MutationErrorEmitter />
      {!position ? null : (
        <ReactQueryDevtools initialIsOpen={false} position={position} />
      )}
      {children}
    </QueryClientProvider>
  );
};

const AppProviders: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  return (
    <ThemeProvider>
      <PageErrorBoundary reloadWindow>
        <Suspense fallback={<WaveLoading />}>
          <MessageProvider>
            <LocaleProvider>
              <ReactQueryProvider>
                <Helmet />
                {children}
                <DevMode />
                <PwaUpdatePrompt />
                <Zendesk />
                <ApplyI18nFromFeatureFlag />
                <RequirePermission />
              </ReactQueryProvider>
            </LocaleProvider>
          </MessageProvider>
        </Suspense>
      </PageErrorBoundary>
    </ThemeProvider>
  );
};

export { App, AppProviders };
