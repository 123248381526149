import styled from "@emotion/styled";
import { theme } from "@zeffiroso/theme";

const TextPreviewWrapper = styled.div`
  display: inline-block;
  width: auto;
  min-width: 30px;
  max-width: calc(100% - 64px);
  height: auto;
  min-height: 30px;
  box-sizing: border-box;
  padding: 5px 10px;
  border-radius: ${theme.shape.borderRadius};
  background-color: white;
  line-height: 1.5;
  white-space: pre-wrap;
`;

const TextPreview = ({ text }: { text: string }): React.ReactNode => {
  return <TextPreviewWrapper>{text}</TextPreviewWrapper>;
};

export { TextPreview };
