import type { ComponentProps } from "@chatbotgang/etude/react/ComponentProps";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { memo } from "@zeffiroso/utils/react/memo";
import { shallow } from "@zeffiroso/utils/zustand/shallow";
import { uniqBy } from "lodash-es";
import { createContext, useContext, useMemo } from "react";
import { createWithEqualityFn } from "zustand/traditional";

import type { CantataTypes } from "@/cantata/types";
import { API_BULK_ACTION_LIMIT } from "@/env";
import { useMembersController } from "@/routes/Chat/ui/MembersPanel/controllers/membersController";
import { sortByPinnedAndLastMessageAtDesc } from "@/routes/Chat/ui/MembersPanel/sortMembers";

type Store = {
  selectionMembers: Array<CantataTypes["Member"]>;
  selectedMembers: Array<CantataTypes["Member"]>;
  isSelecting: boolean;
};

const initialStore: Store = {
  selectionMembers: [],
  selectedMembers: [],
  isSelecting: false,
};

function setupStore() {
  const useStore = createWithEqualityFn<Store>()(() => initialStore, shallow);

  function addSelectionMembers(members: CantataTypes["Member"][]) {
    useStore.setState(({ selectionMembers: prev }) => ({
      selectionMembers: uniqBy([...prev, ...members], "id").sort(
        sortByPinnedAndLastMessageAtDesc,
      ),
    }));
  }
  function addSelectedMembers(members: CantataTypes["Member"][]) {
    useStore.setState(({ selectedMembers: prev }) => ({
      selectedMembers: uniqBy([...prev, ...members], "id").sort(
        sortByPinnedAndLastMessageAtDesc,
      ),
    }));
  }
  function selectMembersByIds(memberIds: Array<CantataTypes["Member"]["id"]>) {
    const members = useStore.getState().selectionMembers;
    const membersToSelect = members.filter((member) =>
      memberIds.includes(member.id),
    );
    addSelectedMembers(membersToSelect);
  }
  function deselectMembersByIds(
    memberIds: Array<CantataTypes["Member"]["id"]>,
  ) {
    const members = useStore.getState().selectedMembers;
    const nextSelectedMembers = members.filter(
      (member) => !memberIds.includes(member.id),
    );
    if (nextSelectedMembers.length === members.length) return;
    useStore.setState({
      selectedMembers: nextSelectedMembers,
    });
  }
  function clearSelectedMembers() {
    useStore.setState({
      selectedMembers: [],
    });
  }
  function reset() {
    useStore.setState(initialStore);
  }
  function useIsSelecting() {
    return useStore((state) => state.isSelecting);
  }
  function useSelectionMembers() {
    return useStore((state) => state.selectionMembers);
  }
  function useSelectedMembers() {
    return useStore((state) => state.selectedMembers);
  }
  function useIsAllSelected() {
    const { selectionMembers, selectedMembers } = useStore((state) => ({
      selectionMembers: state.selectionMembers,
      selectedMembers: state.selectedMembers,
    }));
    return (
      selectionMembers.length > 0 &&
      selectionMembers.length === selectedMembers.length
    );
  }
  function useIsReachedLimit() {
    const { selectedMembers } = useStore((state) => ({
      selectedMembers: state.selectedMembers,
    }));
    return selectedMembers.length >= API_BULK_ACTION_LIMIT;
  }
  function useIsNoneSelected() {
    const { selectedMembers } = useStore((state) => ({
      selectedMembers: state.selectedMembers,
    }));
    return selectedMembers.length === 0;
  }
  function useEnableSelection() {
    const membersController = useMembersController();
    const members = membersController.useMembers();
    const enableSelection = useHandler(function enableSelection() {
      useStore.setState({
        isSelecting: true,
        selectionMembers: members,
        selectedMembers: [],
      });
    });
    return enableSelection;
  }
  function toggleAllMemberSelection() {
    const state = useStore.getState();
    const selectionMembers = state.selectionMembers;
    const selectedMembers = state.selectedMembers;
    const allSelected =
      selectionMembers.length > 0 &&
      selectionMembers.length === selectedMembers.length;
    if (allSelected) {
      clearSelectedMembers();
      return;
    }
    const currentSelectedCount = state.selectedMembers.length;
    const isReachedLimit = currentSelectedCount >= API_BULK_ACTION_LIMIT;
    if (isReachedLimit) {
      clearSelectedMembers();
      return;
    }
    const remainingSelectionCount =
      API_BULK_ACTION_LIMIT - currentSelectedCount;
    const unSelectedMembers = selectionMembers.filter(
      (member) => !selectedMembers.includes(member),
    );
    const membersToSelect = unSelectedMembers.slice(0, remainingSelectionCount);
    addSelectedMembers(membersToSelect);
  }
  return {
    useIsSelecting,
    useSelectionMembers,
    useSelectedMembers,
    useIsAllSelected,
    useIsReachedLimit,
    useIsNoneSelected,
    useEnableSelection,
    addSelectionMembers,
    addSelectedMembers,
    selectMembersByIds,
    deselectMembersByIds,
    clearSelectedMembers,
    reset,
    toggleAllMemberSelection,
    useStore,
  };
}

const Context = createContext<null | ReturnType<typeof setupStore>>(null);

const SelectionControllerProvider = memo<
  Omit<ComponentProps<typeof Context.Provider>, "value">
>(function SelectionControllerProvider(props) {
  const useSelectionController = useMemo(setupStore, []);
  return <Context.Provider value={useSelectionController} {...props} />;
});

function useSelectionController() {
  const useSelectionController = useContext(Context);
  if (useSelectionController === null) {
    throw new Error(
      "useSelectionController must be used within SelectionControllerProvider",
    );
  }

  return useSelectionController;
}

export { SelectionControllerProvider, useSelectionController };
