import { makeApi } from "@zodios/core";
import { z } from "zod";

import {
  ChannelSchema,
  MemberSchema,
  OrgSchema,
  TagSchema,
  TeamSchema,
  UserSchema,
} from "../models";

const api = makeApi([
  {
    /**
     * bind unification code to assignee and member.
     * [Notion](https://www.notion.so/cresclab/Redirect-unification-19f8ce15893880eb8c7afa4a442c6e93?pvs=4#1a08ce15893880b9a7e2dfce5529f6b0)
     */
    alias: "createUnificationRecord",
    method: "post",
    path: "/api/v1/orgs/:orgId/organization/members/:memberId/unification-record",
    parameters: [
      {
        type: "Path",
        name: "orgId",
        schema: OrgSchema.shape.id,
      },
      {
        type: "Path",
        name: "memberId",
        schema: MemberSchema.shape.id,
      },
      {
        type: "Body",
        name: "body",
        schema: z.object({
          unificationCode: z.string(),
          channelId: ChannelSchema.shape.id,
          assignee: z
            .object({
              teamId: TeamSchema.shape.id.nullable(),
              userId: UserSchema.shape.id.nullable(),
            })
            .nullable(),
          tags: TagSchema.shape.id.array(),
        }),
      },
    ],
    response: z.unknown(),
  },
]);

export { api };
