import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { createContext } from "@chatbotgang/etude/react/createContext";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { safePromise } from "@chatbotgang/etude/safe/safePromise";
import { css } from "@emotion/react";
import useSwitch from "@react-hook/switch";
import { theme } from "@zeffiroso/theme";
import { usePortal } from "@zeffiroso/utils/react-lib/usePortal";
import copy from "copy-to-clipboard";
import insertTextAtCursor from "insert-text-at-cursor";
import type { ComponentRef, FC, ReactNode, Ref } from "react";
import { useCallback, useMemo } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { Trans } from "@/app/i18n/Trans";
import type { CantataTypes } from "@/cantata/types";
import { Button } from "@/components/Button";
import { NarrowIconButton } from "@/components/Button/NarrowIconButton";
import { ErrorBoundary } from "@/components/ErrorBoundary";
import { createEasyForm } from "@/components/Form/createEasyForm";
import { Input } from "@/components/Input";
import { Modal } from "@/components/Modal";
import { MotifIcon } from "@/components/MotifIcon";
import { Popover } from "@/components/Popover";
import { Tooltip } from "@/components/Tooltip";
import { useMessage } from "@/internal/message";
import { ga4Event } from "@/lib/ga4";
import { memberQueriesContext } from "@/queriesContext/memberQueriesContext";
import { orgQueriesContext } from "@/queriesContext/orgQueriesContext";
import { useFeatureControl } from "@/resources/organization/featureControl";
import { ReferrerSelector } from "@/resources/user/ReferrerSelector";
import { useTextAreaRef } from "@/routes/Chat/ui/ChatPanel/Editor/Old/textAreaRef";
import { Layout as ChatLayout } from "@/routes/Chat/ui/Layout";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  SalesTracelinkForm: css({
    display: "flex",
    flexDirection: "column",
    justifyContent: "stretch",
    gap: 16,
    ".ant-form-item-label": {
      paddingBottom: 4,
    },
    ".ant-form-item": {
      height: "auto",
      marginBottom: "auto",
    },
  }),
  content: css({
    display: "flex",
    flexDirection: "column",
    justifyContent: "stretch",
    gap: 8,
    "&>*": {
      width: "100%",
    },
  }),
  title: css({
    display: "flex",
    justifyContent: "space-between",
    gap: "inherit",
    alignItems: "center",
  }),
  footer: css({
    display: "flex",
    justifyContent: "flex-end",
    gap: 12,
  }),
  formTitle: css({
    fontSize: "1rem",
    fontWeight: 500,
  }),
  formDesc: css({
    fontSize: "0.875rem",
    color: theme.colors.neutral007,
  }),
  formItem: css({
    display: "flex",
    flexDirection: "column",
    gap: 4,
  }),
  formLabel: css({
    fontSize: "0.875rem",
    color: theme.colors.neutral010,
  }),
  fullWidth: css({
    width: "100%",
  }),
});

type FormValues = {
  url: string;
  referrer: ReferrerSelector.ValueType;
  campain: string;
};

const initialFormValues: FormValues = {
  url: "",
  referrer: undefined,
  campain: "",
};

const EasyForm = createEasyForm<FormValues>();

const useSetupSalesTracelinkModal = () => {
  const [open, toggle] = useSwitch();

  const reset = useHandler(() => {
    toggle.off();
  });

  const openModal = useHandler(() => {
    toggle.on();
  });

  const closeModal = useHandler(() => {
    reset();
  });

  const ret = useMemo(
    () => ({
      open,
      openModal,
      closeModal,
      reset,
    }),
    [closeModal, open, openModal, reset],
  );

  return ret;
};

const Context = createContext<ReturnType<typeof useSetupSalesTracelinkModal>>({
  name: "SalesTracelinkModalContext",
});

const useSalesTracelinkFormModal = Context.useContext;

/**
 * To prepend protocol if missing from URL
 */
const prependProtocol = (url: string): string =>
  /^[a-zA-Z]+:\/\//.test(url) ? url : `https://${url}`;

/**
 * build tracking code for 91 chat commerce
 * [91 Chat commerce](https://docs.google.com/document/d/16-yTyJ9qSY5OaMNwYRaEuYuR_zlodkCIQBmwemPORn8/edit#bookmark=id.r8xuazo5bbsi)
 */
function formatTripId({
  customId = "",
  externalTeamId,
  externalUserId = "",
}: {
  customId?: CantataTypes["MemberDetail"]["customId"];
  externalTeamId: CantataTypes["Team"]["externalTeamId"];
  externalUserId?: CantataTypes["User"]["externalUserId"];
}) {
  const timestamp = new Date().getTime();
  return `${customId}:${externalTeamId}:${externalUserId}:${timestamp}`;
}

/**
 * build tracking code for sales trace link
 * [Chat commerce](https://docs.google.com/document/d/16-yTyJ9qSY5OaMNwYRaEuYuR_zlodkCIQBmwemPORn8/edit?tab=t.0#bookmark=id.7szcm5b2kec2)
 */
const useGetUtmTerm = () => {
  const member = memberQueriesContext.useMember();
  return useCallback(
    (referrer: NonNullable<ReferrerSelector.ValueType>) => {
      return [
        "caac",
        member.channelId,
        member.id,
        !("teamId" in referrer) ? "" : referrer.teamId,
        !("userId" in referrer) ? "" : referrer.userId,
        Date.now(),
      ].join(":");
    },
    [member.channelId, member.id],
  );
};

const useFormatLink = () => {
  const orgData = orgQueriesContext.useData();
  const member = memberQueriesContext.useMember();
  const utmSetting = orgData.utmSetting;
  const user = orgData.me;
  const getUtmTerm = useGetUtmTerm();
  const formatter = useCallback(
    (values: FormValues): string => {
      const url = new URL(prependProtocol(values.url));
      url.searchParams.set("utm_source", utmSetting.source);
      url.searchParams.set("utm_medium", utmSetting.medium);
      url.searchParams.set("utm_campaign", values.campain);

      if (values.referrer) {
        url.searchParams.set("utm_term", getUtmTerm(values.referrer));
      }

      if (orgData.org.salesBindingProvider === "91") {
        if (values.referrer === undefined)
          throw new Error("referrer is required in 91");

        const referrer = values.referrer;
        if (referrer.type === "user")
          throw new Error(
            inspectMessage`Unexpected referrer type ${referrer.type} in 91`,
          );

        const team = user.teams.find((team) => team.id === referrer.teamId);
        if (!team)
          throw new Error(inspectMessage`Unexpected teamId ${referrer.teamId}`);

        const tripId = formatTripId({
          ...(referrer.type !== "user-in-team"
            ? {}
            : {
                externalUserId: user.externalUserId,
              }),
          customId: member.customId,
          externalTeamId: team.externalTeamId,
        });
        // cspell:words tripid
        url.searchParams.set("tripid", tripId);
      }
      return url.toString();
    },
    [
      utmSetting.source,
      utmSetting.medium,
      orgData.org.salesBindingProvider,
      getUtmTerm,
      user.teams,
      user.externalUserId,
      member.customId,
    ],
  );

  return formatter;
};

const SalesTracelinkForm: FC = () => {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const orgQueriesData = orgQueriesContext.useData();
  const { t } = useTranslation();
  const [, form] = EasyForm.useForm();
  const formModal = useSalesTracelinkFormModal();
  const textAreaRef = useTextAreaRef();
  const message = useMessage();
  const formatLink = useFormatLink();
  const member = memberQueriesContext.useMember();

  const handleCopyLink = useHandler(async function handleCopyLink() {
    const result = await safePromise(form.validateFields);
    if (!result.isSuccess) return;

    const formatted = formatLink(result.data);
    copy(formatted);

    message.success(t("common.copiedToClipboard"));
    formModal.closeModal();

    ga4Event("salesTraceLinkClick", {
      type: "copy",
      channelId: member.channelId,
      memberId: member.id,
    });
  });

  const onFinish = useHandler(function onFinish(values: FormValues) {
    const link = formatLink(values);

    if (!textAreaRef.current || !textAreaRef.current.resizableTextArea) return;

    insertTextAtCursor(
      textAreaRef.current.resizableTextArea.textArea,
      `\n${link}\n`,
    );
    formModal.closeModal();

    ga4Event("salesTraceLinkClick", {
      type: "insert",
      channelId: member.channelId,
      memberId: member.id,
    });
  });

  return (
    <EasyForm
      css={styles.SalesTracelinkForm}
      requiredMark="optional"
      layout="vertical"
      form={form}
      onFinish={onFinish}
      initialValues={initialFormValues}
    >
      <div css={styles.content}>
        <div css={styles.title}>
          <div css={styles.formTitle}>
            <Trans i18nKey="chat.salesTracelink.form.title" />
          </div>
          <NarrowIconButton
            size={28}
            iconSize="small"
            onClick={formModal.closeModal}
            icon={<MotifIcon un-i-motif="cross" />}
          />
        </div>
        <div css={styles.formDesc}>
          <Trans i18nKey="chat.salesTracelink.form.desc" />
        </div>
        <EasyForm.Item
          name="url"
          required
          rules={[
            {
              required: true,
              message: t("chat.salesTracelink.form.requireUrl"),
              transform: (value) => {
                if (typeof value !== "string" || !value) return value;
                return prependProtocol(value);
              },
            },
            {
              type: "url",
              message: t("chat.salesTracelink.form.invalidUrlFormat"),
            },
          ]}
        >
          <Input
            css={styles.fullWidth}
            placeholder={t("chat.salesTracelink.form.link.placeholder")}
          />
        </EasyForm.Item>
        <EasyForm.Item
          name="referrer"
          required
          rules={[
            {
              required: true,
              message: t("resource.user.referrerSelect.required"),
            },
          ]}
          label={<Trans i18nKey="chat.salesTracelink.form.referrer.title" />}
        >
          <ReferrerSelector
            orgId={orgId}
            userId={orgQueriesData.me.id}
            isExternalIdRequiredForTeams
          />
        </EasyForm.Item>
        <EasyForm.Item
          name="campain"
          label={<Trans i18nKey="chat.salesTracelink.form.campain.title" />}
        >
          <Input
            placeholder={t("chat.salesTracelink.form.campain.placeholder")}
          />
        </EasyForm.Item>
      </div>
      <div css={styles.footer}>
        <Button
          onClick={handleCopyLink}
          crescendolab-selector="chat-sales_binding-copyButton"
        >
          {t("chat.salesTracelink.form.copyLink")}
        </Button>
        <Button
          type="primary"
          htmlType="submit"
          crescendolab-selector="chat-sales_binding-insertButton"
        >
          {t("chat.salesTracelink.form.insertLink")}
        </Button>
      </div>
    </EasyForm>
  );
};

const useEnabledSalesTracelink = () => {
  const enabledFeatureControl = useFeatureControl(
    "nineOneIntegrationOrgAvailability",
  );
  const orgData = orgQueriesContext.useData();
  const ret = useMemo(() => {
    return (
      enabledFeatureControl &&
      orgData.org.salesBindingProvider === "91" &&
      orgData.utmSetting.medium &&
      orgData.utmSetting.source
    );
  }, [
    enabledFeatureControl,
    orgData.org.salesBindingProvider,
    orgData.utmSetting.medium,
    orgData.utmSetting.source,
  ]);
  return ret;
};

type LayoutProps = {
  contentRef: Ref<ComponentRef<"div">>;
};

const ToggleButton: FC<ComponentProps<typeof NarrowIconButton>> = (props) => {
  const formModal = useSalesTracelinkFormModal();
  return (
    <Tooltip title={<Trans i18nKey="chat.salesTracelink.tooltip" />}>
      <NarrowIconButton
        onClick={formModal.openModal}
        icon={<MotifIcon un-i-motif="url" />}
        {...props}
      />
    </Tooltip>
  );
};

const SmLayout: FC<LayoutProps> = ({ contentRef }) => {
  const formModal = useSalesTracelinkFormModal();
  return (
    <>
      <ToggleButton />
      <Modal open={formModal.open} closable={false} footer={null}>
        <div ref={contentRef} />
      </Modal>
    </>
  );
};

const MdLayout: FC<LayoutProps> = ({ contentRef }) => {
  const formModal = useSalesTracelinkFormModal();
  return (
    <Popover
      arrow
      placement="topLeft"
      content={<div ref={contentRef} />}
      open={formModal.open}
    >
      <ToggleButton />
    </Popover>
  );
};

const Layout: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const layoutSize = ChatLayout.useLayoutSize();
  const LayoutComponent = useMemo(() => {
    if (layoutSize === "sm") {
      return SmLayout;
    }
    return MdLayout;
  }, [layoutSize]);
  const contentRef = usePortal();
  return (
    <>
      <LayoutComponent contentRef={contentRef.setOuterEl} />
      {createPortal(children, contentRef.innerEl)}
    </>
  );
};

const Provider: FC<{ children: ReactNode }> = ({ children }) => {
  const salesTracelink = useSetupSalesTracelinkModal();
  return <Context.Provider value={salesTracelink}>{children}</Context.Provider>;
};

const SalesTracelinkInner: FC = () => {
  const formModal = useSalesTracelinkFormModal();
  return !formModal.open ? null : <SalesTracelinkForm />;
};

const SalesTracelink = () => {
  return (
    <Provider>
      <Layout>
        <ErrorBoundary.Alert>
          <SalesTracelinkInner />
        </ErrorBoundary.Alert>
      </Layout>
    </Provider>
  );
};

/**
 * Count the number of sales links in a message, for tracking purposes.
 */
function useCountSalesTraceLinks() {
  const orgData = orgQueriesContext.useData();
  return useCallback(
    function countSalesLinks(message: string): number {
      const urlRegex = /https?:\/\/[^\s]+/g;
      const urls = message.match(urlRegex) || [];
      const requiredParams = [
        "utm_source",
        "utm_medium",
        "utm_campaign",
        "utm_term",
        "tripid",
      ];

      const uniqueUrls = [...new Set(urls)];

      const validLinks = uniqueUrls.filter(function isTraceLink(url) {
        try {
          const urlObj = new URL(url);
          return (
            // Check if all required parameters are present
            requiredParams.every((param) => urlObj.searchParams.has(param)) &&
            urlObj.searchParams.get("utm_source") ===
              orgData.utmSetting.source &&
            urlObj.searchParams.get("utm_medium") === orgData.utmSetting.medium
          );
        } catch {
          // Skip invalid URLs
          return false;
        }
      });

      return validLinks.length;
    },
    [orgData.utmSetting.medium, orgData.utmSetting.source],
  );
}

const api = Object.assign(SalesTracelink, {
  useEnabledSalesTracelink,
  useCountSalesTraceLinks,
});

export { api as SalesTracelink };
