import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import type { ComponentProps } from "@chatbotgang/etude/react/ComponentProps";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { ClassNames, css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import { flow, groupBy, map, orderBy, some } from "lodash-es";
import useMergedState from "rc-util/lib/hooks/useMergedState";
import { type ElementRef, type FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { Trans } from "@/app/i18n/Trans";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { Chips } from "@/components/Chip/Chips";
import { FormItem } from "@/components/Form";
import { Select } from "@/components/Select";
import { ChannelTypeChattingIcon } from "@/resources/channel/ChannelTypeIcon";
import { useSortChannelByStatusAndTypeAndName } from "@/resources/channel/sortChannel";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  root: css({
    minWidth: 195,
  }),
  option: css({
    display: "flex",
    gap: 8,
    alignItems: "center",
    fontSize: "0.875rem",
  }),
  popupWrapper: css({
    display: "flex",
    gap: 4,
    padding: "4px 8px",
    flexDirection: "column",
    alignItems: "flex-start",
    background: theme.colors.neutral001,
  }),
});

const ChannelTypeLabel: FC<{ type: CantataTypes["Channel"]["type"] }> = ({
  type,
}) => {
  const { t } = useTranslation();

  const translationMap = {
    line: t("dashboard.channelFilter.type.option.line.label"),
    fb: t("dashboard.channelFilter.type.option.fb.label"),
    ig: t("dashboard.channelFilter.type.option.ig.label"),
    wccs: t("dashboard.channelFilter.type.option.wccs.label"),
    whatsapp: t("dashboard.channelFilter.type.option.whatsapp.label"),
  } as const;

  return <>{translationMap[type]}</>;
};

const platformOrder: CantataTypes["ChannelType"][] = [
  "line",
  "fb",
  "ig",
  "wccs",
];

function computeChannelTypes(channels: Array<CantataTypes["Channel"]>) {
  const orderedChannelTypes = flow(
    () => channels,
    (channels) => groupBy(channels, "type"),
    (groupedChannels) =>
      map(groupedChannels, (channels, type) => ({
        type: type as CantataTypes["Channel"]["type"],
        on: some(channels, { status: "connected" }),
      })),
    (channels) =>
      orderBy(channels, [
        (channel) => !channel.on,
        (channel) => platformOrder.indexOf(channel.type),
      ]),
  )();
  return orderedChannelTypes;
}

const FilterBySchema = z.enum(["platform", "channel"]);
type FilterBy = z.infer<typeof FilterBySchema>;

type Value =
  | {
      filterBy: Extract<FilterBy, "platform">;
      value: CantataTypes["ChannelType"][];
    }
  | {
      filterBy: Extract<FilterBy, "channel">;
      value: Array<CantataTypes["Channel"]["id"]>;
    };

const defaultValue: Value = {
  filterBy: "platform",
  value: [],
};

type ChannelPlatformSelectRef = ElementRef<typeof Select>;
type ChannelPlatformSelectProps = Omit<Select.Props, "value" | "onChange"> & {
  value?: Value;
  onChange?: (value: Value) => void;
};

const ChannelPlatformSelect = forwardRef<
  ChannelPlatformSelectRef,
  ChannelPlatformSelectProps
>(function ChannelPlatformSelect({ value, onChange, ...props }, ref) {
  const { t } = useTranslation();
  const orgId = useActiveOrgIdStore((state) => state.value);
  const sortChannelByStatusAndPlatformAndName =
    useSortChannelByStatusAndTypeAndName();
  const channelsQuery = cantata.channel.useList(
    {
      params: { orgId },
    },
    {
      select(data) {
        return {
          channels: data.channels.toSorted(
            sortChannelByStatusAndPlatformAndName,
          ),
          channelTypes: computeChannelTypes(data.channels),
        };
      },
    },
  );
  const [innerValue, setInnerValue] = useMergedState<Value>(defaultValue, {
    value,
    onChange,
  });
  const options = useMemo<Select.Props["options"]>(() => {
    return [
      ...(innerValue.filterBy !== "platform"
        ? []
        : [
            {
              label: (
                <span>
                  <Trans i18nKey="dashboard.channelFilter.options.platform.label" />
                </span>
              ),
              title: t("dashboard.channelFilter.options.platform.label"),
              options: channelsQuery.data?.channelTypes.map((channel) => ({
                key: channel.type,
                label: (
                  <div css={styles.option}>
                    <ChannelTypeChattingIcon
                      style={{ fontSize: "1.25rem" }}
                      channelType={channel.type}
                      on={channel.on}
                    />
                    <ChannelTypeLabel type={channel.type} />
                  </div>
                ),
                value: channel.type,
              })),
            },
          ]),
      ...(innerValue.filterBy !== "channel"
        ? []
        : [
            {
              label: (
                <span>
                  <Trans i18nKey="dashboard.channelFilter.options.channel.label" />
                </span>
              ),
              title: t("dashboard.channelFilter.options.channel.label"),
              options: channelsQuery.data?.channels.map((channel) => ({
                key: channel.id,
                label: (
                  <div css={styles.option}>
                    <ChannelTypeChattingIcon
                      style={{ fontSize: "1.25rem" }}
                      channel={channel}
                    />
                    {channel.name}
                  </div>
                ),
                value: channel.id,
              })),
            },
          ]),
    ];
  }, [
    channelsQuery.data?.channelTypes,
    channelsQuery.data?.channels,
    innerValue.filterBy,
    t,
  ]);

  const handleSelectionChange = useHandler<Select.Props["onChange"]>(
    (value) => {
      const draft = {
        filterBy: innerValue.filterBy,
        value,
      };
      setInnerValue(draft);
    },
  );

  const handleFilterByChange = useHandler(function handleFilterByChange(
    filter: FilterBy,
  ) {
    const draft = {
      filterBy: filter,
      value: [],
    };
    setInnerValue(draft);
  });

  const preventClose = useHandler<ComponentProps<"div">["onMouseDown"]>(
    function preventClose(e) {
      e.preventDefault();
      e.stopPropagation();
    },
  );

  if (channelsQuery.isLoading) {
    return <Select ref={ref} css={styles.root} loading disabled {...props} />;
  }

  if (channelsQuery.isError) {
    <FormItem
      help={inspectMessage`query error: ${channelsQuery.error}`}
      validateStatus="error"
    >
      <Select ref={ref} {...props} loading disabled />
    </FormItem>;
  }

  return (
    <ClassNames>
      {({ css }) => (
        <Select
          allowClear
          ref={ref}
          css={styles.root}
          mode="multiple"
          options={options}
          value={innerValue.value}
          onChange={handleSelectionChange}
          popupClassName={css({ padding: "0 0 4px" })}
          popupMatchSelectWidth={false}
          placeholder={t("dashboard.channelFilter.placeholder")}
          dropdownRender={(menu) => (
            <>
              <div css={styles.popupWrapper} onMouseDown={preventClose}>
                <div>
                  <Trans i18nKey="dashboard.channelFilter.filterBy" />
                </div>
                <Chips
                  value={innerValue.filterBy}
                  onChange={handleFilterByChange}
                  options={[
                    {
                      value: FilterBySchema.Values.platform,
                      display: (
                        <Trans i18nKey="dashboard.channelFilter.platform.label" />
                      ),
                    },
                    {
                      value: FilterBySchema.Values.channel,
                      display: (
                        <Trans i18nKey="dashboard.channelFilter.channel.label" />
                      ),
                    },
                  ]}
                />
              </div>
              {menu}
            </>
          )}
          {...props}
        />
      )}
    </ClassNames>
  );
});

assignDisplayName(ChannelPlatformSelect, "ChannelPlatformSelect");

export { ChannelPlatformSelect, FilterBySchema };
export type { ChannelPlatformSelectProps, ChannelPlatformSelectRef, FilterBy };
