import { useCallback } from "react";

import type { cantataClient } from "@/cantata";
import { useLocaleCompare } from "@/shared/hooks/useLocaleCompare";

type QuickTemplate = Awaited<
  ReturnType<typeof cantataClient.quickTemplate.list>
>["quickTemplates"][number];

const useSelectSortQuickTemplates = () => {
  const localeCompare = useLocaleCompare();
  const select = useCallback<
    (data: Awaited<ReturnType<typeof cantataClient.quickTemplate.list>>) => {
      quickTemplates: [QuickTemplate, ...Array<QuickTemplate>];
    }
  >(
    (data) => {
      const quickTemplates: [QuickTemplate, ...Array<QuickTemplate>] =
        data.quickTemplates
          .toSorted((a, b) => {
            if (a.category.name && !b.category.name) return 1;
            if (!a.category.name && b.category.name) return -1;
            if (
              a.category.name &&
              b.category.name &&
              a.category.name !== b.category.name
            )
              return localeCompare(a.category.name, b.category.name);
            return 0;
          })
          .map((qt) => ({
            ...qt,
            templates: qt.templates.toSorted((a, b) =>
              localeCompare(a.name, b.name),
            ),
          })) satisfies Array<QuickTemplate> as [
          QuickTemplate,
          ...Array<QuickTemplate>,
        ];
      if (quickTemplates.length === 0 || quickTemplates[0].category.id) {
        /**
         * Ensure first category is un-category.
         */
        quickTemplates.unshift({
          category: {
            id: null,
            name: null,
          },
          templates: [],
        });
      }
      return {
        quickTemplates,
      };
    },
    [localeCompare],
  );

  return select;
};

export { useSelectSortQuickTemplates };
