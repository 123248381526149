import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { backToFlutterWebviewAppPage } from "@/app/auth/backToFlutterWebviewAppPage";
import { DisabledContextProvider } from "@/components/Form/DisabledContext";
import { Ui } from "@/router/components/Protected/BackToFlutterWebviewAppPage/Ui";
import { compileToString } from "@/router/utils/compileTo";
import { useLogoutMutation } from "@/shared/application/authenticate";
import { useLastUserEmailStore } from "@/shared/utils/createZustandStorageStore";

namespace BackToFlutterWebviewAppPage {}

const BackToFlutterWebviewAppPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const leaveTo = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete(backToFlutterWebviewAppPage.searchParamKey);
    const search = searchParams.toString();
    const leaveTo =
      compileToString({
        pathname: location.pathname as any,
      }) +
      (!search ? "" : `?${search}`) +
      location.hash;
    return leaveTo;
  }, [location.hash, location.pathname, location.search]);
  const email = useLastUserEmailStore(useCallback((state) => state.value, []));
  const logoutMutation = useLogoutMutation();
  const signOut: NonNullable<Ui.Props["signOutButtonProps"]>["onClick"] =
    useHandler((e) => {
      e.preventDefault();
      logoutMutation.mutate({ redirectToHome: false });
    });
  const signOutButtonProps: Ui.Props["signOutButtonProps"] = useMemo<
    Ui.Props["signOutButtonProps"]
  >(() => {
    return {
      onClick: signOut,
      loading: logoutMutation.isLoading,
    };
  }, [signOut, logoutMutation.isLoading]);
  const continueButtonProps: Ui.Props["continueButtonProps"] = useMemo<
    Ui.Props["continueButtonProps"]
  >(() => {
    return {
      onClick: async (e) => {
        e.preventDefault();
        navigate(leaveTo);
      },
    };
  }, [leaveTo, navigate]);

  const appDeepLink = useMemo(() => {
    // TODO: fix this when we have a deep link for CAAC APP. Use fb for testing for now.
    return "fb://profile";
  }, []);
  const openAppButtonProps: Ui.Props["openAppButtonProps"] = useMemo<
    Ui.Props["openAppButtonProps"]
  >(() => {
    return {
      onClick: async () => {
        // Try to open the app deep link.
        window.location = appDeepLink as any;
      },
    };
  }, [appDeepLink]);
  const uiProps: Ui.Props = useMemo<Ui.Props>(() => {
    return {
      email,
      signOutButtonProps,
      continueButtonProps,
      openAppButtonProps,
    };
  }, [continueButtonProps, email, signOutButtonProps, openAppButtonProps]);
  return (
    <DisabledContextProvider disabled={logoutMutation.isLoading}>
      <Ui {...uiProps} />
    </DisabledContextProvider>
  );
};

export { BackToFlutterWebviewAppPage };
