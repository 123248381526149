import urlJoin from "url-join";

import { BASE_URL } from "@/env";
import type { ValidPathString } from "@/router/types";

/**
 * Create a full internal URL from a path.
 *
 * @example
 *
 * ```ts
 * // assuming `BASE_URL` is `/foo`
 * createFullInternalUrl('/bar'); // => `/foo/bar`
 * createFullInternalUrl('/bar', { includingOrigin: true }); // => `https://localhost/foo/bar`
 * ```
 */
function createFullInternalUrl(
  url: ValidPathString,
  options?: {
    includingOrigin?: boolean;
  },
) {
  const includingOrigin = options?.includingOrigin ?? false;
  if (includingOrigin) {
    return urlJoin(
      window.location.origin,
      BASE_URL === "/" ? "" : BASE_URL,
      url,
    );
  }
  return urlJoin(BASE_URL, url);
}

export { createFullInternalUrl };
