import { createQueriesContext } from "@zeffiroso/utils/react-query/createQueriesContext";
import { useMemo } from "react";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";

function useQueries() {
  const orgId = useActiveOrgIdStore((state) => state.value);

  const tagsQuery = cantata.unificationSetting.useGetUnificationTags(
    {
      params: {
        orgId,
      },
    },
    {
      select(data) {
        return data.tagIds;
      },
      suspense: true,
      useErrorBoundary: true,
    },
  );

  const queries = useMemo(
    () => ({
      tags: tagsQuery,
    }),
    [tagsQuery],
  );

  return queries;
}

const TagsQueriesContext =
  createQueriesContext<ReturnType<typeof useQueries>>();

const QueriesContextProvider: React.FC<{
  children: React.ReactNode;
}> = (props) => {
  const queries = useQueries();
  return (
    <TagsQueriesContext.Provider queries={queries}>
      {props.children}
    </TagsQueriesContext.Provider>
  );
};

const tagsQueriesContext = {
  Provider: QueriesContextProvider,
  useQueries: TagsQueriesContext.useQueries,
  useData: TagsQueriesContext.useData,
};

export { tagsQueriesContext };
