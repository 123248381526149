import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { safeString } from "@chatbotgang/etude/string/safeString";
import { define } from "@chatbotgang/etude/util/define";
import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import type { OverrideWith } from "@zeffiroso/utils/type/object/OverrideWith";
// eslint-disable-next-line no-restricted-imports -- Non-exported types.
import type { DefaultOptionType } from "antd/es/select";
import { isEmpty } from "lodash-es";
import PQueue from "p-queue";
import useMergedState from "rc-util/lib/hooks/useMergedState";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { Alert } from "@/components/Alert";
import { useMergeFormDisabled } from "@/components/Form/DisabledContext";
import { MotifIcon } from "@/components/MotifIcon";
import { Select } from "@/components/Select";
import { cssOneLine, defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  root: css({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: 8,
  }),
  tagsContainer: css({
    display: "flex",
    flexFlow: "row wrap",
    gap: 8,
  }),
  warning: css({
    color: theme.colors.red006,
  }),
  createOption: css({
    display: "flex",
    alignItems: "center",
    color: theme.colors.neutral009,
    gap: 5,
    textOverflow: "ellipsis",
    height: "100%",
  }),
  invalidLabelOption: css({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: "4px",
  }),
});

const pQueue = new PQueue({ concurrency: 1 });

const MAX_TAG_NAME_LENGTH = 50;

type Value = Array<CantataTypes["Tag"]["id"]>;

namespace MultipleTagSelect {
  export interface OwnProps {
    value?: Value;
    onChange?: (value: Value) => void;
  }
  export type Props = OverrideWith<
    Omit<
      Select.Props<Value>,
      "mode" | "onSearch" | "optionFilterProp" | "options" | "filterOption"
    >,
    OwnProps
  >;
  export type Ref = Select.Ref;
}

function formatName(name: string) {
  return safeString(name, { trim: true });
}

function formatNameForSearch(name: string) {
  return safeString(name, { trim: true, deburr: true }).toLocaleLowerCase();
}

const filterOption: Select.Props<Value>["filterOption"] = (input, option) => {
  // eslint-disable-next-line dot-notation -- option.name is not typed.
  return !option || !("name" in option) || typeof option["name"] !== "string"
    ? false
    : formatNameForSearch(
        // eslint-disable-next-line dot-notation -- option.name is not typed.
        option["name"],
      ).includes(formatNameForSearch(input));
};

const MultipleTagSelect = forwardRef<
  MultipleTagSelect.Ref,
  MultipleTagSelect.Props
>(({ value, onChange, ...props }, ref) => {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const allTagsQuery = cantata.tag.useList(
    {
      params: {
        orgId,
      },
    },
    {
      suspense: true,
      useErrorBoundary: true,
    },
  );

  const { t } = useTranslation();
  // const [value, setValue] = useState<Value | undefined>(undefined);
  const [searchText, setSearchText] = useState("");
  const formattedSearchText = useMemo(
    () => formatName(searchText),
    [searchText],
  );

  const createTagMutation = cantata.tag.useCreate({
    params: {
      orgId,
    },
  });

  const [innerValue, setInnerValue] = useMergedState<Value>([], {
    value,
    onChange,
  });

  const createTag = useHandler(async function createTag(tagName: string) {
    await pQueue.add(async () => {
      const result = await createTagMutation.mutateAsync({
        name: tagName,
      });

      setInnerValue((value) => [...value, result.id]);
    });
  });

  const handleChange = useHandler<Select.Props<Value>["onChange"]>(
    function onChange(draft) {
      // draft should be an array of [number, ...number, string | number]
      // mode must be multiple
      if (!Array.isArray(draft) || !allTagsQuery.isSuccess) return;

      const currentLength = innerValue.length;
      const newLength = draft.length;

      const removedTags = innerValue.filter((tag) => !draft.includes(tag));
      if (removedTags.length > 0) {
        setInnerValue(draft);
        return;
      }

      const addedTags = draft.slice(currentLength, newLength);
      if (addedTags.length === 0) return;
      const newTag = addedTags[0];
      const matchedValue = allTagsQuery.data.tags.find(
        (tag) => tag.id === newTag,
      );

      // if the tag is in the list, add it to the inner value
      if (matchedValue) {
        setInnerValue([...innerValue, matchedValue.id]);
        return;
      }

      // if the tag is not in the list, create a new tag
      if (createTagOptionStatus !== "valid") return;
      createTag(formattedSearchText);
    },
  );

  const tagNameExists = useCallback(
    function tagNameExists(name: string) {
      if (!allTagsQuery.isSuccess) return false;
      return allTagsQuery.data.tags.some((tag) => tag.name === name);
    },
    [allTagsQuery.data?.tags, allTagsQuery.isSuccess],
  );

  const createTagOptionStatus = useMemo<
    "empty" | "exist" | "conflict" | "valid" | "invalid"
  >(
    () =>
      isEmpty(formattedSearchText)
        ? "empty"
        : tagNameExists(formattedSearchText)
          ? "exist"
          : formattedSearchText.length <= MAX_TAG_NAME_LENGTH
            ? "valid"
            : "invalid",
    [formattedSearchText, tagNameExists],
  );

  const allTagOptions = useMemo(() => {
    if (!allTagsQuery.isSuccess) return [];
    return allTagsQuery.data.tags.map<
      NonNullable<Select.Props["options"]>[number]
    >((tag) => ({
      value: tag.id,
      label: tag.name,
      name: tag.name,
    }));
  }, [allTagsQuery.data?.tags, allTagsQuery.isSuccess]);

  const createOption = useMemo<NonNullable<Select.Props["options"]>>(() => {
    if (createTagOptionStatus === "valid") {
      return [
        {
          key: "create",
          value: formattedSearchText,
          label: (
            <span css={styles.createOption}>
              <MotifIcon un-i-motif="tag" />
              <div css={cssOneLine}>
                {t("tag.createWithNewOne", {
                  name: formattedSearchText,
                })}
              </div>
            </span>
          ),
          name: formattedSearchText,
        },
      ];
    }
    if (createTagOptionStatus === "invalid") {
      return [
        define<DefaultOptionType>({
          key: "create",
          value: formattedSearchText,
          label: (
            <div css={styles.invalidLabelOption}>
              <span css={styles.createOption}>
                <MotifIcon un-i-motif="tag" />
                <div css={cssOneLine}>
                  {t("tag.createWithNewOne", {
                    name: formattedSearchText,
                  })}
                </div>
              </span>
              <div css={styles.warning}>
                {t("validation.text.maxLength", {
                  count: MAX_TAG_NAME_LENGTH,
                })}
              </div>
            </div>
          ),
          name: formattedSearchText,
          disabled: true,
        }),
      ];
    }
    return [];
  }, [createTagOptionStatus, formattedSearchText, t]);

  const options = useMemo<Select.Props["options"]>(
    () => [...allTagOptions, ...createOption],
    [allTagOptions, createOption],
  );

  const onSearch = useHandler<Select.Props["onSearch"]>(
    function onSearch(value) {
      return setSearchText(
        safeString(value, {
          trim: true,
        }),
      );
    },
  );

  const mergeFormDisabled = useMergeFormDisabled();

  const isLoading = useMemo(
    function mergeLoading() {
      return props.loading || createTagMutation.isLoading;
    },
    [createTagMutation.isLoading, props.loading],
  );

  const disabled = mergeFormDisabled(
    props.disabled || createTagMutation.isLoading,
  );

  if (allTagsQuery.isLoading) return <Select loading disabled />;

  if (allTagsQuery.isError)
    return <Alert type="error" message={allTagsQuery.error.message} />;

  return (
    <Select<Value>
      placeholder={t("common.tagSelectPlaceholder")}
      {...props}
      mode="multiple"
      value={innerValue}
      onChange={handleChange}
      disabled={disabled}
      ref={ref}
      options={options}
      loading={isLoading}
      onSearch={onSearch}
      optionFilterProp="name"
      filterOption={filterOption}
    />
  );
});

export { MultipleTagSelect };
