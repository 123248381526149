import { createFbSdk } from "@chatbotgang/fb-sdk";

import { FB_APP_ID, FB_CURSOR_BASED_PAGINATION_LIMIT } from "@/env";
import { logError } from "@/shared/application/logger/sentry";

const fbSdk = createFbSdk({
  logError,
  appId: FB_APP_ID,
  cursorBasedPaginationLimit: FB_CURSOR_BASED_PAGINATION_LIMIT,
});

export { fbSdk };
