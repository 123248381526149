// TODO: I'm wondering if we should put this in "application". The better idea would be putting this in the "src/lib" and add an application function to use the lib.
import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { logErrorWithCause } from "@chatbotgang/etude/debug/logErrorWithCause";
import { captureException, captureMessage } from "@sentry/react";

function logError<T = Error>(error: T): string {
  if (import.meta.env.DEV && import.meta.env.NODE_ENV === "test")
    logErrorWithCause("[logError]", error);

  if (error instanceof Error) {
    return captureException(error);
  }
  return captureMessage(inspectMessage`Caught a non-error thrown: ${error}`);
}

export { logError };
