import TagManager from "react-gtm-module";

import { GTM_ID } from "@/env";

if (import.meta.env.PROD) {
  /**
   * Google Tag Manager setup
   */
  TagManager.initialize({
    gtmId: GTM_ID,
  });
}
