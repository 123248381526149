import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { shallow } from "@zeffiroso/utils/zustand/shallow";
import { format } from "date-fns";
import EventEmitter from "eventemitter3";
import PQueue from "p-queue";
import { createWithEqualityFn } from "zustand/traditional";

import {
  flutterNotificationPermissionMapToNotificationPermission,
  type FlutterWebviewAppSdk,
} from "./definitions";

const MAX_LOG_SIZE = 100;

const useFlutterDebugLogsStore = createWithEqualityFn<{
  logs: string[];
}>(
  () => ({
    logs: [],
  }),
  shallow,
);

function log(message: string) {
  useFlutterDebugLogsStore.setState((state) => ({
    logs: [
      `[${format(new Date(), "mm:ss.sss")}] ${message}`,
      ...state.logs,
    ].slice(0, MAX_LOG_SIZE),
  }));
}

const isFlutterWebViewApp = Boolean(
  "flutter_inappwebview" in window && window.flutter_inappwebview,
);

class NonFlutterWebViewAppError extends Error {
  override name = "NonFlutterWebViewAppError";
}

/**
 * The queue for the requests.
 */
const pQueue = new PQueue({ concurrency: 1 });

const callHandler = (() => {
  const callHandler: NonNullable<
    typeof window.flutter_inappwebview
  >["callHandler"] = async (...args) => {
    log(inspectMessage`callHandler, args: ${args}`);
    if (!isFlutterWebViewApp || !window.flutter_inappwebview) {
      const errorMsg = inspectMessage`not a Flutter WebView App, isFlutterWebViewApp: ${isFlutterWebViewApp}, window.flutter_inappwebview: ${window.flutter_inappwebview}`;
      log(errorMsg);
      throw new NonFlutterWebViewAppError(errorMsg);
    }
    const result = await window.flutter_inappwebview.callHandler(...args);
    log(inspectMessage`callHandler, args: ${args}, result: ${result}`);
    return result;
  };

  const queuedCallHandler: typeof callHandler = async (...args) => {
    log(inspectMessage`queuedCallHandler, args: ${args}`);
    const result = await pQueue.add(async () => callHandler(...args));
    return result;
  };
  return queuedCallHandler;
})();

const requestNotificationPermission = async (): Promise<
  FlutterWebviewAppSdk.RequestMap["requestNotificationPermission"]["response"]
> => {
  return callHandler("requestNotificationPermission");
};

const getFcmToken = async (): Promise<string> => {
  return callHandler("getCaacWebViewAppFcmToken");
};

const revokeFcmToken = async (): Promise<void> => {
  return callHandler("revokeCaacWebViewAppFcmToken");
};

const eventEmitter = (() => {
  if (!isFlutterWebViewApp) return;
  const eventEmitter = new EventEmitter<FlutterWebviewAppSdk.EventMap>();
  const originalCallback = window.flutterPermissionUpdated;
  window.flutterPermissionUpdated = (...args) => {
    // prevent overriding the original callback
    originalCallback?.(...args);
    const [permission] = args;
    eventEmitter.emit(
      "flutterPermissionUpdated",
      flutterNotificationPermissionMapToNotificationPermission(permission),
    );
  };
  return eventEmitter;
})();

/**
 * cspell:words caacapp ariontechs
 *
 * Slack: [#proj-caacapp-with-ariontechs](https://chatbotgang.slack.com/archives/C08106MDD19/p1738649909452309?thread_ts=1738580082.597549&cid=C08106MDD19)
 */
const isAndroid = /Android/i.test(navigator.userAgent);

async function downloadFile(base64: string) {
  await callHandler("downloadBase64Image", base64);
}

async function openBrowser(url: string) {
  await callHandler("caacWebViewAppOpenBrowser", url);
}

log(inspectMessage`initialized, isFlutterWebViewApp: ${isFlutterWebViewApp}`);

/**
 * The SDK for the Flutter WebView App.
 */
const flutterWebViewAppSdk = {
  isFlutterWebViewApp,
  isAndroid,
  requestNotificationPermission,
  getFcmToken,
  revokeFcmToken,
  downloadFile,
  openBrowser,
  eventEmitter,
  useFlutterDebugLogsStore,
  log,
};

export { flutterWebViewAppSdk };
