import styled from "@emotion/styled";
import type { FormListFieldData } from "antd";

import type { CantataTypes } from "@/cantata/types";
import { FormItem } from "@/components/Form";
import { FilePreview } from "@/components/MessagePreview/FilePreview";
import { ImagePreview } from "@/components/MessagePreview/ImagePreview";
import { MessagePreview } from "@/components/MessagePreview/MessagePreview";
import { TextPreview } from "@/components/MessagePreview/TextPreview";
import { VideoPreview } from "@/components/MessagePreview/VideoPreview";

const MessagesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

const PreviewMessage = ({
  value: message,
}: {
  value?: CantataTypes["QuickTemplateDetail"]["messages"][number];
  onChange?: () => void;
}) => {
  switch (message?.type) {
    case "text":
      return <TextPreview text={message.text} />;
    case "image":
      return <ImagePreview src={message.originUrl} />;
    case "video":
      return (
        <VideoPreview src={message.originUrl} previewUrl={message.previewUrl} />
      );
    case "file":
      return (
        <FilePreview
          fileName={message.metadata.filename}
          fileSizePrefix={message.metadata.filesizePrefix}
          fileSize={message.metadata.filesizeBytes}
          expiryPrefix={message.metadata.expirationDatePrefix}
          downloadUrl={message.originUrl}
          small={true}
        />
      );
    default:
      return null;
  }
};

const PreviewFactory = (fields: FormListFieldData[]): React.ReactNode => {
  return (
    <MessagePreview>
      <MessagesWrapper>
        {fields.map((field) => (
          <FormItem {...field} key={field.key} noStyle>
            <PreviewMessage />
          </FormItem>
        ))}
      </MessagesWrapper>
    </MessagePreview>
  );
};

export { PreviewFactory };
