import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { isExternalUrl } from "@chatbotgang/etude/url/isExternalUrl";
import { flutterWebViewAppSdk } from "@zeffiroso/flutter-webview-app-sdk/flutterWebViewAppSdk";

import { shouldCallFlutterWebviewAppOpenBrowser } from "@/app/flutterWebviewApp";
import { BASE_URL } from "@/env";
import { shouldProcessLinkClick } from "@/shared/event/shouldProcessLinkClick";

/**
 * Capture all clicks on anchor links and open them in the browser if the app is running in a Flutter WebView.
 */
(() => {
  function findClosestAnchorLink(
    element: HTMLElement,
  ): HTMLAnchorElement | null {
    if (element instanceof HTMLAnchorElement) return element;
    return element.closest("a");
  }

  document.body.addEventListener(
    "click",
    (e) => {
      if (!(e.target instanceof HTMLElement)) return;
      const anchorLink = findClosestAnchorLink(e.target);
      if (!anchorLink) return;
      const href =
        anchorLink
          // Do not use `.href` because it returns the resolved URL.
          .getAttribute("href") ?? "";
      const isInternalLink =
        !isExternalUrl(href) &&
        // If the link starts with `/`, check if it's an internal link in the SPA.
        (!href.startsWith("/") || href.startsWith(BASE_URL));
      if (isInternalLink && shouldProcessLinkClick(e, anchorLink.target)) {
        // Handled by `react-router`, not external link. Do nothing.
        return;
      }
      if (!shouldCallFlutterWebviewAppOpenBrowser()) return;
      e.preventDefault();
      e.stopPropagation();
      flutterWebViewAppSdk.openBrowser(anchorLink.href);
    },
    {
      capture: true,
    },
  );
})();

/**
 * Wrapped `window.open` function.
 */
(() => {
  const vanillaWindowOpen = window.open.bind(window);

  function getUrlString(url: Parameters<typeof window.open>[0]): string {
    if (typeof url === "string") return url;
    if (url instanceof URL) return url.href;
    if (url === undefined) return "";
    url satisfies never;
    throw new Error(inspectMessage`Invalid url: ${url}`);
  }

  const windowOpen: typeof window.open = (...args) => {
    if (shouldCallFlutterWebviewAppOpenBrowser()) {
      const urlString = getUrlString(args[0]);
      flutterWebViewAppSdk.openBrowser(urlString);
      return null;
    }
    return vanillaWindowOpen(...args);
  };

  window.open = windowOpen;
})();
