import { delay } from "@chatbotgang/etude/timer/delay";
import { flutterWebViewAppSdk } from "@zeffiroso/flutter-webview-app-sdk/flutterWebViewAppSdk";
import { StringToNumberSchema } from "@zeffiroso/utils/zod/StringToNumberSchema";
import { shallow } from "@zeffiroso/utils/zustand/shallow";
import { hoursToMilliseconds } from "date-fns";
import { registerSW } from "virtual:pwa-register";
import { createWithEqualityFn } from "zustand/traditional";

import { getFeatureFlag } from "@/app/featureFlag";
import { emit2Sw } from "@/app/sw/emit2Sw";
import {
  FIREBASE_CONTENT_STORAGE_API_KEY,
  FIREBASE_CONTENT_STORAGE_APP_ID,
  FIREBASE_CONTENT_STORAGE_PROJECT_ID,
  FIREBASE_CONTENT_STORAGE_SENDER_ID,
  SENTRY_DSN,
  SENTRY_RELEASE,
  SITE,
} from "@/env";

const useSwStatus = createWithEqualityFn<{
  needRefresh: boolean;
}>()(
  () => ({
    needRefresh: false,
  }),
  shallow,
);

let unregisterSwInternal: ServiceWorkerRegistration["unregister"] = async () =>
  false;

const unregisterSw: ServiceWorkerRegistration["unregister"] = async () =>
  unregisterSwInternal();

const defaultCheckUpdateInterval = hoursToMilliseconds(1);

const CHECK_UPDATE_INTERVAL_MS = StringToNumberSchema.catch(
  defaultCheckUpdateInterval,
).parse(getFeatureFlag("periodicServiceWorkerUpdates"));

/**
 * Periodically check for updates.
 *
 * Reference: <https://vite-pwa-org.netlify.app/guide/periodic-sw-updates.html>
 */
const onRegisteredSW: NonNullable<
  Parameters<typeof registerSW>[0]
>["onRegisteredSW"] = !CHECK_UPDATE_INTERVAL_MS
  ? undefined
  : async (swScriptUrl, registration) => {
      if (!registration) return;
      unregisterSwInternal = registration.unregister.bind(registration);
      emit2Sw("sentry", {
        dsn: SENTRY_DSN,
        environment: SITE,
        ...(!SENTRY_RELEASE ? null : { release: SENTRY_RELEASE }),
      });
      emit2Sw("caacFirebaseApp", {
        apiKey: FIREBASE_CONTENT_STORAGE_API_KEY,
        projectId: FIREBASE_CONTENT_STORAGE_PROJECT_ID,
        messagingSenderId: FIREBASE_CONTENT_STORAGE_SENDER_ID,
        appId: FIREBASE_CONTENT_STORAGE_APP_ID,
      });
      while (true) {
        try {
          await (async () => {
            if (registration.installing || !navigator) return;
            if ("connection" in navigator && !navigator.onLine) return;
            const resp = await fetch(swScriptUrl, {
              cache: "no-store",
              headers: {
                cache: "no-store",
                "cache-control": "no-cache",
              },
            });
            if (resp?.status === 200) {
              await registration.update();
            }
          })();
        } catch (_e) {
          // Ignore errors.
        }
        await delay(CHECK_UPDATE_INTERVAL_MS);
      }
    };

const updateSw: ReturnType<typeof registerSW> = (() => {
  if (flutterWebViewAppSdk.isFlutterWebViewApp)
    return () => {
      const errorMessage = "SW should not be registered in FlutterWebViewApp";
      flutterWebViewAppSdk.log(errorMessage);
      throw new Error(errorMessage);
    };
  return registerSW({
    onNeedRefresh() {
      useSwStatus.setState({ needRefresh: true });
    },

    ...(!onRegisteredSW
      ? null
      : {
          onRegisteredSW,
        }),
  });
})();

export { unregisterSw, updateSw, useSwStatus };
