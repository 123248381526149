import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";
import { Button } from "@/components/Button";
import { ErrorBoundary } from "@/components/ErrorBoundary";
import { createEasyForm } from "@/components/Form/createEasyForm";
import { useMessage } from "@/internal/message";
import { tagsQueriesContext } from "@/resources/redirectUnification/tagsQueriesContext";
import { MultipleTagSelect } from "@/resources/tag/MultipleTagSelect";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  addButton: css({
    padding: 0,
    lineHeight: "normal",
    height: "auto",
  }),
  buttons: css({
    display: "flex",
    gap: "8px",
  }),
  form: css({
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    maxWidth: 488,
  }),
});

type TagFormValues = {
  tagIds: number[];
};

const EasyForm = createEasyForm<TagFormValues>();

const InvitationTagsSettingInternal: React.FC = () => {
  const { t } = useTranslation();
  const queriesData = tagsQueriesContext.useData();
  const hasTags = queriesData.tags.length > 0;

  const [displayStatus, setDisplayStatus] = useState<
    "addTagsButton" | "readonly" | "editable"
  >(() => (!hasTags ? "addTagsButton" : "readonly"));

  const message = useMessage();
  const orgId = useActiveOrgIdStore((state) => state.value);

  const [easyForm, antForm] = EasyForm.useForm();

  const reset = useHandler(() => {
    easyForm.controller.resetFields();
    // Revert to initial state based on data
    setDisplayStatus(hasTags ? "readonly" : "addTagsButton");
  });

  const updateTagsMutation =
    cantata.unificationSetting.useUpdateUnificationTags(
      {
        params: {
          orgId,
        },
      },
      {
        onSuccess() {
          message.success(t("common.updatedSuccessfully"));
          reset();
        },
      },
    );

  const handleSubmit = useHandler((values: TagFormValues) => {
    updateTagsMutation.mutate({
      tagIds: values.tagIds,
    });
  });

  const enableEditing = useHandler(() => {
    setDisplayStatus("editable");
  });

  return (
    <>
      {displayStatus === "addTagsButton" ? (
        <div>
          <Button css={styles.addButton} type="link" onClick={enableEditing}>
            {t("settings.conversation.invitation.tags.add.label")}
          </Button>
        </div>
      ) : (
        <>
          <EasyForm
            css={styles.form}
            autoHeightFormItem
            form={antForm}
            initialValues={{ tagIds: queriesData.tags }}
            onFinish={handleSubmit}
            disabled={
              updateTagsMutation.isLoading || displayStatus !== "editable"
            }
          >
            <EasyForm.RouterBlocker />
            <EasyForm.Item name="tagIds">
              <MultipleTagSelect />
            </EasyForm.Item>
            {displayStatus !== "editable" ? null : (
              <div css={styles.buttons}>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={updateTagsMutation.isLoading}
                >
                  {t("common.save")}
                </Button>
                <Button onClick={reset}>{t("common.cancel")}</Button>
              </div>
            )}
          </EasyForm>
          {displayStatus !== "readonly" ? null : (
            <div>
              <Button type="primary" ghost onClick={enableEditing}>
                {t("settings.conversation.invitation.tags.edit.label")}
              </Button>
            </div>
          )}
        </>
      )}
    </>
  );
};

const InvitationTagsSetting: React.FC = () => {
  return (
    <ErrorBoundary.Alert>
      <tagsQueriesContext.Provider>
        <InvitationTagsSettingInternal />
      </tagsQueriesContext.Provider>
    </ErrorBoundary.Alert>
  );
};

export { InvitationTagsSetting };
