import { useHandler } from "@chatbotgang/etude/react/useHandler";
import PQueue from "p-queue";

import { ZENDESK_KEY } from "@/env";
import { useMessage } from "@/internal/message";
import { zendesk } from "@/internal/zendesk/sdk";
import {
  setupZendeskFab,
  zendeskButtonContainerClass,
} from "@/internal/zendesk/Zendesk";
import { useStore } from "@/internal/zendesk/zustandStore";
import { useGetErrorMessage } from "@/shared/application/error/handleError";
import { logError } from "@/shared/application/logger/sentry";

async function loadScript(): Promise<void> {
  return new Promise((resolve, reject) => {
    const state = zendesk.getState();
    if (state === "loaded") {
      resolve();
      return;
    }

    if (state === "loading") {
      const err = new Error("Zendesk Web Widget script is already loading");
      err.name = "ZendeskWebWidgetLoadingError";
      reject(err);
      return;
    }

    const apiKey = ZENDESK_KEY;

    const s = document.createElement("script");

    s.id = "ze-snippet";
    const urlSearchParams = new URLSearchParams({
      key: apiKey,
    });
    s.src = `https://static.zdassets.com/ekr/snippet.js?${urlSearchParams.toString()}`;
    s.async = true;
    document.body.appendChild(s);
    useStore.setState({ state: "loading" });

    let resolved = false;

    s.onload = function onload() {
      if (resolved) return;
      resolved = true;
      setupZendeskFab();

      (function setupInjectButtonContainerClass() {
        function styleButtonDiv(container: HTMLDivElement) {
          function injectButtonContainerClass() {
            if (container.classList.contains(zendeskButtonContainerClass))
              return;
            container.classList.add(zendeskButtonContainerClass);
          }
          injectButtonContainerClass();
          const mutation = new MutationObserver(injectButtonContainerClass);
          mutation.observe(container, {
            attributes: true,
          });
        }
        /**
         * Detect when the Web Widget is loaded, inject CSS to style it and make it draggable.
         */
        const mutationCallback: ConstructorParameters<
          typeof MutationObserver
        >[0] = function mutationCallback(mutations) {
          const allAddedNodes = [
            ...new Set(
              mutations.flatMap((mutation) => [...mutation.addedNodes]),
            ),
          ];
          const webWidgetNode = allAddedNodes.find(
            (
              node,
            ): node is HTMLDivElement & {
              childNodes: [HTMLDivElement, HTMLDivElement];
            } =>
              node instanceof HTMLDivElement &&
              node.childNodes.length === 2 &&
              [...node.childNodes].every(
                (node) => node instanceof HTMLDivElement,
              ),
          );
          if (!webWidgetNode) return;
          mutationObserver.disconnect();
          // Do something with the node
          const buttonDiv = webWidgetNode.childNodes[1];
          styleButtonDiv(buttonDiv);
        };
        const mutationObserver = new MutationObserver(mutationCallback);
        mutationObserver.observe(document.body, {
          childList: true,
        });
      })();
      useStore.setState({ state: "loaded" });
      resolve();
    };
    s.onerror = function onerror(_e, _source, _lineno, _colno, err) {
      if (resolved) return;
      resolved = true;
      s.remove();
      const newError = new Error("Failed to load Zendesk Web Widget script", {
        cause: err,
      });
      newError.name = "ZendeskWebWidgetLoadError";
      useStore.setState({ state: "error" });
      reject(newError);
    };
  });
}

/**
 * Make sure only one script is loading at a time.
 */
const pQueue = new PQueue({ concurrency: 1 });
const queuedLoadScript = () => pQueue.add(loadScript);

const ZENDESK_WIDGET_OPEN_STORAGE_NAME = "ZD-widgetOpen";
window.addEventListener("beforeunload", () => {
  // Forget the open/close state of the widget.
  sessionStorage.removeItem(ZENDESK_WIDGET_OPEN_STORAGE_NAME);
});

function useLoadScript() {
  const message = useMessage();
  const getErrorMessage = useGetErrorMessage();
  const loadScript = useHandler(async function loadScript() {
    try {
      await queuedLoadScript();
    } catch (err) {
      message.error(getErrorMessage(err));
      logError(err);
      throw err;
    }
  });
  return loadScript;
}

export { useLoadScript };
