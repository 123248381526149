import { flutterWebViewAppSdk } from "@zeffiroso/flutter-webview-app-sdk/flutterWebViewAppSdk";

import { getFeatureFlag } from "@/app/featureFlag";

function shouldCallFlutterWebviewAppOpenBrowser(): boolean {
  const flutterWebviewAppOpenBrowser = getFeatureFlag(
    "flutterWebviewAppOpenBrowser",
  );
  return (
    flutterWebviewAppOpenBrowser === "always" ||
    (flutterWebviewAppOpenBrowser !== "never" &&
      flutterWebViewAppSdk.isFlutterWebViewApp)
  );
}

export { shouldCallFlutterWebviewAppOpenBrowser };
