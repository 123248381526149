import type { JsonValue } from "type-fest";
import { z } from "zod";

import { StringToBooleanSchema } from "../utils/zod/StringToBooleanSchema";
import { StringToNumberSchema } from "../utils/zod/StringToNumberSchema";

/**
 * Because all environment variables are optional, we need to convert all string
 * types to optional string types.
 */
function toOptionalStringSchemaShape<
  T extends Record<string, z.ZodType<any, any, string | undefined>>,
>(
  shape: T,
): {
  [TKey in keyof T]: z.ZodPipeline<z.ZodOptional<z.ZodString>, T[TKey]>;
} {
  return Object.fromEntries(
    Object.entries(shape).map(([key, value]) => [
      key,
      z.pipeline(z.string().optional(), value),
    ]),
  ) as any;
}

type EnvSchemaBase = z.ZodType<
  /**
   * Environment variables will be passed by serializing them to JSON
   */
  Record<string, JsonValue | undefined>,
  any,
  /**
   * All environment variables are optional strings.
   */
  Record<string, string | undefined>
>;

const AppEnvSchema = z.object(
  toOptionalStringSchemaShape({
    // Rest API host
    VITE_CANTATA_API_HOST: z.string().url(),

    // Other API Related configs
    VITE_API_DEFAULT_LIMIT: StringToNumberSchema,
    VITE_API_BULK_ACTION_LIMIT: StringToNumberSchema,

    // Web Socket server
    VITE_LEGATO_WS_HOST: z.string().url(),

    // MAAC forget password URL
    VITE_MAAC_FORGET_PASSWORD_URL: z.string().url(),

    // Admin Center host
    VITE_ADMIN_CENTER_HOST: z.string().url(),

    // Firebase configs
    VITE_FIREBASE_CONTENT_STORAGE_API_KEY: z.string().min(1),
    VITE_FIREBASE_CONTENT_STORAGE_PROJECT_ID: z.string().min(1),
    VITE_FIREBASE_CONTENT_STORAGE_BUCKET: z.string().min(1),
    VITE_FIREBASE_CONTENT_STORAGE_APP_ID: z.string().min(1),
    VITE_FIREBASE_CONTENT_STORAGE_IMMORTAL_BUCKET: z.string().min(1),
    VITE_FIREBASE_CONTENT_STORAGE_SENDER_ID: z.string().min(1),
    VITE_FIREBASE_CONTENT_STORAGE_VAPID_KEY: z.string().min(1),

    VITE_FIREBASE_SHARED_API_KEY: z.string().min(1),
    VITE_FIREBASE_SHARED_AUTH_DOMAIN: z.string().min(1),
    VITE_FIREBASE_SHARED_PROJECT_ID: z.string().min(1),
    VITE_FIREBASE_SHARED_APP_ID: z.string().min(1),

    VITE_FIREBASE_LOCALE_STORAGE_API_KEY: z.string().min(1),
    VITE_FIREBASE_LOCALE_STORAGE_APP_ID: z.string().min(1),
    VITE_FIREBASE_LOCALE_STORAGE_AUTH_DOMAIN: z.string().min(1),
    VITE_FIREBASE_LOCALE_STORAGE_PROJECT_ID: z.string().min(1),
    VITE_FIREBASE_LOCALE_STORAGE_STORAGE_BUCKET: z.string().min(1),
    VITE_FIREBASE_LOCALE_STORAGE_PATH_PREFIX: z.string().min(1),

    // Facebook configs
    VITE_FB_APP_ID: z.string().min(1),
    // Keep it empty for default limit defined by Facebook.
    VITE_FB_CURSOR_BASED_PAGINATION_LIMIT: StringToNumberSchema.optional(),

    VITE_DEFAULT_QUERY_RETRY_MAX_COUNT: StringToNumberSchema,
    VITE_DEFAULT_UPLOAD_CONCURRENCY: StringToNumberSchema,

    // We inject git commit sha as the release in CI commonly.
    VITE_SENTRY_RELEASE: z.string().min(1).optional(),

    // https://docs.sentry.io/product/sentry-basics/dsn-explainer/
    VITE_SENTRY_DSN: z.string().min(1),
    VITE_GTM_ID: z.string().min(1),
    VITE_GA4_ID: z.string().min(1),

    VITE_USERFLOW_TOKEN: z.string().min(1),
    // Prevent conflict with other environment. Change this for each environment.
    VITE_USERFLOW_USER_ID_PREFIX: z.string().min(1),
    // Prevent conflict with other apps. Do not change this for each environment.
    VITE_USERFLOW_ATTRIBUTE_PREFIX: z.string().min(1),

    VITE_ZENDESK_KEY: z.string().min(1),

    VITE_SITE: z.enum(["staging", "production", "jp"]),

    VITE_TITLE: z.string().min(1),
    VITE_DESCRIPTION: z.string().min(1),

    VITE_CAAC_APPLE_APP_STORE_LINK: z.string().url(),
    VITE_CAAC_GOOGLE_PLAY_LINK: z.string().url(),
  }),
) satisfies EnvSchemaBase;

const NodeEnvSchema = z.object(
  toOptionalStringSchemaShape({
    CI: StringToBooleanSchema.default("false"),
    IS_STORYBOOK: StringToBooleanSchema.default("false"),
    NODE_ENV: z
      .enum(["development", "production", "test"])
      .default("development"),
    PORT: StringToNumberSchema.default("3000"),
    // This is for `lint-staged.config.js`
    FORMAT: StringToBooleanSchema.default("false"),
    SENTRY_AUTH_TOKEN: z.string().optional(),

    // Enabled source map or not.
    SOURCEMAP: StringToBooleanSchema.default("false"),

    // Enabled rollup-plugin-visualizer or not.
    VISUALIZER: StringToBooleanSchema.default("false"),

    // Enable Turbo Console or not.
    // https://github.com/unplugin/unplugin-turbo-console
    TURBO_CONSOLE: StringToBooleanSchema.default("false"),

    // Enable SSL or not.
    // https://vitejs.dev/config/server-options.html#server-https
    ENABLE_SSL: StringToBooleanSchema.default("false"),
  }),
) satisfies EnvSchemaBase;

type AppEnv = z.infer<typeof AppEnvSchema>;
type NodeEnv = z.input<typeof NodeEnvSchema>;

export { AppEnvSchema, NodeEnvSchema };
export type { AppEnv, NodeEnv };
