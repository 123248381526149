import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import type { ElementRef, ReactNode } from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { EMPTY_STRING_PLACEHOLDER } from "@/appConstant";
import { cantata } from "@/cantata";
import { FormItem } from "@/components/Form";
import { useMergeFormDisabled } from "@/components/Form/DisabledContext";
import { Select } from "@/components/Select";

type Value = number[];

type OptionType = {
  key: number;
  value: number;
  text: string;
  label: ReactNode;
};

type TeamsSelectorRef = ElementRef<typeof Select>;
type TeamsSelectorProps = Omit<
  Select.Props<Value, OptionType>,
  "options" | "mode" | "optionFilterProp"
>;

/**
 * Teams selector
 *
 * allows customization using all props of the Select component, except for `options` and `mode`.
 */
const TeamsSelector = forwardRef<TeamsSelectorRef, TeamsSelectorProps>(
  function TeamsSelector({ value, ...props }, ref) {
    const { t } = useTranslation();
    const orgId = useActiveOrgIdStore((state) => state.value);
    const query = cantata.team.useList({
      params: { orgId },
    });
    const mergeDisabled = useMergeFormDisabled();
    const disabled = mergeDisabled(props.disabled || !query.isSuccess);

    const options = useMemo<Select.Props<number, OptionType>["options"]>(() => {
      if (!query.isSuccess) return [];
      return query.data.teams.map((team) => ({
        key: team.id,
        value: team.id,
        text: team.name,
        label: team.name,
      }));
    }, [query.data, query.isSuccess]);

    if (query.isLoading) return <Select {...props} loading disabled />;

    if (query.isError) {
      return (
        <FormItem
          help={inspectMessage`query error: ${query.error}`}
          validateStatus="error"
        >
          <Select {...props} loading disabled />
        </FormItem>
      );
    }

    return (
      <Select<Value, OptionType>
        ref={ref}
        showSearch
        value={value}
        disabled={disabled}
        placeholder={
          disabled
            ? EMPTY_STRING_PLACEHOLDER
            : t("feature.teamsSelector.placeholder")
        }
        {...props}
        mode="multiple"
        optionFilterProp="text"
        options={options}
      />
    );
  },
);

assignDisplayName(TeamsSelector, "TeamsSelector");

export { TeamsSelector };
export type { TeamsSelectorProps, TeamsSelectorRef };
