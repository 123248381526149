import type { SVGProps } from "react";

import type { MotifIcon } from "@/components/MotifIcon";

/**
 * @deprecated Use `MotifIcon` instead.
 * @see {@link MotifIcon}
 */
const SearchHolderIcon = (props: SVGProps<SVGSVGElement>): React.ReactNode => (
  <svg
    width={240}
    height={118}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M50.522 83.82c0 2.567-.987 4.65-2.206 4.65a.22.22 0 0 1-.047 0c-1.198-.052-2.158-2.114-2.158-4.647 0-2.533.96-4.597 2.158-4.647a.22.22 0 0 1 .047 0c1.218-.007 2.206 2.075 2.206 4.644Z"
      fill="#BAD3FF"
    />
    <path
      d="M48.316 88.809a.544.544 0 0 1-.083 0c-1.403-.082-2.46-2.218-2.46-4.984 0-2.767 1.057-4.905 2.46-4.984a.57.57 0 0 1 .083 0c1.426 0 2.542 2.19 2.542 4.989s-1.116 4.979-2.542 4.979Zm0-9.302h-.039c-.887.037-1.836 1.778-1.836 4.31 0 2.531.949 4.27 1.836 4.308h.036c1.103-.016 1.864-2.28 1.864-4.312 0-2.033-.758-4.297-1.854-4.306h-.007Z"
      fill="#3B6BEF"
    />
    <path
      d="M46.111 83.82c0 2.537.96 4.595 2.158 4.647H2.548a.22.22 0 0 1-.047 0C1.298 88.415.338 86.353.338 83.82c0-2.534.96-4.597 2.158-4.648a.233.233 0 0 1 .047 0h45.721c-1.193.051-2.153 2.113-2.153 4.648Z"
      fill="#3B6BEF"
    />
    <path
      d="M48.269 88.809H2.548a.543.543 0 0 1-.083 0C1.056 88.722 0 86.589 0 83.819c0-2.768 1.056-4.904 2.46-4.983a.543.543 0 0 1 .083 0H48.6v.65l-.322.018c-.887.037-1.836 1.777-1.836 4.309s.949 4.27 1.836 4.309l.322.013v.663l-.332.01Zm-45.721-.677h44.459c-.75-.845-1.233-2.439-1.233-4.312 0-1.874.484-3.46 1.233-4.313H2.51c-.887.04-1.835 1.781-1.835 4.313 0 2.531.948 4.27 1.835 4.309l.038.003Z"
      fill="#3B6BEF"
    />
    <path d="M39.781 88.467a.227.227 0 0 0 .048 0h-.048Z" fill="#3B6BEF" />
    <path
      d="M39.828 88.809h-.385v-.758l.405.085h-.02v.338h.29-.29v.335Z"
      fill="#3B6BEF"
    />
    <path d="M54.074 83.818h.043a.181.181 0 0 0-.043 0Z" fill="#E3EEFF" />
    <path
      d="M53.736 84.256v-.773h.38v.338h.295-.295v.339h.042l-.422.096Z"
      fill="#000"
    />
    <path
      d="M62.185 83.82c0 1.197.803 2.171 1.806 2.198H49.805c-1.002-.025-1.805-1-1.805-2.198 0-1.2.803-2.173 1.805-2.199h14.186c-1.002.026-1.806 1-1.806 2.199Z"
      fill="#E3EEFF"
    />
    <path
      d="M63.992 86.356H49.788c-1.193-.035-2.124-1.146-2.124-2.536 0-1.39.931-2.5 2.122-2.537H64.33v.668l-.338.01c-.813.019-1.474.846-1.474 1.86 0 1.015.661 1.839 1.474 1.86l.338.01v.667l-.338-.002ZM49.82 81.96c-.815.019-1.476.846-1.476 1.86 0 1.015.661 1.839 1.476 1.861h12.725a2.852 2.852 0 0 1 0-3.72h-12.73.005Z"
      fill="#3B6BEF"
    />
    <path d="M49.845 86.356h-.376v-.718l.376.042v.676Z" fill="#3B6BEF" />
    <path
      d="M71.78 83.82c0 2.148-.825 3.89-1.844 3.89a.148.148 0 0 1-.039 0c-1.002-.043-1.805-1.768-1.805-3.89 0-2.123.803-3.844 1.805-3.89h.039c1.019 0 1.844 1.742 1.844 3.89Z"
      fill="#E3EEFF"
    />
    <path
      d="M69.935 88.048a.46.46 0 0 1-.074 0c-1.203-.07-2.107-1.88-2.107-4.228 0-2.35.904-4.152 2.107-4.228a.708.708 0 0 1 .074 0c1.224 0 2.182 1.86 2.182 4.228 0 2.367-.959 4.228-2.182 4.228Zm.01-7.78h-.035c-.7.03-1.481 1.495-1.481 3.552 0 2.056.78 3.517 1.481 3.551h.032c.716-.018 1.5-1.471 1.5-3.551s-.786-3.54-1.496-3.552Z"
      fill="#3B6BEF"
    />
    <path
      d="M68.092 83.82c0 2.119.803 3.844 1.805 3.89h-7.06a.148.148 0 0 1-.04 0c-1.002-.043-1.805-1.768-1.805-3.89 0-2.123.803-3.844 1.805-3.89h7.1c-1.002.046-1.805 1.769-1.805 3.89Z"
      fill="#E3EEFF"
    />
    <path
      d="M69.896 88.048h-7.06a.44.44 0 0 1-.073 0c-1.203-.07-2.109-1.88-2.109-4.228 0-2.35.905-4.152 2.107-4.228a.708.708 0 0 1 .075 0h7.398v.661l-.324.017c-.7.03-1.481 1.495-1.481 3.551 0 2.057.78 3.518 1.48 3.552l.325.015v.661l-.338-.001Zm-7.052-.677h5.881c-.593-.744-.971-2.03-.971-3.551 0-1.522.378-2.81.971-3.552h-5.914c-.7.03-1.482 1.495-1.482 3.552 0 2.056.781 3.517 1.481 3.551h.034Z"
      fill="#3B6BEF"
    />
    <path d="M62.797 87.706a.157.157 0 0 0 .039 0h-.04Z" fill="#E3EEFF" />
    <path
      d="M62.835 88.048h-.376v-.756l.403.082h-.027v.339h.299-.299v.335Z"
      fill="#3B6BEF"
    />
    <path
      d="M198.811 85.15c-1.046 2.2-2.957 4.245-5.716 6.104-3.477 2.344-8.334 4.414-14.438 6.154-12.062 3.437-28.025 5.331-44.951 5.331-16.925 0-32.889-1.894-44.95-5.327-6.104-1.74-10.966-3.81-14.438-6.155-2.759-1.86-4.67-3.901-5.716-6.103.674-1.423 1.71-2.776 3.104-4.059a22.306 22.306 0 0 1 2.612-2.043c3.477-2.344 8.334-4.414 14.438-6.154 12.061-3.436 28.025-5.33 44.95-5.33 16.926 0 32.889 1.889 44.951 5.322 6.104 1.74 10.966 3.81 14.438 6.154.92.616 1.793 1.3 2.612 2.046 1.392 1.282 2.427 2.635 3.104 4.06Z"
      fill="#E3EEFF"
    />
    <path
      d="M133.707 103.077c-16.952 0-32.948-1.897-45.046-5.344-6.138-1.75-11.029-3.836-14.535-6.198-2.799-1.886-4.76-3.986-5.832-6.239l-.07-.145.07-.146c.695-1.458 1.764-2.86 3.182-4.163a22.694 22.694 0 0 1 2.65-2.076c3.506-2.367 8.397-4.449 14.535-6.198 12.095-3.446 28.091-5.344 45.046-5.344 16.956 0 32.948 1.898 45.047 5.344 6.138 1.749 11.029 3.836 14.535 6.198a22.63 22.63 0 0 1 2.647 2.072c1.417 1.304 2.487 2.706 3.182 4.164l.069.145-.069.146c-1.072 2.252-3.037 4.353-5.833 6.238-3.506 2.368-8.395 4.448-14.534 6.199-12.096 3.45-28.09 5.347-45.044 5.347Zm-64.73-17.926c1.034 2.088 2.894 4.045 5.53 5.822 3.446 2.324 8.267 4.379 14.341 6.11 12.043 3.429 27.968 5.318 44.859 5.318 16.892 0 32.822-1.889 44.858-5.317 6.074-1.732 10.897-3.787 14.341-6.11 2.637-1.778 4.496-3.735 5.53-5.823a13.29 13.29 0 0 0-2.956-3.805 22.158 22.158 0 0 0-2.573-2.011c-3.445-2.324-8.267-4.379-14.34-6.11-12.036-3.428-27.966-5.318-44.858-5.318-16.892 0-32.818 1.883-44.86 5.31-6.073 1.733-10.896 3.787-14.34 6.11a22.033 22.033 0 0 0-2.574 2.019 13.295 13.295 0 0 0-2.958 3.805Z"
      fill="#3B6BEF"
    />
    <path
      d="M199.757 81.007a9.54 9.54 0 0 1-.947 4.144c-1.046 2.198-2.957 4.245-5.716 6.103-3.477 2.344-8.334 4.414-14.438 6.154-12.06 3.437-28.023 5.331-44.949 5.331-16.925 0-32.889-1.894-44.95-5.327-6.104-1.74-10.966-3.81-14.439-6.155-2.758-1.86-4.67-3.901-5.715-6.103a9.551 9.551 0 0 1-.949-4.147c0-3.82 2.243-7.272 6.663-10.247 3.477-2.344 8.334-4.414 14.438-6.154 12.062-3.436 28.025-5.33 44.951-5.33 16.925 0 32.89 1.894 44.952 5.327 6.104 1.74 10.966 3.81 14.438 6.154 4.42 2.978 6.661 6.43 6.661 10.25Z"
      fill="#BAD3FF"
    />
    <path
      d="M133.707 103.077c-16.952 0-32.948-1.897-45.047-5.344-6.137-1.75-11.028-3.836-14.534-6.198-2.8-1.886-4.761-3.986-5.833-6.239a9.93 9.93 0 0 1-.977-4.289c0-3.94 2.291-7.482 6.811-10.527 3.506-2.368 8.397-4.45 14.535-6.198 12.095-3.447 28.091-5.345 45.047-5.345 16.955 0 32.948 1.898 45.046 5.345 6.138 1.748 11.029 3.835 14.535 6.198 4.52 3.044 6.811 6.587 6.811 10.527a9.933 9.933 0 0 1-.979 4.29c-1.071 2.252-3.037 4.352-5.832 6.238-3.506 2.368-8.395 4.448-14.535 6.198-12.1 3.447-28.094 5.344-45.048 5.344Zm0-43.463c-16.892 0-32.822 1.889-44.858 5.317-6.073 1.732-10.897 3.787-14.34 6.11-4.325 2.912-6.518 6.264-6.518 9.966a9.234 9.234 0 0 0 .915 3.998c1.012 2.143 2.9 4.15 5.6 5.968 3.444 2.324 8.266 4.379 14.34 6.11 12.044 3.429 27.969 5.318 44.861 5.318 16.891 0 32.821-1.889 44.857-5.317 6.074-1.732 10.898-3.787 14.341-6.11 2.699-1.819 4.582-3.826 5.6-5.969.6-1.247.913-2.613.914-3.998 0-3.702-2.193-7.054-6.512-9.966-3.447-2.323-8.267-4.378-14.341-6.11-12.038-3.428-27.968-5.317-44.859-5.317Z"
      fill="#3B6BEF"
    />
    <path
      d="M133.708 97.75c32.441 0 58.739-7.496 58.739-16.743 0-9.246-26.298-16.742-58.739-16.742s-58.74 7.496-58.74 16.742c0 9.247 26.299 16.743 58.74 16.743Z"
      fill="#E3EEFF"
    />
    <path
      d="M133.708 98.088c-15.721 0-30.504-1.747-41.627-4.916-11.253-3.213-17.45-7.528-17.45-12.165s6.197-8.963 17.45-12.164c11.123-3.17 25.906-4.917 41.627-4.917 15.72 0 30.503 1.747 41.626 4.917 11.253 3.213 17.45 7.527 17.45 12.164s-6.197 8.963-17.45 12.165c-11.123 3.17-25.906 4.916-41.626 4.916Zm0-33.485c-15.66 0-30.369 1.737-41.443 4.89-5.36 1.523-9.563 3.304-12.485 5.277-2.964 2-4.468 4.096-4.468 6.237s1.504 4.238 4.468 6.237c2.929 1.974 7.132 3.748 12.485 5.277 11.066 3.154 25.783 4.89 41.443 4.89 15.66 0 30.368-1.736 41.442-4.89 5.36-1.522 9.563-3.303 12.485-5.277 2.964-1.999 4.468-4.096 4.468-6.237 0-2.14-1.504-4.238-4.468-6.237-2.929-1.973-7.131-3.75-12.485-5.276-11.066-3.154-25.782-4.891-41.442-4.891Z"
      fill="#3B6BEF"
    />
    <path
      d="M199.757 81.007v8.287c0 3.82-2.242 7.272-6.663 10.247-3.477 2.344-8.334 4.414-14.438 6.154-12.06 3.436-28.023 5.331-44.949 5.331-14.477 0-28.249-1.385-39.479-3.937a90.524 90.524 0 0 1-2.193-.523l-.506-.127a92.509 92.509 0 0 1-2.53-.676l-.242-.068-.94-.274a71.053 71.053 0 0 1-1.687-.521 59.29 59.29 0 0 1-7.423-2.888 56.66 56.66 0 0 1-.844-.413 32.975 32.975 0 0 1-3.543-2.058c-4.422-2.975-6.666-6.427-6.666-10.247v-8.287c0 1.435.324 2.852.947 4.144 1.046 2.198 2.957 4.245 5.716 6.103a33.076 33.076 0 0 0 3.543 2.058c.275.137.556.276.843.413a59.283 59.283 0 0 0 7.424 2.888c.55.179 1.112.352 1.687.521l.94.274.24.068c.826.233 1.67.459 2.532.676l.506.127c.72.181 1.45.354 2.193.523 11.23 2.552 25.002 3.937 39.479 3.937 16.925 0 32.889-1.894 44.95-5.33 6.104-1.74 10.967-3.81 14.439-6.155 2.758-1.86 4.67-3.901 5.716-6.103a9.555 9.555 0 0 0 .948-4.144Z"
      fill="#3B6BEF"
    />
    <path
      d="M133.708 111.364c-14.509 0-28.195-1.365-39.553-3.946a99.82 99.82 0 0 1-2.202-.524l-.506-.127c-.88-.223-1.736-.451-2.541-.676l-.14-.037-.1-.029-.946-.276a63.238 63.238 0 0 1-1.687-.524 60.06 60.06 0 0 1-7.47-2.909c-.289-.139-.57-.277-.844-.416a33.697 33.697 0 0 1-3.584-2.08c-4.52-3.044-6.81-6.596-6.81-10.528v-8.285h.674a9.234 9.234 0 0 0 .915 3.998c1.012 2.143 2.9 4.15 5.6 5.968a32.85 32.85 0 0 0 3.507 2.03c.271.135.551.274.843.409a58.866 58.866 0 0 0 7.381 2.875c.507.17 1.063.338 1.688.52l.936.273.101.029.135.037c.803.227 1.652.453 2.53.677l.507.126c.655.17 1.368.339 2.193.521 11.304 2.571 24.936 3.929 39.405 3.929 16.892 0 32.821-1.889 44.857-5.317 6.074-1.732 10.898-3.787 14.341-6.11 2.7-1.818 4.582-3.826 5.6-5.968a9.243 9.243 0 0 0 .882-3.997h.675v8.287c0 3.94-2.291 7.482-6.811 10.528-3.506 2.367-8.395 4.447-14.535 6.198-12.093 3.446-28.087 5.344-45.041 5.344ZM67.992 84.591v4.703c0 3.7 2.194 7.054 6.514 9.966a32.967 32.967 0 0 0 4.352 2.439 58.967 58.967 0 0 0 7.38 2.875c.507.169 1.064.338 1.688.519l.936.274.101.029.135.037c.803.227 1.652.453 2.531.676l.506.127c.655.169 1.368.338 2.194.521 11.303 2.571 24.935 3.929 39.404 3.929 16.892 0 32.822-1.889 44.858-5.317 6.073-1.732 10.897-3.787 14.34-6.11 4.296-2.911 6.489-6.265 6.489-9.965v-4.705c-.093.239-.194.474-.304.707-1.071 2.253-3.036 4.353-5.832 6.239-3.506 2.368-8.395 4.448-14.535 6.198-12.095 3.447-28.09 5.344-45.046 5.344-14.51 0-28.196-1.365-39.553-3.945a104.96 104.96 0 0 1-2.202-.525l-.506-.126a109.17 109.17 0 0 1-2.541-.677l-.14-.037-.1-.029-.946-.276a61.496 61.496 0 0 1-1.687-.524 60.11 60.11 0 0 1-7.466-2.909c-.288-.138-.569-.277-.843-.416a33.63 33.63 0 0 1-3.584-2.08c-2.799-1.886-4.76-3.986-5.832-6.239a10.807 10.807 0 0 1-.31-.703Z"
      fill="#3B6BEF"
    />
    <path
      opacity={0.82}
      d="M132.721 92.78a144.364 144.364 0 0 1-23.799-2.05c-5.932-1.015-11.263-2.304-15.418-3.751-4.412-1.54-7.135-3.114-8.099-4.685a.847.847 0 0 1 .917-1.263c.218.052.406.19.522.38 3.066 5.023 35.963 12.434 60.65 8.654a.84.84 0 0 1 .934.712.844.844 0 0 1-.68.96c-4.548.697-9.645 1.042-15.027 1.042Z"
      fill="#fff"
    />
    <path
      d="M86.13 96.613v8.287a59.295 59.295 0 0 1-7.423-2.888 56.66 56.66 0 0 1-.844-.413v-8.287c.275.137.557.276.844.413a59.283 59.283 0 0 0 7.423 2.888ZM91.528 98.152v8.287a92.412 92.412 0 0 1-2.53-.676l-.242-.068-.94-.274v-8.287l.94.274.241.068c.826.233 1.67.459 2.531.676Z"
      fill="#BAD3FF"
      style={{
        mixBlendMode: "soft-light",
      }}
    />
    <path
      d="M166.69 7.61a6.319 6.319 0 0 0-4.474 1.858 6.347 6.347 0 0 0-1.853 4.484v66.04h-60.062v-66.04c0-1.682.666-3.295 1.853-4.484a6.319 6.319 0 0 1 4.474-1.858h60.062Z"
      fill="#fff"
    />
    <path
      d="M160.446 80.077h-60.231V13.952c0-1.704.675-3.339 1.878-4.544a6.403 6.403 0 0 1 4.533-1.882h60.062v.169c-1.656 0-3.243.66-4.414 1.833a6.263 6.263 0 0 0-1.828 4.424v66.125Zm-60.062-.169h59.893V13.952a6.446 6.446 0 0 1 1.389-3.99 6.418 6.418 0 0 1 3.558-2.267h-58.598c-1.656 0-3.243.66-4.414 1.833a6.263 6.263 0 0 0-1.828 4.424v65.956Z"
      fill="#3B6BEF"
    />
    <path
      d="M179.342 30.78h-18.979V13.951c0-1.682.667-3.295 1.853-4.484a6.32 6.32 0 0 1 8.948 0 6.352 6.352 0 0 1 1.853 4.484v10.486a6.35 6.35 0 0 0 1.852 4.483 6.32 6.32 0 0 0 4.473 1.858Z"
      fill="#FFD0C0"
    />
    <path
      d="M240 88.11a52.53 52.53 0 0 0-11.957-6.205v3.71c5.677 2.267 9.632 4.96 11.957 6.826v-4.33Z"
      fill="#BAD3FF"
    />
    <path
      d="M219.305 64.434c6.094 1.711 10.683 4.05 13.795 6.007v4.167c-2.591-1.845-7.187-4.582-13.795-6.569v-3.605Z"
      fill="#E3EEFF"
    />
    <path
      opacity={0.25}
      d="M216.547 56.135a53.01 53.01 0 0 0-10.576-2.718v3.508a48.887 48.887 0 0 1 10.576 2.943v-3.733Z"
      fill="#B7D1FF"
    />
    <path
      d="M191.455.338c-2.394 3.104-5.784 9.065-5.784 17.927v6.172a6.348 6.348 0 0 1-1.853 4.485 6.317 6.317 0 0 1-8.947 0 6.348 6.348 0 0 1-1.853-4.485V13.953a6.357 6.357 0 0 0-1.854-4.485 6.327 6.327 0 0 0-4.475-1.857h-43.31a27.122 27.122 0 0 1 3.965-7.272h64.111Z"
      fill="#fff"
    />
    <path
      d="M179.342 30.864a6.405 6.405 0 0 1-4.534-1.882 6.436 6.436 0 0 1-1.878-4.544V13.951c0-1.66-.657-3.251-1.828-4.424a6.235 6.235 0 0 0-4.414-1.833h-43.43l.04-.113a27.435 27.435 0 0 1 3.977-7.296l.025-.032h64.326l-.105.137c-2.632 3.409-5.768 9.277-5.768 17.874v6.172a6.436 6.436 0 0 1-1.878 4.545 6.403 6.403 0 0 1-4.533 1.882ZM123.497 7.526h43.191c1.7 0 3.331.677 4.533 1.882a6.432 6.432 0 0 1 1.878 4.544v10.486c0 1.659.658 3.25 1.829 4.424a6.233 6.233 0 0 0 8.828 0 6.268 6.268 0 0 0 1.828-4.424v-6.173c0-8.53 3.072-14.392 5.697-17.842h-63.903a27.152 27.152 0 0 0-3.881 7.103Z"
      fill="#3B6BEF"
    />
    <path
      d="M179.343 31.118a6.664 6.664 0 0 1-4.71-1.96 6.697 6.697 0 0 1-1.954-4.72V13.951a6.019 6.019 0 0 0-1.756-4.244 5.986 5.986 0 0 0-4.234-1.76h-43.793l.169-.454a27.747 27.747 0 0 1 4.012-7.362l.101-.132h64.965l-.422.545c-2.606 3.382-5.712 9.194-5.712 17.72v6.172a6.694 6.694 0 0 1-1.955 4.722 6.666 6.666 0 0 1-4.711 1.959ZM123.867 7.272h42.822a6.664 6.664 0 0 1 4.71 1.96 6.698 6.698 0 0 1 1.955 4.72v10.486c0 1.592.631 3.119 1.754 4.245a5.98 5.98 0 0 0 8.47 0 6.012 6.012 0 0 0 1.754-4.245v-6.173c0-8.274 2.868-14.062 5.445-17.589h-63.268a26.913 26.913 0 0 0-3.642 6.596Z"
      fill="#3B6BEF"
    />
    <path
      d="M179.343 31.118h-19.316V13.952c0-1.771.702-3.47 1.952-4.723a6.657 6.657 0 0 1 9.425 0 6.687 6.687 0 0 1 1.952 4.723v10.486a6.016 6.016 0 0 0 1.755 4.242 5.991 5.991 0 0 0 4.232 1.761v.677Zm-18.642-.677h15.722a6.68 6.68 0 0 1-3.744-6.003V13.951c0-1.592-.631-3.12-1.754-4.245a5.982 5.982 0 0 0-8.47 0 6.012 6.012 0 0 0-1.754 4.245v16.49Z"
      fill="#3B6BEF"
    />
    <path
      d="M160.702 80.33H99.965V13.953a6.697 6.697 0 0 1 1.954-4.72 6.664 6.664 0 0 1 4.708-1.96h60.062v.677a5.989 5.989 0 0 0-4.233 1.76 6.018 6.018 0 0 0-1.756 4.243l.002 66.379Zm-60.062-.676h59.387V13.952a6.697 6.697 0 0 1 1.014-3.539 6.679 6.679 0 0 1 2.73-2.464h-57.144a5.989 5.989 0 0 0-4.233 1.76 6.018 6.018 0 0 0-1.756 4.243l.002 65.702Z"
      fill="#3B6BEF"
    />
    <path
      d="m28.285 29.088.106-.143c5.151 3.816 12.156 5.411 17.127 6.064 2.672.351 5.365.533 8.06.545v3.461a66.69 66.69 0 0 1-8.445-.566c-6.798-.896-12.45-2.706-16.848-5.412v-3.949Z"
      fill="#BAD3FF"
    />
    <path
      d="m17.664 35.963.116-.134c4.842 4.203 11.704 6.329 16.608 7.37 2.638.56 5.308.95 7.995 1.167l-.266 3.45a67.09 67.09 0 0 1-8.373-1.22c-6.708-1.418-12.203-3.669-16.38-6.7l.3-3.933ZM27.123 10.438c-2.664-.994-5.399-2.3-7.818-4.012v4.13a40.73 40.73 0 0 0 7.818 3.567v-3.685Z"
      fill="#E3EEFF"
    />
    <path d="M51.582 49.77h-4.139v4.148h4.139V49.77Z" fill="#BAD3FF" />
    <path
      d="M153.586 61.474a.261.261 0 0 1-.186-.076.262.262 0 0 1-.076-.186v-2.428a.263.263 0 0 1 .262-.263.262.262 0 0 1 .261.263v2.437a.261.261 0 0 1-.261.253Z"
      fill="#6CA0FF"
    />
    <path
      d="M154.796 60.26h-2.423a.263.263 0 0 1 0-.524h2.423a.26.26 0 0 1 .184.076.26.26 0 0 1 .077.186c0 .07-.027.136-.077.185a.26.26 0 0 1-.184.077ZM147.815 67.283a1.416 1.416 0 0 1-1.311-.878 1.427 1.427 0 0 1 1.035-1.939 1.413 1.413 0 0 1 1.456.605 1.424 1.424 0 0 1-1.18 2.212Zm0-2.32a.896.896 0 0 0-.635 1.533.892.892 0 0 0 1.378-.136.897.897 0 0 0-.743-1.397ZM115.759 19.423a1.42 1.42 0 0 1-1.392-1.7 1.422 1.422 0 0 1 1.935-1.037 1.425 1.425 0 0 1 .46 2.32 1.42 1.42 0 0 1-1.003.417Zm0-2.32a.89.89 0 0 0-.829.553.904.904 0 0 0 .193.98.895.895 0 0 0 1.53-.635.898.898 0 0 0-.894-.898ZM200.994 47.776a.256.256 0 0 1-.185-.077.267.267 0 0 1-.077-.186v-2.428a.263.263 0 0 1 .447-.185c.049.049.076.116.076.185v2.437a.26.26 0 0 1-.261.254Z"
      fill="#6CA0FF"
    />
    <path
      d="M202.206 46.561h-2.423a.262.262 0 0 1 0-.524h2.423a.262.262 0 0 1 0 .524ZM195.392 57.305a1.42 1.42 0 0 1-1.392-1.7 1.418 1.418 0 0 1 2.81.278 1.424 1.424 0 0 1-1.418 1.422Zm0-2.32a.896.896 0 0 0-.636 1.532.895.895 0 0 0 1.53-.634.898.898 0 0 0-.894-.898ZM88.969 4.482a.26.26 0 0 1-.262-.254V1.79a.263.263 0 0 1 .262-.262.261.261 0 0 1 .261.262v2.437a.26.26 0 0 1-.261.254Z"
      fill="#6CA0FF"
    />
    <path
      d="M90.178 3.267h-2.422a.261.261 0 0 1-.185-.447.261.261 0 0 1 .185-.077h2.422a.261.261 0 0 1 .185.448.261.261 0 0 1-.185.076ZM83.366 14.011a1.416 1.416 0 0 1-1.31-.878 1.426 1.426 0 0 1 1.033-1.939 1.416 1.416 0 0 1 1.457.605 1.425 1.425 0 0 1-1.18 2.212Zm0-2.32a.893.893 0 0 0-.829.553.9.9 0 0 0 .652 1.226.894.894 0 0 0 1.071-.88.899.899 0 0 0-.894-.899Z"
      fill="#6CA0FF"
    />
    <path
      opacity={0.25}
      d="M120.994 25.271h-20.876a6.257 6.257 0 0 0-4.43 1.84 6.287 6.287 0 0 0 0 8.88 6.257 6.257 0 0 0 4.43 1.839h1.798l1.127 1.46 1.129-1.46h16.822a6.257 6.257 0 0 0 4.43-1.84 6.286 6.286 0 0 0 0-8.88 6.257 6.257 0 0 0-4.43-1.839ZM133.143 42.352h-20.877a6.257 6.257 0 0 0-4.429 1.84 6.286 6.286 0 0 0 0 8.88 6.256 6.256 0 0 0 4.429 1.839h1.799l1.127 1.46 1.128-1.46h16.823a6.255 6.255 0 0 0 4.429-1.84 6.286 6.286 0 0 0 0-8.88 6.255 6.255 0 0 0-4.429-1.839ZM154.71 39.318h24.875c1.98 0 3.878.788 5.278 2.192a7.49 7.49 0 0 1 2.186 5.29 7.486 7.486 0 0 1-4.605 6.916 7.451 7.451 0 0 1-2.857.57h-2.143l-1.35 1.74-1.349-1.74H154.71a7.455 7.455 0 0 1-5.278-2.19 7.49 7.49 0 0 1-2.186-5.291 7.488 7.488 0 0 1 4.607-6.917 7.441 7.441 0 0 1 2.857-.57ZM129.119 61.897h19.103a5.73 5.73 0 0 1 4.057 1.684 5.76 5.76 0 0 1 0 8.132 5.73 5.73 0 0 1-4.057 1.684h-1.646l-1.031 1.336-1.033-1.336h-15.393a5.73 5.73 0 0 1-4.056-1.684 5.756 5.756 0 0 1 0-8.132 5.73 5.73 0 0 1 4.056-1.684Z"
      fill="#B7D1FF"
    />
    <path
      d="M123.428 21.889h-20.607a6.391 6.391 0 0 0-4.525 1.879 6.422 6.422 0 0 0 0 9.071 6.391 6.391 0 0 0 4.525 1.88h1.837l1.153 1.49 1.152-1.49h16.465a6.392 6.392 0 0 0 4.525-1.88 6.423 6.423 0 0 0 0-9.071 6.392 6.392 0 0 0-4.525-1.88Z"
      fill="#fff"
    />
    <path
      d="M105.81 36.548a.338.338 0 0 1-.266-.13l-1.051-1.362h-1.672c-1.787 0-3.5-.711-4.764-1.977a6.76 6.76 0 0 1 0-9.55 6.729 6.729 0 0 1 4.764-1.978h20.606c1.787 0 3.501.711 4.764 1.978a6.762 6.762 0 0 1 0 9.55 6.728 6.728 0 0 1-4.764 1.977h-16.294l-1.051 1.362a.334.334 0 0 1-.272.13Zm-2.989-14.32c-1.608 0-3.15.64-4.287 1.779a6.084 6.084 0 0 0 0 8.593 6.055 6.055 0 0 0 4.287 1.78h1.837a.335.335 0 0 1 .267.13l.885 1.148.886-1.146a.336.336 0 0 1 .267-.132h16.464c1.608 0 3.15-.64 4.287-1.78a6.082 6.082 0 0 0 1.775-4.297 6.083 6.083 0 0 0-1.775-4.296 6.056 6.056 0 0 0-4.287-1.78h-20.606Z"
      fill="#6CA0FF"
    />
    <path
      d="M125.731 25.344h-17.884a.336.336 0 0 1-.337-.338.336.336 0 0 1 .337-.338h17.884a.336.336 0 0 1 .337.338.336.336 0 0 1-.337.338ZM125.731 27.543h-17.884a.336.336 0 0 1-.337-.339.336.336 0 0 1 .337-.338h17.884a.336.336 0 0 1 .337.338.336.336 0 0 1-.337.338ZM125.73 29.741h-3.582a.338.338 0 0 1 0-.677h3.575a.338.338 0 0 1 0 .677h.007ZM120.124 29.741h-12.279a.336.336 0 0 1-.337-.338.336.336 0 0 1 .337-.338h12.279a.336.336 0 0 1 .338.338.34.34 0 0 1-.338.338ZM117.308 31.94h-9.463a.336.336 0 0 1-.337-.339.336.336 0 0 1 .337-.338h9.463a.337.337 0 0 1 .338.338.34.34 0 0 1-.338.338ZM102.305 32.107a3.965 3.965 0 0 0 3.96-3.97 3.965 3.965 0 0 0-3.96-3.968 3.965 3.965 0 0 0-3.96 3.969 3.965 3.965 0 0 0 3.96 3.969Z"
      fill="#E6EFFC"
    />
    <path
      d="M103.008 31.116a3.055 3.055 0 0 0-1.407-5.943 3.055 3.055 0 0 0-2.26 3.677 3.046 3.046 0 0 0 3.667 2.266Z"
      fill="#FFD0C0"
    />
    <path
      d="M152.327 35.346h24.555c2.022 0 3.961.805 5.391 2.238a7.652 7.652 0 0 1 0 10.809 7.617 7.617 0 0 1-5.391 2.24h-1.046l-1.374 1.776-1.373-1.777h-20.762a7.617 7.617 0 0 1-5.391-2.24 7.652 7.652 0 0 1 0-10.808 7.615 7.615 0 0 1 5.391-2.238Z"
      fill="#fff"
    />
    <path
      d="M174.463 52.748a.337.337 0 0 1-.267-.13l-1.272-1.648h-20.596a7.953 7.953 0 0 1-5.556-2.378 7.995 7.995 0 0 1 0-11.206 7.953 7.953 0 0 1 5.556-2.379h24.554a7.953 7.953 0 0 1 5.556 2.379 7.99 7.99 0 0 1 2.292 5.603 7.99 7.99 0 0 1-2.292 5.603 7.953 7.953 0 0 1-5.556 2.378h-.88l-1.272 1.648a.337.337 0 0 1-.267.13Zm-22.135-17.064a7.28 7.28 0 0 0-5.082 2.178 7.315 7.315 0 0 0 0 10.253 7.28 7.28 0 0 0 5.082 2.179h20.762a.337.337 0 0 1 .266.13l1.107 1.432 1.105-1.43a.338.338 0 0 1 .268-.132h1.046a7.28 7.28 0 0 0 5.082-2.179 7.315 7.315 0 0 0 0-10.253 7.28 7.28 0 0 0-5.082-2.178h-24.554Z"
      fill="#6CA0FF"
    />
    <path
      d="M177.36 47.718a4.724 4.724 0 0 0 4.718-4.73 4.724 4.724 0 0 0-4.718-4.73 4.724 4.724 0 0 0-4.719 4.73 4.724 4.724 0 0 0 4.719 4.73Z"
      fill="#E6EFFC"
    />
    <path
      d="M178.198 46.542a3.64 3.64 0 0 0 2.694-4.381 3.63 3.63 0 0 0-4.371-2.7 3.64 3.64 0 0 0-2.694 4.38 3.63 3.63 0 0 0 4.371 2.701Z"
      fill="#FFD0C0"
    />
    <path
      d="M170.896 39.404h-21.311a.336.336 0 0 1-.337-.338.34.34 0 0 1 .337-.338h21.311a.336.336 0 0 1 .337.338.336.336 0 0 1-.337.338ZM165.208 42.017h-15.623a.336.336 0 0 1-.337-.338.34.34 0 0 1 .337-.338h15.623a.337.337 0 0 1 .338.338.34.34 0 0 1-.338.338ZM170.895 42.017h-3.651a.337.337 0 0 1-.338-.338.34.34 0 0 1 .338-.338h3.651a.336.336 0 0 1 .337.338.336.336 0 0 1-.337.338ZM156.061 44.637h-6.476a.336.336 0 0 1-.337-.338.34.34 0 0 1 .337-.339h6.476a.336.336 0 0 1 .337.339.336.336 0 0 1-.337.338ZM170.895 44.637h-12.419a.34.34 0 0 1 0-.676h12.419a.338.338 0 0 1 0 .676ZM163.059 47.257h-13.474a.336.336 0 0 1-.337-.339.34.34 0 0 1 .337-.338h13.474a.339.339 0 0 1 0 .677Z"
      fill="#E6EFFC"
    />
    <path d="M160.026 77.71h-51.964v8.794h51.964V77.71Z" fill="#E3EEFF" />
    <path
      d="M100.639 76.61s16.196-3.89 27.163-.845c16.581 4.603 32.123-1.296 32.123-1.296v7.384h-59.286V76.61Z"
      fill="#E3EEFF"
    />
    <path
      d="M117.835 70.05a.336.336 0 0 1-.337-.338V56.41a.34.34 0 0 1 .337-.338.337.337 0 0 1 .338.338v13.3a.34.34 0 0 1-.338.34ZM117.835 52.697a.336.336 0 0 1-.337-.338v-5.936a.34.34 0 0 1 .337-.339.336.336 0 0 1 .338.339v5.936a.34.34 0 0 1-.338.338ZM127.349 79.404a.336.336 0 0 1-.337-.338v-9.728a.336.336 0 0 1 .337-.338.338.338 0 0 1 .338.338v9.723a.346.346 0 0 1-.208.317.334.334 0 0 1-.13.026ZM74.459 44.921a.337.337 0 0 1-.338-.338V32.219a.338.338 0 0 1 .576-.24c.063.064.099.15.099.24v12.364a.339.339 0 0 1-.338.338ZM74.459 28.79a.337.337 0 0 1-.338-.338v-5.518a.339.339 0 0 1 .338-.338.337.337 0 0 1 .337.338v5.518a.339.339 0 0 1-.338.338ZM69.474 25.816a.337.337 0 0 1-.337-.338V16.44a.339.339 0 0 1 .337-.338.337.337 0 0 1 .338.338v9.038a.338.338 0 0 1-.338.338ZM150.777 81.788a.338.338 0 0 1-.338-.338v-5.516a.34.34 0 0 1 .338-.338.336.336 0 0 1 .337.338v5.518a.337.337 0 0 1-.337.337ZM155.761 76.948a.336.336 0 0 1-.337-.338v-9.032a.336.336 0 0 1 .337-.339.337.337 0 0 1 .338.339v9.032a.34.34 0 0 1-.338.338ZM214.828 40.08a.337.337 0 0 1-.338-.337V27.378a.34.34 0 0 1 .338-.338.336.336 0 0 1 .337.338v12.365a.336.336 0 0 1-.337.338ZM214.828 23.95a.336.336 0 0 1-.338-.338v-5.516a.34.34 0 0 1 .338-.339.336.336 0 0 1 .337.339v5.518a.337.337 0 0 1-.337.336ZM209.843 20.97a.336.336 0 0 1-.337-.338V11.6a.336.336 0 0 1 .337-.338.337.337 0 0 1 .338.338v9.032a.34.34 0 0 1-.338.339Z"
      fill="#6CA0FF"
    />
    <path
      d="M213.455 118a.084.084 0 0 0 .06-.025.084.084 0 0 0-.06-.144.083.083 0 0 0-.059.025.083.083 0 0 0 0 .119.083.083 0 0 0 .059.025Z"
      fill="#3B6BEF"
    />
    <path
      d="M175.384 68.924a65.039 65.039 0 0 1 6.657 2.256l1.665-4.538a79.162 79.162 0 0 0-5.083-1.63 102.04 102.04 0 0 0-5.431-1.382l-1.581 4.306c1.293.311 2.556.641 3.773.988ZM160.877 96.238l-1.721 4.688c4.368-.557 8.519-1.257 12.389-2.09l1.893-5.145a118.166 118.166 0 0 1-12.561 2.547ZM175.899 93.003l-1.94 5.285c1.488-.353 2.928-.727 4.319-1.123.302-.087.597-.17.893-.26l2.107-5.75a62.523 62.523 0 0 1-5.379 1.848ZM183.943 72.012a30.358 30.358 0 0 1 3.916 2.14l1.817-4.95a48.13 48.13 0 0 0-4.034-1.82l-1.699 4.63Z"
      fill="#fff"
      style={{
        mixBlendMode: "soft-light",
      }}
    />
  </svg>
);

export { SearchHolderIcon };
