import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import type { CantataTypes } from "@/cantata/types";
import type { Select } from "@/components/Select";

export const useQuickTemplateOptions = (
  quickTemplates: Array<CantataTypes["QuickTemplate"]>,
): Array<Select.OptionType> => {
  const { t } = useTranslation();
  return useMemo<Select.OptionType[]>(() => {
    const [, ...restCategories] = quickTemplates;
    return [
      {
        label: t("quickTemplate.uncategorized.label"),
        value: 0,
      },
      ...restCategories.map(({ category: { id, name } }) => ({
        label: name,
        value: id,
      })),
    ];
  }, [t, quickTemplates]);
};
