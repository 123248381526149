import { useHandler } from "@chatbotgang/etude/react/useHandler";
import styled from "@emotion/styled";
import { noop } from "lodash-es";

import { Flex } from "@/components/Box";
import { Select } from "@/components/Select";
import { Text } from "@/components/Typography";
import countryCallingCodes from "@/shared/g11n/data/countryCallingCodes.json";

const StyledWrapper = styled(Flex)`
  gap: 16px;
`;

interface CountryCodesItemProps extends Select.Props<string> {
  value?: string;
  onChange?: (value: string) => void;
}

export const CountryCodesItem = ({
  value,
  onChange,
  ...rest
}: CountryCodesItemProps): React.ReactNode => {
  const onChangeCallback = useHandler(onChange || noop);

  return (
    <Select
      defaultValue={value}
      value={value}
      onChange={onChangeCallback}
      optionLabelProp="label"
      popupMatchSelectWidth={false}
      showSearch
      filterOption={(input, option) => {
        const optionString = `${option?.title ?? ""}${option?.label ?? ""}`;
        return optionString.toLowerCase().includes(input.toLowerCase());
      }}
      style={{ minWidth: 75 }}
      {...rest}
    >
      {countryCallingCodes.map((item, index) => (
        <Select.Option
          key={index}
          value={item.code}
          label={item.code}
          title={item.name}
        >
          <StyledWrapper>
            <Text>{item.code}</Text>
            <Text>{item.name}</Text>
          </StyledWrapper>
        </Select.Option>
      ))}
    </Select>
  );
};
