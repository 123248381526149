import { createContext } from "@chatbotgang/etude/react/createContext";
import useSwitch from "@react-hook/switch";

const PopoverContext = createContext<ReturnType<typeof useSwitch>>();

const PopoverSwitchProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const value = useSwitch(false);
  return (
    <PopoverContext.Provider value={value}>{children}</PopoverContext.Provider>
  );
};

const usePopoverSwitch = PopoverContext.useContext;

export { PopoverSwitchProvider, usePopoverSwitch };
