import type {
  AppendKeyPrefix,
  DefaultNamespace,
  InterpolationMap,
  Namespace,
  ParseKeys,
  t,
  TFunctionReturn,
  TOptions,
} from "i18next";
import type { ReactNode } from "react";

import { Trans } from "@/app/i18n/Trans";
import type { fakeT } from "@/shared/g11n/fakeT";

interface ArgsTFunction<
  Ns extends Namespace = DefaultNamespace,
  KPrefix = undefined,
> {
  /**
   * Learned from `t`.
   *
   * @see {@link t}
   */
  <
    const Key extends ParseKeys<Ns, TOpt, KPrefix> | TemplateStringsArray,
    const TOpt extends TOptions,
    Ret extends TFunctionReturn<Ns, AppendKeyPrefix<Key, KPrefix>, TOpt>,
    const ActualOptions extends TOpt & InterpolationMap<Ret> = TOpt &
      InterpolationMap<Ret>,
  >(
    key: Key,
    options?: ActualOptions,
  ): {
    args: [key: Key, options?: ActualOptions];
    key: Key;
    options?: ActualOptions;
    node: ReactNode;
    Component: typeof Trans;
  };
}

/**
 * Similar to `fakeT`, but returns a set of utilities for the translation.
 *
 * @example
 *
 * ```tsx
 * const t = argsT;
 *
 * // Creates a set of utilities for the translation.
 * const transUtils = t("resource.user.reactMaxCountLimit", { count: 30 });  // supports i18n-ally
 *
 * // Renders the translation node directly.
 * message.error(transUtils.node);
 *
 * // Customizes the `<Trans />` component.
 * message.error(<transUtils.Component components={{ Link }} />);
 *
 * // Compatible with `t` function
 * message.error(t(...transUtils.args));
 * message.error(t(transUtils.key, transUtils.options));
 * ```
 *
 * @see {@link fakeT}
 */
const argsT: ArgsTFunction = (...args) => {
  const [key, options] = args;
  const Component: typeof Trans = (...props) => {
    return (
      <Trans
        // @ts-expect-error -- Not sure how to type this for now.
        i18nKey={key}
        {...(!options ? null : { values: options })}
        {...props}
      />
    );
  };
  const node = <Component />;
  return {
    args,
    key,
    options,
    node,
    Component,
  };
};

export { argsT };
