import { makeApi } from "@zodios/core";
import { z } from "zod";

import { InternalNotificationSchema, OrgSchema } from "../models";

const api = makeApi([
  {
    /**
     * Spec: [Notion](https://www.notion.so/cresclab/202503-Broadcast-1af8ce158938801bbb76d6628f850f00?pvs=4#1b38ce15893880fd8d89e1a967f99641)
     */
    alias: "getInternalNotifications",
    method: "get",
    path: "api/v1/orgs/:orgId/organization/internal_notifications",
    parameters: [
      {
        type: "Path",
        name: "orgId",
        schema: OrgSchema.shape.id,
      },
    ],
    response: z.object({
      notifications: InternalNotificationSchema.array(),
    }),
  },
  {
    /**
     * Spec: [Notion](https://www.notion.so/cresclab/202503-Broadcast-1af8ce158938801bbb76d6628f850f00?pvs=4#1b48ce15893880d4a5c7e5fc29a2fbe3)
     */
    alias: "markInternalNotificationAsRead",
    method: "post",
    path: "api/v1/orgs/:orgId/organization/internal_notifications/:notificationId/mark-read",
    parameters: [
      {
        type: "Path",
        name: "orgId",
        schema: OrgSchema.shape.id,
      },
      {
        type: "Path",
        name: "notificationId",
        schema: InternalNotificationSchema.shape.id,
      },
    ],
    response: z.object({
      id: z.number().int(),
    }),
  },
]);

export { api, InternalNotificationSchema };
