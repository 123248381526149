import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";

import { Trans } from "@/app/i18n/Trans";
import image from "@/layout/base/AuthenticatedAppOuter/components/NotificationPanel/images/empty.png";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  Empty: css({
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    padding: "20px 0",
    gap: "8px",
  }),
  image: css({
    width: "80px",
  }),
  desc: css({
    fontSize: "0.75rem",
    color: theme.colors.neutral007,
  }),
});

const Empty = () => {
  return (
    <div css={styles.Empty}>
      <div>
        <img src={image} alt="empty" css={styles.image} />
      </div>
      <span css={styles.desc}>
        <Trans i18nKey="layout.nav.notifications.empty.desc" />
      </span>
    </div>
  );
};

export { Empty };
