import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { css } from "@emotion/react";
import type { DistributiveOmit, Overwrite } from "@mui/types";
import { forwardRef } from "react";

import AppleAppStoreLinkButton from "@/components/AppDownloadLink/AppleAppStoreLinkButton.svg";
import GooglePlayLinkButton from "@/components/AppDownloadLink/GooglePlayLinkButton.svg";
import { ExternalLink } from "@/components/ExternalLink";
import { defineStyles } from "@/shared/emotion";

const defaultComponent =
  ExternalLink satisfies React.ElementType<AppDownloadLink.BaseElementProps>;

const storeTypes = ["appleAppStore", "googlePlayStore"] as const;

const storeTypeToImgSrcRecord = {
  appleAppStore: AppleAppStoreLinkButton,
  googlePlayStore: GooglePlayLinkButton,
} as const satisfies {
  [key in AppDownloadLink.StoreType]: string;
} satisfies Record<AppDownloadLink.StoreType, string>;

const styles = defineStyles({
  root: css({
    aspectRatio: "120 / 40",
    display: "inline-flex",
    alignItems: "stretch",
    maxWidth: "100%",
    // span & img
    "&>*,&>*>*": {
      aspectRatio: "inherit",
      display: "flex",
      alignItems: "stretch",
      flex: 1,
    },
  }),
});

namespace AppDownloadLink {
  export type StoreType = (typeof storeTypes)[number];
  export interface BaseElementProps
    extends Pick<ExternalLink.Props, "className" | "href"> {}
  export type DefaultRootElement = typeof defaultComponent;
  export interface OwnProps {
    /**
     * The root element of the component.
     */
    component?: React.ElementType;
    storeType: StoreType;
    imageProps?: DistributiveOmit<ComponentProps<"img">, "src">;
  }
  export type Props<
    TRootElement extends React.ElementType = DefaultRootElement,
  > = Overwrite<ComponentProps<TRootElement>, OwnProps>;
  export interface Type {
    <TRootElement extends React.ElementType = DefaultRootElement>(
      props: Overwrite<Props<TRootElement>, { component: TRootElement }>,
      ref?: React.ForwardedRef<React.ElementRef<TRootElement>>,
    ): React.ReactNode;
    (
      props: DistributiveOmit<Props, "component">,
      ref?: React.ForwardedRef<React.ElementRef<DefaultRootElement>>,
    ): React.ReactNode;
  }
}

const AppDownloadLinkInternal: AppDownloadLink.Type = forwardRef(
  function AppDownloadLinkInternal<
    TRootElement extends React.ElementType = AppDownloadLink.DefaultRootElement,
  >(
    {
      component: Component = defaultComponent,
      storeType,
      imageProps,
      ...props
    }: AppDownloadLink.Props<TRootElement>,
    ref: React.ForwardedRef<React.ElementRef<TRootElement>>,
  ) {
    const imgSrc: React.ComponentProps<"img">["src"] =
      storeTypeToImgSrcRecord[storeType];
    return (
      <Component css={styles.root} {...props} ref={ref}>
        <img {...imageProps} src={imgSrc} />
      </Component>
    );
  },
);

/**
 * Description of the component.
 */
const AppDownloadLink = Object.assign(AppDownloadLinkInternal, {
  defaultComponent,
  styles,
  storeTypes,
  storeTypeToImgSrcRecord,
});

export { AppDownloadLink };
