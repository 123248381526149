import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { css } from "@emotion/react";
import { flutterWebViewAppSdk } from "@zeffiroso/flutter-webview-app-sdk/flutterWebViewAppSdk";
import { usePortal } from "@zeffiroso/utils/react-lib/usePortal";
import { shallow } from "@zeffiroso/utils/zustand/shallow";
import { Tabs } from "antd";
import { type FC, useEffect, useMemo } from "react";
import { createPortal } from "react-dom";
import { createWithEqualityFn } from "zustand/traditional";

import { Action } from "@/layout/DevMode/Action";
import { Env } from "@/layout/DevMode/Env";
import { Feature } from "@/layout/DevMode/Feature";
import { FlutterSdkLogs } from "@/layout/DevMode/Flutter";
import { Info } from "@/layout/DevMode/Info";
import { ReactBoundaryTests } from "@/layout/DevMode/ReactBoundaryTests";
import { defineStyles } from "@/shared/emotion";

type TabItem = NonNullable<ComponentProps<typeof Tabs>["items"]>[number];
type TabItemWithoutKey = Omit<TabItem, "key">;
type DebugTabItem = Omit<TabItemWithoutKey, "children"> & {
  setOuterEl: ReturnType<typeof usePortal>["setOuterEl"];
};

const items: Array<TabItemWithoutKey> = [
  {
    label: "Features",
    children: <Feature />,
  },
  {
    label: "Actions",
    children: <Action />,
  },
  {
    label: "React Boundary Tests",
    children: <ReactBoundaryTests />,
  },
  {
    label: "Info",
    children: <Info />,
  },
  {
    label: "Env",
    children: <Env />,
  },
];

const styles = defineStyles({
  root: css({
    overflow: "auto",
    "&>.ant-tabs-nav": {
      marginBottom: 0,
    },
    "&>.ant-tabs-content-holder": {
      paddingTop: 16,
      overflow: "auto",
    },
  }),
});

/**
 * The store to manage the additional tabs in the DevMode modal.
 */
const useDebugTabsStore = createWithEqualityFn<{
  tabs: Array<DebugTabItem>;
}>()(
  () => ({
    tabs: [],
  }),
  shallow,
);

/**
 * The component to render the content of the DevMode modal.
 */
const Content: FC = () => {
  const additionalTabs = useDebugTabsStore((state) => state.tabs);
  const allTabs = useMemo(() => {
    let index = 0;
    const tabs = [
      ...items,
      ...(additionalTabs.map((tab) => ({
        ...tab,
        children: <div ref={tab.setOuterEl} />,
      })) satisfies Array<TabItemWithoutKey>),
    ].map<TabItem>((tab) => ({
      ...tab,
      key: String(index++),
    }));
    const flutterSdkLogsTab: TabItem = {
      label: "Flutter SDK Logs",
      children: <FlutterSdkLogs />,
      key: String(index++),
    };
    if (flutterWebViewAppSdk.isFlutterWebViewApp) {
      tabs.splice(0, 0, flutterSdkLogsTab);
    } else {
      tabs.push(flutterSdkLogsTab);
    }
    return tabs;
  }, [additionalTabs]);
  return <Tabs css={styles.root} defaultActiveKey="0" items={allTabs} />;
};

/**
 * Setup a debug tab in the DevMode modal. Notice that the `children` prop is
 * replaced with a `setOuterEl` function prop based on the `usePortal` hook to
 * allow the tab to render its content in a portal.
 *
 * To optimize the performance, the `tabItem` should be memoized.
 *
 * ```tsx
 * const tabItem = useMemo<DebugTabItem>(...);
 * useSetupDebugTab(tabItem);
 * ```
 */
function useSetupDebugTab(tabItem: DebugTabItem) {
  useEffect(() => {
    useDebugTabsStore.setState((state) => ({
      tabs: [...state.tabs, tabItem],
    }));
    return function cleanup() {
      useDebugTabsStore.setState((state) => ({
        tabs: state.tabs.filter((tab) => tab !== tabItem),
      }));
    };
  }, [tabItem]);
}

/**
 * The component to render a debug tab in the DevMode modal.
 *
 * `useSetupDebugTab` integrated.
 *
 * To optimize the performance, the `tab` should be memoized.
 *
 * ```tsx
 * const tab = useMemo<TabItemWithoutKey>(...);
 * return <DebugTab tab={tab} />;
 * ```
 */
const DebugTab: FC<{ tab: TabItemWithoutKey }> = ({ tab }) => {
  const portal = usePortal();
  const tabItem = useMemo<DebugTabItem>(
    () => ({
      ...tab,
      setOuterEl: portal.setOuterEl,
    }),
    [portal.setOuterEl, tab],
  );
  useSetupDebugTab(tabItem);
  return createPortal(tab.children, portal.innerEl);
};

export { Content, DebugTab };
export type { TabItemWithoutKey };
