import { CloseCircleFilled, SearchOutlined } from "@ant-design/icons";
import { checkKey } from "@chatbotgang/etude/event/keycode";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { delay } from "@chatbotgang/etude/timer/delay";
import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import { memo } from "@zeffiroso/utils/react/memo";
import { Space } from "antd";
import { isEmpty, kebabCase, pick } from "lodash-es";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { Trans } from "@/app/i18n/Trans";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { Alert } from "@/components/Alert";
import { NarrowIconButton } from "@/components/Button/NarrowIconButton";
import { NotFoundContent } from "@/components/Empty/NotFoundContent";
import type { FormProps } from "@/components/Form";
import { Form } from "@/components/Form";
import type { InputProps } from "@/components/Input";
import { Input } from "@/components/Input";
import { Select } from "@/components/Select";
import { SideMenu } from "@/layout/SideMenu";
import { useSearchParamFromDirectChatStore } from "@/routes/Chat/ui/directChat";
import { Layout } from "@/routes/Chat/ui/Layout";
import { useMembersController } from "@/routes/Chat/ui/MembersPanel/controllers/membersController";
import { searchController } from "@/routes/Chat/ui/MembersPanel/controllers/searchController";
import { useSelectionController } from "@/routes/Chat/ui/MembersPanel/controllers/selectionController";
import { defineStyles } from "@/shared/emotion";
import { useInputComposition } from "@/shared/hooks/useInputComposition2";

type FormValues =
  | {
      action: Exclude<CantataTypes["MemberSearchAction"], "tags">;
      query: string;
    }
  | {
      action: Extract<CantataTypes["MemberSearchAction"], "tags">;
      query: Array<number>;
    };

const SearchAction = memo(function SearchAction() {
  const form = Form.useFormInstance();
  const handleSelectChange = useHandler<
    Select.Props<FormValues["action"]>["onChange"]
  >(function handleSelectChange(value) {
    searchController.changeAction(value);
    form.setFieldValue("query", value === "tags" ? [] : "");
  });
  return (
    <Form.Item noStyle name="action">
      <Select<CantataTypes["MemberSearchAction"]>
        css={css`
          /* prevent the select from shrinking */
          flex-shrink: 0;
        `}
        onChange={handleSelectChange}
        popupMatchSelectWidth={false}
        options={[
          {
            label: <Trans i18nKey="chat.searchByMassage" />,
            value: "messages",
          },
          { label: <Trans i18nKey="chat.searchByName" />, value: "names" },
          { label: <Trans i18nKey="chat.searchByEmail" />, value: "emails" },
          { label: <Trans i18nKey="chat.searchByMobile" />, value: "mobiles" },
          { label: <Trans i18nKey="chat.searchByTag" />, value: "tags" },
          { label: <Trans i18nKey="chat.searchByNotes" />, value: "notes" },
          {
            label: (
              /**
               * Display Line uid for now
               *
               * Slack: [#product-caac](https://chatbotgang.slack.com/archives/C02R6ETJMEY/p1705471882772009?thread_ts=1704867795.211299&cid=C02R6ETJMEY)
               */
              <Trans i18nKey="chat.searchByLineUid" />
            ),
            value: "external-member-id",
          },
        ]}
      />
    </Form.Item>
  );
});

type OptionType = {
  key: number;
  value: number;
  label: string;
};

type TagSelectProps = Select.Props;

const TagSelect = memo(function TagSelect(props: TagSelectProps) {
  const { t } = useTranslation();
  const orgId = useActiveOrgIdStore((state) => state.value);
  const query = cantata.tag.useList({
    params: {
      orgId,
    },
  });
  const options = useMemo<Select.Props<Array<number>, OptionType>["options"]>(
    () =>
      (query.data?.tags || []).map((tag) => ({
        key: tag.id,
        value: tag.id,
        label: tag.name,
      })),
    [query.data],
  );

  if (query.isLoading) return <Select loading disabled />;

  if (query.isError)
    return <Alert type="error" message={query.error.message} />;

  return (
    <Select<number>
      allowClear
      showSearch
      optionFilterProp="label"
      notFoundContent={<NotFoundContent message={t("common.noResults")} />}
      suffixIcon={
        isEmpty(props.value) ? (
          <SearchOutlined style={{ color: theme.colors.neutral009 }} />
        ) : (
          <CloseCircleFilled />
        )
      }
      placeholder={<Trans i18nKey="chat.searchPlaceholder" />}
      options={options}
      {...props}
    />
  );
});

const SearchQuery = memo(function SearchQuery() {
  const { t } = useTranslation();
  const formInstance = Form.useFormInstance();
  const { isComposition, props: inputCompositionProps } = useInputComposition<
    HTMLInputElement,
    InputProps
  >({
    props: {
      onKeyDown(e) {
        if (isComposition) return;

        if (checkKey(e, "Enter")) {
          e.preventDefault();
          formInstance.submit();
        }
      },
    },
  });

  const handleTagChanged = useHandler<Select.Props["onChange"]>(
    function handleChanged(value) {
      formInstance.setFieldValue(
        "query",
        typeof value === "number" ? [value] : [],
      );
    },
  );

  const handleDropdownVisibleChange = useHandler<
    Select.Props["onDropdownVisibleChange"]
  >(function handleDropdownVisibleChange(visible) {
    if (!visible) formInstance.submit();
  });

  const handleInputChange = useHandler<InputProps["onChange"]>(
    function handleInputChange(e) {
      if (!e.target.value) {
        searchController.clearQuery();
      }
    },
  );

  return (
    <Form.Item noStyle dependencies={["action"]}>
      {(form) =>
        form.getFieldValue("action") === "tags" ? (
          <Form.Item noStyle name="query">
            {/**
             * Search for a single tag for now.
             */}
            <TagSelect
              style={{ width: "100%" }}
              onClear={searchController.clearQuery}
              onChange={handleTagChanged}
              onDropdownVisibleChange={handleDropdownVisibleChange}
            />
          </Form.Item>
        ) : (
          <Form.Item noStyle name="query">
            <Input
              allowClear
              placeholder={t("chat.searchPlaceholder")}
              onChange={handleInputChange}
              suffix={
                <NarrowIconButton
                  css={css`
                    color: ${theme.colors.neutral009};
                  `}
                  size="small"
                  iconSize="small"
                  icon={<SearchOutlined />}
                  onClick={form.submit}
                />
              }
              {...inputCompositionProps}
            />
          </Form.Item>
        )
      }
    </Form.Item>
  );
});

const SyncDataFromDirectChat = (function getSyncDataFromDirectChat() {
  const actionSchema = z.enum(["external-member-id"]);
  const SyncDataFromDirectChat = memo(function SyncDataFromDirectChat() {
    const form = Form.useFormInstance<FormValues>();
    const searchParamFromDirectChatStore = useSearchParamFromDirectChatStore();
    useEffect(() => {
      if (!searchParamFromDirectChatStore.search) return;
      const rawAction = searchParamFromDirectChatStore.search.category;
      /**
       * The parameter from search is in camelCase, but the action in the store is
       * in kebab-case.
       */
      const kebabCasedAction = kebabCase(rawAction);
      const parsedActionResult = actionSchema.safeParse(kebabCasedAction);
      if (parsedActionResult.success) {
        form.setFieldsValue({
          action: parsedActionResult.data,
          query: searchParamFromDirectChatStore.search.query,
        });
      }
    }, [form, searchParamFromDirectChatStore.search]);
    return null;
  });
  return SyncDataFromDirectChat;
})();

const searchPanelStyles = defineStyles({
  root: css({
    display: "flex",
    alignItems: "center",
    gap: 6,
    padding: "24px 16px 4px",
  }),
  form: css({
    display: "flex",
    overflow: "hidden",
    width: "100%",
    flex: "1",
  }),
});

const SearchPanel = memo(function SearchPanel() {
  const membersController = useMembersController();
  const selectionController = useSelectionController();
  const [form] = Form.useForm<FormValues>();
  const handleSubmit = useHandler<FormProps<FormValues>["onFinish"]>(
    async function handleSubmit(values) {
      if (values.query.length === 0) return;
      searchController.clearSearch();
      membersController.reset();
      selectionController.reset();
      await delay(1);
      searchController.setState({
        search: values,
      });
    },
  );

  const gteMdLayout = Layout.breakpointHooks.useGteMd();
  const sideMenuPanelOpened = Layout.sideMenu.panel.useOpened();
  const showSideMenuPanelOpenButton = gteMdLayout && !sideMenuPanelOpened;

  return (
    <div
      css={searchPanelStyles.root}
      style={{
        paddingLeft: showSideMenuPanelOpenButton ? 0 : undefined,
        paddingTop: !gteMdLayout ? 4 : undefined,
      }}
    >
      {!showSideMenuPanelOpenButton ? null : (
        <SideMenu.Panel.OpenButton onClick={Layout.sideMenu.panel.open} />
      )}
      <Form<FormValues>
        css={searchPanelStyles.form}
        form={form}
        initialValues={pick(searchController.getState().search, [
          "action",
          "query",
        ])}
        onFinish={handleSubmit}
        routerPromptOptions={{
          disabled: true,
        }}
      >
        <SyncDataFromDirectChat />
        <Space.Compact block>
          <SearchAction />
          <SearchQuery />
        </Space.Compact>
      </Form>
    </div>
  );
});

export { SearchPanel };
