//#endregion
import { fc } from "@chatbotgang/etude/react/fc";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import useEnhancedEffect from "@mui/utils/useEnhancedEffect";
import { theme } from "@zeffiroso/theme";
import { memo } from "@zeffiroso/utils/react/memo";
import { usePortal } from "@zeffiroso/utils/react-lib/usePortal";
import { first } from "lodash-es";
import { type FC, useCallback, useMemo, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import type { ValueOf } from "type-fest";
import { z } from "zod";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { Alert } from "@/components/Alert";
import type { ButtonProps } from "@/components/Button";
import { Button } from "@/components/Button";
import { Empty } from "@/components/Empty";
import { BarLoading } from "@/components/Loading/BarLoading";
import { ChannelSelect } from "@/components/Select/ChannelSelect";
import { MainLayout } from "@/layout/MainLayout";
import { useSelectSortQuickTemplates } from "@/resources/quickTemplate/selectSortQuickTemplates";
import { SelectedChannelContext } from "@/routes/QuickTemplate/SelectedChannelContext";
import { CreateOrEditTemplate } from "@/routes/QuickTemplate/ui/CreateOrEditTemplate";
import { InitialValuesProvider } from "@/routes/QuickTemplate/ui/InitialValuesProvider";
import { SideFoldPanel } from "@/routes/QuickTemplate/ui/SideFoldPanel";
import { UnSaveModal } from "@/routes/QuickTemplate/ui/UnSaveModal";
import { cssFlexInheritAndFill } from "@/shared/emotion";
import { useLocaleCompare } from "@/shared/hooks/useLocaleCompare";

//#region styles
const ItemContainer = styled.div`
  height: 40px;
  box-sizing: border-box;
  padding: 0 8px;
`;

const HorizontalContainer = styled.div`
  ${cssFlexInheritAndFill}
  flex: 1;
  overflow-y: auto;
`;

const QuickTemplateContainer = styled.div`
  display: grid;
  width: 100%;
  min-width: 1157px;
  height: 100%;
  gap: 20px;
  grid-template: auto / 232fr 905fr;
`;

const CreateButton = styled(Button)`
  display: block;
  margin: 28px auto 0;
`;

const cssPaper = css`
  border: 1px solid ${theme.colors.neutral003};
  border-radius: 4px;
  background: ${theme.colors.white};
`;

const cssSideBarTile = css`
  ${cssPaper}
  ${cssFlexInheritAndFill}
  overflow: auto;
  flex-direction: column;
  padding: 8px;
`;

const cssEditorTile = css`
  ${cssPaper}
  ${cssFlexInheritAndFill}
  overflow: auto;

  & > * {
    ${cssFlexInheritAndFill}
    height: fit-content;
    padding: 32px;
  }
`;

const cssEditorTileCentered = css`
  align-items: center;
  justify-content: center;
`;

const QueryQuickTemplates = fc<{
  children: (
    data: [
      CantataTypes["QuickTemplate"],
      ...Array<CantataTypes["QuickTemplate"]>,
    ],
  ) => React.ReactNode;
}>(function ({ children }) {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const channel = SelectedChannelContext.useContext();
  const channelId = channel.id;
  const selectSortQuickTemplates = useSelectSortQuickTemplates();
  const query = cantata.quickTemplate.useList(
    {
      params: {
        orgId,
        channelId,
      },
    },
    {
      select: selectSortQuickTemplates,
    },
  );

  if (query.isLoading) return <BarLoading />;

  if (query.isError)
    return <Alert type="error" message={query.error.message} />;

  return children(query.data.quickTemplates);
});

const CategoryHasNoTemplate = ({
  onCreateClick,
}: {
  onCreateClick: ButtonProps["onClick"];
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <Empty>{t("common.noData")}</Empty>
      <CreateButton type="primary" onClick={onCreateClick}>
        {t("quickTemplate.createTemplate")}
      </CreateButton>
    </div>
  );
};

const CategoryHasTemplate = () => {
  const { t } = useTranslation();

  return <Empty>{t("quickTemplate.blankPageMessage")}</Empty>;
};

const ModeSchema = z.enum([
  "NoTemplate",
  "HasTemplate",
  "CreateTemplate",
  "EditTemplate",
]);
type Mode = z.infer<typeof ModeSchema>;
const ModeRecord = Object.fromEntries(
  ModeSchema.options.map((option) => [option, option]),
) as { [key in Mode]: Mode };

(() => {
  // Make sure all enum values are used in the record.
  const anyObj: any = new Object();
  type Result = ValueOf<typeof ModeRecord>;
  type Expected = Mode;
  anyObj as Result satisfies Expected;
  anyObj as Expected satisfies Result;
})();

const QuickTemplate = memo(function QuickTemplate() {
  const { t } = useTranslation();
  const orgId = useActiveOrgIdStore((state) => state.value);

  const [unSaveModalOpen, setUnSaveModalOpen] = useState(false);

  const [activeCategoryId, setActiveCategoryId] = useState<number | null>(null);
  const [activeTemplateId, setActiveTemplateId] = useState<number | null>(null);
  const nextEditTemplate = useRef<{
    categoryId: number | null;
    templateId: number;
  } | null>(null);
  const [mode, setMode] = useState<Mode>(ModeRecord.NoTemplate);

  const channelsQuery = cantata.channel.useList({
    params: {
      orgId,
    },
  });
  const localeCompare = useLocaleCompare();
  const sortChannelCompareFn = useCallback(
    function sortChannelCompareFn(
      a: CantataTypes["Channel"],
      b: CantataTypes["Channel"],
    ) {
      return localeCompare(a.name, b.name);
    },
    [localeCompare],
  );
  const channels = useMemo(
    () => channelsQuery.data?.channels.toSorted(sortChannelCompareFn) ?? [],
    [channelsQuery.data?.channels, sortChannelCompareFn],
  );
  const [channelId, setChannelId] = useState(Number.NaN);
  const channel = useMemo(
    () => channels.find((c) => c.id === channelId),
    [channelId, channels],
  );

  const handleCreateTemplateClick = useHandler<ButtonProps["onClick"]>(
    function handleCreateTemplateClick(event) {
      event.stopPropagation();
      setMode(ModeRecord.CreateTemplate);
      setActiveTemplateId(null);
    },
  );

  const handleCategoryClick = useHandler(function handleCategoryClick(
    categoryId: number | null,
    isEmptyCategory: boolean,
  ) {
    setMode(isEmptyCategory ? ModeRecord.NoTemplate : ModeRecord.HasTemplate);
    setActiveCategoryId(categoryId);
    setActiveTemplateId(null);
  });

  const editSelectedTemplate = useHandler(function editSelectedTemplate(
    categoryId: number | null,
    templateId: number,
  ) {
    setMode(ModeRecord.EditTemplate);
    setActiveCategoryId(categoryId);
    setActiveTemplateId(templateId);
  });

  const beforeCallEditTemplate = useHandler(function beforeCallEditTemplate(
    categoryId: number | null,
    templateId: number,
  ) {
    // if (activeTemplateId || mode === Mode.CreateTemplate) {
    //   setUnSaveModalOpen(true);
    //   nextEditTemplate.current = {
    //     categoryId,
    //     templateId,
    //   };
    //   return;
    // }

    editSelectedTemplate(categoryId, templateId);
  });

  const clearDirtyFormValue = useHandler(function clearDirtyFormValue() {
    if (nextEditTemplate.current) {
      editSelectedTemplate(
        nextEditTemplate.current.categoryId,
        nextEditTemplate.current?.templateId,
      );
    }

    setUnSaveModalOpen(false);
    nextEditTemplate.current = null;
  });

  useEnhancedEffect(() => {
    // TODO: fix this which is making side effect
    // update channels after switch organization
    const c = channels.find((c) => c.id === channelId);
    if (c !== undefined) return;

    setChannelId(first(channels)?.id ?? Number.NaN);
  }, [channelId, channels]);

  const handleClearTemplate = useHandler(function handleClearTemplate() {
    setMode(ModeRecord.HasTemplate);
    setActiveCategoryId(null);
    setActiveTemplateId(null);
  });

  useEnhancedEffect(() => {
    if (!channelId) return;
    return handleClearTemplate;
  }, [channelId, handleClearTemplate]);

  return (
    <>
      <MainLayout.Header>{t("menu.templates")}</MainLayout.Header>
      <MainLayout.Body css={cssFlexInheritAndFill}>
        <MainLayout.Content css={cssFlexInheritAndFill}>
          <HorizontalContainer>
            {channel && (
              <SelectedChannelContext.Provider value={channel}>
                <QueryQuickTemplates>
                  {(quickTemplates) => (
                    <QuickTemplateContainer>
                      <div css={cssSideBarTile}>
                        <ItemContainer>
                          <ChannelSelect
                            channels={channels}
                            value={channel.id}
                            onChange={setChannelId}
                            hideBorder
                          />
                        </ItemContainer>

                        <SideFoldPanel
                          quickTemplates={quickTemplates}
                          activeCategoryId={activeCategoryId}
                          activeTemplateId={activeTemplateId}
                          onCreateClick={handleCreateTemplateClick}
                          onCategoryClick={handleCategoryClick}
                          onTemplateClick={beforeCallEditTemplate}
                          onDeleteTemplate={handleClearTemplate}
                        />
                      </div>
                      <div
                        css={css`
                          ${cssEditorTile}
                          ${![
                            ModeRecord.NoTemplate,
                            ModeRecord.HasTemplate,
                          ].includes(mode)
                            ? null
                            : cssEditorTileCentered}
                        `}
                      >
                        <div>
                          {mode === ModeRecord.NoTemplate && (
                            <CategoryHasNoTemplate
                              onCreateClick={handleCreateTemplateClick}
                            />
                          )}
                          {mode === ModeRecord.HasTemplate && (
                            <CategoryHasTemplate />
                          )}
                          {mode === ModeRecord.CreateTemplate && (
                            <InitialValuesProvider
                              channelId={channel.id}
                              categoryId={activeCategoryId}
                              quickTemplateId={Number.NaN}
                            >
                              <CreateOrEditTemplate
                                channelId={channel.id}
                                channelType={channel.type}
                                quickTemplates={quickTemplates}
                                onCreateSuccess={editSelectedTemplate}
                              />
                            </InitialValuesProvider>
                          )}
                          {mode === ModeRecord.EditTemplate &&
                            activeTemplateId && (
                              <InitialValuesProvider
                                key={activeTemplateId}
                                channelId={channel.id}
                                categoryId={activeCategoryId}
                                quickTemplateId={activeTemplateId}
                              >
                                <CreateOrEditTemplate
                                  key={activeTemplateId}
                                  channelId={channel.id}
                                  channelType={channel.type}
                                  templateId={activeTemplateId}
                                  quickTemplates={quickTemplates}
                                  onDeleteSuccess={() =>
                                    setMode(ModeRecord.NoTemplate)
                                  }
                                />
                              </InitialValuesProvider>
                            )}
                        </div>
                      </div>
                    </QuickTemplateContainer>
                  )}
                </QueryQuickTemplates>
              </SelectedChannelContext.Provider>
            )}
          </HorizontalContainer>
          <UnSaveModal
            open={unSaveModalOpen}
            onLeave={clearDirtyFormValue}
            onKeepEdit={() => setUnSaveModalOpen(false)}
          />
        </MainLayout.Content>
      </MainLayout.Body>
    </>
  );
});

const WrappedQuickTemplate: FC = () => {
  const portal = usePortal();
  return (
    <>
      <MainLayout.Outer mainRef={portal.setOuterEl} />
      {createPortal(<QuickTemplate />, portal.innerEl)}
    </>
  );
};

export { WrappedQuickTemplate as QuickTemplate };
