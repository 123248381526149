import { css } from "@emotion/react";
import { flutterWebViewAppSdk } from "@zeffiroso/flutter-webview-app-sdk/flutterWebViewAppSdk";
import { useMemo } from "react";

import { CopyButton } from "@/components/Button/CopyButton";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  container: css({
    position: "relative",
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
  }),
  pre: css({
    fontSize: "0.75rem",
  }),
  copyButton: css({
    background: "white",
    boxShadow: "0 0 4px rgba(0, 0, 0, 0.1)",
    fontSize: "0.75rem",
    height: "1rem",
    width: "1rem",
    borderRadius: "50%",
    marginLeft: "auto",
  }),
});

const FlutterSdkLogs: React.FC = () => {
  const { logs } = flutterWebViewAppSdk.useFlutterDebugLogsStore();
  const text = useMemo(() => logs.toReversed().join("\n"), [logs]);
  return (
    <div css={styles.container}>
      <CopyButton text={text} css={styles.copyButton} />
      <pre css={styles.pre}>
        <code>
          {logs.length === 0 && <i>No logs</i>}
          <ol>
            {logs.map((log, i) => (
              <li key={i}>{log}</li>
            ))}
          </ol>
        </code>
      </pre>
    </div>
  );
};

export { FlutterSdkLogs };
