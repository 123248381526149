import styled from "@emotion/styled";
import { theme } from "@zeffiroso/theme";
import type { ReactNode } from "react";

import {
  PREVIEW_HEIGHT,
  PREVIEW_WIDTH,
} from "@/components/MessagePreview/constants";

const PreviewWrapperAniWrapper = styled.div`
  position: sticky;
  top: 0;
  display: inline-block;
  width: ${PREVIEW_WIDTH};
  height: ${PREVIEW_HEIGHT};
  vertical-align: top;
`;

const PreviewWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 72px 20px;
  border: 2px solid ${theme.colors.neutral003};
  border-radius: 50px;
  background-color: ${theme.colors.white};

  &::before {
    position: absolute;
    top: 35px;
    left: 50%;
    width: 71px;
    height: 6px;
    border-radius: 6.5px;
    background-color: ${theme.colors.neutral002};
    content: "";
    transform: translateX(-50%);
  }

  &::after {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: 6px solid ${theme.colors.neutral001};
    border-radius: 48px;
    content: "";
  }
`;

const ScreenBody = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  overflow: auto;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  flex-direction: column;
  padding: 16px;
  border-radius: ${theme.shape.borderRadius};
  background-color: ${theme.colors.neutral002};
`;

const HomeButton = styled.div`
  position: absolute;
  bottom: 12px;
  left: 50%;
  width: 50px;
  height: 50px;
  box-sizing: border-box;
  border: 7px solid ${theme.colors.neutral001};
  border-radius: 50%;
  transform: translateX(-50%);
`;

const MessagePreview = ({
  children,
  homeButton,
}: {
  children?: ReactNode;
  homeButton?: true;
}): React.ReactNode => {
  return (
    <PreviewWrapperAniWrapper data-test="preview-wrapper">
      <PreviewWrapper>
        <ScreenBody>{children}</ScreenBody>
        {homeButton && <HomeButton />}
      </PreviewWrapper>
    </PreviewWrapperAniWrapper>
  );
};

export { MessagePreview };
