import { create } from "zustand";

import { isSameEmail } from "@/app/auth/isSameEmail";
import { useLastUserEmailStore } from "@/shared/utils/createZustandStorageStore";

const useIsDifferentAccountSignedInStore = create<boolean>()(() => false);

/**
 * If the session is not fully signed out and the email changed, that means the
 * user signed in with a different account in another tab.
 */
useLastUserEmailStore.subscribe(function reloadIfDifferentUserSignedIn(
  { value: current },
  { value: prev },
) {
  if (isSameEmail(current, prev)) return;
  useIsDifferentAccountSignedInStore.setState(true);
  window.location.reload();
});

export { useIsDifferentAccountSignedInStore };
