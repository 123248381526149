import { z } from "zod";

const falsyStrings = ["false", "0", ""] as const;
const truthyStrings = ["true", "1"] as const;

namespace StringToBooleanSchema {
  export type FalsyString = (typeof falsyStrings)[number];
  export type TruthyString = (typeof truthyStrings)[number];
}

const StringToBooleanSchemaInternal = z
  .enum([...truthyStrings, ...falsyStrings])
  .transform((val) => {
    return !(
      falsyStrings as ReadonlyArray<
        StringToBooleanSchema.FalsyString | StringToBooleanSchema.TruthyString
      >
    ).includes(val);
  });

/**
 * Only `false` and `0` and zero-length string are falsy.
 */
const StringToBooleanSchema = Object.assign(StringToBooleanSchemaInternal, {
  falsyStrings,
  truthyStrings,
});

export { StringToBooleanSchema };
