import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { memo } from "@zeffiroso/utils/react/memo";
import type { ReactNode } from "react";
import { useMemo } from "react";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { Avatar } from "@/components/Avatar";
import { FormItem } from "@/components/Form";
import { ItemWithIcon } from "@/components/Menu/ItemWithIcon";
import { Select } from "@/components/Select";
import { availableUserFilter } from "@/resources/user/availableUserFilter";
import { UserName } from "@/resources/user/UserName";
import type { ArrayPredicate } from "@/shared/types/ArrayPredicate";

type OptionType = {
  key: number;
  value: number;
  label: ReactNode;
  text: string;
};

export type MultipleUserSelectorProps = Omit<
  Select.Props<number[], OptionType>,
  "options" | "mode"
> & {
  /**
   * filter function to filter users. Default is to filter active users only.
   */
  filter?: ArrayPredicate<CantataTypes["User"]>;
};

/**
 * User selector
 *
 * You can customize the selector with all props of Select component other than `options` and `mode`.
 *
 * Note: a value of `[]` means no user selected.
 */
export const MultipleUserSelector = memo<MultipleUserSelectorProps>(
  function MultipleUserSelector({ filter = availableUserFilter, ...props }) {
    const orgId = useActiveOrgIdStore((state) => state.value);
    const query = cantata.user.useList({
      params: {
        orgId,
      },
    });
    const options = useMemo<
      Select.Props<number[], OptionType>["options"]
    >(() => {
      if (!query.isSuccess) return [];

      return query.data.users.filter(filter).map((user) => ({
        key: user.id,
        value: user.id,
        text: user.name,
        label: (
          <ItemWithIcon
            data-test={`user-option-${user.id}`}
            gap={8}
            startIcon={<Avatar src={user.avatar ?? ""} size={16} />}
          >
            <UserName user={user} />
          </ItemWithIcon>
        ),
      }));
    }, [filter, query.data, query.isSuccess]);
    if (query.isLoading) return <Select {...props} loading disabled />;

    if (query.isError) {
      return (
        <FormItem
          help={inspectMessage`query error: ${query.error}`}
          validateStatus="error"
        >
          <Select {...props} loading disabled />
        </FormItem>
      );
    }
    return (
      <Select<number[], OptionType>
        mode="multiple"
        showSearch
        {...props}
        optionFilterProp="text"
        options={options}
      />
    );
  },
);
