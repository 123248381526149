import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";
import { Button } from "@/components/Button";
import { Modal } from "@/components/Modal";

const DeleteCategoryModal = ({
  categoryId,
  categoryName,
  channelId,
  visible,
  onClose,
}: {
  channelId: number;
  categoryId: number;
  categoryName: string;
  visible: boolean;
  onClose: () => void;
}): React.ReactNode => {
  const { t } = useTranslation();
  const orgId = useActiveOrgIdStore((state) => state.value);

  const deleteCategoryMutation = cantata.quickTemplate.useDeleteCategory(
    {
      params: {
        orgId,
        channelId,
        quickTemplateCategoryId: categoryId,
      },
    },
    {
      onSuccess: () => {
        onClose();
      },
    },
  );

  const handleSubmit = () => {
    deleteCategoryMutation.mutate(undefined);
  };

  return (
    <Modal
      title={t("quickTemplate.deleteCategory.title", { name: categoryName })}
      open={visible}
      onCancel={onClose}
      footer={[
        <Button key="cancel" type="default" onClick={onClose}>
          {t("common.cancel")}
        </Button>,
        <Button
          key="submit"
          danger={true}
          type="primary"
          onClick={handleSubmit}
          loading={deleteCategoryMutation.isLoading}
        >
          {t("common.delete")}
        </Button>,
      ]}
    >
      {t("quickTemplate.deleteCategory.desc")}
    </Modal>
  );
};

export { DeleteCategoryModal };
