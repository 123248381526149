import { makeApi } from "@zodios/core";

import { MemberSchema, OrgSchema, UnificationDeepLinkSchema } from "../models";

const api = makeApi([
  {
    /**
     * Get unification deep links for a user.
     * Note: While the URL path contains "unification-qrcodes", the alias is named "listUserUnificationDeepLinks"
     * because the response contains deep links for redirection to the app, not actual QR code images.
     * [Notion](https://www.notion.so/cresclab/Redirect-unification-19f8ce15893880eb8c7afa4a442c6e93?pvs=4#1ae8ce1589388000a6d9dc74eb6fb600)
     */
    alias: "listUserUnificationDeepLinks",
    method: "get",
    path: "/api/v1/orgs/:orgId/organization/members/:memberId/unification-qrcodes",
    parameters: [
      {
        type: "Path",
        name: "orgId",
        schema: OrgSchema.shape.id,
      },
      {
        type: "Path",
        name: "memberId",
        schema: MemberSchema.shape.id,
      },
    ],
    response: UnificationDeepLinkSchema,
  },
]);

export { api };
