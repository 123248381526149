import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { delay } from "@chatbotgang/etude/timer/delay";
import { css } from "@emotion/react";
import { useMutation } from "@tanstack/react-query";
import { theme } from "@zeffiroso/theme";
import { shallow } from "@zeffiroso/utils/zustand/shallow";
import { Typography } from "antd";
import { hoursToMilliseconds, secondsToMilliseconds } from "date-fns";
import { noop } from "lodash-es";
import { type ComponentProps, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { create } from "zustand";
import { createWithEqualityFn } from "zustand/traditional";

import { Flex } from "@/components/Box";
import { Button } from "@/components/Button";
import { createEasyForm } from "@/components/Form/createEasyForm";
import { updateSw, useSwStatus } from "@/internal/sw";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  prompt: css({
    position: "fixed",
    zIndex: theme.zIndices.ultimate,
    right: "40px",
    bottom: "18px",
    width: "296px",
    maxWidth: "100%",
    boxSizing: "border-box",
    flexDirection: "column",
    padding: "1em 0.5em 0.5em 1.5em",
    borderRadius: "4px",
    background: theme.colors.white,
    boxShadow: "4px 4px 16px rgb(0 0 0 / 10%)",
  }),
  promptActions: css({
    display: "flex",
    justifyContent: "flex-end",
    gap: "1em",
    marginTop: "1em",
  }),
});

const EasyForm = createEasyForm();

const initialValues = {};

const Prompt = () => {
  const { t } = useTranslation();
  const [_, form] = EasyForm.useForm();
  const mutation = useMutation({
    mutationFn: async () => {
      await updateSw();
      await delay(secondsToMilliseconds(10));
      // Force reload the page if the update is not triggered.
      window.location.reload();
      // Infinite loading.
      await new Promise(noop);
    },
  });

  const onFinish = useHandler<ComponentProps<typeof EasyForm>["onFinish"]>(
    () => {
      mutation.mutate(undefined);
    },
  );
  return (
    <EasyForm
      form={form}
      initialValues={initialValues}
      disabled={mutation.isLoading}
      onFinish={onFinish}
    >
      <Flex css={styles.prompt}>
        <h2>{t("checkUpdate.prompt.title")}</h2>
        <Typography
          css={css`
            margin-top: 1em;
          `}
        >
          {t("checkUpdate.prompt.body")}
        </Typography>
        <Flex css={styles.promptActions}>
          <Button onClick={closeUpdateDialog}>
            {t("checkUpdate.prompt.cancel")}
          </Button>
          <Button type="primary" htmlType="submit" loading={mutation.isLoading}>
            {t("checkUpdate.prompt.confirm")}
          </Button>
        </Flex>
      </Flex>
    </EasyForm>
  );
};

const useShowUpdateDialogStore = create<boolean>()(
  () => useSwStatus.getState().needRefresh,
);
function closeUpdateDialog() {
  useShowUpdateDialogStore.setState(false);
}
const useLastIgnoreTimeStore = createWithEqualityFn<{
  lastIgnoreTime: Date;
}>()(
  () => ({
    lastIgnoreTime: new Date(Number.NaN),
  }),
  shallow,
);

// Once the `needRefresh` turns to true, show the update dialog.
const unsubscribeListenOnNeedRefresh = useSwStatus.subscribe(
  (current, previous) => {
    if (current.needRefresh && !previous.needRefresh) {
      useShowUpdateDialogStore.setState(true);
      unsubscribeListenOnNeedRefresh();
    }
  },
);

// The showUpdateDialog state will be false when the prompt is ignored.
useShowUpdateDialogStore.subscribe((showUpdateDialog) => {
  if (showUpdateDialog) return;
  useLastIgnoreTimeStore.setState({
    lastIgnoreTime: new Date(),
  });
});

const reOpenPromptDelayMs = hoursToMilliseconds(2);

function useReopenPrompt() {
  const needRefresh: boolean = useSwStatus(
    useMemo(
      () =>
        ((state) => state.needRefresh) satisfies Parameters<
          typeof useSwStatus
        >[0],
      [],
    ),
  );
  const showUpdateDialog = useShowUpdateDialogStore();
  useEffect(() => {
    if (!(needRefresh && !showUpdateDialog)) return;
    const timeout = setTimeout(() => {
      useShowUpdateDialogStore.setState(true);
    }, reOpenPromptDelayMs);
    return () => {
      clearTimeout(timeout);
    };
  }, [needRefresh, showUpdateDialog]);
  return null;
}

const PwaUpdatePrompt = () => {
  const showUpdateDialog = useShowUpdateDialogStore();
  useReopenPrompt();
  return !showUpdateDialog ? null : <Prompt />;
};

export { PwaUpdatePrompt };
