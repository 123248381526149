import { memo } from "@zeffiroso/utils/react/memo";
import { useTranslation } from "react-i18next";

import { MainLayout } from "@/layout/MainLayout";
import { SideMenuLayout } from "@/layout/SideMenuLayout";
import { useFeatureControl } from "@/resources/organization/featureControl";
import { InvitationSetting } from "@/routes/Settings/ChatSettings/Invitation";
import { UserRecognition } from "@/routes/Settings/ChatSettings/UserRecognition";

const ChatSettings = memo(function ChatSettings() {
  const { t } = useTranslation();
  const enabledRedirectUnification = useFeatureControl(
    "redirectUnificationAvailability",
  );
  return (
    <SideMenuLayout.Layout>
      <MainLayout.Header>
        {t("organization.chatSettings.title")}
      </MainLayout.Header>
      <MainLayout.Body>
        <MainLayout.Content>
          {!enabledRedirectUnification ? null : (
            <MainLayout.Section>
              <InvitationSetting />
            </MainLayout.Section>
          )}
          <MainLayout.Section>
            <UserRecognition />
          </MainLayout.Section>
        </MainLayout.Content>
      </MainLayout.Body>
    </SideMenuLayout.Layout>
  );
});

export { ChatSettings };
