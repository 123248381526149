import { crescendoLabBirthDay } from "@chatbotgang/etude/dummy";
import { secondsToMilliseconds } from "date-fns";

import {
  defineConfig,
  defineTestAccount,
} from "@/internal/featureFlag/defineConfig";
import { availableLocales } from "@/shared/g11n/config";

const configUtils = (function iife() {
  const enabled = {
    autoEnableAt: crescendoLabBirthDay,
  } as const;

  const disabled = {
    autoEnableAt: undefined,
  } as const;

  const configUtils = {
    enabled,
    disabled,
  };

  return configUtils;
})();

interface LanguageOptionBase {
  label: string;
  value: string;
}

const languageOptions: [LanguageOptionBase, ...Array<LanguageOptionBase>] = [
  ...availableLocales.map<LanguageOptionBase>(({ code, displayName }) => ({
    label: displayName,
    value: code,
  })),
  /**
   * "cimode" makes `t` return keys instead of values, which is useful for testing.
   */
  {
    label: "Keys",
    value: "cimode",
  },
] satisfies [
  ...Array<LanguageOptionBase>,
  LanguageOptionBase,
] as Array<LanguageOptionBase> as [
  LanguageOptionBase,
  ...Array<LanguageOptionBase>,
];

const debugFeatureFlags = defineConfig({
  debugDivider: {
    type: "divider",
    label: "Debug Tools",
  },
  keepFeatureFlags: {
    type: "toggle",
    label: "Don't clear feature flags when last email changed",
  },
  alwaysHideDevModeFab: {
    type: "toggle",
    label: "Always hide Dev Mode FAB",
  },
  quickI18n: {
    label: "Quick switch language",
    type: "singleSelect",
    options: languageOptions,
  },
  overrideConversationHistoryAction: {
    label: "Chat: Override conversation history action",
    type: "singleSelect",
    options: [
      {
        value: "jumpTo",
        label: "Go to chat",
      },
      {
        value: "viewHistory",
        label: "View history",
      },
    ],
  },
  reactQueryDevtool: {
    label: "Enable react-query devtool",
    type: "singleSelect",
    options: [
      {
        value: "bottom-right",
        label: "Bottom right",
      },
      {
        value: "top-left",
        label: "Top left",
      },
      {
        value: "top-right",
        label: "Top right",
      },
      {
        value: "bottom-left",
        label: "Bottom left",
      },
    ],
  },
  periodicServiceWorkerUpdates: {
    type: "singleSelect",
    label: "Periodic Service Worker Updates",
    options: [
      {
        value: secondsToMilliseconds(10).toString(),
        label: "10 seconds",
      },
      {
        value: secondsToMilliseconds(30).toString(),
        label: "30 seconds",
      },
    ],
  },
  renderMemberId: {
    type: "toggle",
    label: "Render member ID in the profile panel",
  },
  renderRawEcValue: {
    type: "toggle",
    label: "Render raw value from EC platform in the profile panel",
  },
  siteSelector: {
    type: "toggle",
    label: "Login: Site selector",
  },
  markAsRead: {
    type: "singleSelect",
    label: "Chat: Mark as read",
    options: [
      {
        value: "always",
      },
      {
        value: "never",
      },
    ],
  },
  flutterWebviewAppDownload: {
    type: "singleSelect",
    label: "Enforce Flutter WebView App base64 download",
    options: [
      {
        value: "always",
      },
      {
        value: "never",
      },
    ],
  },
  flutterWebviewAppOpenBrowser: {
    type: "singleSelect",
    label: "Enforce Flutter WebView App external link",
    options: [
      {
        value: "always",
      },
      {
        value: "never",
      },
    ],
  },
});

const nextFeatureFlags = defineConfig({
  NextDivider: {
    type: "divider",
    label: "Next Features",
  },
});

/**
 * Please do not remove these feature flags; they are used for disabling
 * features temporarily.
 */
const releasedFeatureFlags = defineConfig({
  releasedFeatures: {
    type: "divider",
    label: "Released Features",
  },
  aiQuickTemplate: {
    type: "toggle",
    label: "Chat: AI quick template",
    ...configUtils.enabled,
  },
});

/**
 * Feature flags that are still in development. They can be temporarily enabled
 * for specific purposes.
 */
const experimentalFeatureFlags = defineConfig({
  experimentalFeatures: {
    type: "divider",
    label: "Experimental Features",
  },
  /**
   * Try `metadata.backupMediaUrl` first, then `originUrl` by default.
   *
   * If the feature flag `mediaOriginUrlOnly` is enabled, only `originUrl` will
   * be used.
   *
   * Slack:
   * [#product-caac](https://chatbotgang.slack.com/archives/C02R6ETJMEY/p1708360164026869?thread_ts=1707981664.646579&cid=C02R6ETJMEY)
   */
  originUrlOnly: {
    type: "toggle",
    label: "Chat: Use `originUrl` only, don't use `metadata.backup_media_url`",
  },
  deletedMessageFallback: {
    type: "toggle",
    label:
      "Chat: Show deleted message fallback instead of the original message",
  },
  notificationEmail: {
    type: "toggle",
    label: "Notification: email (TBD)",
  },
  aiAssistantRewrite: {
    type: "toggle",
    label: "Chat: AI assistant rewrite",
  },
});

const featureFlags = defineConfig({
  ...debugFeatureFlags,
  ...nextFeatureFlags,
  ...releasedFeatureFlags,
  ...experimentalFeatureFlags,
});

const testAccounts = defineTestAccount(featureFlags)([
  /**
   * Meta review
   */
  {
    email: "crescender+meta@cresclab.com",
    feature: ["alwaysHideDevModeFab"],
  },
]);

export { featureFlags, testAccounts };
