import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { safeFunction } from "@chatbotgang/etude/safe/safeFunction";
import { random } from "@chatbotgang/etude/string/random";
import { dateToStringDeeply } from "@chatbotgang/etude/transform/dateToStringDeeply";
import { parseJson } from "@crescendolab/parse-json";
import useChange from "@react-hook/change";
import type { LegatoEmitEventTypes } from "@zeffiroso/legato/emit";
import type {
  LegatoOnEventType,
  LegatoOnEventTypes,
} from "@zeffiroso/legato/on";
import { InvalidInputError, parseLegatoOnEvent } from "@zeffiroso/legato/on";
import { memo } from "@zeffiroso/utils/react/memo";
import { useSafeInvalidateQuery } from "@zeffiroso/zodios/useSafeInvalidateQuery";
import camelcaseKeys from "camelcase-keys";
import EventEmitter from "eventemitter3";
import objectInspect from "object-inspect";
import type { ReactNode } from "react";
import { createContext, useEffect, useMemo, useState } from "react";
import type { Options } from "react-use-websocket";
import useWebSocket, { ReadyState } from "react-use-websocket";
import snakecaseKeys from "snakecase-keys";
import type { JsonValue } from "type-fest";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { LEGATO_PING_TIMEOUT, LEGATO_PONG_TIMEOUT } from "@/appConstant";
import { cantata } from "@/cantata";
import { CAAC_WEBSOCKET_URL } from "@/env";
import { logError } from "@/shared/application/logger/sentry";
import { useOnline } from "@/shared/hooks/useOnline";
import { useTokenStore } from "@/shared/services/token";
import { isPrimitive } from "@/shared/types/isPrimitive";

const safeParseLegatoOnEvent = safeFunction(parseLegatoOnEvent);

type EventTypes = {
  [EventType in LegatoOnEventType]: (
    data: LegatoOnEventTypes[EventType],
  ) => void;
};

class LegatoError extends Error {
  override name = "LegatoError";
  constructor({
    cause,
    eventType,
    data,
  }: {
    cause: unknown;
    eventType: string;
    data: JsonValue;
  }) {
    super(
      inspectMessage`Got an error from Legato event: ${eventType} with data: ${data}`,
    );
    this.cause = cause;
  }
}

const pingMessageString = JSON.stringify({
  type: "ping",
}) satisfies Extract<Options["heartbeat"], object>["message"] &
  Extract<Options["heartbeat"], object>["returnMessage"];

/**
 * The extends web socket with defined event types.
 */
const useExtendedWebSocket = function useExtendedWebSocket(
  ...args: Parameters<typeof useWebSocket>
) {
  const [eventEmitter] = useState(() => new EventEmitter<EventTypes, never>());
  const [urlArg, optionsArg, connectArg] = args;
  const options = useMemo<Options>(
    () => ({
      ...optionsArg,
      onMessage(event: MessageEvent<unknown>) {
        if (typeof event.data !== "string") {
          throw new TypeError(
            inspectMessage`event.data is not a string, event: ${event}`,
          );
        }
        const data = parseJson(event.data, {
          fallback: null,
        });
        const camelcaseData = isPrimitive(data)
          ? data
          : camelcaseKeys(data, { deep: true });
        if (typeof optionsArg?.onMessage === "function") {
          optionsArg?.onMessage({
            ...event,
            data: camelcaseData,
          });
        }
        const {
          isError,
          data: parsedData,
          error,
        } = safeParseLegatoOnEvent(camelcaseData);
        if (isError) {
          if (error instanceof InvalidInputError) {
            // Do nothing if the input is invalid.
            return;
          }
          logError(
            new LegatoError({
              cause: error,
              eventType: objectInspect(
                data instanceof Object && "type" in data
                  ? // eslint-disable-next-line dot-notation -- data.type is not typed.
                    data["type"]
                  : undefined,
              ),
              data: event.data,
            }),
          );
          throw error;
        }

        switch (parsedData.type) {
          case "user-updated":
            eventEmitter.emit(parsedData.type, parsedData);
            break;
          case "messages":
            eventEmitter.emit(parsedData.type, parsedData);
            break;
          case "message-update":
            eventEmitter.emit(parsedData.type, parsedData);
            break;
          case "member-list-updated":
            eventEmitter.emit(parsedData.type, parsedData);
            break;
          case "merge-state-updated":
            eventEmitter.emit(parsedData.type, parsedData);
            break;
          case "group-member-list-updated":
            eventEmitter.emit(parsedData.type, parsedData);
            break;
          case "group-rejoined":
            eventEmitter.emit(parsedData.type, parsedData);
            break;
          default:
            parsedData satisfies never;
        }
      },
    }),
    [eventEmitter, optionsArg],
  );
  const webSocketHook = useWebSocket(
    urlArg,
    options,
    ...(args.length > 2 ? [connectArg] : []),
    ...args.slice(3),
  );
  /**
   * Transforms the JSON message to snake case and sends it.
   *
   * This is a low-end function that you might not want to use directly. Use
   * `send` instead.
   */
  const sendJsonMessage = useHandler(function sendJsonMessage(
    jsonMessage: JsonValue,
    keep?: boolean,
  ) {
    webSocketHook.sendJsonMessage(
      isPrimitive(jsonMessage)
        ? jsonMessage
        : snakecaseKeys(jsonMessage, {
            deep: true,
          }),
      keep,
    );
  });

  /**
   * The standard `send` method for Legato events.
   */
  const send = useHandler(function send<
    Type extends keyof LegatoEmitEventTypes,
    Data extends LegatoEmitEventTypes[Type],
  >(_type: Type, data: Data) {
    sendJsonMessage(dateToStringDeeply(data));
  });

  const extendedWebSocket = useMemo(
    function memoExtendedWebSocket() {
      return {
        ...webSocketHook,
        sendJsonMessage,
        send,
        eventEmitter,
      };
    },
    [eventEmitter, send, sendJsonMessage, webSocketHook],
  );
  return extendedWebSocket;
};

type ContextValue = ReturnType<typeof useExtendedWebSocket> & {
  connected: boolean;
  shouldConnect: boolean;
};

const Context = createContext<ContextValue | undefined>(undefined);

const LegatoProvider = memo(function LegatoProvider({
  children,
}: {
  children: ReactNode;
}) {
  const safeInvalidateQuery = useSafeInvalidateQuery();
  const orgId = useActiveOrgIdStore((state) => state.value);
  const queryMe = cantata.user.useGetMe({
    params: {
      orgId,
    },
  });
  const userToken = useTokenStore((state) => state.value);
  /**
   * Cached token prevent reconnection when token is empty.
   * `useWebSocket` generate another connection without destroy the previous one when `queryParams` changed.
   */
  const [cachedUserToken, setCachedUserToken] = useState(userToken);
  useEffect(() => {
    if (!userToken) return;
    setCachedUserToken(userToken);
  }, [userToken]);
  const online = useOnline();
  const [requestId, setRequestId] = useState(() => random());
  const freshRequestId = useHandler(() => {
    setRequestId(random());
  });

  const queryParams = useMemo<NonNullable<Options["queryParams"]>>(
    () =>
      snakecaseKeys(
        {
          orgId,
          /** Cached token prevent reconnection when token is empty. */
          token: cachedUserToken,
          requestId,
        },
        {
          deep: true,
        },
      ),
    [cachedUserToken, orgId, requestId],
  );

  const shouldConnect = useMemo<
    ReturnType<NonNullable<Options["shouldReconnect"]>>
  >(() => Boolean(userToken) && online, [online, userToken]);

  const extendsWebSocket = useExtendedWebSocket(
    CAAC_WEBSOCKET_URL,
    {
      queryParams,
      heartbeat: {
        message: pingMessageString,
        returnMessage: pingMessageString,
        timeout: LEGATO_PONG_TIMEOUT,
        interval: LEGATO_PING_TIMEOUT,
      },
      shouldReconnect: () => shouldConnect,
      reconnectAttempts: Number.POSITIVE_INFINITY,
      reconnectInterval: (attemptCount) => {
        const maxBaseCount = 5;
        const count = Math.min(attemptCount, maxBaseCount);
        return count ** 2 * 100;
      },

      /**
       * If got any problem with the websocket, we need to refetch the me query
       * to check the token.
       *
       * If the token is expired, it will be cleared from the local storage by
       * the axios object.
       */
      onError: () => {
        safeInvalidateQuery(queryMe.key);
      },
      onClose: () => {
        /**
         * Disable `refreshRequestId` for now.
         */
        const shouldRefreshRequestId: boolean = (() => false)();
        if (!shouldRefreshRequestId) return;
        freshRequestId();
      },
    },
    shouldConnect,
  );

  useChange(
    shouldConnect,
    function disconnectWhenShouldConnectChanged(current) {
      if (current) return;
      /**
       * Disconnect the websocket when the `shouldConnect` is false.
       */
      extendsWebSocket.getWebSocket()?.close();
    },
  );

  const connected = extendsWebSocket.readyState === ReadyState.OPEN;

  const contextValue = useMemo<ContextValue>(
    function memoContextValue() {
      const contextValue: ContextValue = {
        ...extendsWebSocket,
        connected,
        shouldConnect,
      };
      return contextValue;
    },
    [connected, extendsWebSocket, shouldConnect],
  );

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
});

export { Context, LegatoProvider };
export type { ContextValue, LegatoOnEventTypes };
