import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { safePromise } from "@chatbotgang/etude/safe/safePromise";
import { delay } from "@chatbotgang/etude/timer/delay";
import type { UseMutationOptions } from "@tanstack/react-query";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSafeInvalidateQuery } from "@zeffiroso/zodios/useSafeInvalidateQuery";
import { secondsToMilliseconds } from "date-fns";
import { merge } from "lodash-es";
import { useEffect, useMemo } from "react";

import { cantata, cantataClient } from "@/cantata";
import { BASE_URL } from "@/env";
import { fcm } from "@/internal/firebase/firebaseMessaging";
import { useTokenStore } from "@/shared/services/token";
import { useLastUserEmailStore } from "@/shared/utils/createZustandStorageStore";

const authStatusMap = {
  signedOut: {
    status: "signedOut",
    isSignedOut: true,
    isSignedIn: false,
    isExpired: false,
    isLoading: false,
  },
  signedIn: {
    status: "signedIn",
    isSignedOut: false,
    isSignedIn: true,
    isExpired: false,
    isLoading: false,
  },
  expired: {
    status: "expired",
    isSignedOut: false,
    isSignedIn: false,
    isExpired: true,
    isLoading: false,
  },
  loading: {
    status: "loading",
    isSignedOut: false,
    isSignedIn: false,
    isExpired: false,
    isLoading: true,
  },
} as const;

/**
 * |             | Token | User data | Condition                                | UI                                     |
 * | ----------- | ----- | --------- | ---------------------------------------- | -------------------------------------- |
 * | `signedOut` | ❌    | ❌        | First visit or signed out                | Show Unauthorized pages                |
 * | `loading`   | ✅    | ❌        | Attempting signed in / Refresh / new Tab | Show loading                           |
 * | `signedIn`  | ✅    | ✅        | Authorized successfully                  | Show authorized pages                  |
 * | `expired`   | ❌    | ✅        | Detect session expired                   | Stay authorized pages but show warning |
 *
 * @see {@link https://github.com/chatbotgang/Zeffiroso/pull/375}
 */
type AuthStatus = (typeof authStatusMap)[keyof typeof authStatusMap];

function useAuthStatus(): AuthStatus {
  const safeInvalidateQuery = useSafeInvalidateQuery();
  const { value: token } = useTokenStore(({ value }) => ({ value }));
  const orgsQuery = cantata.org.useList(undefined, {
    enabled: Boolean(token),
  });
  const invalidateQuery = useHandler(function invalidateQuery() {
    safeInvalidateQuery(orgsQuery.key);
  });
  useEffect(() => {
    if (!token) return;
    invalidateQuery();
  }, [invalidateQuery, token]);

  const status = useMemo<keyof typeof authStatusMap>(
    () =>
      orgsQuery.data
        ? token
          ? "signedIn"
          : "expired"
        : token && orgsQuery.isLoading
          ? "loading"
          : "signedOut",
    [orgsQuery.data, orgsQuery.isLoading, token],
  );
  return authStatusMap[status];
}

const useLogoutMutation = (() => {
  type TData = unknown;
  type TError = unknown;
  type TVariables = void | {
    redirectToHome?: boolean;
  };
  type TContext = unknown;
  const defaultVariables: TVariables = {
    redirectToHome: true,
  };
  function useLogoutMutation(
    options?: Omit<
      UseMutationOptions<TData, TError, TVariables, TContext>,
      "mutationFn"
    >,
  ) {
    const clearLastUserEmail = useLastUserEmailStore(({ clear }) => clear);
    const tokenStore = useTokenStore();
    const revokeFcmTokenMutation = fcm.useRevokeFcmTokenMutation();
    const queryClient = useQueryClient();

    const logout = useHandler(async function logout(variables: TVariables) {
      const resolvedVariables = merge({}, defaultVariables, variables);
      await safePromise(() =>
        Promise.race([
          revokeFcmTokenMutation.mutateAsync(),
          delay(secondsToMilliseconds(5)),
        ]),
      );
      queryClient.clear();
      tokenStore.setValue("");
      /**
       * Clear last user email and all data stored in forUser stores.
       */
      clearLastUserEmail();
      if (resolvedVariables?.redirectToHome) {
        /**
         * Force reload the page to clear the cache and go back to homepage.
         */
        window.location.replace(BASE_URL);
      } else {
        window.location.reload();
      }
    });
    return useMutation<TData, TError, TVariables, TContext>(logout, options);
  }
  return useLogoutMutation;
})();

/**
 * Update the last user email when the user is signed in.
 */
async function updateLastUserEmail() {
  const me = await cantataClient.credential.me();
  if (me.email && me.email !== useLastUserEmailStore.getState().value)
    useLastUserEmailStore.getState().setValue(me.email);
}

export { updateLastUserEmail, useAuthStatus, useLogoutMutation };
