import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { delay } from "@chatbotgang/etude/timer/delay";
import { css } from "@emotion/react";
import { useMutation } from "@tanstack/react-query";
import { AppEnvSchema } from "@zeffiroso/env/env.schema";
import { Typography } from "antd";
import { secondsToMilliseconds } from "date-fns";
import Fuse from "fuse.js";
import { noop } from "lodash-es";
import { type FC, useMemo, useState } from "react";

import { Button } from "@/components/Button";
import { Form } from "@/components/Form";
import { Input } from "@/components/Input";
import { PhIcon } from "@/components/PhIcon";
import { overrideEnvStore } from "@/env";
import { unregisterSw } from "@/internal/sw";

const EnvInputField: FC<{
  envKey: keyof ReturnType<typeof overrideEnvStore.useStore.getState>["value"];
}> = ({ envKey }) => {
  const values = overrideEnvStore.useStore((state) => state.value);
  const onChange = useHandler<ComponentProps<typeof Input>["onChange"]>(
    function onChange(e) {
      const state = overrideEnvStore.useStore.getState();
      const nextValue = { ...state.value };
      if (e.target.value === "") {
        delete nextValue[envKey];
      } else {
        nextValue[envKey] = e.target.value;
      }

      state.setValue(nextValue);
    },
  );
  return (
    <Input
      value={values[envKey]}
      onChange={onChange}
      placeholder={String(window.env[envKey])}
    />
  );
};

const fuse = new Fuse(Object.keys(AppEnvSchema.shape));

type EnvKey = keyof typeof AppEnvSchema.shape;

const EnvOverride: FC = () => {
  const [search, setSearch] = useState("");
  const trimmedSearch = useMemo(() => search.trim(), [search]);
  const filteredEnvKeys: Array<EnvKey> = useMemo(() => {
    if (!trimmedSearch)
      return Object.keys(AppEnvSchema.shape).sort((a, b) =>
        a.localeCompare(b),
      ) as Array<EnvKey>;
    const searchResult = fuse.search(trimmedSearch);
    return searchResult.map(({ item }) => item) as Array<EnvKey>;
  }, [trimmedSearch]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        alignItems: "stretch",
      }}
    >
      <Typography.Title level={5}>Environment variables</Typography.Title>
      <Form.Item
        noStyle
        label="Search"
        style={{
          marginBottom: "0.75rem",
        }}
      >
        <Input
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          allowClear
          prefix={<PhIcon un-i-ph="magnifying-glass" />}
        />
      </Form.Item>
      {filteredEnvKeys.length === 0 ? (
        <Typography.Text type="secondary">No env found</Typography.Text>
      ) : (
        filteredEnvKeys.map((envKey) => (
          <Form.Item
            key={envKey}
            label={envKey}
            css={css({
              marginBottom: "0",
              ".ant-form-item-label": { paddingBottom: 0 },
            })}
          >
            <EnvInputField envKey={envKey} />
          </Form.Item>
        ))
      )}
    </div>
  );
};

const Env: FC = () => {
  const mutation = useMutation({
    mutationFn: async () => {
      try {
        await Promise.race([delay(secondsToMilliseconds(5)), unregisterSw()]);
      } catch (error) {
        console.error(error);
      }
      window.location.reload();
      // Infinite loading.
      await new Promise(noop);
    },
  });
  return (
    <Form
      layout="vertical"
      disabled={mutation.isLoading}
      onFinish={mutation.mutate}
    >
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: "4px",
          }}
        >
          <Typography.Title level={4} style={{ flex: 1 }}>
            Environment Variable Override
          </Typography.Title>
        </div>
        <EnvOverride />
        <div
          style={{
            position: "sticky",
            bottom: 0,
            display: "flex",
            justifyContent: "flex-end",
            gap: "8px",
            padding: "8px",
            marginTop: "8px",
            backgroundColor: "white",
          }}
        >
          <Button
            onClick={() => overrideEnvStore.useStore.getState().setValue({})}
          >
            Reset
          </Button>
          <Button type="primary" htmlType="submit" loading={mutation.isLoading}>
            Reload to apply
          </Button>
        </div>
      </div>
    </Form>
  );
};

export { Env };
