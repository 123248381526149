import type { ComponentProps } from "@chatbotgang/etude/react/ComponentProps";

import type { Table } from "@/components/Table";

const paginationProp: NonNullable<ComponentProps<typeof Table>["pagination"]> =
  {
    defaultPageSize: 5,
    /**
     * - [Figma](https://www.figma.com/design/2dKDXAEzNSR7s82PVYcLek?node-id=1221-26830&m=dev#841785809)
     * - Slack: [#team-eng-caac](https://chatbotgang.slack.com/archives/C03MSFGNT2A/p1741326977629319?thread_ts=1741323967.092949&cid=C03MSFGNT2A)
     */
    pageSizeOptions: [5, 10, 15, 20, 50, 100],
  };

export { paginationProp };
