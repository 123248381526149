import type { CantataTypes } from "@/cantata/types";
import type { uploadAttachment } from "@/resources/attachment/uploadAttachment";

function checkUploadAttachmentType<T extends CantataTypes["ChannelType"]>(
  data: Awaited<ReturnType<typeof uploadAttachment>>,
  type: T,
): data is Extract<
  Awaited<ReturnType<typeof uploadAttachment>>,
  {
    inputParams: {
      channelType: T;
    };
  }
> {
  return data.inputParams.channelType === type;
}

export { checkUploadAttachmentType };
