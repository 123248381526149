import { createQueriesContext } from "@zeffiroso/utils/react-query/createQueriesContext";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";

function useChannelsQueries({ orgId }: { orgId: CantataTypes["Org"]["id"] }) {
  const channelQuery = cantata.channel.useList(
    {
      params: {
        orgId,
      },
    },
    {
      suspense: true,
      useErrorBoundary: true,
    },
  );
  return {
    channel: channelQuery,
  };
}

const ChannelsQueriesContext =
  createQueriesContext<ReturnType<typeof useChannelsQueries>>();

function ChannelQueriesProvider({ children }: { children: React.ReactNode }) {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const queries = useChannelsQueries({
    orgId,
  });
  return (
    <ChannelsQueriesContext.Provider queries={queries}>
      {children}
    </ChannelsQueriesContext.Provider>
  );
}

function useQueries() {
  return ChannelsQueriesContext.useQueries();
}

function useData() {
  return ChannelsQueriesContext.useData().channel;
}

const channelsQueriesContext = {
  Provider: ChannelQueriesProvider,
  useQueries,
  useData,
};

export { channelsQueriesContext };
