import type { ArrayPredicate } from "@chatbotgang/etude/array/ArrayPredicate";
import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { define } from "@chatbotgang/etude/util/define";
import { css } from "@emotion/react";
import type { OverridableComponent, OverrideProps } from "@mui/types";
import type { Ymd } from "@zeffiroso/utils/date/Ymd";
import { constant } from "lodash-es";
import {
  type ElementRef,
  type ElementType,
  type ForwardedRef,
  useCallback,
  useMemo,
} from "react";

import { Trans } from "@/app/i18n/Trans";
import type { CantataTypes } from "@/cantata/types";
import { Select } from "@/components/Select";
import { defineStyles } from "@/shared/emotion";

type DateIntervalOptionType = Select.OptionType & {
  value: CantataTypes["DashboardInterval"];
};

const defaultComponent = Select;

type DefaultComponent = typeof defaultComponent<
  CantataTypes["DashboardInterval"]
>;

/**
 * List the own props of the component.
 */
interface DateIntervalSelectOwnProps {
  /**
   * The component used for the root node.
   */
  component?: ElementType;
  /**
   * filter function to filter interval options.
   */
  filter?: ArrayPredicate<DateIntervalOptionType>;
}

interface DateIntervalSelectTypeMap<
  AdditionalProps = unknown,
  RootComponent extends ElementType = DefaultComponent,
> {
  props: AdditionalProps & DateIntervalSelectOwnProps;
  defaultComponent: RootComponent;
}

type DateIntervalSelectProps<
  RootComponent extends
    ElementType = DateIntervalSelectTypeMap["defaultComponent"],
  // eslint-disable-next-line ts/ban-types -- inherit
  AdditionalProps = {},
> = DateIntervalSelectOwnProps &
  OverrideProps<
    DateIntervalSelectTypeMap<AdditionalProps, RootComponent>,
    RootComponent
  > & {
    component?: ElementType;
  };

const styles = defineStyles({
  root: css({
    display: "inline-block",
  }),
});

const defaultOptions = define<Array<DateIntervalOptionType>>([
  {
    value: "daily",
    label: <Trans i18nKey="dateIntervalSelect.option.day.label" />,
  },
  {
    value: "weekly",
    label: <Trans i18nKey="dateIntervalSelect.option.week.label" />,
  },
  {
    value: "monthly",
    label: <Trans i18nKey="dateIntervalSelect.option.month.label" />,
  },
]);

/**
 * Select for date intervals.
 */
const DateIntervalSelect: OverridableComponent<DateIntervalSelectTypeMap> =
  forwardRef(function DateIntervalSelect(
    {
      component: Component = defaultComponent,
      children,
      filter = constant(true),
      variant = "borderless",
      ...props
    }: DateIntervalSelectProps,
    ref: ForwardedRef<ElementRef<typeof Component>>,
  ) {
    const options = useMemo(() => defaultOptions.filter(filter), [filter]);
    return (
      <Component
        css={styles.root}
        variant={variant}
        popupMatchSelectWidth={false}
        options={options}
        {...props}
        ref={ref}
      />
    );
  }) as OverridableComponent<DateIntervalSelectTypeMap>;

/**
 * filter date interval options by range.
 * - [Figma](https://www.figma.com/design/2dKDXAEzNSR7s82PVYcLek/Insights?node-id=1840-5710)
 */
const useFilterIntervalByRange = (range: { startTime: Ymd; endTime: Ymd }) => {
  const rangeInDays = useMemo(
    function computeSelectedDays() {
      return range.endTime.diffInDays(range.startTime) + 1;
    },
    [range.endTime, range.startTime],
  );
  return useCallback<NonNullable<DateIntervalSelectProps["filter"]>>(
    (option) => {
      switch (option.value) {
        case "monthly":
          return rangeInDays >= 30;
        case "weekly":
          return rangeInDays >= 8;
        case "daily":
          return true;
        default:
          option.value satisfies never;
          throw new Error(inspectMessage`Invalid option: ${option.value}`);
      }
    },
    [rangeInDays],
  );
};

/**
 * get default selection by selected days.
 * - [Figma](https://www.figma.com/design/2dKDXAEzNSR7s82PVYcLek/Insights?node-id=1840-5710)
 */
const getDefaultIntervalByRange = (range: {
  startTime: Ymd;
  endTime: Ymd;
}): CantataTypes["DashboardInterval"] => {
  const selectedDays = range.endTime.diffInDays(range.startTime) + 1;
  if (selectedDays >= 120) {
    return "monthly";
  }
  if (selectedDays >= 28) {
    return "weekly";
  }
  return "daily";
};

const api = Object.assign(DateIntervalSelect, {
  useFilterIntervalByRange,
  getDefaultIntervalByRange,
});

export { api as DateIntervalSelect, defaultComponent };

export type {
  DateIntervalSelectOwnProps,
  DateIntervalSelectProps,
  DateIntervalSelectTypeMap,
};
