/* eslint-disable no-restricted-imports -- Copied from antd. */

/**
 * Copied from antd but replaced `Button` with Zeffiroso's `Button`.
 *
 * <https://github.com/ant-design/ant-design/blob/45eeee6/components/modal/components/NormalOkBtn.tsx>
 */

import { convertLegacyProps } from "antd/es/button/buttonHelpers";
import { ModalContext } from "antd/es/modal/context";
import type { ModalProps } from "antd/es/modal/interface";
import type { FC } from "react";
import { useContext } from "react";

import { Button } from "@/components/Button";

export interface NormalOkBtnProps
  extends Pick<
    ModalProps,
    "confirmLoading" | "okType" | "okButtonProps" | "onOk"
  > {
  okTextLocale?: React.ReactNode;
}

const NormalOkBtn: FC = () => {
  const { confirmLoading, okButtonProps, okType, okTextLocale, onOk } =
    useContext(ModalContext);
  return (
    <Button
      {...convertLegacyProps(okType)}
      {...(!confirmLoading
        ? null
        : {
            loading: true,
          })}
      onClick={onOk}
      {...okButtonProps}
    >
      {okTextLocale}
    </Button>
  );
};

export { NormalOkBtn };
