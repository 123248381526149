import { addOpacityToHex } from "@zeffiroso/utils/colors/hex";
import { z } from "zod";

/**
 * Design Tokens
 * @see {@link https://www.notion.so/cresclab/f6cde3c1a0c84384b673898b5ea8aaef?v=7453e5b553f34c8192eb776cbf90d552&pvs=4}
 */

function definePalette<const TEntries extends Array<[string, string]>>(
  entries: TEntries,
) {
  const Options = entries.map(([_key, value]) => value) as {
    [TKey in keyof TEntries]: TEntries[TKey][1 & keyof TEntries[TKey]];
  } & [string, ...Array<string>];
  const schema = z.enum(Options);
  const record = Object.fromEntries(entries) as {
    [TKey in keyof TEntries & string as TEntries[TKey][0 &
      keyof TEntries[TKey]] &
      PropertyKey]: TEntries[TKey][1 & keyof TEntries[TKey]];
  };
  return { schema, record };
}
const NeutralPalette = definePalette([
  ["neutral001", "#F8F9FB"],
  ["neutral002", "#E7EBEF"],
  ["neutral003", "#D7DEE4"],
  ["neutral004", "#C7D0D9"],
  ["neutral005", "#BBC6D1"],
  ["neutral006", "#AFBCC9"],
  ["neutral007", "#8C9CAB"],
  ["neutral008", "#5C7388"],
  ["neutral009", "#425A70"],
  ["neutral010", "#223B53"],
]);

const RedPalette = definePalette([
  ["red001", "#FFF3EF"],
  ["red002", "#FFEBE4"],
  ["red003", "#FFBAAB"],
  ["red004", "#FF8569"],
  ["red005", "#E9573B"],
  ["red006", "#D93721"],
  ["red007", "#CF2A18"],
  ["red008", "#8C0C00"],
  ["red009", "#660500"],
  ["red010", "#400100"],
]);

const YellowPalette = definePalette([
  ["yellow001", "#FFFAE4"],
  ["yellow002", "#FFF6D0"],
  ["yellow003", "#FFE976"],
  ["yellow004", "#FFD235"],
  ["yellow005", "#FAB918"],
  ["yellow006", "#F29B00"],
  ["yellow007", "#EE8900"],
  ["yellow008", "#AE7013"],
  ["yellow009", "#875410"],
  ["yellow010", "#61390B"],
]);

const GreenPalette = definePalette([
  ["green001", "#F2FDF1"],
  ["green002", "#E7FBE7"],
  ["green003", "#99ED9E"],
  ["green004", "#6AD170"],
  ["green005", "#43B849"],
  ["green006", "#179C34"],
  ["green007", "#118A27"],
  ["green008", "#2E6B03"],
  ["green009", "#1A4500"],
  ["green010", "#063306"],
]);

const BluePalette = definePalette([
  ["blue001", "#F3F9FF"],
  ["blue002", "#E7F2FF"],
  ["blue003", "#B5D7FF"],
  ["blue004", "#7DAFFF"],
  ["blue005", "#5998FF"],
  ["blue006", "#4586F0"],
  ["blue007", "#1F56E5"],
  ["blue008", "#1834A3"],
  ["blue009", "#0C1F7D"],
  ["blue010", "#081257"],
]);

const CeruleanPalette = definePalette([
  ["cerulean001", "#F0FEFF"],
  ["cerulean002", "#E0FDFE"],
  ["cerulean003", "#B3FBFF"],
  ["cerulean004", "#84EBF5"],
  ["cerulean005", "#58D7E8"],
  ["cerulean006", "#2DC4D8"],
  ["cerulean007", "#259AB0"],
  ["cerulean008", "#1C788C"],
  ["cerulean009", "#0F5366"],
  ["cerulean010", "#0A3946"],
]);

const PurplePalette = definePalette([
  ["purple001", "#F8F2FF"],
  ["purple002", "#F2E8FF"],
  ["purple003", "#D4B9F9"],
  ["purple004", "#B98EF5"],
  ["purple005", "#A257FF"],
  ["purple006", "#8020F8"],
  ["purple007", "#6C1DED"],
  ["purple008", "#4716A1"],
  ["purple009", "#2E0B7A"],
  ["purple010", "#1D0754"],
]);

const colorPalette = {
  white: "#ffffff",
  primary: BluePalette.record.blue006,
  primaryHover: BluePalette.record.blue005,
  primaryActive: BluePalette.record.blue007,
  primaryOutline: addOpacityToHex(BluePalette.record.blue006, "33"),
  secondary: NeutralPalette.record.neutral006,
  secondaryHover: NeutralPalette.record.neutral005,
  secondaryActive: NeutralPalette.record.neutral007,
  success: GreenPalette.record.green006,
  successHover: GreenPalette.record.green005,
  successActive: GreenPalette.record.green007,
  error: RedPalette.record.red006,
  errorHover: RedPalette.record.red005,
  errorActive: RedPalette.record.red007,
  errorOutline: addOpacityToHex(RedPalette.record.red006, "33"),
  warning: YellowPalette.record.yellow006,
  warningHover: YellowPalette.record.yellow005,
  warningActive: YellowPalette.record.yellow007,
  info: CeruleanPalette.record.cerulean006,
  infoHover: CeruleanPalette.record.cerulean005,
  infoActive: CeruleanPalette.record.cerulean007,
  light: NeutralPalette.record.neutral001,
  dark: NeutralPalette.record.neutral008,
  colorText: NeutralPalette.record.neutral010,
  backgroundDisabled: NeutralPalette.record.neutral001,
};

export const colors = {
  ...NeutralPalette.record,
  ...RedPalette.record,
  ...YellowPalette.record,
  ...GreenPalette.record,
  ...BluePalette.record,
  ...CeruleanPalette.record,
  ...PurplePalette.record,
  ...colorPalette,
};
