import styled from "@emotion/styled";
import { Children, useRef } from "react";

import { Bubble } from "@/components/LINEFlexMessage/bubble";
import { PropsProvider } from "@/components/LINEFlexMessage/context";
import type { FlexPreviewProps } from "@/components/LINEFlexMessage/types";

const CarrouselScrollWrapper = styled.div`
  position: relative;
  display: flex;
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;

  > div {
    min-width: 223px;
  }
`;

/**
 * Only supports 'FlexBubble' now
 */
export const LINEFlexMessage = (props: FlexPreviewProps): React.ReactNode => {
  const { message } = props;
  const scrollWrapperRef = useRef<HTMLDivElement>(null);

  return (
    <PropsProvider value={props}>
      {message.type === "bubble" && <Bubble bubble={message} index={0} />}
      {message.type === "carousel" && (
        <CarrouselScrollWrapper ref={scrollWrapperRef}>
          {Children.toArray(
            message.contents.map((content, index) => (
              <Bubble bubble={content} index={index} />
            )),
          )}
        </CarrouselScrollWrapper>
      )}
    </PropsProvider>
  );
};
