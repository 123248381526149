import { AppEnvSchema } from "@zeffiroso/env/env.schema";
import { omitBy } from "lodash-es";
import { objectFromEntries, objectKeys } from "tsafe";
import urlJoin from "url-join";
import { z } from "zod";

import { LOCAL_STORAGE_ENV } from "@/appConstant";
import { createZustandStorageStore } from "@/shared/utils/createZustandStorageStore";

const OverrideEnvSchema = z
  .object(
    objectFromEntries(
      objectKeys(AppEnvSchema.shape).flatMap((key) => {
        return [[key, z.string().optional().catch(undefined)]];
      }),
    ) as {
      [TKey in keyof typeof AppEnvSchema.shape]: z.ZodCatch<
        z.ZodUnion<[z.ZodString, z.ZodUndefined]>
      >;
    },
  )
  .catch(
    objectFromEntries(
      objectKeys(AppEnvSchema.shape).flatMap((key) => {
        return [[key, undefined]];
      }),
    ),
  );

const overrideEnvStore = createZustandStorageStore(
  LOCAL_STORAGE_ENV,
  (json) => OverrideEnvSchema.parse(json),
  {
    forUser: false,
  },
);

const ParsedOverrideEnvSchema = z.object(
  objectFromEntries(
    objectKeys(AppEnvSchema.shape).flatMap((key) => {
      const schema: z.ZodType = AppEnvSchema.shape[key];
      const optionalSchema = schema.or(z.undefined()).catch(undefined);
      return [[key, optionalSchema]];
    }),
  ) as {
    [TKey in keyof typeof AppEnvSchema.shape]: z.ZodCatch<
      z.ZodUnion<[(typeof AppEnvSchema.shape)[TKey], z.ZodUndefined]>
    >;
  },
);

if (import.meta.env.NODE_ENV === "test") {
  window.env = {
    ...window.env,
    ...AppEnvSchema.parse(
      // eslint-disable-next-line no-process-env, node/prefer-global/process -- Only for vitest.
      process.env,
    ),
  };
}

const env: typeof window.env = {
  ...window.env,
  ...omitBy(
    ParsedOverrideEnvSchema.parse(overrideEnvStore.useStore.getState().value),
    (value) => value === undefined,
  ),
};

const TITLE = env.VITE_TITLE;

/** CAAC API service host */
const CAAC_API_URL = env.VITE_CANTATA_API_HOST;
const API_DEFAULT_LIMIT = env.VITE_API_DEFAULT_LIMIT;
const API_BULK_ACTION_LIMIT = env.VITE_API_BULK_ACTION_LIMIT;
/** CAAC Websocket service host */
const CAAC_WEBSOCKET_HOST = env.VITE_LEGATO_WS_HOST;
const CAAC_WEBSOCKET_URL = urlJoin(CAAC_WEBSOCKET_HOST, "/api/v1/ws");
/** MAAC forget password URL */
const MAAC_FORGET_PASSWORD_URL = env.VITE_MAAC_FORGET_PASSWORD_URL;
/** Admin service host */
const ADMIN_CENTER_HOST = env.VITE_ADMIN_CENTER_HOST;

/** Basic environment variables */
/** is NODE_ENV development */
const BASE_URL = import.meta.env.BASE_URL;

/** Firebase configs */
const FIREBASE_CONTENT_STORAGE_API_KEY =
  env.VITE_FIREBASE_CONTENT_STORAGE_API_KEY;
const FIREBASE_CONTENT_STORAGE_APP_ID =
  env.VITE_FIREBASE_CONTENT_STORAGE_APP_ID;
const FIREBASE_CONTENT_STORAGE_BUCKET =
  env.VITE_FIREBASE_CONTENT_STORAGE_BUCKET;
const FIREBASE_CONTENT_STORAGE_PROJECT_ID =
  env.VITE_FIREBASE_CONTENT_STORAGE_PROJECT_ID;
const FIREBASE_CONTENT_STORAGE_IMMORTAL_BUCKET =
  env.VITE_FIREBASE_CONTENT_STORAGE_IMMORTAL_BUCKET;

const FIREBASE_CONTENT_STORAGE_SENDER_ID =
  env.VITE_FIREBASE_CONTENT_STORAGE_SENDER_ID;
const FIREBASE_CONTENT_STORAGE_VAPID_KEY =
  env.VITE_FIREBASE_CONTENT_STORAGE_VAPID_KEY;

const FIREBASE_LOCALE_STORAGE_API_KEY =
  env.VITE_FIREBASE_LOCALE_STORAGE_API_KEY;

const FIREBASE_LOCALE_STORAGE_AUTH_DOMAIN =
  env.VITE_FIREBASE_LOCALE_STORAGE_AUTH_DOMAIN;

const FIREBASE_LOCALE_STORAGE_PROJECT_ID =
  env.VITE_FIREBASE_LOCALE_STORAGE_PROJECT_ID;

const FIREBASE_LOCALE_STORAGE_STORAGE_BUCKET =
  env.VITE_FIREBASE_LOCALE_STORAGE_STORAGE_BUCKET;

const FIREBASE_LOCALE_STORAGE_APP_ID = env.VITE_FIREBASE_LOCALE_STORAGE_APP_ID;

const FIREBASE_LOCALE_STORAGE_PATH_PREFIX =
  env.VITE_FIREBASE_LOCALE_STORAGE_PATH_PREFIX;

const FB_APP_ID = env.VITE_FB_APP_ID;
const FB_CURSOR_BASED_PAGINATION_LIMIT =
  env.VITE_FB_CURSOR_BASED_PAGINATION_LIMIT;

const SITE = env.VITE_SITE;

const SENTRY_RELEASE = env.VITE_SENTRY_RELEASE;
const SENTRY_DSN = env.VITE_SENTRY_DSN;
const GA4_ID = env.VITE_GA4_ID;
const GTM_ID = env.VITE_GTM_ID;
const ZENDESK_KEY = env.VITE_ZENDESK_KEY;

const USERFLOW_TOKEN = env.VITE_USERFLOW_TOKEN;
const USERFLOW_USER_ID_PREFIX = env.VITE_USERFLOW_USER_ID_PREFIX;
const USERFLOW_ATTRIBUTE_PREFIX = env.VITE_USERFLOW_ATTRIBUTE_PREFIX;

const DEFAULT_QUERY_RETRY_MAX_COUNT = env.VITE_DEFAULT_QUERY_RETRY_MAX_COUNT;
const DEFAULT_UPLOAD_CONCURRENCY = env.VITE_DEFAULT_UPLOAD_CONCURRENCY;

const CAAC_APPLE_APP_STORE_LINK = env.VITE_CAAC_APPLE_APP_STORE_LINK;
const CAAC_GOOGLE_PLAY_LINK = env.VITE_CAAC_GOOGLE_PLAY_LINK;

export {
  ADMIN_CENTER_HOST,
  API_BULK_ACTION_LIMIT,
  API_DEFAULT_LIMIT,
  BASE_URL,
  CAAC_API_URL,
  CAAC_APPLE_APP_STORE_LINK,
  CAAC_GOOGLE_PLAY_LINK,
  CAAC_WEBSOCKET_URL,
  DEFAULT_QUERY_RETRY_MAX_COUNT,
  DEFAULT_UPLOAD_CONCURRENCY,
  FB_APP_ID,
  FB_CURSOR_BASED_PAGINATION_LIMIT,
  FIREBASE_CONTENT_STORAGE_API_KEY,
  FIREBASE_CONTENT_STORAGE_APP_ID,
  FIREBASE_CONTENT_STORAGE_BUCKET,
  FIREBASE_CONTENT_STORAGE_IMMORTAL_BUCKET,
  FIREBASE_CONTENT_STORAGE_PROJECT_ID,
  FIREBASE_CONTENT_STORAGE_SENDER_ID,
  FIREBASE_CONTENT_STORAGE_VAPID_KEY,
  FIREBASE_LOCALE_STORAGE_API_KEY,
  FIREBASE_LOCALE_STORAGE_APP_ID,
  FIREBASE_LOCALE_STORAGE_AUTH_DOMAIN,
  FIREBASE_LOCALE_STORAGE_PATH_PREFIX,
  FIREBASE_LOCALE_STORAGE_PROJECT_ID,
  FIREBASE_LOCALE_STORAGE_STORAGE_BUCKET,
  GA4_ID,
  GTM_ID,
  MAAC_FORGET_PASSWORD_URL,
  overrideEnvStore,
  SENTRY_DSN,
  SENTRY_RELEASE,
  SITE,
  TITLE,
  USERFLOW_ATTRIBUTE_PREFIX,
  USERFLOW_TOKEN,
  USERFLOW_USER_ID_PREFIX,
  ZENDESK_KEY,
};
