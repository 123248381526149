import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { createContext } from "@chatbotgang/etude/react/createContext";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { random } from "@chatbotgang/etude/string/random";
import { ClassNames, css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import type { ElementRef, FC } from "react";
import { Suspense, useMemo } from "react";
import { useTranslation } from "react-i18next";
import type { RequireAtLeastOne, Tagged } from "type-fest";

import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { ErrorBoundary } from "@/components/ErrorBoundary";
import { Select } from "@/components/Select";
import { cssOneLine, defineStyles } from "@/shared/emotion";

const seed = random();
const classNamePrefix = `referrer-selector-${seed}-` as const;
const nameClassName = `${classNamePrefix}name` as const;
const externalIdClassName = `${classNamePrefix}external-id` as const;

const styles = defineStyles({
  root: css({
    [`& .ant-select-selection-item .${nameClassName}`]: cssOneLine,
    [`& .ant-select-selection-item .${externalIdClassName}`]: {
      display: "none",
    },
  }),
});

const referrerOptionValuePrefix = "referrer-";
type ReferrerOptionValuePrefix = typeof referrerOptionValuePrefix;

const PropsContext = createContext<ReferrerSelector.Props>({
  name: "ReferrerSelectPropsProvider",
});

namespace ReferrerSelector {
  export type ValueType =
    /**
     * referrer to a user
     */
    | {
        type: "user";
        userId: CantataTypes["User"]["id"];
      }
    /**
     * referrer to a team
     */
    | {
        type: "team";
        teamId: CantataTypes["Team"]["id"];
      }
    /**
     * referrer to a user under a team
     */
    | {
        type: "user-in-team";
        userId: CantataTypes["User"]["id"];
        teamId: CantataTypes["Team"]["id"];
      }
    | undefined;

  export interface Props
    extends Omit<
      Select.Props<ValueType>,
      | "mode"
      | "options"
      | "onSelect"
      | "onDeselect"
      | "defaultValue"
      | "optionFilterProp"
    > {
    orgId: CantataTypes["Org"]["id"];
    userId: CantataTypes["User"]["id"];
    /**
     * Whether to show the external ID in the option label.
     */
    hideExternalId?: boolean;
    /**
     * Whether to exclude the options that don't have an external ID.
     */
    isExternalIdRequiredForTeams?: boolean;
  }

  export type Ref = ElementRef<typeof Select<InternalSelectValue>>;

  /**
   * The value of the user option.
   */
  export type UserOptionValue = Tagged<
    `${ReferrerOptionValuePrefix}user-${CantataTypes["User"]["id"]}`,
    "UserOptionValue"
  >;
  /**
   * 'The value of the team option.
   */
  export type TeamOptionValue = Tagged<
    `${ReferrerOptionValuePrefix}team-${CantataTypes["Team"]["id"]}`,
    "TeamOptionValue"
  >;
  /**
   * The value of the user-in-team option.
   */
  export type UserInTeamOptionValue = Tagged<
    `${ReferrerOptionValuePrefix}user-in-team-${number}-${number}`,
    "UserInTeamOptionValue"
  >;
  export type OptionValue =
    | UserOptionValue
    | TeamOptionValue
    | UserInTeamOptionValue
    | undefined;
}

type OptionType = NonNullable<
  Select.Props<ReferrerSelector.ValueType>["options"]
>[number];

type InternalSelectValue<
  T extends ReferrerSelector.ValueType = ReferrerSelector.ValueType,
> = T extends {
  type: "user";
  userId: CantataTypes["User"]["id"];
}
  ? ReferrerSelector.UserOptionValue
  : T extends { type: "team"; teamId: CantataTypes["Team"]["id"] }
    ? ReferrerSelector.TeamOptionValue
    : T extends {
          type: "user-in-team";
          userId: CantataTypes["User"]["id"];
          teamId: CantataTypes["Team"]["id"];
        }
      ? ReferrerSelector.UserInTeamOptionValue
      : undefined;

function selectedOptionValue<T extends ReferrerSelector.ValueType>(
  value: T,
): ReferrerSelector.OptionValue {
  if (!value) return;
  switch (value.type) {
    case "user":
      return `${referrerOptionValuePrefix}user-${value.userId}` as ReferrerSelector.UserOptionValue;
    case "team":
      return `${referrerOptionValuePrefix}team-${value.teamId}` as ReferrerSelector.TeamOptionValue;
    case "user-in-team":
      return `${referrerOptionValuePrefix}user-in-team-${value.userId}-${value.teamId}` as ReferrerSelector.UserInTeamOptionValue;
    default:
      value satisfies never;
      throw new Error(inspectMessage`Unexpected value type: ${value}`);
  }
}

function optionValueToValue(
  value: ReferrerSelector.OptionValue,
): ReferrerSelector.ValueType {
  if (!value) return undefined;
  const match = value.match(
    /^referrer-(user|team|user-in-team)-(\d+)(?:-(\d+))?$/,
  );
  if (!match) throw new Error(inspectMessage`Unexpected value: ${value}`);
  const [, type, userId, teamId] = match;
  switch (type) {
    case "user":
      return { type: "user", userId: Number(userId) };
    case "team":
      return { type: "team", teamId: Number(userId) };
    case "user-in-team":
      return {
        type: "user-in-team",
        userId: Number(userId),
        teamId: Number(teamId),
      };
    default:
      throw new Error(inspectMessage`Unexpected type: ${type}`);
  }
}

const optionLabelStyles = defineStyles({
  root: css({
    color: theme.colors.neutral009,
    fontSize: "0.875rem",
    whiteSpace: "pre-wrap",
  }),
  externalId: css({
    fontSize: "0.75rem",
  }),
});

const OptionLabel: FC<
  RequireAtLeastOne<
    {
      user: Pick<CantataTypes["User"], "id" | "name" | "externalUserId">;
      team: Pick<CantataTypes["Team"], "id" | "name" | "externalTeamId">;
    },
    "team" | "user"
  >
> = ({ team, user }) => {
  const name = [team?.name, user?.name].filter(Boolean).join(" - ");
  const externalId =
    team !== undefined && user !== undefined
      ? `${team.externalTeamId} - ${user.externalUserId}`
      : team !== undefined
        ? team.externalTeamId
        : user !== undefined
          ? user.externalUserId
          : (() => {
              throw new Error("Require at least one of team or user");
            })();

  const { hideExternalId } = PropsContext.useContext();

  return (
    <div css={optionLabelStyles.root} title={name}>
      <div className={nameClassName}>{name}</div>
      {hideExternalId ? null : (
        <div css={optionLabelStyles.externalId} className={externalIdClassName}>
          {externalId}
        </div>
      )}
    </div>
  );
};

function createFilterValue(...values: Array<string | number | null>) {
  /**
   * Since it's not possible to type `\n` in the search box, we utilize
   * it as a separator to align the search box with the option label.
   */
  return values.filter(Boolean).join("\n");
}

function excludeTeamWithoutExternalId(team: CantataTypes["Team"]) {
  return team.externalTeamId !== null;
}

function createUserInTeamOption(
  user: Pick<CantataTypes["User"], "id" | "name" | "externalUserId">,
  team: Pick<CantataTypes["Team"], "id" | "name" | "externalTeamId">,
): OptionType {
  const optionValue = selectedOptionValue({
    type: "user-in-team",
    userId: user.id,
    teamId: team.id,
  });
  return {
    label: <OptionLabel team={team} user={user} />,
    key: optionValue,
    value: optionValue,
    filter: createFilterValue(
      team.name,
      team.externalTeamId,
      user.name,
      user.externalUserId,
    ),
  };
}

function createUserOption(
  user: Pick<CantataTypes["User"], "id" | "name" | "externalUserId">,
): OptionType {
  const userOptionValue = selectedOptionValue({
    type: "user",
    userId: user.id,
  });
  return {
    label: <OptionLabel user={user} />,
    key: userOptionValue,
    value: userOptionValue,
    filter: createFilterValue(user.name, user.externalUserId),
  };
}

function createTeamOption(
  team: Pick<CantataTypes["Team"], "id" | "name" | "externalTeamId">,
) {
  const optionValue = selectedOptionValue({
    type: "team",
    teamId: team.id,
  });
  return {
    label: <OptionLabel team={team} />,
    key: optionValue,
    value: optionValue,
    filter: createFilterValue(team.name, team.externalTeamId),
  };
}

const ReferrerSelectorInternal = forwardRef<
  ReferrerSelector.Ref,
  ReferrerSelector.Props
>(function ReferrerSelector(
  {
    value,
    onChange,
    orgId,
    userId,
    isExternalIdRequiredForTeams,
    hideExternalId,
    ...props
  },
  ref,
) {
  const { t } = useTranslation();
  const userQuery = cantata.user.useGetById(
    {
      params: {
        orgId,
        userId,
      },
    },
    {
      suspense: true,
      useErrorBoundary: true,
    },
  );

  const agentOptions = useMemo<OptionType[]>(() => {
    if (!userQuery.isSuccess) return [];
    const user = userQuery.data;
    const teams = user.teams;

    const teamOptions = (teams: CantataTypes["Team"][]) =>
      teams.map((team) => createUserInTeamOption(user, team));

    if (!isExternalIdRequiredForTeams) {
      return [createUserOption(user), ...teamOptions(teams)];
    }

    /**
     * If the user doesn't have an external user ID,
     * we assume the available agent options are the same as the team options.
     * In this case, we omit the agent options from the list,
     * showing only the teams they belong to.
     */
    return user.externalUserId === null
      ? []
      : teamOptions(teams.filter(excludeTeamWithoutExternalId));
  }, [isExternalIdRequiredForTeams, userQuery.data, userQuery.isSuccess]);

  const teamOptions = useMemo<OptionType[]>(() => {
    if (!userQuery.isSuccess) return [];

    return userQuery.data.teams
      .filter(
        (team) =>
          !isExternalIdRequiredForTeams ||
          (isExternalIdRequiredForTeams && excludeTeamWithoutExternalId(team)),
      )
      .map(createTeamOption);
  }, [
    isExternalIdRequiredForTeams,
    userQuery.data?.teams,
    userQuery.isSuccess,
  ]);

  const options = useMemo<OptionType[]>(() => {
    return [
      ...(agentOptions.length === 0
        ? []
        : [
            {
              key: "user",
              label: t("resource.user.referrerSelect.agent"),
              options: agentOptions,
            },
          ]),
      ...(teamOptions.length === 0
        ? []
        : [
            {
              key: "team",
              label: t("resource.user.referrerSelect.team"),
              options: teamOptions,
            },
          ]),
    ];
  }, [agentOptions, t, teamOptions]);

  const formattedValue = useMemo<undefined | InternalSelectValue>(
    function formatValue() {
      return !value ? undefined : selectedOptionValue(value);
    },
    [value],
  );

  const changeHandler = useHandler<
    Select.Props<InternalSelectValue>["onChange"]
  >((v, option) => {
    const externalValue = optionValueToValue(v);
    if (!v || externalValue === undefined) {
      onChange?.(undefined, option);
      return;
    }
    onChange?.(externalValue, option);
  });

  if (!userQuery.isSuccess) return null;

  return (
    <ClassNames>
      {({ css }) => (
        <Select<InternalSelectValue>
          {...props}
          ref={ref}
          css={styles.root}
          options={options}
          value={formattedValue}
          onChange={changeHandler}
          optionFilterProp="filter"
          popupClassName={css`
            .ant-select-item-option-grouped {
              padding-inline-start: 12px;
            }
          `}
          placeholder={t("resource.user.referrerSelect.placeholder")}
        />
      )}
    </ClassNames>
  );
});

assignDisplayName(ReferrerSelectorInternal, "ReferrerSelector");

const ErrorBoundaryWrapped: typeof ReferrerSelectorInternal = forwardRef(
  function ErrorBoundaryWrapped(props, ref) {
    return (
      <ErrorBoundary.Alert>
        <Suspense fallback={<Select ref={ref} disabled loading />}>
          <PropsContext.Provider value={props}>
            <ReferrerSelectorInternal {...props} ref={ref} />
          </PropsContext.Provider>
        </Suspense>
      </ErrorBoundary.Alert>
    );
  },
);

assignDisplayName(ErrorBoundaryWrapped, "ReferrerSelector");

const ReferrerSelector = Object.assign(ErrorBoundaryWrapped, {
  selectedOptionValue,
  optionValueToValue,
});

export { ReferrerSelector };
