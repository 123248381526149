import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { createContext } from "@chatbotgang/etude/react/createContext";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { safePromise } from "@chatbotgang/etude/safe/safePromise";
import { css } from "@emotion/react";
import useSwitch from "@react-hook/switch";
import { useMutation } from "@tanstack/react-query";
import { theme } from "@zeffiroso/theme";
import { useUseAbortControllerStore } from "@zeffiroso/utils/react/abortControllerStore";
import { Suspense, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { Trans } from "@/app/i18n/Trans";
import { cantataClient } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { NarrowIconButton } from "@/components/Button/NarrowIconButton";
import { ErrorBoundary } from "@/components/ErrorBoundary";
import { ExternalLink } from "@/components/ExternalLink";
import { createEasyForm } from "@/components/Form/createEasyForm";
import { InputTextArea } from "@/components/Input";
import { Modal } from "@/components/Modal";
import { MotifIcon } from "@/components/MotifIcon";
import { toFile } from "@/components/QrCode/toFile";
import { ChannelSelect } from "@/components/Select/ChannelSelect";
import { uuid } from "@/lib/uuid";
import { channelsQueriesContext } from "@/queriesContext/channelsQueriesContext";
import { memberQueriesContext } from "@/queriesContext/memberQueriesContext";
import { orgQueriesContext } from "@/queriesContext/orgQueriesContext";
import { checkUploadAttachmentType } from "@/resources/attachment/checkUploadAttachmentType";
import { uploadAttachment } from "@/resources/attachment/uploadAttachment";
import { memberIdUtils } from "@/resources/member/memberIdUtils";
import { useFeatureControl } from "@/resources/organization/featureControl";
import { tagsQueriesContext } from "@/resources/redirectUnification/tagsQueriesContext";
import { MultipleTagSelect } from "@/resources/tag/MultipleTagSelect";
import { ReferrerSelector } from "@/resources/user/ReferrerSelector";
import { compileToString } from "@/router/utils/compileTo";
import { useLastInvitationMessage } from "@/routes/Chat/ui/ChatPanel/Editor/Old/useLastInvitationMessage";
import { useSendingMessagesController } from "@/routes/Chat/ui/ChatPanel/sendingMessages";
import { useUserPermission } from "@/shared/application/user";
import { defineStyles } from "@/shared/emotion";

interface FieldValues {
  channelId: CantataTypes["Channel"]["id"];
  assignee: ReferrerSelector.ValueType;
  tags: Array<CantataTypes["Tag"]["id"]>;
  message: string;
}

const EasyForm = createEasyForm<FieldValues>();

function useSetupRedirectUnification() {
  const [open, toggleModal] = useSwitch();

  const reset = useHandler(function reset() {
    toggleModal.off();
  });

  const close = useHandler(function close() {
    reset();
  });

  const ret = useMemo(
    () => ({
      open,
      openModal: toggleModal.on,
      close,
      reset,
    }),
    [open, toggleModal.on, close, reset],
  );
  return ret;
}

const Context = createContext<ReturnType<typeof useSetupRedirectUnification>>({
  name: "RedirectUnificationContext",
});

const useRedirectUnification = Context.useContext;

const Provider: React.FC<
  Omit<ComponentProps<typeof Context.Provider>, "value">
> = (props) => {
  const value = useSetupRedirectUnification();
  return <Context.Provider value={value} {...props} />;
};

const styles = defineStyles({
  groups: css({
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  }),
  items: css({
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  }),
  note: css({
    color: theme.colors.neutral007,
  }),
  tagLabel: css({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "8px",
    label: {
      color: theme.colors.neutral009,
      fontWeight: 500,
    },
  }),
});

const RedirectUnificationModal: React.FC = () => {
  const { t } = useTranslation();
  const orgId = useActiveOrgIdStore((state) => state.value);
  const orgQueriesData = orgQueriesContext.useData();
  const memberId = memberIdUtils.useGet();
  const memberChannel = memberQueriesContext.useMemberChannel();
  const [easyForm, form] = EasyForm.useForm();
  const channelsQueriesData = channelsQueriesContext.useData();
  const { setLastInvitationMessage, lastInvitationMessage } =
    useLastInvitationMessage();
  const redirectUnificationContext = useRedirectUnification();

  const channels = useMemo(
    () =>
      channelsQueriesData.channels.filter(
        (channel) =>
          channel.type === "line" &&
          channel.status === "connected" &&
          // unify member profiles across different channels
          channel.id !== memberChannel.id,
      ),
    [channelsQueriesData.channels, memberChannel.id],
  );

  const [channelId, setChannelId] = useState(channels[0]?.id ?? Number.NaN);

  const useAbortControllerStore = useUseAbortControllerStore();
  useEffect(
    function abortWhenUnmount() {
      return function abort() {
        useAbortControllerStore.getState().abort();
      };
    },
    [useAbortControllerStore],
  );

  const reset = useHandler(function reset() {
    easyForm.controller.resetFields();
    redirectUnificationContext.close();
  });

  const sendingMessagesController = useSendingMessagesController();

  const mutation = useMutation({
    async mutationFn(values: FieldValues) {
      const deepLinksResp =
        await cantataClient.unificationDeepLinks.listUserUnificationDeepLinks({
          params: {
            orgId,
            memberId,
          },
        });

      const deepLink = deepLinksResp.unificationQrcodes.find(
        (deepLink) => deepLink.channel.id === values.channelId,
      );

      if (!deepLink) {
        throw new Error(
          inspectMessage`Failed to get deep link for channel ${values.channelId}`,
        );
      }

      const imageFile = await toFile(deepLink.qrcodeUrl, "qr-code.png");

      const tryDownloadLink = await safePromise(() =>
        uploadAttachment({
          channelType: memberChannel.type,
          feature: "attachment",
          file: imageFile,
          orgId,
          channelId: memberChannel.id,
          pathParams: {
            channelId,
            memberId,
          },
          signal: useAbortControllerStore.getState().signal,
        }),
      );

      if (tryDownloadLink.isError) throw tryDownloadLink.error;

      const result = await safePromise(() =>
        cantataClient.unificationRecord.createUnificationRecord(
          {
            unificationCode: deepLinksResp.unificationCode,
            channelId: values.channelId,
            assignee:
              values.assignee === undefined
                ? null
                : values.assignee.type === "user"
                  ? { userId: values.assignee.userId, teamId: null }
                  : values.assignee.type === "team"
                    ? { teamId: values.assignee.teamId, userId: null }
                    : {
                        teamId: values.assignee.teamId,
                        userId: values.assignee.userId,
                      },
            tags: values.tags,
          },
          {
            params: {
              orgId,
              memberId,
            },
          },
        ),
      );

      if (result.isError) throw result.error;

      sendingMessagesController.createRequest(
        {
          orgId,
          memberId,
        },
        [
          {
            uuid: uuid(),
            type: "image",
            text: "",
            originUrl: tryDownloadLink.data.uploadResult.downloadUrl,
            previewUrl: tryDownloadLink.data.uploadResult.downloadUrl,
            ...(!(
              checkUploadAttachmentType(tryDownloadLink.data, "fb") ||
              checkUploadAttachmentType(tryDownloadLink.data, "ig") ||
              checkUploadAttachmentType(tryDownloadLink.data, "whatsapp")
            )
              ? null
              : {
                  attachmentId: tryDownloadLink.data.apiResult.attachmentId,
                }),
          },
          {
            type: "text" as const,
            text: [values.message, deepLink.qrcodeUrl].join(" "),
            uuid: uuid(),
          },
        ],
      );
    },
    onSuccess(_, variables) {
      setLastInvitationMessage(variables.message);
      reset();
    },
  });

  const onFinish = useHandler<ComponentProps<typeof EasyForm>["onFinish"]>(
    async function onFinish(values) {
      mutation.mutate(values);
    },
  );

  const defaultTags = tagsQueriesContext.useData().tags;

  const initialState = useMemo(() => {
    return {
      channelId: channels[0]?.id ?? Number.NaN,
      assignee: undefined,
      tags: defaultTags,
      message:
        lastInvitationMessage ||
        t("chat.redirectUnification.modal.invitation.message"),
    };
  }, [channels, defaultTags, lastInvitationMessage, t]);

  const { hasPermission } = useUserPermission();
  const hasManageOrgPermission = useMemo(
    () => hasPermission("manageOrg"),
    [hasPermission],
  );
  const hasEditMemberTagPermission = useMemo(
    () => hasPermission("editMemberTag"),
    [hasPermission],
  );

  return (
    <Modal
      open
      title={<Trans i18nKey="chat.redirectUnification.modal.title" />}
      cancelButtonProps={{ style: { display: "none" } }}
      okText={t("chat.redirectUnification.modal.send.label")}
      confirmLoading={mutation.isLoading}
      onOk={form.submit}
      onCancel={reset}
    >
      <div css={styles.groups}>
        <EasyForm
          form={form}
          layout="vertical"
          autoHeightFormItem
          requiredMark="optional"
          initialValues={initialState}
          disabled={mutation.isLoading}
          onFinish={onFinish}
        >
          <div css={styles.items}>
            <Trans i18nKey="chat.redirectUnification.modal.desc" />
            <EasyForm.Item
              required
              name="channelId"
              label={
                <Trans i18nKey="chat.redirectUnification.modal.channel.label" />
              }
            >
              <ChannelSelect
                channels={channels}
                value={channelId}
                onChange={setChannelId}
              />
            </EasyForm.Item>
            <EasyForm.Item
              name="assignee"
              label={
                <Trans i18nKey="chat.redirectUnification.modal.assignee.label" />
              }
            >
              <ReferrerSelector
                orgId={orgId}
                userId={orgQueriesData.me.id}
                hideExternalId
                allowClear
              />
            </EasyForm.Item>
            <EasyForm.Item
              name="tags"
              label={
                <Trans i18nKey="chat.redirectUnification.modal.tags.label" />
              }
              {...(!hasManageOrgPermission
                ? null
                : {
                    afterLabel: (
                      <ExternalLink
                        trailingIcon
                        href={compileToString("/settings/chat-settings")}
                      >
                        <Trans i18nKey="chat.redirectUnification.goToSetting" />
                      </ExternalLink>
                    ),
                  })}
            >
              <MultipleTagSelect disabled={!hasEditMemberTagPermission} />
            </EasyForm.Item>
            <EasyForm.Item
              name="message"
              label={
                <Trans i18nKey="chat.redirectUnification.modal.invitation.label" />
              }
            >
              <InputTextArea
                maxLength={300}
                placeholder={t(
                  "chat.redirectUnification.modal.invitation.message",
                )}
                autoSize={{
                  minRows: 3,
                  maxRows: 8,
                }}
              />
            </EasyForm.Item>
            <span css={styles.note}>
              <Trans i18nKey="chat.redirectUnification.modal.invitation.note" />
            </span>
          </div>
        </EasyForm>
      </div>
    </Modal>
  );
};

const RedirectUnificationInternal: React.FC = () => {
  const redirectUnification = useRedirectUnification();
  return (
    <>
      <NarrowIconButton
        crescendolab-selector="chat-redirect-unification-openButton"
        onClick={redirectUnification.openModal}
        icon={<MotifIcon un-i-motif="qrcode" />}
        tooltipProps={{
          title: <Trans i18nKey="chat.redirectUnification.tooltip.title" />,
        }}
      />
      {!redirectUnification.open ? null : <RedirectUnificationModal />}
    </>
  );
};

const useEnabledRedirectUnification = () => {
  const enabledRedirectUnification = useFeatureControl(
    "redirectUnificationAvailability",
  );
  const memberChannel = memberQueriesContext.useMemberChannel();
  /**
   * [slack](https://chatbotgang.slack.com/archives/C02R6ETJMEY/p1743489710715179?thread_ts=1743476405.630209&cid=C02R6ETJMEY)
   * Messenger and Instagram are not supported LINE deeplinks, disable them
   * for now.
   */
  return (
    enabledRedirectUnification &&
    (memberChannel.type === "line" ||
      memberChannel.type === "wccs" ||
      memberChannel.type === "whatsapp")
  );
};

const WrappedRedirectUnificationInternal: React.FC = () => {
  const enabledRedirectUnification = useEnabledRedirectUnification();
  if (!enabledRedirectUnification) return null;
  return (
    <ErrorBoundary>
      <Suspense fallback={<Modal.Loading open />}>
        <channelsQueriesContext.Provider>
          <tagsQueriesContext.Provider>
            <Provider>
              <RedirectUnificationInternal />
            </Provider>
          </tagsQueriesContext.Provider>
        </channelsQueriesContext.Provider>
      </Suspense>
    </ErrorBoundary>
  );
};

const RedirectUnification = Object.assign(WrappedRedirectUnificationInternal, {
  useRedirectUnification,
});

assignDisplayName(RedirectUnification, "RedirectUnification");

export { RedirectUnification };
