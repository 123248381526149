/* eslint-disable no-restricted-imports -- Copied from antd. */

/**
 * Copied from antd but replaced Buttons with Zeffiroso's Buttons and removed `DisabledContextProvider`.
 *
 * <https://github.com/ant-design/ant-design/blob/45eeee6/components/modal/shared.tsx>
 */

import CloseOutlined from "@ant-design/icons/CloseOutlined";
import { useLocale } from "antd/es/locale";
import NormalCancelBtn from "antd/es/modal/components/NormalCancelBtn";
import type { ModalContextProps } from "antd/es/modal/context";
import { ModalContextProvider } from "antd/es/modal/context";
import type { ModalProps } from "antd/es/modal/interface";
import { getConfirmLocale } from "antd/es/modal/locale";
import { useMemo } from "react";

import { NormalOkBtn } from "@/components/Modal/components/NormalOkButton";

export function renderCloseIcon(
  prefixCls: string,
  closeIcon?: React.ReactNode,
) {
  return (
    <span className={`${prefixCls}-close-x`}>
      {closeIcon || <CloseOutlined className={`${prefixCls}-close-icon`} />}
    </span>
  );
}

interface FooterProps {
  onOk?: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
  onCancel?: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
}

export const Footer: React.FC<
  FooterProps &
    Pick<
      ModalProps,
      | "footer"
      | "okText"
      | "okType"
      | "cancelText"
      | "confirmLoading"
      | "okButtonProps"
      | "cancelButtonProps"
    >
> = (props) => {
  const {
    okText,
    okType = "primary",
    cancelText,
    confirmLoading,
    onOk,
    onCancel,
    okButtonProps,
    cancelButtonProps,
    footer,
  } = props;

  const [locale] = useLocale("Modal", getConfirmLocale());

  // ================== Locale Text ==================
  const okTextLocale: React.ReactNode = okText || locale?.okText;
  const cancelTextLocale = cancelText || locale?.cancelText;

  // ================= Context Value =================
  const btnCtxValue: ModalContextProps = {
    confirmLoading,
    okButtonProps,
    cancelButtonProps,
    okTextLocale,
    cancelTextLocale,
    okType,
    onOk,
    onCancel,
  };

  const btnCtxValueMemo = useMemo(
    () => btnCtxValue,
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Copied from antd.
    [...Object.values(btnCtxValue)],
  );

  let footerNode: React.ReactNode;
  if (typeof footer === "function" || typeof footer === "undefined") {
    footerNode = (
      <>
        <NormalCancelBtn />
        <NormalOkBtn />
      </>
    );

    if (typeof footer === "function") {
      footerNode = footer(footerNode, {
        OkBtn: NormalOkBtn,
        CancelBtn: NormalCancelBtn,
      });
    }

    footerNode = (
      <ModalContextProvider value={btnCtxValueMemo}>
        {footerNode}
      </ModalContextProvider>
    );
  } else {
    footerNode = footer;
  }

  return footerNode;
};
