import {
  FileOutlined,
  PaperClipOutlined,
  SendOutlined,
} from "@ant-design/icons";
import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { createContext } from "@chatbotgang/etude/react/createContext";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { safePromise } from "@chatbotgang/etude/safe/safePromise";
import { random } from "@chatbotgang/etude/string/random";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import useSwitch from "@react-hook/switch";
import { theme } from "@zeffiroso/theme";
import { useUseAbortControllerStore } from "@zeffiroso/utils/react/abortControllerStore";
import type { ExtractDeepReadonlyObject } from "@zeffiroso/zodios/types";
import { Badge } from "antd";
import { isEqual, omit, uniq } from "lodash-es";
import { extname } from "pathe";
import type { ComponentProps, ElementRef, FC } from "react";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import type { DropzoneOptions } from "react-dropzone";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { NBSP } from "@/appConstant";
import type { cantataClient } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { Alert } from "@/components/Alert";
import { Flex } from "@/components/Box";
import { Button } from "@/components/Button";
import { NarrowIconButton } from "@/components/Button/NarrowIconButton";
import { SortableDndContext } from "@/components/dnd/SortableDndContext";
import { DisabledContext } from "@/components/Form/DisabledContext";
import { Image } from "@/components/Image";
import { BarLoading } from "@/components/Loading/BarLoading";
import { Modal } from "@/components/Modal";
import { MotifIcon } from "@/components/MotifIcon";
import type { DndTable } from "@/components/Table/DndTable";
import { Tooltip } from "@/components/Tooltip";
import { ga4Event } from "@/lib/ga4";
import { uuid } from "@/lib/uuid";
import { memberQueriesContext } from "@/queriesContext/memberQueriesContext";
import { orgQueriesContext } from "@/queriesContext/orgQueriesContext";
import type { FileInvalidatedError } from "@/resources/attachment/attachmentValidator";
import {
  validateAudio,
  validateFile,
  validateImage,
  validateVideo,
  validator,
} from "@/resources/attachment/attachmentValidator";
import { checkUploadAttachmentType } from "@/resources/attachment/checkUploadAttachmentType";
import { uploadAttachment } from "@/resources/attachment/uploadAttachment";
import { getFileExpiresDate } from "@/resources/datetime";
import { memberIdUtils } from "@/resources/member/memberIdUtils";
import { useSendingMessagesController } from "@/routes/Chat/ui/ChatPanel/sendingMessages";
import { getVideoThumbnail } from "@/shared/application/file/getVideoThumbnail";
import { logError } from "@/shared/application/logger/sentry";
import { cssFlexInheritAndFill } from "@/shared/emotion";
import { fakeT } from "@/shared/g11n/fakeT";

const HintWrapper = styled.div`
  color: ${theme.colors.neutral009};
  font-size: 0.75rem;
  line-height: 1.375rem;
`;

const HintHeader = styled.div`
  font-weight: 700;
`;

const HintList = styled.ul`
  ${cssFlexInheritAndFill};
  flex-direction: column;
  margin-bottom: 0;
  font-weight: 400;

  li {
    ${cssFlexInheritAndFill};
    flex-direction: row;

    ::before {
      width: 3px;
      height: 3px;
      border-radius: 9999px;
      margin: 9px 6px 0;
      background: ${theme.colors.neutral009};
      content: "";
    }
  }
`;

type AttachmentProps = {
  attachment: ReturnType<typeof useAttachment>;
};

const unknownFileUpload = Symbol("unknownFileUpload");

type BatchMessage = ExtractDeepReadonlyObject<
  Parameters<(typeof cantataClient)["message"]["batchCreate"]>[0]
>["messages"][number];

type FileStatus =
  | {
      status: "loading";
    }
  | ({
      status: "success";
    } & {
      message: BatchMessage;
    })
  | ({
      status: "error";
    } & (
      | {
          error: FileInvalidatedError;
        }
      | {
          error: typeof unknownFileUpload;
        }
    ));

type WithFile<T extends FileStatus> = T & {
  file: File;
};

type ErrorFileStatus = Extract<FileStatus, { status: "error" }>;

function getFileStatusFromFileStatusWithFile<T extends FileStatus>(
  fileStatusWithFile: WithFile<T>,
): T {
  return omit(fileStatusWithFile, "file") as unknown as T;
}

const ErrorModal: FC = () => {
  const { t } = useTranslation();
  const attachment = useInternalAttachment();
  return (
    <Modal
      open
      title={t("chat.failedToSendMessage.title")}
      onCancel={attachment.clearAllFiles}
      footer={[
        <Button key="cancel" onClick={attachment.clearAllFiles}>
          {t("common.cancel")}
        </Button>,
        ...(attachment.filesStatus.some(
          (fileStatus) => fileStatus.status === "success",
        )
          ? [
              <Button
                key="confirm"
                type="primary"
                onClick={attachment.clearErrors}
              >
                {t("common.confirm")}
              </Button>,
            ]
          : []),
      ]}
    >
      <Flex
        css={css`
          flex-direction: column;
          gap: 0.5em;
        `}
      >
        {attachment.errorsGroupedByType.map((error, index) => (
          <div key={index}>
            <Flex>
              {typeof error.type.error !== "object" ||
              !("code" in error.type.error)
                ? null
                : error.type.error.reactNode}
            </Flex>
            <Flex
              css={css`
                color: ${theme.colors.neutral005};
              `}
            >
              {error.errors.map((error) => error.file.name).join(", ")}
            </Flex>
          </div>
        ))}
      </Flex>
    </Modal>
  );
};

const getFileDraggableId = (() => {
  const fileDraggableIdWeakMap = new WeakMap<File, string>();
  function getFileDraggableId(file: File): string {
    let draggableId = fileDraggableIdWeakMap.get(file);
    if (!draggableId) {
      draggableId = `draggable-${random()}`;
      fileDraggableIdWeakMap.set(file, draggableId);
    }
    return draggableId;
  }
  return getFileDraggableId;
})();

const Item: FC<{
  fileStatus: WithFile<FileStatus & { status: "success" }>;
}> = ({ fileStatus }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    setActivatorNodeRef,
  } = useSortable({ id: getFileDraggableId(fileStatus.file) });

  const style: ComponentProps<"div">["style"] = useMemo(
    () => ({
      transform: CSS.Transform.toString(transform),
      transition,
    }),
    [transform, transition],
  );

  const attachment = useInternalAttachment();
  const successFilesStatus = attachment.successFilesStatus;

  return (
    <div ref={setNodeRef} style={style} {...attributes}>
      <Badge
        count={
          <NarrowIconButton
            iconSize="small"
            css={css`
              z-index: 1;
              border-radius: 50%;
              transform: translate(-0.25rem, 0.25rem);

              &:where(:hover, :focus, :active, :disabled) {
                background-color: ${theme.colors.blue001};
                color: ${theme.colors.blue006};
              }

              &:not(:hover, :focus, :active, :disabled) {
                background-color: ${theme.colors.neutral002};
                color: ${theme.colors.neutral006};
              }
            `}
            icon={<MotifIcon un-i-motif="bin" />}
            onClick={function remove() {
              attachment.removeFile(fileStatus.file);
            }}
          />
        }
      >
        <div
          css={css`
            position: relative;
            width: 200px;
            height: 200px;
            background: ${theme.colors.neutral001};
          `}
        >
          {fileStatus.message.type === "image" ? (
            <div
              css={css`
                width: 100%;
                height: 100%;

                & > * {
                  width: 100%;
                  height: 100%;
                }

                & img {
                  width: 100%;
                  min-width: 100%;
                  height: 100%;
                  min-height: 100%;
                  object-fit: cover;
                }
              `}
            >
              <Image src={fileStatus.message.originUrl} />
            </div>
          ) : fileStatus.message.type === "video" ? (
            <video
              src={fileStatus.message.originUrl}
              poster={fileStatus.message.previewUrl}
              height="100%"
              width="100%"
              controls
            />
          ) : fileStatus.message.type === "file" ? (
            <div
              css={css`
                display: flex;
                width: 100%;
                height: 100%;
                box-sizing: border-box;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: 1em;
                color: ${theme.colors.neutral006};
                font-size: 1.25rem;
                gap: 0.5em;
                text-align: center;
              `}
            >
              <FileOutlined
                css={css`
                  font-size: 3rem;
                `}
              />
              <Tooltip title={fileStatus.file.name} placement="bottom">
                <div
                  css={css`
                    display: -webkit-box;
                    overflow: hidden;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 3;
                  `}
                >
                  {fileStatus.file.name}
                </div>
              </Tooltip>
            </div>
          ) : (
            <Alert type="error" message="File not supported" />
          )}
          <div
            css={css([
              css`
                display: flex;
                align-items: center;
                justify-content: center;
              `,
              successFilesStatus.length > 1
                ? null
                : css`
                    visibility: hidden;
                  `,
            ])}
          >
            <div
              css={css`
                display: flex;
                width: 1.5rem;
                height: 1.5rem;
                align-items: center;
                justify-content: center;
                border-bottom-left-radius: 0.25rem;
                border-bottom-right-radius: 0.25rem;
                color: ${theme.colors.neutral006};
                cursor: grab;
                font-size: 1rem;
                transition: color 0.3s ease-in-out;

                &:where(:hover, :focus, :active) {
                  color: ${theme.colors.primary};
                }
              `}
              ref={setActivatorNodeRef}
              {...listeners}
            >
              <MotifIcon un-i-motif="arrow_horizontal" />
            </div>
          </div>
        </div>
      </Badge>
    </div>
  );
};

const EditModal: FC = () => {
  const { t } = useTranslation();
  const attachment = useInternalAttachment();
  const successFilesStatus = attachment.successFilesStatus;
  const onDragEnd = useHandler<ComponentProps<typeof DndTable>["onDragEnd"]>(
    function onDragEnd(result) {
      if (!result.over) return;
      const over = result.over;

      const sourceIndex = successFilesStatus.findIndex(
        (fileStatus) =>
          getFileDraggableId(fileStatus.file) === result.active.id,
      );
      if (sourceIndex < 0) return;
      const destinationIndex = successFilesStatus.findIndex(
        (fileStatus) => getFileDraggableId(fileStatus.file) === over.id,
      );
      if (destinationIndex < 0) return;
      if (sourceIndex === destinationIndex) return;
      attachment.moveFile(sourceIndex, destinationIndex);
    },
  );
  return (
    <Modal
      open
      onCancel={attachment.clearAllFiles}
      onOk={attachment.sendMessages}
      title={NBSP}
      okButtonProps={{
        icon: <SendOutlined />,
      }}
      okText={t("chat.send")}
      width="fit-content"
    >
      <Image.PreviewGroup>
        <SortableDndContext
          direction="horizontal"
          items={successFilesStatus.map((status) =>
            getFileDraggableId(status.file),
          )}
          onDragEnd={onDragEnd}
        >
          <div
            css={css`
              display: flex;
              overflow: auto visible;
              flex-direction: row;
              gap: 1rem;
            `}
          >
            {successFilesStatus.map((fileStatus) => (
              <Item
                key={getFileDraggableId(fileStatus.file)}
                fileStatus={fileStatus}
              />
            ))}
          </div>
        </SortableDndContext>
      </Image.PreviewGroup>
    </Modal>
  );
};

/**
 * @deprecated Deleted when the new editor is ready.
 */
const Attachment = (function declareAttachment() {
  const channelTypeToTooltipTitleI18nKeyMap: Record<
    CantataTypes["ChannelType"],
    string
  > = (() => {
    const t = fakeT;
    return {
      line: t("chat.uploadHint.desc.line"),
      fb: t("chat.uploadHint.desc.fb"),
      ig: t("chat.uploadHint.desc.ig"),
      wccs: t("chat.uploadHint.desc.wccs"),
      whatsapp: t("chat.uploadHint.desc.whatsapp"),
    };
  })();
  const Attachment: FC<AttachmentProps> = ({ attachment }) => {
    const { t } = useTranslation();
    const formDisabled = useContext(DisabledContext);
    const channel = memberQueriesContext.useMemberChannel();

    const title = useMemo(
      function computeTitle() {
        const content = t(channelTypeToTooltipTitleI18nKeyMap[channel.type]);
        const listItmes = content.split("\n").flatMap<string>((line) => {
          const trimmedLine = line.trim();
          if (!trimmedLine) return [];
          return [trimmedLine];
        });
        return (
          <HintWrapper>
            <HintHeader>{t("chat.uploadHint.title")}</HintHeader>
            <HintList>
              {listItmes.map((listItem, i) => (
                <li key={i}>{listItem}</li>
              ))}
            </HintList>
          </HintWrapper>
        );
      },
      [channel.type, t],
    );

    const inputProps = attachment.dropzone.getInputProps();

    const acceptAttribute = useMemo(
      () =>
        uniq([
          ...validator[channel.type].rules.image.extnames,
          ...validator[channel.type].rules.video.extnames,
          ...validator[channel.type].rules.audio.extnames,
          ...validator[channel.type].rules.other.extnames,
        ])
          .map((ext) => `.${ext}`)
          .join(","),
      [channel.type],
    );

    const inputRef = useRef<ElementRef<"input">>(null);

    const onClickButton = useHandler(function onClickButton() {
      inputRef.current?.click();
    });

    return (
      <internalAttachment.Provider value={attachment}>
        <input {...inputProps} accept={acceptAttribute} ref={inputRef} />
        <NarrowIconButton
          tooltipProps={{ title: formDisabled ? "" : title }}
          onClick={onClickButton}
          icon={<PaperClipOutlined />}
        />
        {attachment.isLoading ? (
          <Modal open footer={null} closable={false}>
            <div
              css={css`
                position: relative;
                min-height: 5em;
              `}
            >
              <BarLoading />
            </div>
          </Modal>
        ) : attachment.isError ? (
          <ErrorModal />
        ) : attachment.isSuccessful ? (
          <EditModal />
        ) : null}
      </internalAttachment.Provider>
    );
  };
  return Attachment;
})();

const dropzoneOptions: Omit<DropzoneOptions, "onDrop" | "disabled"> = {
  preventDropOnDocument: true,
};

/**
 * @deprecated Deleted when the new editor is ready.
 */
function useAttachment() {
  const { t } = useTranslation();
  const orgId = useActiveOrgIdStore((state) => state.value);
  const channel = memberQueriesContext.useMemberChannel();
  const orgLevelData = orgQueriesContext.useData();
  const userId = orgLevelData.me.id;
  const formDisabled = useContext(DisabledContext);
  const [filesStatus, setFilesStatus] = useState<Array<WithFile<FileStatus>>>(
    [],
  );
  const removeFile = useHandler((file: File) => {
    setFilesStatus((prev) =>
      prev.some((f) => f.file === file)
        ? prev.filter((f) => f.file !== file)
        : prev,
    );
  });
  const moveFile = useHandler(function moveFile(
    sourceIndex: number,
    destinationIndex: number,
  ) {
    setFilesStatus((prev) => {
      if (sourceIndex === destinationIndex) return prev;

      if (sourceIndex < 0 || sourceIndex >= prev.length) return prev;

      if (destinationIndex < 0 || destinationIndex >= prev.length) return prev;

      const newFilesStatus = [...prev];
      const [removed] = newFilesStatus.splice(sourceIndex, 1);
      if (!removed)
        throw new Error(
          inspectMessage`removed not found, ${{
            removed,
            sourceIndex,
            newFilesStatus,
          }}`,
        );
      newFilesStatus.splice(destinationIndex, 0, removed);
      return newFilesStatus;
    });
  });
  const clearAllFiles = useHandler(() => setFilesStatus([]));
  const clearErrors = useHandler(() =>
    setFilesStatus((prev) =>
      prev.some((f) => f.status === "error")
        ? prev.filter((f) => f.status !== "error")
        : prev,
    ),
  );
  const errorsGroupedByType = useMemo(() => {
    const filesStatusErrors = filesStatus.filter(
      (
        f,
      ): f is typeof f & {
        status: "error";
      } => f.status === "error",
    );
    const filesStatusErrorsGroupedByType =
      (function filesStatusErrorsGroupedByType() {
        const filesStatusErrorsGroupedByType: {
          type: ErrorFileStatus;
          errors: Array<WithFile<ErrorFileStatus>>;
        }[] = [];
        for (const filesStatusError of filesStatusErrors) {
          const errorType =
            getFileStatusFromFileStatusWithFile(filesStatusError);
          const matchedErrorType =
            filesStatusErrorsGroupedByType.find((f) =>
              isEqual(f.type, errorType),
            ) ||
            (function fallback() {
              const newErrorType = errorType;
              const newGroup: (typeof filesStatusErrorsGroupedByType)[number] =
                {
                  type: newErrorType,
                  errors: [],
                };
              filesStatusErrorsGroupedByType.push(newGroup);
              return newGroup;
            })();
          matchedErrorType.errors.push(filesStatusError);
        }
        return filesStatusErrorsGroupedByType;
      })();
    return filesStatusErrorsGroupedByType;
  }, [filesStatus]);
  const memberId = memberIdUtils.useGet();
  const sendingMessagesController = useSendingMessagesController();

  const [dropped, toggleDropped] = useSwitch(false);

  const onDrop = useHandler(function onDrop(fileList: File[] | FileList) {
    const selectedFiles = Array.from(fileList); // To Array;
    if (!selectedFiles || selectedFiles.length === 0) return;
    selectedFiles.forEach((file) => {
      ga4Event("attachmentUpload", {
        orgId,
        channelId: channel.id,
        orgUserId: userId,
        feature: "attachment",
        extname: extname(file.name),
        fileSize: file.size,
      });
    });
    const newFilesStatus = selectedFiles.map<WithFile<FileStatus>>((file) => {
      const validateError = validateFile(channel.type, file);
      if (validateError) {
        return {
          file,
          status: "error",
          error: validateError,
        };
      }
      return {
        file,
        status: "loading",
      };
    });
    setFilesStatus((prev) => [...prev, ...newFilesStatus]);
    toggleDropped.on();
  });

  const useAbortControllerStore = useUseAbortControllerStore();
  useEffect(
    function abortWhenUnmount() {
      return function abort() {
        useAbortControllerStore.getState().abort();
      };
    },
    [useAbortControllerStore],
  );

  const uploadFiles = useHandler(async function uploadFiles() {
    const selectedFiles = filesStatus
      .filter((f) => f.status === "loading")
      .map((f) => f.file);
    const result = await Promise.allSettled(
      Array.from(selectedFiles).map(async (selectedFile) => {
        const { name: fileName } = selectedFile;
        const isImage = !validateImage(channel.type, selectedFile);
        const isVideo = !isImage && !validateVideo(channel.type, selectedFile);
        const isAudio =
          !isImage && !isVideo && !validateAudio(channel.type, selectedFile);

        const tryDownloadLink = await safePromise(() =>
          uploadAttachment({
            channelType: channel.type,
            feature: "attachment",
            file: selectedFile,
            orgId,
            channelId: channel.id,
            pathParams: {
              channelId: channel.id,
              memberId,
            },
            signal: useAbortControllerStore.getState().signal,
          }),
        );
        function updateFileStatus(status: FileStatus) {
          setFilesStatus((prev) =>
            prev.some((f) => f.file === selectedFile)
              ? prev.map((f) =>
                  f.file === selectedFile
                    ? {
                        file: f.file,
                        ...status,
                      }
                    : f,
                )
              : prev,
          );
        }
        function setError() {
          updateFileStatus({
            status: "error",
            error: unknownFileUpload,
          });
        }
        if (tryDownloadLink.isError) {
          setError();
          return;
        }
        if (isImage) {
          updateFileStatus({
            status: "success",
            message: {
              uuid: uuid(),
              type: "image",
              text: fileName,
              originUrl: tryDownloadLink.data.uploadResult.downloadUrl,
              previewUrl: tryDownloadLink.data.uploadResult.downloadUrl,
              ...(!(
                checkUploadAttachmentType(tryDownloadLink.data, "fb") ||
                checkUploadAttachmentType(tryDownloadLink.data, "ig") ||
                checkUploadAttachmentType(tryDownloadLink.data, "whatsapp")
              )
                ? null
                : {
                    attachmentId: tryDownloadLink.data.apiResult.attachmentId,
                  }),
            },
          });
          return;
        }
        if (isVideo) {
          const tryGetThumbnail = await safePromise(
            async function getThumbnail() {
              const thumbnail = await getVideoThumbnail(selectedFile);
              const videoThumbnail = await uploadAttachment({
                channelType: channel.type,
                feature: "attachment",
                file: thumbnail,
                orgId,
                channelId: channel.id,
                pathParams: {
                  channelId: channel.id,
                  memberId,
                },
                signal: useAbortControllerStore.getState().signal,
              });
              return videoThumbnail;
            },
          );
          if (tryGetThumbnail.isError) {
            setError();
            return;
          }
          updateFileStatus({
            status: "success",
            message: {
              uuid: uuid(),
              type: "video",
              text: fileName,
              originUrl: tryDownloadLink.data.uploadResult.downloadUrl,
              previewUrl: tryDownloadLink.data.uploadResult.downloadUrl,
              ...(!(
                checkUploadAttachmentType(tryDownloadLink.data, "fb") ||
                checkUploadAttachmentType(tryDownloadLink.data, "ig") ||
                checkUploadAttachmentType(tryDownloadLink.data, "whatsapp")
              )
                ? null
                : {
                    attachmentId: tryDownloadLink.data.apiResult.attachmentId,
                  }),
            },
          });
          return;
        }
        if (isAudio) {
          // Do nothing.
          // Send as a file for now.
        }
        updateFileStatus({
          status: "success",
          message: {
            uuid: uuid(),
            type: "file",
            text: t("chat.oaFileMessage", { oaName: channel.name }),
            previewUrl: null,
            originUrl: tryDownloadLink.data.uploadResult.downloadUrl,
            metadata: {
              filename: fileName,
              filesizePrefix: t("chat.fileSize.prefix"),
              filesizeBytes: selectedFile.size,
              expirationDatePrefix: t("chat.expireAt.prefix"),
              downloadExpirationDate: getFileExpiresDate(new Date(), 365),
            },
            ...(!(
              checkUploadAttachmentType(tryDownloadLink.data, "fb") ||
              checkUploadAttachmentType(tryDownloadLink.data, "ig") ||
              checkUploadAttachmentType(tryDownloadLink.data, "whatsapp")
            )
              ? null
              : {
                  attachmentId: tryDownloadLink.data.apiResult.attachmentId,
                }),
          },
        });
      }),
    );
    result.forEach((r) => {
      /**
       * Log if some tasks are failed unexpectedly.
       */
      if (r.status === "rejected") logError(r.reason);
    });
    /**
     * To prevent if some tasks are failed.
     */
    (function resolveAllLoadingTasks() {
      setFilesStatus((prev) =>
        prev.some((f) => f.status === "loading")
          ? prev.map((f) =>
              f.status === "loading"
                ? {
                    ...f,
                    status: "error",
                    error: unknownFileUpload,
                  }
                : f,
            )
          : prev,
      );
    })();
  });

  const sendMessages = useHandler(async function sendMessages() {
    if (!isSuccessful) return;

    const files = filesStatus.filter(
      function filterSuccessfulFiles(f): f is typeof f & {
        status: "success";
      } {
        return f.status === "success";
      },
    );
    sendingMessagesController.createRequest(
      {
        orgId,
        memberId,
      },
      files.map((f) => f.message),
    );
    clearAllFiles();
  });

  useEffect(
    function uploadFilesIfDropped() {
      if (!dropped) return;
      toggleDropped.off();
      uploadFiles();
    },
    [dropped, toggleDropped, uploadFiles],
  );

  const mergedDropzoneOptions = useMemo(
    function mergedDropzoneOptions() {
      return { ...dropzoneOptions, onDrop, disabled: formDisabled };
    },
    [formDisabled, onDrop],
  );
  const dropzone = useDropzone(mergedDropzoneOptions);

  const isLoading = useMemo(
    () => filesStatus.some((f) => f.status === "loading"),
    [filesStatus],
  );
  const isError = useMemo(
    () => !isLoading && filesStatus.some((f) => f.status === "error"),
    [filesStatus, isLoading],
  );
  const isEmpty = useMemo(() => filesStatus.length === 0, [filesStatus]);
  const isSuccessful = useMemo(
    () =>
      !isLoading &&
      !isError &&
      !isEmpty &&
      filesStatus.every((f) => f.status === "success"),
    [filesStatus, isEmpty, isError, isLoading],
  );

  const successFilesStatus = useMemo(
    function memoFilesStatus() {
      return filesStatus.filter(
        function successFileStatus(
          fileStatus,
        ): fileStatus is WithFile<FileStatus & { status: "success" }> {
          return fileStatus.status === "success";
        },
      );
    },
    [filesStatus],
  );

  const ret = useMemo(
    function ret() {
      return {
        dropzone,
        filesStatus,
        successFilesStatus,
        removeFile,
        moveFile,
        clearAllFiles,
        clearErrors,
        isLoading,
        isError,
        isSuccessful,
        uploadFiles,
        sendMessages,
        errorsGroupedByType,
        onDrop,
      };
    },
    [
      dropzone,
      filesStatus,
      successFilesStatus,
      removeFile,
      moveFile,
      clearAllFiles,
      clearErrors,
      isLoading,
      isError,
      isSuccessful,
      uploadFiles,
      sendMessages,
      errorsGroupedByType,
      onDrop,
    ],
  );

  return ret;
}

const internalAttachment = createContext<ReturnType<typeof useAttachment>>();
const useInternalAttachment = internalAttachment.useContext;

/**
 * @deprecated Deleted when the new editor is ready.
 */
const Exp = Object.assign(Attachment, {
  useAttachment,
});

export { Exp as Attachment };
