import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { define } from "@chatbotgang/etude/util/define";
import useChange from "@react-hook/change";
import { addDays, hoursToMilliseconds } from "date-fns";
import { flow } from "lodash-es";
import { useEffect, useMemo, useState } from "react";

import type { CantataTypes } from "@/cantata/types";
import { memberTypeToChannelTypeMap } from "@/resources/member/typeToChannelTypeMap";

const DAY = hoursToMilliseconds(24);

/**
 * The state of the chat.
 * This is used to determine whether the chat can send a message or not.
 * Please order the state by the time it will be updated.
 */
const statesRecord = define<
  Record<
    CantataTypes["ChannelType"],
    | Array<{
        state: string;
        delay: number;
      }>
    | Array<{
        state: string;
        status: CantataTypes["Member"]["status"];
      }>
  >
>()({
  line: [
    {
      state: "CannotSendAnyContent",
      status: "inactive",
    },
  ],
  /**
   * https://developers.facebook.com/docs/messenger-platform/policy/policy-overview#standard_messaging
   */
  fb: [
    {
      state: "CannotSendPromotionalContent",
      delay: 1 * DAY,
    },
    {
      state: "AlmostCannotSendAnyContent",
      delay: 5 * DAY,
    },
    {
      state: "CannotSendAnyContent",
      delay: 7 * DAY,
    },
  ],
  ig: [
    {
      state: "CannotSendPromotionalContent",
      delay: 1 * DAY,
    },
    {
      state: "AlmostCannotSendAnyContent",
      delay: 5 * DAY,
    },
    {
      state: "CannotSendAnyContent",
      delay: 7 * DAY,
    },
  ],
  wccs: [
    {
      state: "CannotSendAnyContent",
      delay: 30 * DAY,
    },
  ],
  /**
   * https://developers.facebook.com/docs/whatsapp/messaging-limits/
   */
  whatsapp: [
    {
      state: "CannotSendAnyContent",
      delay: 1 * DAY,
    },
  ],
});

type StateRule = (typeof statesRecord)[keyof typeof statesRecord][number];

/**
 * Represents the current state of the chat.
 * If `undefined`, it indicates the regular state.
 */
type State = undefined | StateRule["state"];

/**
 * The hook to get the current state of the chat.
 * If `undefined`, it indicates the regular state.
 *
 * @see {@link State}
 */
function useChatState<
  Member extends Pick<
    CantataTypes["Member"],
    "lastMemberMessageSend" | "type" | "status"
  >,
>(member: Member): State {
  const [
    /**
     * The re-render time of the component.
     * Update this to change to state
     */
    now,
    setNow,
  ] = useState(() => new Date());

  /**
   * Update the `now` state to update the state
   */
  const updateNow = useHandler(function updateNow() {
    setNow(new Date());
  });

  /**
   * Always update the `now` state when the last message send time changes to
   * re-calculate the state.
   */
  useChange(member.lastMemberMessageSend, updateNow);

  const fromLastMemberMessageSend = useMemo(
    function computeFromLastMemberMessageSend() {
      if (!member.lastMemberMessageSend) return undefined;
      return now.getTime() - member.lastMemberMessageSend.getTime();
    },
    [member.lastMemberMessageSend, now],
  );

  const states = useMemo(
    function computeStates() {
      return flow(
        () => member.type,
        (type) => memberTypeToChannelTypeMap[type],
        (channelType) => statesRecord[channelType],
        (states) =>
          states.every((state) => "delay" in state)
            ? states.toSorted((a, b) => a.delay - b.delay)
            : states.filter((state) => state.status === member.status),
      )();
    },
    [member.status, member.type],
  );

  const { currentStateRule, nextStateRule } = useMemo<{
    currentStateRule?: StateRule;
    nextStateRule?: StateRule;
  }>(
    function compute() {
      const lastStateRule: undefined | StateRule = states[states.length - 1];
      /**
       * If the member has never sent a message, it equals to the most restrictive state.
       */
      if (fromLastMemberMessageSend === undefined) {
        return {
          currentStateRule: lastStateRule,
          nextStateRule: undefined,
        };
      }

      const currentStateRule: undefined | StateRule = (() => {
        const possibleStates = states.every((state) => "delay" in state)
          ? states.filter((state) => {
              // No further state
              return state.delay <= fromLastMemberMessageSend;
            })
          : states;
        // The most restrictive state
        return possibleStates[possibleStates.length - 1];
      })();

      const nextStateRule: undefined | StateRule =
        currentStateRule === lastStateRule
          ? undefined
          : (states.every((state) => "delay" in state)
              ? states.filter((state) => {
                  // No further state
                  return state.delay > fromLastMemberMessageSend;
                })
              : states)[0];

      return {
        currentStateRule,
        nextStateRule,
      };
    },
    [fromLastMemberMessageSend, states],
  );

  useEffect(
    function updateNowInterval() {
      if (!nextStateRule) return;
      if (!fromLastMemberMessageSend) return;
      if (!("delay" in nextStateRule)) return;
      const timeoutDelay = nextStateRule.delay - fromLastMemberMessageSend;
      const timeout = setTimeout(updateNow, timeoutDelay);
      return function cleanUp() {
        clearTimeout(timeout);
      };
    },
    [fromLastMemberMessageSend, nextStateRule, updateNow],
  );

  return currentStateRule?.state;
}

function getMessageTimeWindowEndAt(
  lastMemberMessageSendAt: Date,
  dayAmount: number,
) {
  return addDays(lastMemberMessageSendAt, dayAmount);
}

export { getMessageTimeWindowEndAt, useChatState };
