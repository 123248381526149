import { memo } from "@zeffiroso/utils/react/memo";
import type { ReactNode } from "react";

import type { CantataTypes } from "@/cantata/types";
import { ItemWithIcon } from "@/components/Menu/ItemWithIcon";
import { Select } from "@/components/Select";
import { Dot } from "@/resources/user/onDutyStatus/Dot";
import { Name } from "@/resources/user/onDutyStatus/Name";

type ValueType = CantataTypes["UserOnDutyStatus"];

type OptionType = {
  key: ValueType;
  value: ValueType;
  label: ReactNode;
};

type UserOnDutyStatusSelectorProps = Omit<
  Select.Props<ValueType, OptionType>,
  "options"
>;

function getStatusOption(status: CantataTypes["UserOnDutyStatus"]): OptionType {
  return {
    key: status,
    value: status,
    label: (
      <ItemWithIcon startIcon={<Dot status={status} size={12} />}>
        <Name status={status} />
      </ItemWithIcon>
    ),
  };
}

const UserOnDutyStatusSelector = memo(function UserOnDutyStatusSelector(
  props: UserOnDutyStatusSelectorProps,
) {
  return (
    <Select<ValueType, OptionType>
      {...props}
      options={[
        getStatusOption("online"),
        getStatusOption("offline"),
        getStatusOption("away"),
      ]}
    />
  );
});

export { UserOnDutyStatusSelector };
export type { UserOnDutyStatusSelectorProps };
