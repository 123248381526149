import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { css } from "@emotion/react";
import type { DistributiveOmit, Overwrite } from "@mui/types";
import { get, omit } from "lodash-es";
import { forwardRef, useMemo } from "react";

import logoSrc from "@/components/CrescendoLabPageCornerLogo/logo.svg";
import { defineStyles } from "@/shared/emotion";

const defaultComponent =
  "img" satisfies React.ElementType<CrescendoLabPageCornerLogo.BaseElementProps>;

const styles = defineStyles({
  root: css({
    height: "56px",
    maxWidth: "100%",
  }),
});

namespace CrescendoLabPageCornerLogo {
  export interface BaseElementProps
    extends Pick<React.ComponentProps<"img">, "className" | "src"> {}
  export type DefaultRootElement = typeof defaultComponent;
  export interface OwnProps {
    component?: React.ElementType<BaseElementProps>;
  }
  export type Props<
    TRootElement extends
      React.ElementType<BaseElementProps> = DefaultRootElement,
  > = Omit<Overwrite<ComponentProps<TRootElement>, OwnProps>, "src">;
  export interface Type {
    <
      TRootElement extends
        React.ElementType<BaseElementProps> = DefaultRootElement,
    >(
      props: Overwrite<Props<TRootElement>, { component: TRootElement }>,
      ref?: React.ForwardedRef<React.ElementRef<TRootElement>>,
    ): React.ReactNode;
    (
      props: DistributiveOmit<Props, "component">,
      ref?: React.ForwardedRef<React.ElementRef<DefaultRootElement>>,
    ): React.ReactNode;
  }
}

const CrescendoLabPageCornerLogoInternal: CrescendoLabPageCornerLogo.Type =
  forwardRef(function CrescendoLabPageCornerLogoInternal(
    props: Parameters<CrescendoLabPageCornerLogo.Type>[0],
    ref: Parameters<CrescendoLabPageCornerLogo.Type>[1],
  ) {
    const Component = get(props, "component", defaultComponent);
    const restProps = useMemo(() => omit(props, "component"), [props]);
    return (
      <Component
        css={styles.root}
        alt="Crescendo Lab"
        {...restProps}
        src={logoSrc}
        ref={ref}
      />
    );
  });

/**
 * Description of the component.
 */
const CrescendoLabPageCornerLogo = Object.assign(
  CrescendoLabPageCornerLogoInternal,
  {
    defaultComponent,
    styles,
  },
);

export { CrescendoLabPageCornerLogo };
