import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { css } from "@emotion/react";
import type { Overwrite } from "@mui/types";
import { theme } from "@zeffiroso/theme";
import type { ElementRef } from "react";

import { Badge } from "@/components/Badge";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  PreviewBlock: css({
    alignSelf: "stretch",
    padding: 12,
    borderRadius: theme.shape.borderRadiusLarge,
    background: theme.colors.neutral001,
    color: theme.colors.neutral009,
    fontSize: "0.875rem",
    width: "100%",
    maxWidth: 488,
  }),
  tag: css({
    borderRadius: theme.shape.borderRadius,
  }),
});

namespace PreviewBlock {
  export type Ref = ElementRef<"div">;
  export interface OwnProps {
    // props here
  }
  export type Props = Overwrite<ComponentProps<"div">, OwnProps>;
}

const PreviewBlockInternal = forwardRef<PreviewBlock.Ref, PreviewBlock.Props>(
  function PreviewBlockInternal(props, ref) {
    return <div {...props} css={styles.PreviewBlock} ref={ref} />;
  },
);

const Tag = forwardRef<Badge.Ref, Badge.Props>((props, ref) => (
  <Badge css={styles.tag} ref={ref} variant="primary" {...props} />
));

/**
 * A block that displays a preview of sending message.
 */
const PreviewBlock = Object.assign(PreviewBlockInternal, {
  styles,
  Tag,
});

assignDisplayName(PreviewBlock, "PreviewBlock");

export { PreviewBlock };
