import { LoadingOutlined } from "@ant-design/icons";
import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import type { Overwrite } from "@mui/types";
import type { ElementRef } from "react";

import { Trans } from "@/app/i18n/Trans";
import type { CantataTypes } from "@/cantata/types";
import { ExternalLink } from "@/components/ExternalLink";
import { usePlatformLink } from "@/resources/platform/platformLink";

namespace GoToAdminCenter {
  type ExternalLinkType = typeof ExternalLink;

  export type Ref = ElementRef<ExternalLinkType>;
  export interface OwnProps extends Pick<CantataTypes["Channel"], "uuid"> {
    type: Extract<CantataTypes["Channel"]["type"], "wccs" | "whatsapp">;
  }
  export type Props = Overwrite<
    Omit<ComponentProps<ExternalLinkType>, "href">,
    OwnProps
  >;
}

/**
 * Navigates to the Admin Center’s channel edit page for WhatsApp and WCCS,
 * based on the specified channel type and UUID.
 */
const GoToAdminCenter = forwardRef<GoToAdminCenter.Ref, GoToAdminCenter.Props>(
  function GoToAdminCenter({ type, uuid, ...props }, ref) {
    const platformLink = usePlatformLink({
      path: "channels/:type/:uuid",
      params: {
        type,
        uuid,
      },
    });

    return (
      <ExternalLink
        href={platformLink.href}
        onClick={platformLink.handleClick}
        ref={ref}
        trailingIcon={platformLink.isLoading ? <LoadingOutlined /> : true}
        {...props}
      >
        <Trans i18nKey="organization.channels.goToAdminCenter.label" />
      </ExternalLink>
    );
  },
);

export { GoToAdminCenter };
