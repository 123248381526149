import type { FC } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { BASE_URL } from "@/env";
import { routes } from "@/routes";

const router = createBrowserRouter(routes, {
  basename: BASE_URL,
});

const Router: FC = () => {
  return <RouterProvider router={router} />;
};

export { Router };
