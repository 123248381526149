import { UserOutlined } from "@ant-design/icons";
import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { createContext } from "@chatbotgang/etude/react/createContext";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import { useMutation } from "@tanstack/react-query";
import { ErrorSchema } from "@zeffiroso/cantata/models";
import { useUseAbortControllerStore } from "@zeffiroso/utils/react/abortControllerStore";
import { getDomainFromEmail } from "@zeffiroso/utils/string/getDomainFromEmail";
import { Button } from "antd";
import { AxiosError } from "axios";
import type { AuthProvider } from "firebase/auth";
import type { FC, ReactNode } from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";

import {
  authSdk,
  useAllowPasswordAuthQuery,
  useFirstSsoProviderQuery,
  useSignInUtils,
} from "@/app/auth";
import { isSameEmail } from "@/app/auth/isSameEmail";
import { Trans } from "@/app/i18n/Trans";
import { Alert } from "@/components/Alert";
import { Form } from "@/components/Form";
import { Input } from "@/components/Input";
import { BarLoadingBlock } from "@/components/Loading/BarLoading";
import { useTwoFaFormUtils } from "@/resources/auth/twoFa/useTwoFaFormUtils";
import { LoginForm } from "@/routes/Auth/components/Form";
import { LoginSlogan } from "@/routes/Auth/components/LoginSlogan";
import { SignInConflict } from "@/routes/Auth/components/SignInConflict";
import {
  Container,
  LeftWrapper,
  MainTitle,
  RightWrapper,
} from "@/routes/Auth/components/Styled";
import { useAuthStatus } from "@/shared/application/authenticate";
import { apiErrorNameTransUtilsMap } from "@/shared/domains/error";
import { defineStyles } from "@/shared/emotion";
import { useLastUserEmailStore } from "@/shared/utils/createZustandStorageStore";

const signInUtilsContext = createContext<ReturnType<typeof useSignInUtils>>();

const TwoFa = (() => {
  const styles = defineStyles({
    content: css({
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
      gap: 24,
      "& p": {
        marginBottom: 0,
      },
      "& .ant-form-item": {
        marginBottom: 0,
      },
    }),
  });
  const TwoFa: FC = () => {
    const { t } = useTranslation();
    const signInUtils = signInUtilsContext.useContext();
    const twoFaFormUtils = useTwoFaFormUtils({
      signInUtils,
    });

    const verifyTwoFaMutation = signInUtils.twoFa.mutations.verifyTwoFaMutation;

    return (
      <twoFaFormUtils.EasyForm {...twoFaFormUtils.easyFormProps}>
        <div css={styles.content}>
          {twoFaFormUtils.content}
          <div
            style={{
              display: "flex",
              flexDirection: "inherit",
              alignItems: "inherit",
              gap: 16,
            }}
          >
            {twoFaFormUtils.expired ? null : (
              <Button
                type="primary"
                htmlType="submit"
                loading={verifyTwoFaMutation.isLoading}
              >
                {t("page.login.2fa.actions.verify")}
              </Button>
            )}
            {twoFaFormUtils.resendButton}
          </div>
        </div>
      </twoFaFormUtils.EasyForm>
    );
  };
  return TwoFa;
})();

const RedirectIfSignedIn: FC<{
  email: string;
}> = ({ email }) => {
  const authStatus = useAuthStatus();
  const lastEmail = useLastUserEmailStore((state) => state.value);

  if (authStatus.isSignedIn && lastEmail && lastEmail === email) {
    return <Navigate to="/" replace />;
  }
  return null;
};

const Activate: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const emailToActivate = searchParams.get("email") ?? "";
  const domain = getDomainFromEmail(emailToActivate);
  const allowPasswordAuthQuery = useAllowPasswordAuthQuery({
    domain,
  });
  const providerQuery = useFirstSsoProviderQuery({
    domain,
  });
  const [errorMessage, setErrorMessage] = useState<ReactNode>(null);
  const clearErrorMessage = useHandler(function clearErrorMessage() {
    setErrorMessage(null);
  });

  const signInUtils = useSignInUtils({
    onSuccess() {
      navigate("/", {
        replace: true,
      });
    },
    onError(error) {
      if (!(error instanceof AxiosError)) return;
      const statusCode = error.response?.status;
      if (!statusCode) return;
      if (statusCode < 400 || statusCode >= 500) return;
      const parsedErrorResult = ErrorSchema.safeParse(error.response?.data);

      setErrorMessage(
        parsedErrorResult.success &&
          parsedErrorResult.data.name ===
            "PARAMETER_EMAIL_OR_PASSWORD_INVALID" ? (
          <Trans i18nKey="login.wrongEmailOrPassword" />
        ) : (
          inspectMessage`Error: ${error}`
        ),
      );
    },
  });

  const twoFaState = signInUtils.twoFa.utils.useState();

  const signInMutation = signInUtils.mutation;

  const signInWithPassword = useHandler(function signInWithPassword({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) {
    clearErrorMessage();
    signInMutation.mutate({ email, password });
  });

  const useAbortControllerStore = useUseAbortControllerStore();
  useEffect(
    function abortWhenUnmount() {
      return function cleanup() {
        useAbortControllerStore.getState().abort();
      };
    },
    [useAbortControllerStore],
  );
  const firebaseSignInMutation = useMutation({
    mutationFn: async (params: { domain: string; provider: AuthProvider }) => {
      const signal = useAbortControllerStore.getState().signal;
      const firebaseSignInResult = await authSdk.signInPopup({
        provider: params.provider,
        signal,
      });
      signal.throwIfAborted();
      if (!firebaseSignInResult.idToken) throw new Error("No access token");
      if (
        !firebaseSignInResult.email ||
        !isSameEmail(firebaseSignInResult.email, emailToActivate)
      ) {
        setErrorMessage(
          t("tokenExpired.error.mailConflict.content", {
            email: firebaseSignInResult.email,
          }),
        );
        return;
      }
      signInMutation.mutate({
        domain,
        idToken: firebaseSignInResult.idToken,
        uid: firebaseSignInResult.uid,
        provider: params.provider,
      });
    },
  });
  const providerMutation = useMutation({
    mutationFn: async () => {
      const signal = useAbortControllerStore.getState().signal;
      signal.throwIfAborted();
      const provider = providerQuery.data;
      if (!provider) {
        setErrorMessage(
          t("login.signInWithDomain.error.providerNotFound.content"),
        );
        return;
      }
      firebaseSignInMutation.mutate({ provider, domain });
    },
  });
  const loginWithDomain = useHandler(function loginWithDomain() {
    clearErrorMessage();
    providerMutation.mutate();
  });

  const isLoading =
    allowPasswordAuthQuery.isLoading ||
    providerQuery.isLoading ||
    providerMutation.isLoading ||
    signInMutation.isLoading;

  return (
    <signInUtilsContext.Provider value={signInUtils}>
      <Container>
        <SignInConflict email={emailToActivate} />
        <RedirectIfSignedIn email={emailToActivate} />
        <LeftWrapper>
          <LoginSlogan />
        </LeftWrapper>
        <RightWrapper>
          {!emailToActivate || !domain ? (
            <MainTitle>
              <MainTitle.Logo />
              <Trans i18nKey="login.title.activate" />
              <Alert
                type="error"
                message={apiErrorNameTransUtilsMap.PARAMETER_INVALID.node}
              />
            </MainTitle>
          ) : (
            <>
              <MainTitle>
                <MainTitle.Logo />
                <Trans i18nKey="login.title.activate" />
                {!emailToActivate ? (
                  <Alert
                    type="error"
                    message={apiErrorNameTransUtilsMap.PARAMETER_INVALID.node}
                  />
                ) : (
                  <Alert message={t("login.maacUserLogin")} />
                )}
              </MainTitle>
              {providerQuery.isLoading ? (
                <BarLoadingBlock />
              ) : providerQuery.isError ? (
                <Alert
                  type="error"
                  message={inspectMessage`providerQuery.error: ${providerQuery.error}`}
                />
              ) : allowPasswordAuthQuery.isLoading ? (
                <BarLoadingBlock />
              ) : allowPasswordAuthQuery.isError ? (
                <Alert
                  type="error"
                  message={inspectMessage`allowPasswordAuthQuery.error: ${allowPasswordAuthQuery.error}`}
                />
              ) : !allowPasswordAuthQuery.data && providerQuery.data ? (
                <Form
                  layout="vertical"
                  requiredMark={false}
                  onFinish={loginWithDomain}
                  disabled={isLoading}
                >
                  {!errorMessage ? null : (
                    <Alert type="error" message={errorMessage} />
                  )}
                  <Form.Item
                    label={<Trans i18nKey="glossary.email" />}
                    getValueProps={() => ({ value: emailToActivate })}
                  >
                    <Input
                      prefix={
                        <UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                      }
                      type="email"
                      value={emailToActivate}
                      disabled
                    />
                  </Form.Item>
                  <Button type="primary" htmlType="submit" block>
                    {t("common.enable")}
                  </Button>
                </Form>
              ) : twoFaState ? (
                <TwoFa />
              ) : (
                <LoginForm
                  errorMessage={errorMessage}
                  loading={isLoading}
                  actionText={t("common.enable")}
                  onSubmit={signInWithPassword}
                  formValues={{ email: emailToActivate }}
                  disabledFields={["email"]}
                  shouldShowResetPasswordLink
                />
              )}
            </>
          )}
        </RightWrapper>
      </Container>
    </signInUtilsContext.Provider>
  );
};

export { Activate };
