import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { ClassNames, css } from "@emotion/react";
import useSwitch from "@react-hook/switch";
import { theme } from "@zeffiroso/theme";
import type { OverrideWith } from "@zeffiroso/utils/type/object/OverrideWith";
import { Suspense } from "react";
import { useTranslation } from "react-i18next";

import { Trans } from "@/app/i18n/Trans";
import { NarrowIconButton } from "@/components/Button/NarrowIconButton";
import { ErrorBoundary } from "@/components/ErrorBoundary";
import { MotifIcon } from "@/components/MotifIcon";
import { Popover } from "@/components/Popover";
import { Empty } from "@/layout/base/AuthenticatedAppOuter/components/NotificationPanel/Empty";
import {
  PopoverSwitchProvider,
  usePopoverSwitch,
} from "@/layout/base/AuthenticatedAppOuter/components/NotificationPanel/usePopoverSwitch";
import { defineStyles } from "@/shared/emotion";

const trigger: Popover.Props["trigger"] = ["click"];

const styles = defineStyles({
  content: css({
    minWidth: "min(300px, 100%)",
    width: 300,
    maxWidth: "min(300px, 100%)",
    maxHeight: "400px",
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
  }),
  title: css({
    padding: "16px 16px 8px 16px",
    textAlign: "center",
    color: theme.colors.neutral010,
    fontWeight: 700,
    fontSize: "1rem",
  }),
});

namespace NotificationPanel {
  export interface OwnProps {
    popoverProps?: Omit<
      Popover.Props,
      "content" | "children" | "trigger" | "open" | "onOpenChange"
    >;
  }
  export type Props = OverrideWith<NarrowIconButton.Props, OwnProps>;
}

/**
 * [Figma](https://www.figma.com/design/mx37wzAD7RmXI0FPiQfxST/Inbox?node-id=4776-24343)
 */
const NotificationPanelInternal: React.FC<NotificationPanel.Props> = ({
  popoverProps,
  ...buttonProps
}) => {
  const { t } = useTranslation();
  const [popoverOpened, togglePopover] = usePopoverSwitch();
  const [tooltipOpened, toggleTooltip] = useSwitch(false);
  const onOpenChange = useHandler<Popover.Props["onOpenChange"]>((open) => {
    if (open) {
      togglePopover.on();
      toggleTooltip.off();
      return;
    }
    togglePopover.off();
  });
  return (
    <ClassNames>
      {({ css }) => (
        <Popover
          rootClassName={css({
            "& .ant-popover-inner": {
              borderRadius: 8,
              padding: 0,
            },
          })}
          {...popoverProps}
          content={
            <div css={styles.content}>
              <div css={styles.title}>
                <Trans i18nKey="layout.nav.notifications.title" />
              </div>
              <Empty />
            </div>
          }
          placement="right"
          trigger={trigger}
          open={popoverOpened}
          onOpenChange={onOpenChange}
        >
          <NarrowIconButton
            {...buttonProps}
            tooltipProps={{
              open: tooltipOpened,
              onOpenChange: toggleTooltip,
              title: t("layout.nav.notifications.tooltip"),
              placement: "right",
            }}
            icon={<MotifIcon un-i-motif="bell" />}
          />
        </Popover>
      )}
    </ClassNames>
  );
};

const WrapNotificationPanel: React.FC<NotificationPanel.Props> = (props) => {
  return (
    <PopoverSwitchProvider>
      <ErrorBoundary>
        <Suspense fallback={<NarrowIconButton loading />}>
          <NotificationPanelInternal {...props} />
        </Suspense>
      </ErrorBoundary>
    </PopoverSwitchProvider>
  );
};

const NotificationPanel = Object.assign(WrapNotificationPanel, {
  styles,
});

assignDisplayName(NotificationPanel, "NotificationPanel");

export { NotificationPanel };
