import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { Skeleton, Typography } from "antd";

import { fbSdk } from "@/app/fbSdk";
import { EMPTY_STRING_PLACEHOLDER } from "@/appConstant";
import { Alert } from "@/components/Alert";
import { CopyInput } from "@/components/Input";
import { SENTRY_RELEASE } from "@/env";
import { fcm } from "@/internal/firebase/firebaseMessaging";

const Item: React.FC<{
  label: React.ReactNode;
  value: React.ReactNode;
}> = ({ label, value }) => {
  return (
    <>
      <Typography.Title level={5}>{label}</Typography.Title>
      <Typography.Text>{value}</Typography.Text>
    </>
  );
};

const SentryRelease: React.FC = () => {
  return (
    <Item
      label="Sentry Release (SHA)"
      value={<CopyInput value={SENTRY_RELEASE} />}
    />
  );
};

const FcmToken: React.FC = () => {
  const query = fcm.useFcmTokenQuery();
  const requesting = fcm.useDeviceNotificationRequesting();
  return (
    <Item
      label="FCM Token"
      value={
        query.isError ? (
          <Alert type="error" message={inspectMessage`${query.error}`} />
        ) : requesting ? (
          <Skeleton.Input active />
        ) : !query.data ? (
          <>{EMPTY_STRING_PLACEHOLDER}</>
        ) : (
          <CopyInput value={query.data} />
        )
      }
    />
  );
};

const FbToken: React.FC = () => {
  const loginStatusQuery = fbSdk.hooks.queries.useLoginStatusQuery();
  const authResponse = fbSdk.hooks.queries.useAuthResponse();

  return (
    <Item
      label="FB Token"
      value={
        loginStatusQuery.isError ? (
          <Alert
            type="error"
            message={inspectMessage`${loginStatusQuery.error}`}
          />
        ) : loginStatusQuery.isLoading ? (
          <Skeleton.Input active />
        ) : !authResponse || !("accessToken" in authResponse) ? (
          <>{EMPTY_STRING_PLACEHOLDER}</>
        ) : (
          <CopyInput value={authResponse.accessToken} />
        )
      }
    />
  );
};

const UserAgent: React.FC = () => {
  return (
    <Item
      label="User Agent"
      value={<CopyInput value={navigator.userAgent} />}
    />
  );
};

const Info: React.FC = () => {
  return (
    <>
      <SentryRelease />
      <UserAgent />
      <FcmToken />
      <FbToken />
    </>
  );
};

export { Info };
