import type {
  FlexBox,
  FlexButton,
  FlexComponent,
  FlexFiller,
  FlexIcon,
  FlexImage,
  FlexSeparator,
  FlexText,
} from "@line/bot-sdk";
import { noop } from "lodash-es";
import { Children } from "react";

import { FlexButtonComponent } from "@/components/LINEFlexMessage/button";
import { FlexIconComponent } from "@/components/LINEFlexMessage/icon";
import { FlexImageComponent } from "@/components/LINEFlexMessage/image";
import { FlexSeparatorComponent } from "@/components/LINEFlexMessage/separator";
import { classNames } from "@/components/LINEFlexMessage/styled";
import { FlexTextComponent } from "@/components/LINEFlexMessage/text";
import {
  absoluteClassName,
  cornerRadiusClassName,
  flexAlignItemsClassName,
  flexJustifyContentsClassName,
  marginClassName,
  parseFlexBoxStyle,
  spacingClassName,
} from "@/components/LINEFlexMessage/utils";

interface FlexBoxComponentProps {
  parentLayout?: "horizontal" | "vertical";
  data: FlexBox;
}

const canUseInBaselineBox = (
  content: FlexComponent,
): content is FlexIcon | FlexText | FlexFiller =>
  ["icon", "text", "filler"].includes(content.type);

const canUseInLayoutBox = (
  content: FlexComponent,
): content is
  | FlexBox
  | FlexButton
  | FlexImage
  | FlexText
  | FlexSeparator
  | FlexFiller =>
  ["box", "button", "image", "text", "separator", "filler"].includes(
    content.type,
  );

type BaselineBoxProps = { contents: FlexBox["contents"] };

/**
 * Render baseline box contents
 */
const BaselineBox = ({ contents }: BaselineBoxProps) => {
  return (
    <>
      {Children.toArray(
        contents.filter(canUseInBaselineBox).map((content) => {
          switch (content.type) {
            case "icon":
              return <FlexIconComponent data={content} />;
            case "text":
              return (
                <FlexTextComponent parentLayout="baseline" data={content} />
              );
            // case 'filler':
            //   return <FlexFiller data={content} />;
            default:
              /** maybe we can throw ValueError if unavailable type detect.  */
              return null;
          }
        }),
      )}
    </>
  );
};

type LayoutBoxProps = {
  contents: FlexBox["contents"];
  layout: "horizontal" | "vertical";
};

/**
 * Render horizontal/vertical box contents
 */
const LayoutBox = ({ contents, layout }: LayoutBoxProps) => {
  return (
    <>
      {Children.toArray(
        contents.filter(canUseInLayoutBox).map((content) => {
          switch (content.type) {
            case "box":
              return <FlexBoxComponent parentLayout={layout} data={content} />;
            case "button":
              return (
                <FlexButtonComponent parentLayout={layout} data={content} />
              );
            case "image":
              return (
                <FlexImageComponent parentLayout={layout} data={content} />
              );
            case "text":
              return <FlexTextComponent parentLayout={layout} data={content} />;
            case "separator":
              return <FlexSeparatorComponent data={content} />;
            // case 'filler':
            //   return <FlexFillerComponent data={{ ...content }} />;
            default:
              return null;
          }
        }),
      )}
    </>
  );
};

/**
 * Render `Box` component. A box's type determines which components can be used as child elements.
 * check [Available components](https://developers.line.biz/en/docs/messaging-api/flex-message-layout/#component) for more information
 */
export const FlexBoxComponent = ({
  data,
}: FlexBoxComponentProps): React.ReactNode => {
  const {
    layout,
    contents,
    action,
    position,
    margin,
    spacing,
    alignItems,
    justifyContent,
    ...boxStyles
  } = data;
  const style = parseFlexBoxStyle(boxStyles);
  let handleClick = noop;
  if (action?.type === "uri") {
    handleClick = () => window.open(action.uri);
    style.cursor = "pointer";
  }
  return (
    <div
      className={classNames(
        "box",
        layout,
        flexAlignItemsClassName(alignItems),
        flexJustifyContentsClassName(justifyContent),
        spacingClassName(spacing),
        marginClassName(margin, "t"),
        cornerRadiusClassName(boxStyles.cornerRadius),
        absoluteClassName(position),
      )}
      style={style}
      onClick={handleClick}
    >
      {layout === "baseline" ? (
        <BaselineBox contents={contents} />
      ) : (
        <LayoutBox contents={contents} layout={layout} />
      )}
    </div>
  );
};
