import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { OrgSchema } from "@zeffiroso/cantata/models";
import { useMemo } from "react";
import { z } from "zod";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { LOCAL_STORAGE_LAST_INVITATION_MESSAGE } from "@/appConstant";
import { createZustandStorageStore } from "@/shared/utils/createZustandStorageStore";

const MAX_SIZE = 10;

const LastInvitationMessageSchema = z.string();

const LastInvitationMessageEntrySchema = z.object({
  orgId: OrgSchema.shape.id,
  message: LastInvitationMessageSchema,
});

const LastInvitationMessagesSchema = z.array(LastInvitationMessageEntrySchema);

type LastInvitationMessage = z.infer<typeof LastInvitationMessageSchema>;
type LastInvitationMessageEntry = z.infer<
  typeof LastInvitationMessageEntrySchema
>;
type LastInvitationMessages = z.infer<typeof LastInvitationMessagesSchema>;

function parseLastInvitationMessages(input: unknown): LastInvitationMessages {
  // If it's not an array, return an empty array
  if (!Array.isArray(input)) return [];

  // Attempt to parse each entry
  const parsedEntries = input
    .map((entry) => {
      const parseResult = LastInvitationMessageEntrySchema.safeParse(entry);
      return parseResult.success ? parseResult.data : null;
    })
    .filter((entry): entry is LastInvitationMessageEntry => entry !== null);

  return parsedEntries;
}

const { useStore: useInvitationMessageStore } = createZustandStorageStore(
  LOCAL_STORAGE_LAST_INVITATION_MESSAGE,
  parseLastInvitationMessages,
);

export function useLastInvitationMessage() {
  const store = useInvitationMessageStore();
  const orgId = useActiveOrgIdStore((state) => state.value);

  // Find the message for the current orgId
  const lastInvitationMessage = useMemo<
    LastInvitationMessage | undefined
  >(() => {
    const entry = store.value.find((entry) => entry.orgId === orgId);
    return entry?.message;
  }, [orgId, store.value]);

  const setLastInvitationMessage = useHandler(function setLastInvitationMessage(
    value: LastInvitationMessage,
  ) {
    store.setValue((prev) => {
      // Create a new array by removing the existing entry for this orgId (if any)
      const filteredEntries = prev.filter((entry) => entry.orgId !== orgId);

      // Create the new entry
      const newEntry: LastInvitationMessageEntry = {
        orgId,
        message: value,
      };

      // Add the new entry to the beginning of the array
      const newEntries = [newEntry, ...filteredEntries];

      // Limit the array to MAX_SIZE entries
      return newEntries.slice(0, MAX_SIZE);
    });
  });

  const ret = useMemo(
    () => ({
      lastInvitationMessage,
      setLastInvitationMessage,
    }),
    [lastInvitationMessage, setLastInvitationMessage],
  );

  return ret;
}
