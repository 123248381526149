import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import type { FlexImage } from "@line/bot-sdk";

import { classNames } from "@/components/LINEFlexMessage/styled";
import {
  absoluteClassName,
  parseFlexBoxStyle,
  sizeClassName,
} from "@/components/LINEFlexMessage/utils";

interface FlexImageComponentProps {
  parentLayout?: "horizontal" | "vertical";
  data: FlexImage;
}

const calcRatio = (ratio: string | undefined) => {
  if (ratio && /^\d+\.?\d*:\d+\.?\d*$/.test(ratio)) {
    const parts = ratio.split(":");
    if (parts.length === 2) {
      const [width, height] = parts.map(Number);
      if (typeof width !== "number" || typeof height !== "number") {
        throw new TypeError(inspectMessage`Unreachable, ${{ width, height }}`);
      }
      return `${((height / width) * 100).toFixed(1)}%`;
    }
  }
  return undefined;
};

export const FlexImageComponent = ({
  data,
}: FlexImageComponentProps): React.ReactNode => {
  const { aspectRatio, aspectMode, size, position, ...styles } = data;
  const paddingBottom = calcRatio(aspectRatio);
  const style = parseFlexBoxStyle(styles);

  return (
    <div
      className={classNames(
        "image",
        sizeClassName(size),
        aspectMode,
        absoluteClassName(position),
      )}
      style={style}
    >
      <div>
        {/**
         * TODO: Check if this is a bug in the original code.
         * Not sure why the original code has this anchor tag.
         */}
        <a style={{ paddingBottom }}>
          <span
            style={{
              backgroundImage: `url('${data.url}')`,
            }}
          ></span>
        </a>
      </div>
    </div>
  );
};
