import { blobToFile } from "@zeffiroso/utils/file/blobToFile";
import { dataUrlToBlob } from "@zeffiroso/utils/file/dataUrlToBlob";
import type { QRCodeSegment, QRCodeToDataURLOptions } from "qrcode";
import { toDataURL } from "qrcode";

import type { QrCodeType } from "@/components/QrCode/utils/ensureExtname";
import { ensureExtname } from "@/components/QrCode/utils/ensureExtname";

async function toFile(
  text: string | QRCodeSegment[],
  fileName: string,
  options?: QRCodeToDataURLOptions,
) {
  const qrCodeType: QrCodeType = options?.type ?? "image/png";
  const dataUrl = await toDataURL(text, options);
  const blob = await dataUrlToBlob(dataUrl);
  return blobToFile(blob, ensureExtname(fileName, qrCodeType));
}

export { toFile };
